import axios from "axios";
import { SERVER_URL } from "./consts";


export default async function DeleteDBClient(id){

    return await axios.delete(SERVER_URL + '/deleteClient', {data: {id: id}}).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante l\'eliminazione del partecipante!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return true;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}

export async function RemoveDBClientBundle(id){

    return await axios.delete(SERVER_URL + '/removeClientBundle', {data: {idPartecipante: id}}).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante la sottrazione del pacchetto al partecipante!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return true;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}

export async function RemoveDBClientFromRoom(id){

    return await axios.delete(SERVER_URL + '/removeClientFromRoom', {data: {idPartecipante: id}}).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante la rimozione del partecipante dalla camera!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return true;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}

// export async function DeleteDBCollaborator(id){

//     return await axios.delete(SERVER_URL + '/deleteCollaborator', {data: {idCollaboratore: id}}).then((res) => {

//         if(res.status !== 200){
//             alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
//             return false;
//         }

//         if(res.data.err != null){
//             alert('Errore durante l\'eliminazione del collaboratore!\n\nDettagli errore:\n' + res.data.err);
//             return false;
//         }

//         return true;

//     }).catch((err) => {
//         //console.log(err);
//         alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
//         return false;
//     });

// }

export async function DeleteDBAdmin(id){

    return await axios.delete(SERVER_URL + '/deleteAdmin', {data: {idAdmin: id}}).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante l\'eliminazione dell\'admin!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return true;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}

export async function DeleteDBEvent(id){

    return await axios.delete(SERVER_URL + '/deleteEvent', {data: {idEvento: id}}).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante l\'eliminazione dell\'evento!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return true;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}

export async function DeleteAbilitazione(abilitazioneData){

    return await axios.delete(SERVER_URL + '/deleteAbilitazione', {data: abilitazioneData}).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante la disabilitazione del collaboratore!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return true;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}

export async function DeleteDBHotel(id){

    return await axios.delete(SERVER_URL + '/deleteHotel', {data: {idHotel: id}}).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante l\'eliminazione dell\'hotel!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return true;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}

export async function DeleteDBRooms(roomIDs){

    return await axios.delete(SERVER_URL + '/deleteRooms', {data: {idStanze: roomIDs}}).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante l\'eliminazione delle camere!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return true;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}

export async function DeleteDBBundle(id){

    return await axios.delete(SERVER_URL + '/deleteBundle', {data: {idPacchetto: id}}).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante l\'eliminazione del pacchetto!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return true;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}

export async function DeleteDBClients(clientIDs){

    return await axios.delete(SERVER_URL + '/deleteMultipleClients', {data: {idPartecipanti: clientIDs}}).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante l\'eliminazione dei partecipanti!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return true;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}

export async function DeleteDBReservations(reservationIDs){

    return await axios.delete(SERVER_URL + '/deleteReservations', {data: {idPartecipanti: reservationIDs}}).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante l\'eliminazione delle prenotazioni!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return true;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}

export async function DeleteDBTesseramenti(idTesseramenti){

    return await axios.delete(SERVER_URL + '/deleteMultipleTesseramenti', {data: {idTesseramenti: idTesseramenti}}).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante l\'eliminazione dei tesserati!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return true;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}