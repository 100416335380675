import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { AiOutlineClose } from 'react-icons/ai';
import { TextareaAutosize, TextField } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { CreateBundle } from '../../../../serverRequests/postRequests'
import { UpdateDBBundleData } from '../../../../serverRequests/putRequests';

const validationSchema = yup.object({
    nome: yup
    .string('Nome deve essere una stringa')
    .required('Nome obbligatorio')
    .max(50, 'Massimo 50 caratteri'),
    prezzo: yup
    .number('Prezzo deve essere un numero')
    .required('Prezzo obbligatorio')
    .min(0, 'Prezzo deve essere positivo')
    .max(9999.99, 'Massimo 9999.99'),
    dataInizio: yup
    .string('Data Inizio deve essere una stringa')
    .required('Data Inizio obbligatoria'),
    dataFine: yup
    .string('Data Fine deve essere una stringa')
    .required('Data Fine obbligatoria'),
    note: yup
    .string('Note deve essere una stringa')
    .nullable(true)
    .max(255, 'Massimo 255 caratteri'),
});

const noteValidationSchema = yup.object({
    note: yup
    .string('Note deve essere una stringa')
    .nullable(true)
    .max(255, 'Massimo 255 caratteri'),
});

export default function NewAdminBundle({ setCurrentOverlayPage, CreateNewBundle, SetStatusLed }) {

    //Detect mobile device and use default inputs in case
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
            setWidth(window.innerWidth);
        }
    useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);

    let isMobile = (width < 1024);



    const CloseOverlay = () => {
        setCurrentOverlayPage('none');
    }

    const HandleSubmit = async (data, {setSubmitting}) => {

        setSubmitting(true);
        SetStatusLed(true);

        let newBundleData = {
            idEvento: localStorage.getItem('currEventID'),
            nome: data.nome == '' ? null : data.nome,
            prezzo: data.prezzo === '' ? null : data.prezzo,
            dataInizio: data.dataInizio == '' ? null : data.dataInizio,
            dataFine: data.dataFine == '' ? null : data.dataFine,
            note: data.note == '' ? null : data.note
        }

        let newBundleID = await CreateBundle(newBundleData);
        
        setSubmitting(false);
        SetStatusLed(false);


        if (newBundleID) {
            newBundleData.id = newBundleID;
            let endDate = new Date(newBundleData.dataFine);
            let startDate = new Date(newBundleData.dataInizio);
            newBundleData.durata = Math.abs(Math.floor((Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) - Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()) ) /(1000 * 60 * 60 * 24))) + 1;
            newBundleData.dataInizio = newBundleData.dataInizio.split('-').reverse().join('/');
            newBundleData.dataFine = newBundleData.dataFine.split('-').reverse().join('/');
            delete newBundleData.idEvento;
            CreateNewBundle(newBundleData);
            CloseOverlay();
        }
        //Otherwise do nothing (the error displaying is already handled by CreateBundle)

    }

    return (
        <div id='new-admin-bundle-overlay' className='overlay-div'>
            <div className='close-overlay clickable' onClick={CloseOverlay} > <AiOutlineClose /> </div>

            <div className='inner-overlay-div'>
                <h2> Nuovo Pacchetto </h2>
                <div className='overlay-top-divider' />
                
                <div className='overlay-content-div'>
                    <Formik
                        initialValues={{
                            nome:'',
                            prezzo: '',
                            dataInizio:'',
                            dataFine:'',
                            note:''
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(data, {setSubmitting}) => HandleSubmit(data, {setSubmitting})}
                    >

                    {({ errors, touched, isSubmitting }) => (
                        <Form autoComplete='off'>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Nome: </h4><Field className='overlay-text-input' type='text' name='nome' as={TextField} autoFocus/> {touched.nome && errors.nome ? <a className='validation'>{errors.nome}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Prezzo: € </h4><Field className='overlay-text-input' type='number' name='prezzo' as={TextField} /> {touched.prezzo && errors.prezzo ? <a className='validation'>{errors.prezzo}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Data Inizio: </h4><Field className='overlay-text-input' type='date' name='dataInizio' as={isMobile ? '' : TextField} /> {touched.dataInizio && errors.dataInizio ? <a className='validation'>{errors.dataInizio}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Data Fine: </h4><Field className='overlay-text-input' type='date' name='dataFine' as={isMobile ? '' : TextField} /> {touched.dataFine && errors.dataFine ? <a className='validation'>{errors.dataFine}</a> : ''} </div>
                        <div className='overlay-input-field'> <h4 className='overlay-input-key'>Note: </h4><Field className='overlay-date-input' type='text' name='note' as={TextareaAutosize} />{touched.note && errors.note ? <a className='validation'>{errors.note}</a> : ''}</div>
                            <button disabled={isSubmitting} type='submit'> Conferma </button>
                        </Form>
                    )}

                    </Formik>
                </div>
            </div>

        </div>
    )
}

export function ModifyAdminBundleData({ setCurrentOverlayPage, currentlyEditedBundle, pacchetti, ModifyBundle, SetStatusLed }){

    //Detect mobile device and use default inputs in case
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
            setWidth(window.innerWidth);
        }
    useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);

    let isMobile = (width < 1024);



    const [dataError, setDataError] = useState('');

    const CloseOverlay = () => {
        setCurrentOverlayPage('none');
    }

    const HandleSubmit = async (data, {setSubmitting}) => {

        for (let i = 0; i < pacchetti.length; i++){
            if (pacchetti[i].nome == data.nome && pacchetti[i].id != currentlyEditedBundle.id){
                setDataError('Pacchetto con questo nome già esistente in questo evento');
                setSubmitting(false);
                return;
            }
        }

        setSubmitting(true);
        SetStatusLed(true);

        let updatedBundleData = {
            idPacchetto: currentlyEditedBundle.id,
            nome: data.nome == '' ? null : data.nome,
            prezzo: data.prezzo === '' ? null : data.prezzo,
            dataInizio: data.dataInizio == '' ? null : data.dataInizio,
            dataFine: data.dataFine == '' ? null : data.dataFine,
            note: data.note == '' ? null : data.note
        }
        
        let res = await UpdateDBBundleData(updatedBundleData);
        
        setSubmitting(false);
        SetStatusLed(false);


        if (res) {
            updatedBundleData.id = updatedBundleData.idPacchetto;
            let endDate = new Date(updatedBundleData.dataFine);
            let startDate = new Date(updatedBundleData.dataInizio);
            updatedBundleData.durata = Math.abs(Math.floor((Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) - Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()) ) /(1000 * 60 * 60 * 24))) + 1;
            updatedBundleData.dataInizio = updatedBundleData.dataInizio.split('-').reverse().join('/');
            updatedBundleData.dataFine = updatedBundleData.dataFine.split('-').reverse().join('/');
            delete updatedBundleData.idPacchetto;
            ModifyBundle(updatedBundleData);
            CloseOverlay();
        }
        //Otherwise do nothing (the error displaying is already handled by UpdateDBBundleData)

    }

    return (
        <div id='new-admin-bundle-overlay' className='overlay-div'>
            <div className='close-overlay clickable' onClick={CloseOverlay} > <AiOutlineClose /> </div>

            <div className='inner-overlay-div'>
                <h2> Modifica Pacchetto </h2>
                <div className='overlay-top-divider' />
                
                <div className='overlay-content-div'>
                    <Formik
                        initialValues={{
                            nome: currentlyEditedBundle.nome,
                            prezzo: currentlyEditedBundle.prezzo,
                            dataInizio: currentlyEditedBundle.dataInizio.split('/').reverse().join('-'),
                            dataFine: currentlyEditedBundle.dataFine.split('/').reverse().join('-'),
                            note: currentlyEditedBundle.note == null ? '' : currentlyEditedBundle.note
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(data, {setSubmitting}) => HandleSubmit(data, {setSubmitting})}
                    >

                    {({ errors, touched, isSubmitting }) => (
                        <Form autoComplete='off'>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Nome: </h4><Field className='overlay-text-input' type='text' name='nome' as={TextField} autoFocus/> {touched.nome && errors.nome ? <a className='validation'>{errors.nome}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Prezzo: € </h4><Field className='overlay-text-input' type='number' name='prezzo' as={TextField} /> {touched.prezzo && errors.prezzo ? <a className='validation'>{errors.prezzo}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Data Inizio: </h4><Field className='overlay-text-input' type='date' name='dataInizio' as={isMobile ? '' : TextField} /> {touched.dataInizio && errors.dataInizio ? <a className='validation'>{errors.dataInizio}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Data Fine: </h4><Field className='overlay-text-input' type='date' name='dataFine' as={isMobile ? '' : TextField} /> {touched.dataFine && errors.dataFine ? <a className='validation'>{errors.dataFine}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Note: </h4><Field className='overlay-date-input' type='text' name='note' as={TextareaAutosize} />{touched.note && errors.note ? <a className='validation'>{errors.note}</a> : ''}</div>
                            <div className='form-submit-button-div'>
                                <button disabled={isSubmitting} type='submit'> Conferma </button>
                                {dataError !== '' ? <a className='data-error'>{dataError}</a> : ''}
                            </div>
                        </Form>
                    )}

                    </Formik>
                </div>
            </div>

        </div>
    )

}