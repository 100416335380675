import React from 'react'
import { FiMinus, FiPlus } from 'react-icons/fi'


//Takes as parameters the key and value of the main, always visible, field and an array of subfield with key and value for each --> <CollapsableField mainKey={'Pagamento'} mainValue={'Pagato'} subFields=[{key: 'key', value:'val'}, {key: 'key2', value:'val2'}]
export default function CollapsableField({ UpdateReservationData, mainKey, mainValue, subFields }) {

    const ToggleCollapsableDataDiv = (e) => {
        let sections = e.currentTarget.parentElement.parentElement.children;
        let toggleIcons = e.currentTarget.children;

        for (let i = 0; i < sections.length; i++){
            if (sections[i].id == 'sub-fields-div'){
                if (sections[i].className == 'sub-fields-div'){
                    sections[i].className = 'sub-fields-div sub-fields-div-collapsed';
                    for (let i = 0; i < toggleIcons.length; i++){
                        if (toggleIcons[i].className.baseVal == 'plus'){
                            toggleIcons[i].style.display = 'block';
                        } else {
                            toggleIcons[i].style.display = 'none';
                        }
                    }
                } else {
                    sections[i].className = 'sub-fields-div';
                    for (let i = 0; i < toggleIcons.length; i++){
                        if (toggleIcons[i].className.baseVal == 'minus'){
                            toggleIcons[i].style.display = 'block';
                        } else {
                            toggleIcons[i].style.display = 'none';
                        }
                    }
                }
            }
            if (sections[i].id == 'vertical-line'){
                if (sections[i].className == 'vertical-line'){
                    sections[i].className = 'vertical-line vertical-line-collapsed';
                    for (let i = 0; i < toggleIcons.length; i++){
                        if (toggleIcons[i].className.baseVal == 'plus'){
                            toggleIcons[i].style.display = 'block';
                        } else {
                            toggleIcons[i].style.display = 'none';
                        }
                    }
                } else {
                    sections[i].className = 'vertical-line';
                    for (let i = 0; i < toggleIcons.length; i++){
                        if (toggleIcons[i].className.baseVal == 'minus'){
                            toggleIcons[i].style.display = 'block';
                        } else {
                            toggleIcons[i].style.display = 'none';
                        }
                    }
                }
            }
        }
    }
    
    return (
        <div className='collapsable-data-field'>
            <div className='toggle-collapsable-data-field'> <button className='toggle-button' onClick={e => ToggleCollapsableDataDiv(e)}> <FiPlus className='plus' /> <FiMinus className='minus' /> </button> </div>
            <div className='data-field main-data-field'>
                <h4 className='data-field-key'> {mainKey}: </h4>
                <div className='data-field-value'>
                    <h4> {mainValue} </h4>
                </div>
            </div>
            <div id='vertical-line' className='vertical-line vertical-line-collapsed' />
            <div id='sub-fields-div' className='sub-fields-div sub-fields-div-collapsed'>
                {subFields.map( field => {
                    if (field.fixed){
                        return(
                            <div className='data-field fixed-sub-field'>
                                <h4 className='data-field-key'> {field.key}: </h4>
                                <div className='data-field-value'>
                                    {field.isPeopleList && field.value != null &&
                                        <ul>
                                            {field.value.map(person => {
                                                return(
                                                    <li>
                                                        <h4
                                                            onClick={person.clickable ? () => {UpdateReservationData(person.idPartecipante)} : null}
                                                            style={person.clickable ? {textDecoration: 'underline', cursor: 'pointer'} : null}
                                                        >
                                                            {person.cognome} {person.nome}
                                                        </h4>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    }

                                    {!field.isPeopleList && (field.value == null || field.value === '') &&
                                        <h4> N/P </h4>
                                    }

                                    {!field.isPeopleList && field.value != null && field.value !== '' &&
                                        <h4> {field.value} </h4>
                                    }
                                </div>
                            </div>
                        )
                    } else{
                        return(
                            <div className='data-field'>
                                <h4 className='data-field-key'> {field.key}: </h4>
                                <div className='data-field-value'>
                                    {field.isPeopleList && field.value != null &&
                                        <ul>
                                            {field.value.map(person => {
                                                return(
                                                    <li>
                                                        <h4
                                                            onClick={person.clickable ? () => {UpdateReservationData(person.idPartecipante)} : null}
                                                            style={person.clickable ? {textDecoration: 'underline', cursor: 'pointer'} : null}
                                                        >
                                                            {person.cognome} {person.nome}
                                                        </h4>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    }

                                    {!field.isPeopleList && (field.value == null || field.value === '') &&
                                        <h4> N/P </h4>
                                    }

                                    {!field.isPeopleList && field.value != null && field.value !== '' &&
                                        <h4> {field.value} </h4>
                                    }
                                </div>
                            </div>
                        )
                    }
                }
                )}

            </div>

        </div>
    )
}
