import { TextareaAutosize, TextField } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai';
import * as yup from 'yup'
import { CreateEvent, CopyDBEvent } from '../../../../serverRequests/postRequests'
import { UpdateDBEventData } from '../../../../serverRequests/putRequests'


const validationSchema = yup.object({
    nome: yup
    .string('Nome deve essere una stringa')
    .required('Nome obbligatorio')
    .max(100, 'Massimo 100 caratteri'),
    luogo: yup
    .string('Luogo deve essere una stringa')
    .required('Luogo obbligatorio')
    .max(255, 'Massimo 255 caratteri'),
    dataInizio: yup
    .string('Data Inizio deve essere una stringa')
    .required('Data Inizio obbligatorio'),
    dataFine: yup
    .string('Data Fine deve essere una stringa')
    .required('Data Fine obbligatorio'),
    descrizione: yup
    .string('Descrizione deve essere una stringa')
    .nullable(true)
    .max(255, 'Massimo 255 caratteri'),
});

export default function NewEvent({ setCurrentOverlayPage, CreateNewEvent, SetStatusLed }) {

    //Detect mobile device and use default inputs in case
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
            setWidth(window.innerWidth);
        }
    useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);

    let isMobile = (width < 1024);

    const CloseOverlay = () => {
        setCurrentOverlayPage('none');
    }

    const HandleSubmit = async (data, {setSubmitting}) => {

        setSubmitting(true);
        SetStatusLed(true);

        let newEventData = {
            nomeEvento: data.nome == '' ? null : data.nome,
            luogoEvento: data.luogo == '' ? null : data.luogo,
            dataInizio: data.dataInizio == '' ? null : data.dataInizio,
            dataFine: data.dataFine == '' ? null : data.dataFine,
            descrizione: data.descrizione == '' ? null : data.descrizione
        }

        let newEventID = await CreateEvent(newEventData);
        
        setSubmitting(false);
        SetStatusLed(false);


        if (newEventID) {
            data.id = newEventID;
            data.dataInizio = data.dataInizio.split('-').reverse().join('/');
            data.dataFine = data.dataFine.split('-').reverse().join('/');
            CreateNewEvent(data);
            setCurrentOverlayPage('none');
        }
        //Otherwise do nothing (the error displaying is already handled by CreateEvent)

    }

    return (
        <div id='new-event-overlay' className='overlay-div'>
            <div className='close-overlay clickable' onClick={CloseOverlay} > <AiOutlineClose /> </div>

            <div className='inner-overlay-div'>
                <h2> Nuovo Evento </h2>
                <div className='overlay-top-divider' />
                
                <div className='overlay-content-div'>
                    <Formik
                        initialValues={{
                            nome:'',
                            luogo:'',
                            dataInizio:'',
                            dataFine:'',
                            descrizione:''
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(data, {setSubmitting}) => HandleSubmit(data, {setSubmitting})}
                    >

                    {({ errors, touched, isSubmitting }) => (
                        <Form autoComplete='off'>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Nome: </h4><Field className='overlay-text-input' type='text' name='nome' as={TextField} autoFocus/> {touched.nome && errors.nome ? <a className='validation'>{errors.nome}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Luogo: </h4><Field className='overlay-text-input' type='text' name='luogo' as={TextField} /> {touched.luogo && errors.luogo ? <a className='validation'>{errors.luogo}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Data Inizio: </h4><Field className='overlay-date-input' type='date' name='dataInizio' as={isMobile ? '' : TextField} /> {touched.dataInizio && errors.dataInizio ? <a className='validation'>{errors.dataInizio}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Data Fine: </h4><Field className='overlay-date-input' type='date' name='dataFine' as={isMobile ? '' : TextField} /> {touched.dataFine && errors.dataFine ? <a className='validation'>{errors.dataFine}</a> : ''} </div>
                            
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Note: </h4><Field className='overlay-date-input' type='text' name='descrizione' as={TextareaAutosize} />{touched.descrizione && errors.descrizione ? <a className='validation'>{errors.descrizione}</a> : ''}</div>

                            <div className='form-submit-button-div'>
                            <button disabled={isSubmitting} type='submit'> Conferma </button>
                            </div>
                        </Form>
                    )}

                    </Formik>
                </div>
            </div>
            
        </div>
    )
}

export function ModifyEventData({ setCurrentOverlayPage, ModifyEvent, currentlyEditedEvent, SetStatusLed }) {

    //Detect mobile device and use default inputs in case
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
            setWidth(window.innerWidth);
        }
    useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);

    let isMobile = (width < 1024);



    const CloseOverlay = () => {
        setCurrentOverlayPage('none');
    }

    const HandleSubmit = async (data, {setSubmitting}) => {

        setSubmitting(true);
        SetStatusLed(true);

        let updatedEventData = {
            idEvento: currentlyEditedEvent.id,
            nomeEvento: data.nome == '' ? null : data.nome,
            luogoEvento: data.luogo == '' ? null : data.luogo,
            dataInizio: data.dataInizio == '' ? null : data.dataInizio,
            dataFine: data.dataFine == '' ? null : data.dataFine,
            descrizione: data.descrizione == '' ? null : data.descrizione
        }

        let res = await UpdateDBEventData(updatedEventData);
        
        setSubmitting(false);
        SetStatusLed(false);


        if (res) {
            let updatedEvent = currentlyEditedEvent;
            updatedEvent.nome = data.nome;
            updatedEvent.luogo = data.luogo;
            updatedEvent.dataInizio = data.dataInizio.split('-').reverse().join('/');
            updatedEvent.dataFine = data.dataFine.split('-').reverse().join('/');
            updatedEvent.descrizione = data.descrizione;
            ModifyEvent(updatedEvent);
            setCurrentOverlayPage('none');
        }
        //Otherwise do nothing (the error displaying is already handled by UpdateDBEventData)

    }

    return (
        <div id='modify-event-data-overlay' className='overlay-div'>
            <div className='close-overlay clickable' onClick={CloseOverlay} > <AiOutlineClose /> </div>

            <div className='inner-overlay-div'>
                <h2> Modifica Evento </h2>
                <div className='overlay-top-divider' />
                
                <div className='overlay-content-div'>
                    <Formik
                        initialValues={{
                            nome: currentlyEditedEvent.nome,
                            luogo: currentlyEditedEvent.luogo,
                            dataInizio: currentlyEditedEvent.dataInizio.split('/').reverse().join('-'),
                            dataFine: currentlyEditedEvent.dataFine.split('/').reverse().join('-'),
                            descrizione: currentlyEditedEvent.descrizione
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(data, {setSubmitting}) => HandleSubmit(data, {setSubmitting})}
                    >

                    {({ errors, touched, isSubmitting }) => (
                        <Form autoComplete='off'>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Nome: </h4><Field className='overlay-text-input' type='text' name='nome' as={TextField} autoFocus/> {touched.nome && errors.nome ? <a className='validation'>{errors.nome}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Luogo: </h4><Field className='overlay-text-input' type='text' name='luogo' as={TextField} /> {touched.luogo && errors.luogo ? <a className='validation'>{errors.luogo}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Data Inizio: </h4><Field className='overlay-date-input' type='date' name='dataInizio' as={isMobile ? '' : TextField} /> {touched.dataInizio && errors.dataInizio ? <a className='validation'>{errors.dataInizio}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Data Fine: </h4><Field className='overlay-date-input' type='date' name='dataFine' as={isMobile ? '' : TextField} /> {touched.dataFine && errors.dataFine ? <a className='validation'>{errors.dataFine}</a> : ''} </div>
                            
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Note: </h4><Field className='overlay-date-input' type='text' name='descrizione' as={TextareaAutosize} />{touched.descrizione && errors.descrizione ? <a className='validation'>{errors.descrizione}</a> : ''}</div>

                            <div className='form-submit-button-div'>
                            <button disabled={isSubmitting} type='submit'> Conferma </button>
                            </div>
                        </Form>
                    )}

                    </Formik>
                </div>
            </div>
            
        </div>
    )
}

export function CopyEventData({ setCurrentOverlayPage, CopyEvent, currentlyEditedEvent, AdminID, SetStatusLed }) {

    //Detect mobile device and use default inputs in case
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
            setWidth(window.innerWidth);
        }
    useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);

    let isMobile = (width < 1024);



    const CloseOverlay = () => {
        setCurrentOverlayPage('none');
    }

    const HandleSubmit = async (data, {setSubmitting}) => {

        setSubmitting(true);
        SetStatusLed(true);

        let eventCopyData = {
            idEventToCopy: currentlyEditedEvent.id,
            nomeEvento: data.nome == '' ? null : data.nome,
            luogoEvento: data.luogo == '' ? null : data.luogo,
            dataInizio: data.dataInizio == '' ? null : data.dataInizio,
            dataFine: data.dataFine == '' ? null : data.dataFine,
            descrizione: data.descrizione == '' ? null : data.descrizione
        }

        let newEventID = await CopyDBEvent(eventCopyData);
        
        setSubmitting(false);
        SetStatusLed(false);


        if (newEventID) {
            data.id = newEventID;
            data.dataInizio = data.dataInizio.split('-').reverse().join('/');
            data.dataFine = data.dataFine.split('-').reverse().join('/');
            CopyEvent(data);
            setCurrentOverlayPage('none');
        }
        //Otherwise do nothing (the error displaying is already handled by CopyDBEvent)

    }

    return (
        <div id='copy-event-data-overlay' className='overlay-div'>
            <div className='close-overlay clickable' onClick={CloseOverlay} > <AiOutlineClose /> </div>

            <div className='inner-overlay-div'>
                <h2> Copia Evento </h2>
                <div className='overlay-top-divider' />
                
                <div className='overlay-content-div'>
                    <Formik
                        initialValues={{
                            nome: currentlyEditedEvent.nome,
                            luogo: currentlyEditedEvent.luogo,
                            dataInizio: currentlyEditedEvent.dataInizio.split('/').reverse().join('-'),
                            dataFine: currentlyEditedEvent.dataFine.split('/').reverse().join('-'),
                            descrizione: currentlyEditedEvent.descrizione
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(data, {setSubmitting}) => HandleSubmit(data, {setSubmitting})}
                    >

                    {({ errors, touched, isSubmitting }) => (
                        <Form autoComplete='off'>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Nome: </h4><Field className='overlay-text-input' type='text' name='nome' as={TextField} autoFocus/> {touched.nome && errors.nome ? <a className='validation'>{errors.nome}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Luogo: </h4><Field className='overlay-text-input' type='text' name='luogo' as={TextField} /> {touched.luogo && errors.luogo ? <a className='validation'>{errors.luogo}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Data Inizio: </h4><Field className='overlay-date-input' type='date' name='dataInizio' as={isMobile ? '' : TextField} /> {touched.dataInizio && errors.dataInizio ? <a className='validation'>{errors.dataInizio}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Data Fine: </h4><Field className='overlay-date-input' type='date' name='dataFine' as={isMobile ? '' : TextField} /> {touched.dataFine && errors.dataFine ? <a className='validation'>{errors.dataFine}</a> : ''} </div>
                            
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Note: </h4><Field className='overlay-date-input' type='text' name='descrizione' as={TextareaAutosize} />{touched.descrizione && errors.descrizione ? <a className='validation'>{errors.descrizione}</a> : ''}</div>

                            <div className='form-submit-button-div'>
                            <button disabled={isSubmitting} type='submit'> Conferma </button>
                            </div>
                        </Form>
                    )}

                    </Formik>
                </div>
            </div>
            
        </div>
    )
}
