import React, { useEffect, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai';
import { ChoiceRoom } from '../room'
import { Form, Formik, Field } from 'formik';
import * as yup from 'yup'
import { Masonry } from 'react-masonry-responsive';
import { TextareaAutosize, TextField } from '@material-ui/core';
import { OverviewBundle } from '../bundle';
import { ConfirmStay, ChangeStay } from '../stay';
import { AddClientWithBundleToRoom } from '../../../../serverRequests/postRequests';
import { UpdatePernottamentoData } from '../../../../serverRequests/putRequests';
import CollaboratorUpdateRoomData from '../../../../serverRequests/patchRequests';
import { BiIdCard } from 'react-icons/bi';

const validationSchema = yup.object({
    dataIn: yup
    .string('Data In deve essere una stringa')
    .required('Data In obbligatoria'),
    dataOut: yup
    .string('Data Out deve essere una stringa')
    .required('Data Out obbligatoria'),
});

const noteValidationSchema = yup.object({
    note: yup
    .string('Le note devono essere una stringa')
    .nullable(true)
    .max(255, 'Massimo 255 caratteri')
});

export default function SelectRoom({ setCurrentOverlayPage, currentlyEditedClient, stanze, SetClientRoom, roomTypes, SetStatusLed }) {

    //Detect mobile device and use default inputs in case
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
            setWidth(window.innerWidth);
        }
    useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);

    let isMobile = (width < 1024);

    const [isSubmitting, setSubmitting] = useState(false);
    let [scelteStanza, setScelteStanza] = useState([...stanze]);
    const [roomGroups, setRoomGroups] = useState([]);
    const [roomsGroupingKey, setRoomsGroupingKey] = useState('noGrouping');
    const [roomsOrderingKey, setRoomsOrderingKey] = useState('numero');
    const [roomsSearchResults, setRoomsSearchResults] = useState([...stanze]);

    let [stanzaScelta, setStanzaScelta] = useState();

    const CloseOverlay = () => {
        setCurrentOverlayPage('none');
    }

    const GoBackToRoomChoice = () => {
        let sortedRooms = [...stanze];
        
        sortedRooms.sort((a, b) => {
            let keyA = a.numero, keyB = b.numero;
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
        });

        setRoomsSearchResults([...sortedRooms]);
        setStanzaScelta(null);
    }

    const HandleSelectRoom = async (data, {setSubmitting}) => {

        setSubmitting(true);
        SetStatusLed(true);

        
        let pernottamentoData = {
            idPartecipante: currentlyEditedClient.id,
            idStanza: stanzaScelta.id,
            dataIn: data.dataIn == '' ? null : data.dataIn,
            dataOut: data.dataOut == '' ? null : data.dataOut
        }
        let res = false;

        if (currentlyEditedClient.idStanza == '' || currentlyEditedClient.idStanza == null){

            res = await AddClientWithBundleToRoom(pernottamentoData);

        } else {

            res = await UpdatePernottamentoData(pernottamentoData);

        }
        
        setSubmitting(false);
        SetStatusLed(false);


        if (res) {
            let stanza = stanzaScelta;
            stanza.dataIn = data.dataIn.split('-').reverse().join('/');
            stanza.dataOut = data.dataOut.split('-').reverse().join('/');

            SetClientRoom(stanza);
            CloseOverlay();
        }
        //Otherwise do nothing (the error displaying is already handled by UpdateDBClientData)

    }

    const handleOrderRoomsByClick = (e) => {
        let sortedRooms = [...scelteStanza];
        let sortedShownRooms = [...roomsSearchResults];
        let orderRoomsBy = e.target.value;

        if (orderRoomsBy === 'numero'){

            setRoomsOrderingKey(orderRoomsBy);
            sortedRooms.sort((a, b) => {
                let keyA = a.numero, keyB = b.numero;
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });
            sortedShownRooms.sort((a, b) => {
                let keyA = a.numero, keyB = b.numero;
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

        } else if (orderRoomsBy === 'liberi') {

            setRoomsOrderingKey(orderRoomsBy);
            sortedRooms.sort((a, b) => {
                let keyA = a.capienza - a.postiOccupati, keyB = b.capienza - b.postiOccupati;
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });
            sortedShownRooms.sort((a, b) => {
                let keyA = a.capienza - a.postiOccupati, keyB = b.capienza - b.postiOccupati;
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

        } else if (orderRoomsBy === 'capienza') {

            setRoomsOrderingKey(orderRoomsBy);
            sortedRooms.sort((a, b) => {
                let keyA = a.capienza, keyB = b.capienza;
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });
            sortedShownRooms.sort((a, b) => {
                let keyA = a.capienza, keyB = b.capienza;
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

        }

        setScelteStanza([...sortedRooms]);
        setRoomsSearchResults([...sortedShownRooms]);

    }

    const handleGroupRoomsByClick = (e) => {
        let newGroups = [];
        let groupRoomsBy = e.target.value;
        
        if (groupRoomsBy === 'tipo') {

            setRoomsGroupingKey(groupRoomsBy);
            scelteStanza.forEach(stanza => {
                if (!(newGroups.includes(stanza.tipo))){
                    newGroups.push(stanza.tipo);
                }
            });
            newGroups.sort((a, b) => {
                let keyA, keyB;
                for (let i = 0; i < roomTypes.length; i++){
                    if (roomTypes[i].tipo == a) {keyA=roomTypes[i].capienza};
                    if (roomTypes[i].tipo == b) {keyB=roomTypes[i].capienza};
                }
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });
            setRoomGroups([...newGroups]);

        } else if (groupRoomsBy === 'nomeHotel') {

            setRoomsGroupingKey(groupRoomsBy);
            scelteStanza.forEach(stanza => {
                if (!(newGroups.includes(stanza.nomeHotel))){
                    newGroups.push(stanza.nomeHotel);
                }
            });
            newGroups.sort();
            setRoomGroups([...newGroups]);

        } else { //default to 'noGrouping'

            setRoomsGroupingKey('noGrouping');
            setRoomGroups([]);

        }

    }
    
    const SortShownRooms = (roomsToSort) => {
        
        if (roomsOrderingKey === 'numero'){
            roomsToSort.sort((a, b) => {
                let keyA = a.numero, keyB = b.numero;
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

        } else if (roomsOrderingKey === 'liberi') {
            roomsToSort.sort((a, b) => {
                let keyA = a.capienza - a.postiOccupati, keyB = b.capienza - b.postiOccupati;
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

        } else if (roomsOrderingKey === 'capienza') {
            roomsToSort.sort((a, b) => {
                let keyA = a.capienza, keyB = b.capienza;
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

        }

        return roomsToSort;
    }

    const handleChangeClientName = (e, changeHandle) => {

        changeHandle(e);
        let newShownRooms = [];

        if (e.target.value == null || e.target.value == ''){
            newShownRooms = [...stanze];
        } else{

            for (let i = 0; i < stanze.length; i++){

                if (stanze[i].collocati == null) continue;
                if (stanze[i].collocati.length <= 0) continue;

                for (let c = 0; c < stanze[i].collocati.length; c++){
                    let collocatoFullName = stanze[i].collocati[c].cognome == null ? stanze[i].collocati[c].nome : stanze[i].collocati[c].cognome + ' ' + stanze[i].collocati[c].nome;

                    if(collocatoFullName.toLowerCase().includes(e.target.value.toLowerCase())){
                        newShownRooms.push(stanze[i]);
                        break;
                    }

                }

            }

        }

        newShownRooms = SortShownRooms(newShownRooms);
        setRoomsSearchResults([...newShownRooms]);

    }

    useEffect(() => {
        let sortedRooms = [...roomsSearchResults];
        
        sortedRooms.sort((a, b) => {
            let keyA = a.numero, keyB = b.numero;
            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
        });

        setRoomsSearchResults([...sortedRooms]);

    }, [])
    

    return (
        <div id='select-room-overlay' className='overlay-div'>
            <div className='close-overlay clickable' onClick={CloseOverlay} > <AiOutlineClose /> </div>

            <div className='inner-overlay-div'>
                <h2> {currentlyEditedClient.cognome} {currentlyEditedClient.nome} {currentlyEditedClient.tesserato ? <BiIdCard className='tesserato-icon' title='Partecipante Tesserato'/> : null}</h2>
                <div className='overlay-top-divider' />


                {stanzaScelta == null &&
                    <div className='overlay-subtitle'>
                        <h3 className='overlay-subtitle-text'> Seleziona Camera </h3>
                        <div className='overlay-subtitle-divider' />
                        <h5 className='clickable' onClick={CloseOverlay}> o Seleziona in un secondo momento </h5>
                    </div>
                }

                {stanzaScelta == null &&
                    <div className='select-room-commands'>

                        <div className='select-room-find-by-client'>
                            <h3>Ricerca per partecipante: </h3>
                            <Formik
                                initialValues = {{clientName: ''}}
                                onSubmit = { () => {}}
                            >
                                {({ handleChange }) => (
                                    <Form>
                                        <Field id='find-by-name-input' className='text-input' type='text' name='clientName' onChange={(e) => handleChangeClientName(e, handleChange)} />
                                    </Form>
                                )}
                            </Formik>
                        </div>

                        <div className='select-room-radio-commands'>
                            <div className='select-room-order-rooms'>
                                <h3>Ordina per: </h3>
                                <Formik
                                    initialValues={{
                                        orderRoomsBy: roomsOrderingKey,
                                    }}
                                >
                                    {() => (
                                        <Form>
                                            <label> <Field type='radio' name='orderRoomsBy' value='numero' onClick={handleOrderRoomsByClick} /> <a>{'Numero di camera'}</a> </label>
                                            <label> <Field type='radio' name='orderRoomsBy' value='liberi' onClick={handleOrderRoomsByClick} /> <a>{'Posti liberi'}</a> </label>
                                            <label> <Field type='radio' name='orderRoomsBy' value='capienza' onClick={handleOrderRoomsByClick} /> <a>{'Capienza massima'}</a> </label>
                                        </Form>
                                    )}
                                </Formik>
                            </div>

                            <div className='select-room-group-rooms'>
                                <h3>Raggruppa per: </h3>
                                <Formik
                                    initialValues={{
                                        groupRoomsBy: roomsGroupingKey,
                                    }}
                                >
                                    {() => (
                                        <Form>
                                            <label> <Field type='radio' name='groupRoomsBy' value='nomeHotel' onClick={handleGroupRoomsByClick} /> <a>{'Hotel'}</a> </label>
                                            <label> <Field type='radio' name='groupRoomsBy' value='tipo' onClick={handleGroupRoomsByClick} /> <a>{'Tipo di camera'}</a> </label>
                                            <label> <Field type='radio' name='groupRoomsBy' value='noGrouping' onClick={handleGroupRoomsByClick} /> <a>{'Non raggruppare'}</a> </label>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                        
                    </div>
                }
                    
                {stanzaScelta == null && <div className='overlay-choice-list-top-fader'></div> }
                {stanzaScelta == null &&
                <div className='overlay-choice-list'>

                    {roomGroups.length <= 0 && roomsSearchResults.filter(stanza => !stanza.piena || currentlyEditedClient.idStanza == stanza.id).length > 0 && //No grouping
                        <div className='grid-content-div no-padding'>
                            {
                            roomsSearchResults.filter(stanza => !stanza.piena || currentlyEditedClient.idStanza == stanza.id).map(stanza =>
                                <div key={stanza.id} className='room-choice'>
                                    <ChoiceRoom {...stanza} />
                                    {(currentlyEditedClient.idStanza == null || currentlyEditedClient.idStanza != stanza.id) &&
                                        <button onClick={() => setStanzaScelta(stanza)} disabled={isSubmitting} className='select-room-button' > Seleziona Camera </button>
                                    }
                                    {currentlyEditedClient.idStanza == stanza.id &&
                                        <div className='already-selected-room clickable' onClick={() => setStanzaScelta(stanza)} ><h4> Mantieni camera </h4></div>
                                    }
                                </div>
                            )
                            }
                        </div>

                        // <Masonry
                        //     items={
                        //         roomsSearchResults.filter(stanza => !stanza.piena || currentlyEditedClient.idStanza == stanza.id).map(stanza => {
                        //             return(
                        //                 {key: stanza.id, node:
                        //                     <div className='room-choice'>
                        //                         <ChoiceRoom {...stanza} />
                        //                         {(currentlyEditedClient.idStanza == null || currentlyEditedClient.idStanza != stanza.id) &&
                        //                             <button onClick={() => setStanzaScelta(stanza)} disabled={isSubmitting} className='select-room-button' > Seleziona Camera </button>
                        //                         }
                        //                         {currentlyEditedClient.idStanza == stanza.id &&
                        //                             <div className='already-selected-room clickable' onClick={() => setStanzaScelta(stanza)} ><h4> Mantieni camera </h4></div>
                        //                         }
                        //                     </div>
                        //                 }
                                        
                        //             )
                        //         })
                        //     }
                        //     minColumnWidth={272}
                        //     gap={16}
                        // />
                    }

                    {roomGroups.length <= 0 && roomsSearchResults.filter(stanza => !stanza.piena || currentlyEditedClient.idStanza == stanza.id).length <= 0 && //No grouping
                        <h4 className='no-room-found'>Nessuna stanza libera trovata!</h4>
                    }


                    {roomGroups.length > 0 && //Standard key-value grouping
                        roomGroups.filter(groupName => scelteStanza.filter(stanza => (!stanza.piena || currentlyEditedClient.idStanza == stanza.id) && stanza[roomsGroupingKey] == groupName).length > 0).map( groupName => {
                            return(
                                <div className='groups-div'>
                                    <div className='group-div'>
                                        <h3> {groupName} </h3>
                                        <div className='group-divider' />

                                        <div className='grid-content-div no-padding'>
                                            {
                                            scelteStanza.filter(stanza => (!stanza.piena || currentlyEditedClient.idStanza == stanza.id) && stanza[roomsGroupingKey] == groupName).map(stanza =>
                                                <div key={stanza.id} className='room-choice'>
                                                    <ChoiceRoom {...stanza} />
                                                    {(currentlyEditedClient.idStanza == null || currentlyEditedClient.idStanza != stanza.id) &&
                                                        <button onClick={() => setStanzaScelta(stanza)} disabled={isSubmitting} className='select-room-button' > Seleziona Camera </button>
                                                    }
                                                    {currentlyEditedClient.idStanza == stanza.id &&
                                                        <div className='already-selected-room clickable' onClick={() => setStanzaScelta(stanza)} ><h4> Mantieni camera </h4></div>
                                                    }
                                                </div>
                                            )
                                            }
                                        </div>

                                        {/* <Masonry
                                            items={
                                                scelteStanza.filter(stanza => (!stanza.piena || currentlyEditedClient.idStanza == stanza.id) && stanza[roomsGroupingKey] == groupName).map(stanza => {
                                                    return(
                                                        {key: stanza.id, node:
                                                            <div className='room-choice'>
                                                                <ChoiceRoom {...stanza} />
                                                                {(currentlyEditedClient.idStanza == null || currentlyEditedClient.idStanza != stanza.id) &&
                                                                    <button onClick={() => setStanzaScelta(stanza)} disabled={isSubmitting} className='select-room-button' > Seleziona Camera </button>
                                                                }
                                                                {currentlyEditedClient.idStanza == stanza.id &&
                                                                    <div className='already-selected-room clickable' onClick={() => setStanzaScelta(stanza)} ><h4> Mantieni camera </h4></div>
                                                                }
                                                            </div>
                                                        }
                                                        
                                                    )
                                                })
                                            }
                                            minColumnWidth={272}
                                            gap={16}
                                        /> */}
                                    </div>
                                </div>
                            )
                        })
                    }
                    

                </div>
                } 

                {stanzaScelta != null &&
                    <div className='overlay-subtitle'>
                        <h3 className='overlay-subtitle-text'> Pernottamento </h3>
                        <div className='overlay-subtitle-divider' />
                    </div>
                }

                {stanzaScelta != null &&
                    <div className='overlay-stay-content-div'>
                        <div className='stay-content-chosen-room'>
                            <h4> Camera: {stanzaScelta.codice} - {stanzaScelta.nomeHotel}  </h4><a>-</a><a className='clickable' onClick={GoBackToRoomChoice}>Modifica</a>
                        </div>

                        <Formik
                            initialValues={{
                                dataIn: currentlyEditedClient.dataInizioPacchetto == null ? '' : currentlyEditedClient.dataInizioPacchetto.split('/').reverse().join('-'),
                                dataOut: currentlyEditedClient.dataFinePacchetto == null ? '' : currentlyEditedClient.dataFinePacchetto.split('/').reverse().join('-')
                            }}
                            validationSchema={validationSchema}
                            onSubmit={(data, {setSubmitting}) => HandleSelectRoom(data, {setSubmitting})}
                        >

                        {({ errors, touched, isSubmitting }) => (
                            <Form autoComplete='off'>
                                <div className='overlay-input-field'> <h4 className='overlay-input-key'>Data In: </h4><Field className='overlay-date-input' type='date' name='dataIn' as={isMobile ? '' : TextField} autoFocus/> {touched.dataIn && errors.dataIn ? <a className='validation'>{errors.dataIn}</a> : ''} </div>
                                <div className='overlay-input-field'> <h4 className='overlay-input-key'>Data Out: </h4><Field className='overlay-date-input' type='date' name='dataOut' as={isMobile ? '' : TextField} /> {touched.dataOut && errors.dataOut ? <a className='validation'>{errors.dataOut}</a> : ''} </div>
                                <button disabled={isSubmitting} type='submit'> Conferma </button>
                            </Form>
                        )}

                        </Formik>

                    </div>
                }

            </div>
            
        </div>
    )

}

export function DefineStay({stayingClient, ConfirmClientStay }){
    return (
        <div className='overlay-content-div'>
            <h3 className='room-stays-subtitle'> {stayingClient.cognome} {stayingClient.nome} {stayingClient.tesserato ? <BiIdCard className='tesserato-icon' title='Partecipante Tesserato'/> : null}</h3>
            <div className='overlay-top-divider' />
            
                <div className='define-stay-content'>
                    <OverviewBundle
                        nome={stayingClient.nomePacchetto} 
                        prezzo={stayingClient.prezzoPacchetto} 
                        durata={stayingClient.durataPacchetto} 
                        dataInizio={stayingClient.dataInizioPacchetto} 
                        dataFine={stayingClient.dataFinePacchetto} 
                        note={stayingClient.notePacchetto}
                        modifyButtonActive={false}
                    />
                    <ConfirmStay
                        dataIn={stayingClient.dataInizioPacchetto}
                        dataOut={stayingClient.dataFinePacchetto}
                        ConfirmClientStay={ConfirmClientStay}
                    />
                </div>

        </div>
    )
}

export function ChangeStayingClientStay({clientToChangeStayOf, ModifyClientStay, idStanza, SetStatusLed }) {
    return (
        <div className='overlay-content-div'>
            <h3 className='room-stays-subtitle'> {clientToChangeStayOf.cognome} {clientToChangeStayOf.nome} {clientToChangeStayOf.tesserato ? <BiIdCard className='tesserato-icon' title='Partecipante Tesserato'/> : null}</h3>
            <div className='overlay-top-divider' />
            
                <div className='define-stay-content'>
                    <ChangeStay
                        dataIn={clientToChangeStayOf.dataIn}
                        dataOut={clientToChangeStayOf.dataOut}
                        ModifyClientStay={ModifyClientStay}
                        idPartecipante={clientToChangeStayOf.id}
                        idStanza={idStanza}
                        SetStatusLed={SetStatusLed}
                    />
                </div>

        </div>
    )
}

export function ChangeRoomNotes({ setCurrentOverlayPage, currentlyEditedRoom, UpdateRoomNotes, SetStatusLed }){

    const CloseOverlay = () => {
        setCurrentOverlayPage('none');
    }

    const HandleChangeRoomNotes = async (data, {setSubmitting}) => {

        setSubmitting(true);
        SetStatusLed(true);

        let updatedRoomData = {
            idStanza: currentlyEditedRoom.id,
            piena: currentlyEditedRoom.piena,
            matrimoniale: currentlyEditedRoom.matrimoniale,
            note: data.note == '' ? null : data.note
        }

        let res = await CollaboratorUpdateRoomData(updatedRoomData);
        
        setSubmitting(false);
        SetStatusLed(false);


        if (res) {
            UpdateRoomNotes(data.note);
            CloseOverlay();
        }
        //Otherwise do nothing (the error displaying is already handled by CollaboratorUpdateRoomData)

    }

    return (
        <div id='change-room-notes-overlay' className='overlay-div'>
            <div className='close-overlay clickable' onClick={CloseOverlay} > <AiOutlineClose /> </div>

            <div className='inner-overlay-div'>
                <h2> Camera {currentlyEditedRoom.codice} - {currentlyEditedRoom.nomeHotel} </h2>
                <div className='overlay-top-divider' />

                <Formik
                    initialValues={{
                        note: currentlyEditedRoom.note
                    }}
                    validationSchema={noteValidationSchema}
                    onSubmit={(data, {setSubmitting, resetForm}) => HandleChangeRoomNotes(data, {setSubmitting})}
                >

                {({ errors, touched, isSubmitting }) => (
                    <Form autoComplete='off'>
                        <div className='overlay-input-field'> <h4 className='overlay-input-key'>Note: </h4><Field className='overlay-date-input' type='text' name='note' as={TextareaAutosize} autoFocus/>{touched.note && errors.note ? <a className='validation'>{errors.note}</a> : ''}</div>
                        
                        <button disabled={isSubmitting} type='submit'> Conferma </button>
                    </Form>
                )}

                </Formik>
                

            </div>

        </div>
    )
}