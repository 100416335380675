import { Select, TextField } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai';
import * as yup from 'yup'
import { CreateTesseramento } from '../../../../serverRequests/postRequests'
import { UpdateDBTesseramentoData } from '../../../../serverRequests/putRequests';
import { RenewMultipleTesseramenti } from '../../../../serverRequests/patchRequests';

const validationSchema = yup.object({
    nome: yup
    .string('Nome deve essere una stringa')
    .required('Nome obbligatorio')
    .max(50, 'Massimo 50 caratteri'),
    cognome: yup
    .string('Cognome deve essere una stringa')
    .required('Cognome obbligatorio')
    .max(50, 'Massimo 50 caratteri'),
    cf: yup
    .string('CF deve essere una stringa')
    .required('CF obbligatorio')
    .max(50, 'Massimo 50 caratteri'),
    dataNascita: yup
    .string('Data di nascita deve essere una stringa')
    .required('Data di nascita obbligatoria'),
    luogoNascita: yup
    .string('Luogo di nascita deve essere una stringa')
    .required('Luogo di nascita obbligatorio')
    .max(255, 'Massimo 255 caratteri'),
    indirizzo: yup
    .string('Indirizzo deve essere una stringa')
    .required('Indirizzo obbligatorio')
    .max(255, 'Massimo 255 caratteri'),
    email: yup
    .string().email('Inserire una mail valida')
    .required('E-Mail obbligatoria')
    .max(255, 'Massimo 255 caratteri'),
    telefono: yup
    .string('Indirizzo deve essere una stringa')
    .required('Telefono obbligatorio')
    .max(20, 'Massimo 20 caratteri'),
    dataTesseramento: yup
    .string('Data di tesseramento deve essere una stringa')
    .required('Data di tesseramento obbligatoria'),
    scadenzaTesseramento: yup
    .string('Data di scadenza deve essere una stringa')
    .required('Data di scadenza obbligatoria')
});

const validationSchemaUpdate = yup.object({
    nome: yup
    .string('Nome deve essere una stringa')
    .required('Nome obbligatorio')
    .max(50, 'Massimo 50 caratteri'),
    cognome: yup
    .string('Cognome deve essere una stringa')
    .required('Cognome obbligatorio')
    .max(50, 'Massimo 50 caratteri'),
    cf: yup
    .string('CF deve essere una stringa')
    .required('CF obbligatorio')
    .max(50, 'Massimo 50 caratteri'),
    dataNascita: yup
    .string('Data di nascita deve essere una stringa')
    .required('Data di nascita obbligatoria'),
    luogoNascita: yup
    .string('Luogo di nascita deve essere una stringa')
    .required('Luogo di nascita obbligatorio')
    .max(255, 'Massimo 255 caratteri'),
    indirizzo: yup
    .string('Indirizzo deve essere una stringa')
    .required('Indirizzo obbligatorio')
    .max(255, 'Massimo 255 caratteri'),
    email: yup
    .string().email('Inserire una mail valida')
    .required('E-Mail obbligatoria')
    .max(255, 'Massimo 255 caratteri'),
    telefono: yup
    .string('Indirizzo deve essere una stringa')
    .required('Telefono obbligatorio')
    .max(20, 'Massimo 20 caratteri'),
    dataTesseramento: yup
    .string('Data di tesseramento deve essere una stringa')
    .required('Data di tesseramento obbligatoria')
});

const validationSchemaRenew = yup.object({
    scadenzaTesseramento: yup
    .string('Nuova scadenza deve essere una stringa')
    .required('Nuova scadenza obbligatoria')
});

export default function NewTesseramento({ setCurrentOverlayPage, CreateNewTesseramento, tesseramenti, SetStatusLed }) {

    const today = new Date();
    const defaultScadenza = new Date(String(today.getFullYear() + 1) + '-09-20');

    //Detect mobile device and use default inputs in case
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
            setWidth(window.innerWidth);
        }
    useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);

    let isMobile = (width < 1024);



    const [dataError, setDataError] = useState('');

    const CloseOverlay = () => {
        setCurrentOverlayPage('none');
    }

    const HandleSubmit = async (data, {setSubmitting}) => {

        //These checks could be done server side to have clearer errors in case the FE is a little behind on updates, but the chances two people will end up creating someone with the same username at nearly the same time are extremely slim to negligible
        for (let i = 0; i < tesseramenti.length; i++){
            if (tesseramenti[i].cf == data.cf){
                setDataError('CF già esistente');
                setSubmitting(false);
                return;
            }
        }

        setSubmitting(true);
        SetStatusLed(true);

        let newTesseramento = {
            cf: data.cf == '' ? null : data.cf,
            nome: data.nome == '' ? null : data.nome,
            cognome: data.cognome == '' ? null : data.cognome,
            dataNascita: data.dataNascita == '' ? null : data.dataNascita,
            luogoNascita: data.luogoNascita == '' ? null : data.luogoNascita,
            indirizzo: data.indirizzo == '' ? null : data.indirizzo,
            email: data.email == '' ? null : data.email,
            telefono: data.telefono == '' ? null : data.telefono,
            dataTesseramento: data.dataTesseramento == '' ? null : data.dataTesseramento,
            scadenzaTesseramento: data.scadenzaTesseramento == '' ? null : data.scadenzaTesseramento
        }
        // console.log(data);
        let newTesseramentoID = await CreateTesseramento(newTesseramento);
        
        setSubmitting(false);
        SetStatusLed(false);

        if (newTesseramentoID) {
            newTesseramento.id = newTesseramentoID;
            newTesseramento.dataNascita = newTesseramento.dataNascita == null ? null : newTesseramento.dataNascita.split('-').reverse().join('/');
            newTesseramento.dataTesseramento = newTesseramento.dataTesseramento == null ? null : newTesseramento.dataTesseramento.split('-').reverse().join('/');
            newTesseramento.scadenzaTesseramento = newTesseramento.scadenzaTesseramento == null ? null : newTesseramento.scadenzaTesseramento.split('-').reverse().join('/');
            newTesseramento.tesseramentoAttivo = true;
            CreateNewTesseramento(newTesseramento);
            setCurrentOverlayPage('none');
        }
        //Otherwise do nothing (the error displaying is already handled by CreateAdmin)

    }

    return (
        <div id='new-admin-overlay' className='overlay-div'>
            <div className='close-overlay clickable' onClick={CloseOverlay} > <AiOutlineClose /> </div>

            <div className='inner-overlay-div'>
                <h2> Nuovo Tesserato </h2>
                <div className='overlay-top-divider' />
                
                <div className='overlay-content-div'>
                    <Formik
                        initialValues={{
                            cf: '',
                            nome: '',
                            cognome: '',
                            dataNascita: '',
                            luogoNascita: '',
                            indirizzo: '',
                            email: '',
                            telefono: '',
                            dataTesseramento: today.getFullYear() + '-' + (today.getMonth() > 8 ? (today.getMonth() + 1) : ('0' + (today.getMonth() + 1))) + '-' + (today.getDate() > 9 ? today.getDate() : ('0' + today.getDate())),
                            scadenzaTesseramento: defaultScadenza.getFullYear() + '-' + (defaultScadenza.getMonth() > 8 ? (defaultScadenza.getMonth() + 1) : ('0' + (defaultScadenza.getMonth() + 1))) + '-' + (defaultScadenza.getDate() > 9 ? defaultScadenza.getDate() : ('0' + defaultScadenza.getDate()))
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(data, {setSubmitting}) => HandleSubmit(data, {setSubmitting})}
                    >

                    {({ errors, touched, isSubmitting }) => (
                        <Form autoComplete='off'>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Nome: </h4><Field className='overlay-text-input' type='text' name='nome' as={TextField} autoFocus/> {touched.nome && errors.nome ? <a className='validation'>{errors.nome}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Cognome: </h4><Field className='overlay-text-input' type='text' name='cognome' as={TextField} /> {touched.cognome && errors.cognome ? <a className='validation'>{errors.cognome}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Codice Fiscale: </h4><Field className='overlay-text-input' type='text' name='cf' as={TextField}/> {touched.cf && errors.cf ? <a className='validation'>{errors.cf}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Data di Nascita: </h4><Field className='overlay-date-input' type='date' name='dataNascita' as={isMobile ? '' : TextField} /> {touched.dataNascita && errors.dataNascita ? <a className='validation'>{errors.dataNascita}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Luogo di Nascita: </h4><Field className='overlay-text-input' type='text' name='luogoNascita' as={TextField}/> {touched.luogoNascita && errors.luogoNascita ? <a className='validation'>{errors.luogoNascita}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Indirizzo: </h4><Field className='overlay-text-input' type='text' name='indirizzo' as={TextField} /> {touched.indirizzo && errors.indirizzo ? <a className='validation'>{errors.indirizzo}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Mail: </h4><Field className='overlay-text-input' type='text' name='email' as={TextField} /> {touched.email && errors.email ? <a className='validation'>{errors.email}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Telefono: </h4><Field className='overlay-text-input' type='text' name='telefono' as={TextField} /> {touched.telefono && errors.telefono ? <a className='validation'>{errors.telefono}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Data di Tesseramento: </h4><Field className='overlay-date-input' type='date' name='dataTesseramento' as={isMobile ? '' : TextField} /> {touched.dataTesseramento && errors.dataTesseramento ? <a className='validation'>{errors.dataTesseramento}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Scadenza Tesseramento: </h4><Field className='overlay-date-input' type='date' name='scadenzaTesseramento' as={isMobile ? '' : TextField} /> {touched.scadenzaTesseramento && errors.scadenzaTesseramento ? <a className='validation'>{errors.scadenzaTesseramento}</a> : ''} </div>
                            <div className='form-submit-button-div'>
                                <button disabled={isSubmitting} type='submit'> Conferma </button>
                                {dataError !== '' ? <a className='data-error'>{dataError}</a> : ''}
                            </div>
                        </Form>
                    )}

                    </Formik>
                </div>
            </div>
            
        </div>
    )
}

export function ModifyTesseramentoData({ setCurrentOverlayPage, ModifyTesseramento, currentlyEditedTesseramento, tesseramenti, SetStatusLed }) {

    //Detect mobile device and use default inputs in case
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
            setWidth(window.innerWidth);
        }
    useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);

    let isMobile = (width < 1024);



    const [dataError, setDataError] = useState('');

    const CloseOverlay = () => {
        setCurrentOverlayPage('none');
    }

    const HandleSubmit = async (data, {setSubmitting}) => {

        for (let i = 0; i < tesseramenti.length; i++){
            if (tesseramenti[i].cf == data.cf && tesseramenti[i].id != currentlyEditedTesseramento.id){
                setDataError('Codice Fiscale già esistente');
                setSubmitting(false);
                return;
            }
        }

        setSubmitting(true);
        SetStatusLed(true);
        
        let updatedTesseramentoData = {
            id: currentlyEditedTesseramento.id,
            cf: data.cf == '' ? null : data.cf,
            nome: data.nome == '' ? null : data.nome,
            cognome: data.cognome == '' ? null : data.cognome,
            dataNascita: data.dataNascita == '' ? null : data.dataNascita,
            luogoNascita: data.luogoNascita == '' ? null : data.luogoNascita,
            indirizzo: data.indirizzo == '' ? null : data.indirizzo,
            email: data.email == '' ? null : data.email,
            telefono: data.telefono == '' ? null : data.telefono,
            dataTesseramento: data.dataTesseramento == '' ? null : data.dataTesseramento
        }
        
        let res = await UpdateDBTesseramentoData(updatedTesseramentoData);
        
        setSubmitting(false);
        SetStatusLed(false);


        if (res) {
            updatedTesseramentoData.dataNascita = updatedTesseramentoData.dataNascita == null ? null : updatedTesseramentoData.dataNascita.split('-').reverse().join('/');
            updatedTesseramentoData.dataTesseramento = updatedTesseramentoData.dataTesseramento == null ? null : updatedTesseramentoData.dataTesseramento.split('-').reverse().join('/');
            updatedTesseramentoData.scadenzaTesseramento = currentlyEditedTesseramento.scadenzaTesseramento;
            updatedTesseramentoData.tesseramentoAttivo = currentlyEditedTesseramento.tesseramentoAttivo;
            ModifyTesseramento(updatedTesseramentoData);
            setCurrentOverlayPage('none');
        }
        //Otherwise do nothing (the error displaying is already handled by UpdateDBAdminData)

    }

    return (
        <div id='modify-admin-data-overlay' className='overlay-div'>
            <div className='close-overlay clickable' onClick={CloseOverlay} > <AiOutlineClose /> </div>

            <div className='inner-overlay-div'>
                <h2> Modifica Tesseramento </h2>
                <div className='overlay-top-divider' />
                
                <div className='overlay-content-div'>
                    <Formik
                        initialValues={{
                            cf: currentlyEditedTesseramento.cf,
                            nome: currentlyEditedTesseramento.nome,
                            cognome: currentlyEditedTesseramento.cognome,
                            dataNascita: currentlyEditedTesseramento.dataNascita == null ? null : currentlyEditedTesseramento.dataNascita.split('/').reverse().join('-'),
                            luogoNascita: currentlyEditedTesseramento.luogoNascita,
                            indirizzo: currentlyEditedTesseramento.indirizzo,
                            email: currentlyEditedTesseramento.email,
                            telefono: currentlyEditedTesseramento.telefono,
                            dataTesseramento: currentlyEditedTesseramento.dataTesseramento == null ? null : currentlyEditedTesseramento.dataTesseramento.split('/').reverse().join('-')
                        }}
                        validationSchema={validationSchemaUpdate}
                        onSubmit={(data, {setSubmitting}) => HandleSubmit(data, {setSubmitting})}
                    >

                    {({ errors, touched, isSubmitting }) => (
                        <Form autoComplete='off'>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Nome: </h4><Field className='overlay-text-input' type='text' name='nome' as={TextField} autoFocus/> {touched.nome && errors.nome ? <a className='validation'>{errors.nome}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Cognome: </h4><Field className='overlay-text-input' type='text' name='cognome' as={TextField} /> {touched.cognome && errors.cognome ? <a className='validation'>{errors.cognome}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Codice Fiscale: </h4><Field className='overlay-text-input' type='text' name='cf' as={TextField}/> {touched.cf && errors.cf ? <a className='validation'>{errors.cf}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Data di Nascita: </h4><Field className='overlay-date-input' type='date' name='dataNascita' as={isMobile ? '' : TextField} /> {touched.dataNascita && errors.dataNascita ? <a className='validation'>{errors.dataNascita}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Luogo di Nascita: </h4><Field className='overlay-text-input' type='text' name='luogoNascita' as={TextField}/> {touched.luogoNascita && errors.luogoNascita ? <a className='validation'>{errors.luogoNascita}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Indirizzo: </h4><Field className='overlay-text-input' type='text' name='indirizzo' as={TextField} /> {touched.indirizzo && errors.indirizzo ? <a className='validation'>{errors.indirizzo}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Mail: </h4><Field className='overlay-text-input' type='text' name='email' as={TextField} /> {touched.email && errors.email ? <a className='validation'>{errors.email}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Telefono: </h4><Field className='overlay-text-input' type='text' name='telefono' as={TextField} /> {touched.telefono && errors.telefono ? <a className='validation'>{errors.telefono}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Data di Tesseramento: </h4><Field className='overlay-date-input' type='date' name='dataTesseramento' as={isMobile ? '' : TextField} /> {touched.dataTesseramento && errors.dataTesseramento ? <a className='validation'>{errors.dataTesseramento}</a> : ''} </div>
                            <div className='form-submit-button-div'>
                                <button disabled={isSubmitting} type='submit'> Conferma </button>
                                {dataError !== '' ? <a className='data-error'>{dataError}</a> : ''}
                            </div>
                        </Form>
                    )}

                    </Formik>
                </div>
            </div>
            
        </div>
    )
}

export function ConfirmRenewMultipleTesseramenti({ setCurrentOverlayPage, tesseramentiToRenewIDs, RenewMultipleSelectedTesseramenti, SetStatusLed }) {

    const today = new Date();
    const defaultScadenza = new Date(String(today.getFullYear() + 1) + '-09-20');

    //Detect mobile device and use default inputs in case
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
            setWidth(window.innerWidth);
        }
    useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);

    let isMobile = (width < 1024);



    const CloseOverlay = () => {
        setCurrentOverlayPage('none');
    }

    const HandleSubmit = async ( data, setSubmitting ) => {

        setSubmitting(true);
        SetStatusLed(true);

        let renewTesseramentiData = {
            scadenzaTesseramento: data.scadenzaTesseramento == '' ? null : data.scadenzaTesseramento,
            idTesseramenti: tesseramentiToRenewIDs
        }

        let res = await RenewMultipleTesseramenti(renewTesseramentiData);
        
        setSubmitting(false);
        SetStatusLed(false);


        if (res) {
            RenewMultipleSelectedTesseramenti(data.scadenzaTesseramento.split('-').reverse().join('/'));
            CloseOverlay();
        }
        //Otherwise do nothing (the error displaying is already handled by CreateClient)

    }

    return (
        <div id='confirm-delete-admin-bundle-overlay' className='overlay-div confirm-delete-overlay'>
            <div className='close-overlay clickable' onClick={CloseOverlay} > <AiOutlineClose /> </div>

            <div className='inner-overlay-div'>

                <div className='confirm-delete-message'>
                    <h3>Conferma rinnovo di</h3>
                    <h2> {tesseramentiToRenewIDs.length} tesseramenti? </h2>
                </div>

                <Formik
                    initialValues={{
                        scadenzaTesseramento: defaultScadenza.getFullYear() + '-' + (defaultScadenza.getMonth() > 8 ? (defaultScadenza.getMonth() + 1) : ('0' + (defaultScadenza.getMonth() + 1))) + '-' + (defaultScadenza.getDate() > 9 ? defaultScadenza.getDate() : ('0' + defaultScadenza.getDate()))
                    }}
                    validationSchema={validationSchemaRenew}
                    onSubmit={(data, {setSubmitting}) => HandleSubmit(data, setSubmitting)}
                >
                    {({ errors, touched, isSubmitting }) => (
                        <Form>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Nuova Scadenza: </h4><Field className='overlay-date-input' type='date' name='scadenzaTesseramento' as={isMobile ? '' : TextField} autoFocus/> {touched.scadenzaTesseramento && errors.scadenzaTesseramento ? <a className='validation'>{errors.scadenzaTesseramento}</a> : ''} </div>
                            <button className='no-bottom-margin' disabled={isSubmitting} type='submit'> Conferma </button>
                        </Form>
                    )}
                </Formik>
                
                {/* <button onClick={HandleDeleteClick} > Elimina </button> */}

            </div>
        </div>
    )
}

export function ConfirmRenewTesseramento({ setCurrentOverlayPage, currentlyEditedTesseramento, RenewTesseramento, SetStatusLed }) {

    const today = new Date();
    const defaultScadenza = new Date(String(today.getFullYear() + 1) + '-09-20');

    //Detect mobile device and use default inputs in case
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
            setWidth(window.innerWidth);
        }
    useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);

    let isMobile = (width < 1024);



    const CloseOverlay = () => {
        setCurrentOverlayPage('none');
    }

    const HandleSubmit = async ( data, setSubmitting ) => {

        setSubmitting(true);
        SetStatusLed(true);

        let renewTesseramentiData = {
            scadenzaTesseramento: data.scadenzaTesseramento == '' ? null : data.scadenzaTesseramento,
            idTesseramenti: [currentlyEditedTesseramento.id]
        }

        let res = await RenewMultipleTesseramenti(renewTesseramentiData);
        
        setSubmitting(false);
        SetStatusLed(false);


        if (res) {
            RenewTesseramento(currentlyEditedTesseramento.id, data.scadenzaTesseramento.split('-').reverse().join('/'));
            CloseOverlay();
        }
        //Otherwise do nothing (the error displaying is already handled by CreateClient)

    }

    return (
        <div id='confirm-delete-admin-bundle-overlay' className='overlay-div confirm-delete-overlay'>
            <div className='close-overlay clickable' onClick={CloseOverlay} > <AiOutlineClose /> </div>

            <div className='inner-overlay-div'>

                <div className='confirm-delete-message'>
                    <h3>Conferma rinnovo tesseramento di</h3>
                    <h2> {currentlyEditedTesseramento.cognome} {currentlyEditedTesseramento.nome}? </h2>
                </div>

                <Formik
                    initialValues={{
                        scadenzaTesseramento: defaultScadenza.getFullYear() + '-' + (defaultScadenza.getMonth() > 8 ? (defaultScadenza.getMonth() + 1) : ('0' + (defaultScadenza.getMonth() + 1))) + '-' + (defaultScadenza.getDate() > 9 ? defaultScadenza.getDate() : ('0' + defaultScadenza.getDate()))
                    }}
                    validationSchema={validationSchemaRenew}
                    onSubmit={(data, {setSubmitting}) => HandleSubmit(data, setSubmitting)}
                >
                    {({ errors, touched, isSubmitting }) => (
                        <Form>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Nuova Scadenza: </h4><Field className='overlay-date-input' type='date' name='scadenzaTesseramento' as={isMobile ? '' : TextField} autoFocus/> {touched.scadenzaTesseramento && errors.scadenzaTesseramento ? <a className='validation'>{errors.scadenzaTesseramento}</a> : ''} </div>
                            <button className='no-bottom-margin' disabled={isSubmitting} type='submit'> Conferma </button>
                        </Form>
                    )}
                </Formik>
                
                {/* <button onClick={HandleDeleteClick} > Elimina </button> */}

            </div>
        </div>
    )
}
