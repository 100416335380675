import React from 'react'
import { BiIdCard } from 'react-icons/bi';
import { FaExclamation } from 'react-icons/fa';
import { FiMinus, FiPlus } from 'react-icons/fi';
import { ImCross, ImPlus } from 'react-icons/im';
import ParsePartecipantePresenza from '../../../utils/parsePartecipantePresenza';

export default function AdminClient({ id, idCollaboratore, collaborators, eventStartDate, SetClienteTesserato, SetClienteNoteModificabili, ClientNotesPlusPressed, cf, nome, cognome, idPacchetto, nomePacchetto, sesso, nascita, indirizzo, telefono, presenza, note, noteModificabili, tesserato, email, idStanza, codiceStanza, nomeHotel, dataIn, dataOut, PassInfoElement, RemoveBundleFromClient, AddBundleToClient, PlaceClientIntoRoom, UpdateClientData, DeleteClientPressed, RoomStaysPressed }) {

    let nomeCollaboratore;
    for (let i = 0; i < collaborators.length; i++) {
        if (collaborators[i].id == idCollaboratore) {
            nomeCollaboratore = collaborators[i].cognome == null ? collaborators[i].nome : (collaborators[i].cognome + ' ' + collaborators[i].nome);
        }
        
    }

    const ToggleClient = (e) => {
        let sections = e.currentTarget.parentElement.parentElement.children;
        let toggleIcons = e.currentTarget.children;

        for (let i = 0; i < sections.length; i++){
            if (sections[i].id == 'admin-client-data'){
                if (sections[i].className == 'client-data'){
                    sections[i].className = 'client-data client-data-collapsed';
                    for (let i = 0; i < toggleIcons.length; i++){
                        if (toggleIcons[i].className.baseVal == 'plus'){
                            toggleIcons[i].style.display = 'block';
                        } else {
                            toggleIcons[i].style.display = 'none';
                        }
                    }
                } else {
                    sections[i].className = 'client-data';
                    for (let i = 0; i < toggleIcons.length; i++){
                        if (toggleIcons[i].className.baseVal == 'minus'){
                            toggleIcons[i].style.display = 'block';
                        } else {
                            toggleIcons[i].style.display = 'none';
                        }
                    }
                }
            }
        }
    }

    return (
        <div className='client-div' >
            <div className='toggle-client-div'> <button className='toggle-button' onClick={e => ToggleClient(e)}> <FiPlus className='plus' /> <FiMinus className='minus' /> </button> </div>
            <div class='client-name' onClick={e => PassInfoElement(e, id)} >
                <h3>{cognome} {nome} {tesserato ? <BiIdCard className='tesserato-icon' title='Partecipante Tesserato'/> : null}</h3>
                <div class='top-divider' />
            </div>
            
            <div id='admin-client-data' class='client-data client-data-collapsed'>

                {idPacchetto != null &&
                    <div className='data-field client-bundle-field'>
                        <h4 className='data-field-key'> Pacchetto: </h4>
                        <div className='data-field-value'>
                            <h4> {nomePacchetto} </h4>
                            <div className='data-field-divider' />
                            <div className='data-field-command'> <ImCross onClick={() => RemoveBundleFromClient(id)} /> </div>
                        </div>
                    </div>
                }
                
                {idPacchetto == null &&
                    <div className='data-field client-bundle-field'>
                        <div className='data-field-value'>
                            <div className='data-field-warning'> <FaExclamation /> </div>
                            <h4 className='data-field-key'> Nessun Pacchetto </h4>
                            <div className='data-field-divider' />
                            <div className='data-field-command'> <ImPlus onClick={() => AddBundleToClient(id)} /> </div>
                        </div>
                    </div>
                }
                
                {cf && 
                    <div className='data-field'>
                        <h4 className='data-field-key'> CF: </h4>
                        <div className='data-field-value'>
                            <h4> {cf} </h4>
                        </div>
                    </div>
                }

                {sesso && 
                    <div className='data-field'>
                        <h4 className='data-field-key'> Sesso: </h4>
                        <div className='data-field-value'>
                            <h4> {sesso} </h4>
                        </div>
                    </div>
                }
                
                {nascita && 
                    <div className='data-field'>
                        <h4 className='data-field-key'> Nascita: </h4>
                        <div className='data-field-value'>
                            <h4> {nascita} </h4>
                        </div>
                    </div>
                }
                
                {indirizzo && 
                    <div className='data-field'>
                        <h4 className='data-field-key'> Indirizzo: </h4>
                        <div className='data-field-value'>
                            <h4> {indirizzo} </h4>
                        </div>
                    </div>
                }
                
                {telefono && 
                    <div className='data-field'>
                        <h4 className='data-field-key'> Telefono: </h4>
                        <div className='data-field-value'>
                            <h4> {telefono} </h4>
                        </div>
                    </div>
                }
                
                {email && 
                    <div className='data-field'>
                        <h4 className='data-field-key'> Mail: </h4>
                        <div className='data-field-value'>
                            <h4> {email} </h4>
                        </div>
                    </div>
                }

                <div className='data-field'>
                    <h4 className='data-field-key'> Note: </h4>
                    <div className='data-field-value'>
                        <h4> {(note == null || note == '') ? 'N/P' : note} </h4>
                        <div className='data-field-divider' />
                        <div className='data-field-command' onClick={() => ClientNotesPlusPressed(id)} > <ImPlus /> </div>
                    </div>
                </div>

                {(idCollaboratore == null || idCollaboratore == '') &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Collaboratore: </h4>
                        <div className='data-field-value'>
                            <h4> N/P </h4>
                        </div>
                    </div>
                }

                {idCollaboratore != null && idCollaboratore != '' &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Collaboratore: </h4>
                        <div className='data-field-value'>
                            <h4> {nomeCollaboratore} </h4>
                        </div>
                    </div>
                }
                
                {idStanza != null && 
                    <div className='data-field'>
                        <h4 className='data-field-key'> Camera: </h4>
                        <div className='data-field-value'>
                            <h4><a className='clickable' onClick={() => RoomStaysPressed(idStanza)}> {codiceStanza} - {nomeHotel} </a></h4>
                        </div>
                    </div>
                }
                {idStanza != null && idStanza != '' && 
                    <div className='data-field'>
                        <h4 className='data-field-key'> Data In: </h4>
                        <div className='data-field-value'>
                            <h4> {dataIn} </h4>
                        </div>
                    </div>
                }
                {idStanza != null && idStanza != '' && 
                    <div className='data-field'>
                        <h4 className='data-field-key'> Data Out: </h4>
                        <div className='data-field-value'>
                            <h4> {dataOut} </h4>
                        </div>
                    </div>
                }
                
                {presenza && 
                    <div className='data-field'>
                        <h4 className='data-field-key'> Presenza: </h4>
                        <div className='data-field-value'>
                            <h4 style={{ whiteSpace: 'pre-wrap' }}> {ParsePartecipantePresenza.FFLLStringToDateArray(eventStartDate, presenza).map((obj, index) => (index > 0 ? '\n ' : '') + obj.start + '-' + obj.end)} </h4>
                        </div>
                    </div>
                }

                <div className='data-field'>
                    <input type='checkbox' checked={noteModificabili ?? true} onClick={(e) => SetClienteNoteModificabili(id, e.target.checked)} />
                    <h4 className='data-field-key'> &nbsp;Note Modificabili </h4>
                </div>

                <div className='data-field'>
                    <input type='checkbox' checked={tesserato} onClick={(e) => SetClienteTesserato(id, e.target.checked)} />
                    <h4 className='data-field-key'> &nbsp;Tesserato </h4>
                </div>


                <div className='bottom-divider'></div>
                <div className='client-commands'>
                    {idPacchetto &&
                        <a className='clickable' onClick={() => PlaceClientIntoRoom(id)}>Colloca</a>
                    }
                    <a className='clickable' onClick={() => UpdateClientData(id)}>Modifica</a>
                    <a className='clickable' onClick={() => DeleteClientPressed(id)}>Elimina</a>
                    
                </div>

            </div>

        </div>
    )
}

export function AdminClientToDelete({ id, idCollaboratore, collaborators, eventStartDate, cf, nome, cognome, idPacchetto, nomePacchetto, sesso, nascita, indirizzo, telefono, presenza, note, tesserato, email, idStanza, codiceStanza, nomeHotel, dataIn, dataOut, PassInfoElement, clientsToDeleteIDs, ToggleClientToDelete }) {

    let nomeCollaboratore;
    for (let i = 0; i < collaborators.length; i++) {
        if (collaborators[i].id == idCollaboratore) {
            nomeCollaboratore = collaborators[i].cognome == null ? collaborators[i].nome : (collaborators[i].cognome + ' ' + collaborators[i].nome);
        }
        
    }

    let toBeDeleted = false;
    for (let i = 0; i < clientsToDeleteIDs.length; i++){
        if (clientsToDeleteIDs[i] == id){
            toBeDeleted = true;
        }
    }

    const ToggleClient = (e) => {
        let sections = e.currentTarget.parentElement.parentElement.children;
        let toggleIcons = e.currentTarget.children;

        for (let i = 0; i < sections.length; i++){
            if (sections[i].id == 'admin-client-data'){
                if (sections[i].className == 'client-data'){
                    sections[i].className = 'client-data client-data-collapsed';
                    for (let i = 0; i < toggleIcons.length; i++){
                        if (toggleIcons[i].className.baseVal == 'plus'){
                            toggleIcons[i].style.display = 'block';
                        } else {
                            toggleIcons[i].style.display = 'none';
                        }
                    }
                } else {
                    sections[i].className = 'client-data';
                    for (let i = 0; i < toggleIcons.length; i++){
                        if (toggleIcons[i].className.baseVal == 'minus'){
                            toggleIcons[i].style.display = 'block';
                        } else {
                            toggleIcons[i].style.display = 'none';
                        }
                    }
                }
            }
        }
    }

    return (
        <div className='client-div client-to-delete' >
            <div className='multi-delete-checkbox-div'> <input type='checkbox' checked={toBeDeleted} onClick={() => ToggleClientToDelete(id)} /> </div>
            <div className='toggle-client-div'> <button className='toggle-button' onClick={e => ToggleClient(e)}> <FiPlus className='plus' /> <FiMinus className='minus' /> </button> </div>
            <div class='client-name' onClick={e => PassInfoElement(e, id)} >
                <h3>{cognome} {nome} {tesserato ? <BiIdCard className='tesserato-icon' title='Partecipante Tesserato'/> : null}</h3>
                <div class='top-divider' />
            </div>
            
            <div id='admin-client-data' class='client-data client-data-collapsed'>

                {idPacchetto != null &&
                    <div className='data-field client-bundle-field'>
                        <h4 className='data-field-key'> Pacchetto: </h4>
                        <div className='data-field-value'>
                            <h4> {nomePacchetto} </h4>
                        </div>
                    </div>
                }
                
                {idPacchetto == null &&
                    <div className='data-field client-bundle-field'>
                        <div className='data-field-value'>
                            <div className='data-field-warning'> <FaExclamation /> </div>
                            <h4 className='data-field-key'> Nessun Pacchetto </h4>
                        </div>
                    </div>
                }
                
                {cf && 
                    <div className='data-field'>
                        <h4 className='data-field-key'> CF: </h4>
                        <div className='data-field-value'>
                            <h4> {cf} </h4>
                        </div>
                    </div>
                }

                {sesso && 
                    <div className='data-field'>
                        <h4 className='data-field-key'> Sesso: </h4>
                        <div className='data-field-value'>
                            <h4> {sesso} </h4>
                        </div>
                    </div>
                }
                
                {nascita && 
                    <div className='data-field'>
                        <h4 className='data-field-key'> Nascita: </h4>
                        <div className='data-field-value'>
                            <h4> {nascita} </h4>
                        </div>
                    </div>
                }
                
                {indirizzo && 
                    <div className='data-field'>
                        <h4 className='data-field-key'> Indirizzo: </h4>
                        <div className='data-field-value'>
                            <h4> {indirizzo} </h4>
                        </div>
                    </div>
                }
                
                {telefono && 
                    <div className='data-field'>
                        <h4 className='data-field-key'> Telefono: </h4>
                        <div className='data-field-value'>
                            <h4> {telefono} </h4>
                        </div>
                    </div>
                }
                
                {email && 
                    <div className='data-field'>
                        <h4 className='data-field-key'> Mail: </h4>
                        <div className='data-field-value'>
                            <h4> {email} </h4>
                        </div>
                    </div>
                }

                {note &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Note: </h4>
                        <div className='data-field-value'>
                            <h4> {note} </h4>
                        </div>
                    </div>
                }

                {(idCollaboratore == null || idCollaboratore == '') &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Collaboratore: </h4>
                        <div className='data-field-value'>
                            <h4> N/P </h4>
                        </div>
                    </div>
                }

                {idCollaboratore != null && idCollaboratore != '' &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Collaboratore: </h4>
                        <div className='data-field-value'>
                            <h4> {nomeCollaboratore} </h4>
                        </div>
                    </div>
                }
                
                {idStanza != null && 
                    <div className='data-field'>
                        <h4 className='data-field-key'> Camera: </h4>
                        <div className='data-field-value'>
                            <h4> {codiceStanza} - {nomeHotel} </h4>
                        </div>
                    </div>
                }
                {idStanza != null && 
                    <div className='data-field'>
                        <h4 className='data-field-key'> Data In: </h4>
                        <div className='data-field-value'>
                            <h4> {dataIn} </h4>
                        </div>
                    </div>
                }
                {idStanza != null && 
                    <div className='data-field'>
                        <h4 className='data-field-key'> Data Out: </h4>
                        <div className='data-field-value'>
                            <h4> {dataOut} </h4>
                        </div>
                    </div>
                }
                
                {presenza && 
                    <div className='data-field'>
                        <h4 className='data-field-key'> Presenza: </h4>
                        <div className='data-field-value'>
                            <h4 style={{ whiteSpace: 'pre-wrap' }}> {ParsePartecipantePresenza.FFLLStringToDateArray(eventStartDate, presenza).map((obj, index) => (index > 0 ? '\n ' : '') + obj.start + '-' + obj.end)} </h4>
                        </div>
                    </div>
                }


                <div className='bottom-divider'></div>

            </div>

        </div>
    )
}

export function InfoAdminClient({ eventStartDate, SetClienteTesserato, SetClienteNoteModificabili, ClientNotesPlusPressed, deletingClients, PlaceClientIntoRoom, AddBundleToClient, UpdateClientData, id, idCollaboratore, collaborators, cf, nome, cognome, sesso, nascita, indirizzo, telefono, presenza, note, noteModificabili, tesserato, email, idPacchetto, nomePacchetto, prezzoPacchetto, durataPacchetto, dataInizioPacchetto, dataFinePacchetto, notePacchetto, idStanza, codiceStanza, nomeHotel, dataIn, dataOut, RoomStaysPressed }) {
    
    let nomeCollaboratore;
    for (let i = 0; i < collaborators.length; i++) {
        if (collaborators[i].id == idCollaboratore) {
            nomeCollaboratore = collaborators[i].cognome == null ? collaborators[i].nome : (collaborators[i].cognome + ' ' + collaborators[i].nome);
        }
        
    }

    return (
        <div className='info-client-div' >

            {/* Info Cliente */}

            <h3>{cognome} {nome} {tesserato ? <BiIdCard className='tesserato-icon' title='Partecipante Tesserato'/> : null}</h3>
            <div class='info-top-divider' />
                
            {cf && 
                <div className='data-field'>
                    <h4 className='data-field-key'> CF: </h4>
                    <div className='data-field-value'>
                        <h4> {cf} </h4>
                    </div>
                </div>
            }

            {sesso && 
                <div className='data-field'>
                    <h4 className='data-field-key'> Sesso: </h4>
                    <div className='data-field-value'>
                        <h4> {sesso} </h4>
                    </div>
                </div>
            }
            
            {nascita && 
                <div className='data-field'>
                    <h4 className='data-field-key'> Nascita: </h4>
                    <div className='data-field-value'>
                        <h4> {nascita} </h4>
                    </div>
                </div>
            }
            
            {indirizzo && 
                <div className='data-field'>
                    <h4 className='data-field-key'> Indirizzo: </h4>
                    <div className='data-field-value'>
                        <h4> {indirizzo} </h4>
                    </div>
                </div>
            }
            
            {telefono && 
                <div className='data-field'>
                    <h4 className='data-field-key'> Telefono: </h4>
                    <div className='data-field-value'>
                        <h4> {telefono} </h4>
                    </div>
                </div>
            }
            
            {email && 
                <div className='data-field'>
                    <h4 className='data-field-key'> Mail: </h4>
                    <div className='data-field-value'>
                        <h4> {email} </h4>
                    </div>
                </div>
            }

            <div className='data-field'>
                <h4 className='data-field-key'> Note: </h4>
                <div className='data-field-value'>
                    <h4> {(note == null || note == '') ? 'N/P' : note} </h4>
                    <div className='data-field-divider' />
                    <div className='data-field-command' onClick={() => ClientNotesPlusPressed(id)} > <ImPlus /> </div>
                </div>
            </div>

            {(idCollaboratore == null || idCollaboratore == '') &&
                <div className='data-field'>
                    <h4 className='data-field-key'> Collaboratore: </h4>
                    <div className='data-field-value'>
                        <h4> N/P </h4>
                    </div>
                </div>
            }

            {idCollaboratore != null && idCollaboratore != '' &&
                <div className='data-field'>
                    <h4 className='data-field-key'> Collaboratore: </h4>
                    <div className='data-field-value'>
                        <h4> {nomeCollaboratore} </h4>
                    </div>
                </div>
            }
                
            {presenza && 
                <div className='data-field'>
                    <h4 className='data-field-key'> Presenza: </h4>
                    <div className='data-field-value'>
                        <h4 style={{ whiteSpace: 'pre-wrap' }}> {ParsePartecipantePresenza.FFLLStringToDateArray(eventStartDate, presenza).map((obj, index) => (index > 0 ? '\n ' : '') + obj.start + '-' + obj.end)} </h4>
                    </div>
                </div>
            }

            <div className='data-field'>
                <input type='checkbox' checked={noteModificabili ?? true} onClick={(e) => SetClienteNoteModificabili(id, e.target.checked)} />
                <h4 className='data-field-key'> &nbsp;Note Modificabili </h4>
            </div>

            <div className='data-field'>
                <input type='checkbox' checked={tesserato} onClick={(e) => SetClienteTesserato(id, e.target.checked)} />
                <h4 className='data-field-key'> &nbsp;Tesserato </h4>
            </div>

            <button disabled={deletingClients} onClick={() => UpdateClientData(id)}>Modifica</button>


            {/* Pacchetto Cliente */}
            
            {idPacchetto != null &&
                <h3>{nomePacchetto}</h3>
            }
                
            {idPacchetto == null &&
                <h3>Nessun Pacchetto</h3>
            }

            <div class='info-top-divider' />

            {idPacchetto != null &&
                <div className='data-field'>
                <h4 className='data-field-key'> Prezzo: </h4>
                <div className='data-field-value'>
                    <h4> {prezzoPacchetto}€ </h4>
                </div>
            </div>
            }
            
            {idPacchetto != null &&
                <div className='data-field'>
                    <h4 className='data-field-key'> Durata: </h4>
                    <div className='data-field-value'>
                        <h4> {durataPacchetto} giorni </h4>
                    </div>
                </div>
            }
            
            {idPacchetto != null &&
                <div className='data-field'>
                <h4 className='data-field-key'> Inizio previsto: </h4>
                <div className='data-field-value'>
                    <h4> {dataInizioPacchetto} </h4>
                </div>
            </div>
            }
            
            {idPacchetto != null &&
                <div className='data-field'>
                <h4 className='data-field-key'> Fine prevista: </h4>
                <div className='data-field-value'>
                    <h4> {dataFinePacchetto} </h4>
                </div>
            </div>
            }
            
            {idPacchetto != null &&
                <div className='data-field'>
                <h4 className='data-field-key'> Note: </h4>
                <div className='data-field-value'>
                    {!notePacchetto &&
                        <h4> N/P </h4>
                    }

                    {notePacchetto &&
                        <h4> {notePacchetto} </h4>
                    }

                </div>
            </div>
            }

            {idPacchetto == null &&
                <button disabled={deletingClients} className='select-bundle-button' onClick={() => AddBundleToClient(id)} >Seleziona Pacchetto</button>
            }

            {idPacchetto != null &&
                <button disabled={deletingClients} onClick={() => AddBundleToClient(id)} >Modifica</button>
            }

            {/* Pernottamento Cliente */}
            
            {idStanza != null &&
                <h3> Pernottamento </h3>
            }
                
            {idStanza == null &&
                <h3>Nessun Pernottamento</h3>
            }

            <div class='info-top-divider' />

            {idStanza != null &&
                <div className='data-field'>
                <h4 className='data-field-key'> Camera: </h4>
                <div className='data-field-value'>
                    <h4><a className='clickable' onClick={() => RoomStaysPressed(idStanza)}> {codiceStanza} - {nomeHotel} </a></h4>
                </div>
            </div>
            }

            {idStanza != null &&
                <div className='data-field'>
                <h4 className='data-field-key'> Data In: </h4>
                <div className='data-field-value'>
                    <h4> {dataIn} </h4>
                </div>
            </div>
            }

            {idStanza != null &&
                <div className='data-field'>
                <h4 className='data-field-key'> Data Out: </h4>
                <div className='data-field-value'>
                    <h4> {dataOut} </h4>
                </div>
            </div>
            }

            {idStanza == null &&
                <button className='select-room-button' disabled={idPacchetto == null || idPacchetto == '' || deletingClients} onClick={() => PlaceClientIntoRoom(id)} >Seleziona Camera</button>
            }

            {idStanza != null &&
                <button disabled={deletingClients} onClick={() => PlaceClientIntoRoom(id)} >Modifica</button>
            }

        </div>
    
    )
}