import axios from "axios";
import { SERVER_URL } from "./consts";

export default async function CollaboratorUpdateRoomData(updatedRoomData){

    return await axios.patch(SERVER_URL + '/collaboratorUpdateRoomData', updatedRoomData).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante la modifica dei dati della camera!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return true;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}

export async function AdminUpdateRoomFlags(roomFlags){

    return await axios.patch(SERVER_URL + '/adminUpdateRoomFlags', roomFlags).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante la modifica dei dettagli della camera!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return true;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}

export async function SettleDBReservations(settlingData){

    return await axios.patch(SERVER_URL + '/settleReservations', settlingData).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante il saldo delle prenotazioni!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return true;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}

export async function SetAbilitazione(abilitazioneData){

    return await axios.patch(SERVER_URL + '/setAbilitazione', abilitazioneData).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante l\'abilitazione del collaboratore!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return true;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}

export async function UpdateDBTesseramentoAttivo(tesseramentoData){

    return await axios.patch(SERVER_URL + '/updateTesseramentoAttivo', tesseramentoData).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante l\'aggiornamento dello stato del tesseramento!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return true;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}

export async function RenewMultipleTesseramenti(renewTesseramentiData){

    return await axios.patch(SERVER_URL + '/renewMultipleTesseramenti', renewTesseramentiData).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante il rinnovo dei tesseramenti!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return true;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}

export async function UpdateClienteTesserato(tesseramentoData){

    return await axios.patch(SERVER_URL + '/updateClientTesserato', tesseramentoData).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante l\'aggiornamento dello stato del tesseramento!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return true;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });
}

export async function UpdateClienteNoteModificabili(clientNoteModificabiliData){

    return await axios.patch(SERVER_URL + '/updateClientNoteModificabili', clientNoteModificabiliData).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante l\'aggiornamento della modificabilità delle note!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return true;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });
}

export async function UpdateClienteNote(clientNoteData){

    return await axios.patch(SERVER_URL + '/updateClientNote', clientNoteData).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante l\'aggiornamento delle note del partecipante!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return true;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });
}