import React from 'react';
import { Redirect, Route } from 'react-router-dom';

export default function CollaboratorRoute ({sessionData, component:Component, ...rest}) {
  return (
    <Route
      {...rest}
      render = {() => sessionData.role === 1 ?
      (
        <Component {...rest}/>
      )
      :
      (
        <Redirect to='/login' />
      )
    }
      />
  )

  }