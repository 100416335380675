import React, { useState } from 'react'
import { TextField } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { AiOutlineClose } from 'react-icons/ai';
import * as yup from 'yup'
import SendResetLinkTo from '../../serverRequests/pwResetRequests';

const validationSchema = yup.object({
    username: yup
    .string('Username deve essere una stringa')
    .required('Inserire username o e-mail')
    .max(255, 'Massimo 255 caratteri')
});

export default function ResetPasswordOverlay({ setResetPasswordOverlayVisible }) {

    const CloseOverlay = () => {
        setResetPasswordOverlayVisible(false);
    }

    const HandleSubmit = async (data, {setSubmitting}) => {

        setSubmitting(true);

        let res = await SendResetLinkTo(data.username);

        setSubmitting(false);

        if (res) {
            //Alert feels a little error-ish to use for a success message but it's fine, let's keep it simple
            alert('SUCCESSO!\n\nTra qualche minuto riceverete una mail con il link per il reset della password all\'indirizzo ' + res + '.\nIl link è valido per 15 minuti.');
            CloseOverlay();
        }
        //Otherwise do nothing (the error displaying is already handled by SendResetLinkTo)

    }

    return (
        <div id='reset_password-overlay' className='overlay-div'>
            <div className='close-overlay clickable' onClick={CloseOverlay} > <AiOutlineClose /> </div>

            <div className='inner-overlay-div'>
                <h2> Inserire Username o E-mail </h2>
                <div className='overlay-top-divider' />

                <h4 className='reset-password-subtitle'>
                    Il link per modificare la password sarà inviato solamente alla mail abbinata al proprio account.<br/><br/>
                    In non si ricordi nessuno dei propri dati si prega di contattare un amministratore, il quale potrà recuperare il vostro attuale username o mail o modificarli.
                </h4>

                <div className='overlay-content-div'>
                    <Formik
                        initialValues={{
                            username: ''
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(data, {setSubmitting}) => HandleSubmit(data, {setSubmitting})}
                    >

                    {({ errors, touched, isSubmitting }) => (
                        <Form autoComplete='off'>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Username o mail: </h4><Field className='overlay-text-input' type='text' name='username' as={TextField} autoFocus/> {touched.username && errors.username ? <a className='validation'>{errors.username}</a> : ''} </div>
                            
                            <button disabled={isSubmitting} type='submit'> Invia link di recupero </button>
                        </Form>
                    )}

                    </Formik>
                </div>
            </div>
            
        </div>
    )
}