import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import { Select, TextField } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup'
import { ResetCollaboratorPassword, VerifyResetPasswordID } from '../../serverRequests/pwResetRequests';

const validationSchema = yup.object({
    password: yup
    .string('Password deve essere una stringa')
    .required('Password obbligatoria')
    .min(8, 'Minimo 8 caratteri')
    .max(20, 'Massimo 20 caratteri'),
    confermaPassword: yup
    .string('Password deve essere una stringa')
    .required('Password obbligatoria')
    .min(8, 'Minimo 8 caratteri')
    .max(20, 'Massimo 20 caratteri')
});

export default function PasswordResetPage(props) {
    const history = useHistory();
    const id = props.match.params.id;
    const [isLoading, setIsLoading] = useState(true);

    const [dataError, setDataError] = useState('');

    useEffect(() => {
        if (id == null || id == '') {
            alert('PARAMETRI MANCANTI!\n\nL\'identificativo della richiesta di reset della password sembra mancare.\nNel caso si stia cercando di modificare la propria password tramite mail, ripetere il procedimento da capo o, se il problema persiste, contattare un amministratore.');
            history.replace('/login');
        } else {
            VerifyResetPasswordID(id, setIsLoading, history);
        }
    }, [])

    const HandleSubmit = async (data, {setSubmitting}) => {

        if (data.password != data.confermaPassword){
            setDataError('Le password inserite non coincidono');
            setSubmitting(false);
            return;
        }

        setSubmitting(true);

        let resetData = {
            id: id,
            password: data.password
        }
        
        let res = await ResetCollaboratorPassword(resetData);
        
        setSubmitting(false);


        if (res) {
            alert('SUCCESSO!\n\nLa password è stata modificata correttamente.');
            history.replace('/login');
        }
        //Otherwise do nothing (the error displaying is already handled by ResetCollaboratorPassword)

    }

    return (
        <div>
            {isLoading &&
                <div className='content-home'>
                    <h2 className='loading-message'>Caricamento...</h2>
                </div>
            }

            {!isLoading &&
                <div className='container-reset-pw'>
                    <Formik
                        initialValues={{
                            password: '',
                            confermaPassword: ''
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(data, {setSubmitting}) => HandleSubmit(data, {setSubmitting})}
                    >

                    {({ errors, touched, isSubmitting }) => (
                        <Form autoComplete='off'>
                            <div className='reset-pw-input-field'> <h4 className='reset-pw-input-key'>Nuova Password: </h4><Field className='reset-pw-text-input' type='password' name='password' as={TextField} autoFocus/> {touched.password && errors.password ? <a className='validation'>{errors.password}</a> : ''} </div>
                            <div className='reset-pw-input-field'> <h4 className='reset-pw-input-key'>Conferma password: </h4><Field className='reset-pw-text-input' type='password' name='confermaPassword' as={TextField}/> {touched.confermaPassword && errors.confermaPassword ? <a className='validation'>{errors.confermaPassword}</a> : ''} </div>
                            <div className='form-submit-button-div'>
                                <button disabled={isSubmitting} type='submit'> Modifica Password </button>
                                {dataError !== '' ? <a className='data-error'>{dataError}</a> : ''}
                            </div>
                        </Form>
                    )}

                    </Formik>
                </div>
            }
            
        </div>
    )
}
