import React from 'react'
import { FiMinus, FiPlus } from 'react-icons/fi'

export default function Collaborator({id, username, nome, cognome, riferimento, sesso, nascita, indirizzo, email, telefono, UpdateCollaboratorData, DeleteCollaboratorPressed }) {

    const ToggleCollaborator = (e) => {
        let sections = e.currentTarget.parentElement.parentElement.children;
        let toggleIcons = e.currentTarget.children;

        for (let i = 0; i < sections.length; i++){
            if (sections[i].id == 'collaborator-data'){
                if (sections[i].className == 'collaborator-data'){
                    sections[i].className = 'collaborator-data collaborator-data-collapsed';
                    for (let i = 0; i < toggleIcons.length; i++){
                        if (toggleIcons[i].className.baseVal == 'plus'){
                            toggleIcons[i].style.display = 'block';
                        } else {
                            toggleIcons[i].style.display = 'none';
                        }
                    }
                } else {
                    sections[i].className = 'collaborator-data';
                    for (let i = 0; i < toggleIcons.length; i++){
                        if (toggleIcons[i].className.baseVal == 'minus'){
                            toggleIcons[i].style.display = 'block';
                        } else {
                            toggleIcons[i].style.display = 'none';
                        }
                    }
                }
            }
        }
    }

    return (
        <div className='collaborator-div'>
            <div className='toggle-collaborator-div'> <button className='toggle-button' onClick={e => ToggleCollaborator(e)}> <FiPlus className='plus' /> <FiMinus className='minus' /> </button> </div>
            <div class='collaborator-name'>
                <h3>{cognome} {nome}</h3>
                <div class='top-divider' />
            </div>

            <div id='collaborator-data' class='collaborator-data collaborator-data-collapsed'>

                <div className='data-field'>
                    <h4 className='data-field-key'> Username: </h4>
                    <div className='data-field-value'>
                        <h4> {username} </h4>
                    </div>
                </div>

                {riferimento != null && riferimento != '' &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Riferimento: </h4>
                        <div className='data-field-value'>
                            <h4> {riferimento} </h4>
                        </div>
                    </div>
                }

                {sesso != null && sesso != '' &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Sesso: </h4>
                        <div className='data-field-value'>
                            <h4> {sesso} </h4>
                        </div>
                    </div>
                }

                {nascita != null && nascita != '' &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Nascita: </h4>
                        <div className='data-field-value'>
                            <h4> {nascita} </h4>
                        </div>
                    </div>
                }

                {indirizzo != null && indirizzo != '' &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Indirizzo: </h4>
                        <div className='data-field-value'>
                            <h4> {indirizzo} </h4>
                        </div>
                    </div>
                }

                {telefono != null && telefono != '' &&
                    <div className='data-field collaborator-telephone-field'>
                        <h4 className='data-field-key'> Telefono: </h4>
                        <div className='data-field-value'>
                            <h4> {telefono} </h4>
                        </div>
                    </div>
                }

                {email != null && email != '' &&
                    <div className='data-field collaborator-email-field'>
                        <h4 className='data-field-key'> Mail: </h4>
                        <div className='data-field-value'>
                            <h4> {email} </h4>
                        </div>
                    </div>
                }

                <div className='bottom-divider'></div>

                <div className='collaborator-commands'>
                    <a className='clickable' onClick={() => UpdateCollaboratorData(id)} >Modifica</a>
                    <a className='clickable' onClick={() => DeleteCollaboratorPressed(id)}>Elimina</a>
                </div>

            </div>

        </div>
    )
}

export function EventCollaborator({id, username, nome, cognome, riferimento, sesso, nascita, indirizzo, email, telefono, isAdmin, active, ToggleActiveCollaborator, UpdateCollaboratorData, DeleteCollaboratorPressed }) {

    const ToggleCollaborator = (e) => {
        let sections = e.currentTarget.parentElement.parentElement.children;
        let toggleIcons = e.currentTarget.children;

        for (let i = 0; i < sections.length; i++){
            if (sections[i].id == 'collaborator-data'){
                if (sections[i].className == 'collaborator-data'){
                    sections[i].className = 'collaborator-data collaborator-data-collapsed';
                    for (let i = 0; i < toggleIcons.length; i++){
                        if (toggleIcons[i].className.baseVal == 'plus'){
                            toggleIcons[i].style.display = 'block';
                        } else {
                            toggleIcons[i].style.display = 'none';
                        }
                    }
                } else {
                    sections[i].className = 'collaborator-data';
                    for (let i = 0; i < toggleIcons.length; i++){
                        if (toggleIcons[i].className.baseVal == 'minus'){
                            toggleIcons[i].style.display = 'block';
                        } else {
                            toggleIcons[i].style.display = 'none';
                        }
                    }
                }
            }
        }
    }

    return (
        <div className='collaborator-div'>
            <div className='toggle-collaborator-div'> <button className='toggle-button' onClick={e => ToggleCollaborator(e)}> <FiPlus className='plus' /> <FiMinus className='minus' /> </button> </div>
            <div class='collaborator-name'>
                <div className='collaborator-name-text'>
                    <h3>{cognome} {nome}</h3>
                    {isAdmin &&
                        <h5>(Admin)</h5>
                    }
                </div>
                <div class='top-divider' />
            </div>

            <div id='collaborator-data' class='collaborator-data collaborator-data-collapsed'>

                <div className='data-field'>
                    <h4 className='data-field-key'> Username: </h4>
                    <div className='data-field-value'>
                        <h4> {username} </h4>
                    </div>
                </div>

                {riferimento != null && riferimento != '' &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Riferimento: </h4>
                        <div className='data-field-value'>
                            <h4> {riferimento} </h4>
                        </div>
                    </div>
                }

                {sesso != null && sesso != '' &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Sesso: </h4>
                        <div className='data-field-value'>
                            <h4> {sesso} </h4>
                        </div>
                    </div>
                }

                {nascita != null && nascita != '' &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Nascita: </h4>
                        <div className='data-field-value'>
                            <h4> {nascita} </h4>
                        </div>
                    </div>
                }

                {indirizzo != null && indirizzo != '' &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Indirizzo: </h4>
                        <div className='data-field-value'>
                            <h4> {indirizzo} </h4>
                        </div>
                    </div>
                }

                {telefono != null && telefono != '' &&
                    <div className='data-field collaborator-telephone-field'>
                        <h4 className='data-field-key'> Telefono: </h4>
                        <div className='data-field-value'>
                            <h4> {telefono} </h4>
                        </div>
                    </div>
                }

                {email != null && email != '' &&
                    <div className='data-field collaborator-email-field'>
                        <h4 className='data-field-key'> Mail: </h4>
                        <div className='data-field-value'>
                            <h4> {email} </h4>
                        </div>
                    </div>
                }

                <label>
                    <input type='checkbox' checked={active} onClick={() => ToggleActiveCollaborator(id)} />
                    <h4> Abilitato </h4>
                </label>

                {/* {(isAdmin == null || isAdmin == false) && */}
                    <div className='bottom-divider'></div>
                {/* } */}

                {(isAdmin == null || isAdmin == false) &&
                    <div className='collaborator-commands'>
                        <a className='clickable' onClick={() => UpdateCollaboratorData(id)} >Modifica</a>
                        <a className='clickable' onClick={() => DeleteCollaboratorPressed(id)}>Elimina</a>
                        
                    </div>
                }

            </div>

        </div>
    )
}
