import './index.css';
import { BrowserRouter, Route, Switch, Redirect} from 'react-router-dom';
import Login from './pages/login'
import CollaboratorHome from './pages/collaborator/collaboratorHome'
import CollaboratorEvent from './pages/collaborator/collaboratorEvent'
import AdminHome from './pages/admin/adminHome'
import AdminEvent from './pages/admin/adminEvent'
import Axios from 'axios'
import { useEffect, useState } from 'react';
import LoginRoute from './routing/loginRoute'
import CollaboratorRoute from './routing/collaboratorRoute'
import AdminRoute from './routing/adminRoute'
import { SessionLoginRequest } from './serverRequests/loginRequest';
import Connecting from './pages/connecting';
import SessionDataContext from './contexts/sessionData'
import EventsContext from './contexts/eventsContext';
import ClientsContext from './contexts/clientsContext';
import RoomsContext from './contexts/roomsContext';
import BundlesContext from './contexts/bundlesContext';
import RoomTypesContext from './contexts/roomTypesContext';
import CollaboratorsContext from './contexts/collaboratorsContext';
import AdminsContext from './contexts/adminsContext';
import HotelsContext from './contexts/hotelsContext';
import PaymentMethodsContext from './contexts/paymentMethodsContext';
import PaymentStatesContext from './contexts/paymentStatesContext';
import ReservationsContext from './contexts/reservationsContext';
import PasswordResetPage from './pages/passwordReset/passwordResetPage';
import TesseramentiContext from './contexts/tesseramentiContext';
import DestinatariAccontoContext from './contexts/destinatariAccontoContext';

Axios.defaults.withCredentials = true;

function App() {
  const [isConnecting, setIsConncecting] = useState(true);

  const [sessionData, setSessionData] = useState({role: 0, nome: '', cognome: '', id: ''});
  const [events, setEvents] = useState([]);
  const [clienti, setClienti] = useState([]);
  const [stanze, setStanze] = useState([]);
  const [pacchetti, setPacchetti] = useState([]);
  const [roomTypes, setRoomTypes] = useState([]);
  const [collaborators, setCollaborators] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [hotels, setHotels] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentStates, setPaymentStates] = useState([]);
  const [destinatariAcconto, setDestinatariAcconto] = useState([]);
  const [reservations, setReservations] = useState([]);
  const [tesseramenti, setTesseramenti] = useState([]);

  useEffect(() => {
    if (sessionData.role === 0){
        SessionLoginRequest(setSessionData, setIsConncecting);
    }
  }, [])

  if (isConnecting){
    return (
      <Connecting />
    );
  } else {

    return (
      <SessionDataContext.Provider value={{sessionData, setSessionData}}>
      <EventsContext.Provider value={{events, setEvents}}>
      <ClientsContext.Provider value={{clienti, setClienti}}>
      <RoomsContext.Provider value={{stanze, setStanze}}>
      <BundlesContext.Provider value={{pacchetti, setPacchetti}}>
      <RoomTypesContext.Provider value={{roomTypes, setRoomTypes}}>
      <CollaboratorsContext.Provider value={{collaborators, setCollaborators}}>
      <AdminsContext.Provider value={{admins, setAdmins}}>
      <HotelsContext.Provider value={{hotels, setHotels}}>
      <PaymentMethodsContext.Provider value={{paymentMethods, setPaymentMethods}}>
      <PaymentStatesContext.Provider value={{paymentStates, setPaymentStates}}>
      <DestinatariAccontoContext.Provider value={{destinatariAcconto, setDestinatariAcconto}}>
      <ReservationsContext.Provider value={{reservations, setReservations}}>
      <TesseramentiContext.Provider value={{tesseramenti, setTesseramenti}}>

          <BrowserRouter>
            <Switch>
              <LoginRoute sessionData={sessionData} path='/login' component={Login} />
              <CollaboratorRoute sessionData={sessionData} path='/collaborator-home' component={CollaboratorHome} />
              <CollaboratorRoute sessionData={sessionData} path='/collaborator-event' component={CollaboratorEvent} />
              <AdminRoute sessionData={sessionData} path='/admin-home' component={AdminHome} />
              <AdminRoute sessionData={sessionData} path='/admin-event' component={AdminEvent} />
              <Route path='/password-reset/:id?' component={PasswordResetPage} />
              <Route path="*" component={Login}>
                <Redirect to='/login' />
              </Route>
            </Switch>
          </BrowserRouter>

      </TesseramentiContext.Provider>
      </ReservationsContext.Provider>
      </DestinatariAccontoContext.Provider>
      </PaymentStatesContext.Provider>
      </PaymentMethodsContext.Provider>
      </HotelsContext.Provider>
      </AdminsContext.Provider>
      </CollaboratorsContext.Provider>
      </RoomTypesContext.Provider>
      </BundlesContext.Provider>
      </RoomsContext.Provider>
      </ClientsContext.Provider>
      </EventsContext.Provider>
      </SessionDataContext.Provider>
    );

  }
}

export default App;
