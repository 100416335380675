import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import CollaboratorEventCommands from './components/collaboratorEventCommands'
import CollaboratorEventInfo from './components/collaboratorEventInfo'
import Client from './components/client'
import Room from './components/room'
import { Masonry } from 'react-masonry-responsive'
import NewClient, { ChangeClientNotes, ModifyClientData } from './components/overlays/clientData'
import { AddBundle } from './components/overlays/selectBundle'
import SelectRoom, { ChangeRoomNotes } from './components/overlays/selectRoom'
import ConfirmDeleteClient from './components/overlays/confirmDeleteClient'
import RoomStays from './components/overlays/roomStays'
import ReactTooltip from 'react-tooltip'
import EventsContext from '../../contexts/eventsContext';
import SessionDataContext from '../../contexts/sessionData';
import { FetchCollaboratorEventData, VerifyCollaboratorEvents } from '../../serverRequests/fetchRequest';
import ClientsContext from '../../contexts/clientsContext';
import RoomsContext from '../../contexts/roomsContext';
import BundlesContext from '../../contexts/bundlesContext';
import RoomTypesContext from '../../contexts/roomTypesContext';
import { RemoveDBClientBundle, RemoveDBClientFromRoom } from '../../serverRequests/deleteRequests'
import CreateClient, { AddClientWithBundleToRoom, AddBundleAndRoomToClient } from '../../serverRequests/postRequests';
import CollaboratorUpdateRoomData from '../../serverRequests/patchRequests';
import TesseramentiContext from '../../contexts/tesseramentiContext';

export default function CollaboratorEvent({location}) {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [queryingServer, setQueryingServer] = useState(false);
    const {sessionData} = useContext(SessionDataContext);
    const {events, setEvents} = useContext(EventsContext);
    const {clienti, setClienti} = useContext(ClientsContext);
    const {stanze, setStanze} = useContext(RoomsContext);
    const {pacchetti, setPacchetti} = useContext(BundlesContext);
    const {roomTypes, setRoomTypes} = useContext(RoomTypesContext);
    const {tesseramenti, setTesseramenti} = useContext(TesseramentiContext);
    const [currEventData, setCurrEventData] = useState();

    useEffect(() => {
        setClienti([]);
        setStanze([]);
        setPacchetti([]);
        setRoomTypes([]);
        setTesseramenti([]);

        let eventID;
        //If the component has been passed an id, save it in local storage in case of a refresh, otherwise (no state or state.id) look for an id in localstorage and if there is none anywhere send an error
        if (location.state == null){
            eventID = localStorage.getItem('currEventID');
            if (eventID == null){
                //send the user back to the correct home
                history.replace('/login');
                return;
            }
        } else if (location.state.id == null){
            eventID = localStorage.getItem('currEventID');
            if (eventID == null){
                //send the user back to the correct home
                history.replace('/login');
                return;
            }
        } else {
            eventID = location.state.id;
            localStorage.setItem('currEventID', location.state.id);
        }

        //Check if eventID is from one of the events the collaborator can access
        for (let i = 0; i < events.length; i++){
            if (eventID == events[i].id){ //If I find the event, load its data and continue
                setCurrEventData(events[i]);
                FetchCollaboratorEventData(eventID, setIsLoading, setClienti, setStanze, setPacchetti, setRoomTypes, setTesseramenti);
                return;
            }
        }

        //Getting here means the collaborator is not allowed to access the event or our events list is outdated or empty (from a refresh, mainly)
        //Re-fetch events and verify if the collaborator has access to the current event selected, otherwise, kick them out back to /collaborator-home
        VerifyCollaboratorEvents(eventID, setEvents, setCurrEventData, setIsLoading, history, setClienti, setStanze, setPacchetti, setRoomTypes, setTesseramenti);

    }, [])

    //FOR TESTING
    // const [clienti, setClienti] = useState([
    //     {id: 1, nome: 'John', cognome: 'Doe', sesso: 'M', nascita: '18/09/1974', indirizzo: 'Via a Caso 6, Parma', telefono: '333 3333333', email: 'john.doe@email.com', idPacchetto: 1, nomePacchetto: 'Pacchetto A', prezzoPacchetto: 150, durataPacchetto: 3, dataInizioPacchetto: '01/02/2021', dataFinePacchetto: '04/02/2021', notePacchetto: 'Pacchetto base senza serate o eventi inclusi', idStanza: 1, codiceStanza: 'T3', dataIn: '01/02/2021', dataOut: '04/02/2021', nomeHotel: 'Hotel A Caso' },
    //     {id: 2, nome: 'John', cognome: 'Doe', sesso: 'M', nascita: '18/09/1974', indirizzo: 'Via a Caso 6, Parma', telefono: '333 3333333', email: 'john.doe@email.com', idPacchetto: 4, nomePacchetto: 'Pacchetto B', prezzoPacchetto: '', durataPacchetto: '', dataInizioPacchetto: '', dataFinePacchetto: '', notePacchetto: ''},
    //     {id: 3, nome: 'AAA', cognome: 'bbb', sesso: 'M', nascita: '18/09/1974', indirizzo: 'Via a Caso 6, Parma', telefono: '333 3333333', email: 'john.doe@email.com'},
    //     {id: 4, nome: 'BBB', cognome: 'ccc', sesso: 'M', nascita: '18/09/1974', indirizzo: 'Via a Caso 6, Parma', telefono: '333 3333333', email: 'john.doe@email.com'},
    //     {id: 5, nome: 'CCC', cognome: 'aaa', sesso: 'M', nascita: '18/09/1974', indirizzo: 'Via a Caso 6, Parma', telefono: '333 3333333', email: 'john.doe@email.com'},
    //     {id: 6, nome: 'DDD', cognome: 'eee', sesso: 'M', nascita: '18/09/1974', indirizzo: 'Via a Caso 6, Parma', telefono: '333 3333333', email: 'john.doe@email.com', idPacchetto: 1, nomePacchetto: 'Pacchetto A', prezzoPacchetto: '', durataPacchetto: '', dataInizioPacchetto: '', dataFinePacchetto: '', notePacchetto: '', idStanza: 1, codiceStanza: 'T3', dataIn: '01/02/2021', dataOut: '04/02/2021', nomeHotel: 'Hotel A Caso'},
    //     {id: 7, nome: 'DDD', sesso: 'M', nascita: '18/09/1974', indirizzo: 'Via a Caso 6, Parma', telefono: '333 3333333', email: 'john.doe@email.com'},
    //     {id: 8, nome: 'DDD', cognome: 'eee', sesso: 'M', nascita: '18/09/1974', indirizzo: 'Via a Caso 6, Parma', telefono: '333 3333333', email: 'john.doe@email.com', idPacchetto: 1, nomePacchetto: 'Pacchetto A', prezzoPacchetto: '', durataPacchetto: '', dataInizioPacchetto: '', dataFinePacchetto: '', notePacchetto: '', idStanza: 1, codiceStanza: 'T3', dataIn: '01/02/2021', dataOut: '04/02/2021', nomeHotel: 'Hotel A Caso'}
    // ]);

    const [currentlyEditedClient, setCurrentlyEditedClient] = useState();
    const [currentClientsOrder, setCurrentClientsOrder] = useState('cognomeAZ');
    const [currentOverlayPage, setCurrentOverlayPage] = useState('none'); //Works as page selector, we are going to conditionally render overlays

    //FOR TESTING
    // const [stanze, setStanze] = useState([
    //     {id: 1, numero: 3, codice: 'T3', note: '', noteModificabili: false, capienza: 3, postiOccupati: 2, piena: false, matrimoniale: true, tipo: 'Tripla', idHotel: 22, nomeHotel: 'Hotel A Caso', collocati: [{idPartecipante: 1, idCollaboratore: '', nome: 'John', cognome:'Doe', idPacchettoCliente: 1, nomePacchettoCliente: 'Pacchetto A', dataIn: '01/02/2021', dataOut: '04/02/2021'}, {idPartecipante: 2, idCollaboratore: '', nome: 'Tal', cognome:'dei Tali', idPacchettoCliente: 1, nomePacchettoCliente: 'Pacchetto A', dataIn: '01/02/2021', dataOut: '04/02/2021'}] },
    //     {id: 5, numero: 5, codice: 'T5', note: '', noteModificabili: false, capienza: 3, postiOccupati: 2, piena: true, matrimoniale: true, tipo: 'Tripla', idHotel: 22, nomeHotel: 'Hotel A Caso', collocati: [{idPartecipante: 1, idCollaboratore: '', nome: 'John', cognome:'Doe', idPacchettoCliente: 1, nomePacchettoCliente: 'Pacchetto A', dataIn: '01/02/2021', dataOut: '04/02/2021'}, {idPartecipante: 2, idCollaboratore: '', nome: 'Tal', cognome:'dei Tali', idPacchettoCliente: 1, nomePacchettoCliente: 'Pacchetto A', dataIn: '01/02/2021', dataOut: '04/02/2021'}] },
    //     {id: 2, numero: 1, codice: 'S1', note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec laoreet pellentesque nisl, vitae placerat ante semper nec.', noteModificabili: false, capienza: 1, postiOccupati: 1, piena: true, matrimoniale: false, tipo: 'Singola', idHotel: 22, nomeHotel: 'Hotel A Caso', collocati: [{idPartecipante: 1, idCollaboratore: '', nome: 'John', cognome:'Doe', idPacchettoCliente: 1, nomePacchettoCliente: 'Pacchetto A', dataIn: '01/02/2021', dataOut: '04/02/2021'}] },
    //     {id: 3, numero: 2, codice: 'D2', note: '', noteModificabili: true, capienza: 2, postiOccupati: 0, piena: false, matrimoniale: false, tipo: 'Doppia', idHotel: 34, nomeHotel: 'Altro Hotel Meno A Caso', collocati: [] },
    //     {id: 4, numero: 4, codice: 'S4', note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.', noteModificabili: true, capienza: 1, postiOccupati: 1, piena: true, matrimoniale: false, tipo: 'Singola', idHotel: 22, nomeHotel: 'Hotel A Caso', collocati: [{idPartecipante: 1, idCollaboratore: '', nome: 'John', cognome:'Doe', nomePacchettoCliente: 'Pacchetto B', dataIn: '01/02/2021', dataOut: '04/02/2021'}] }
    // ]);

    const [currentlyEditedRoom, setCurrentlyEditedRoom] = useState();

    //FOR TESTING
    // const [pacchetti, setPacchetti] = useState([
    //     {id: 1, nome: 'Pacchetto A', prezzo: 150.00, durata: 3, dataInizio: '01/02/2021', dataFine: '04/02/2021', note: 'Pacchetto base senza serate o eventi inclusi'},
    //     {id: 2, nome: 'Pacchetto B', prezzo: 200.00, durata: 4, dataInizio: '01/02/2021', dataFine: '05/02/2021', note: 'Pacchetto lungo'},
    //     {id: 3, nome: 'Pacchetto C', prezzo: 250.00, durata: 5, dataInizio: '01/02/2021', dataFine: '06/02/2021', note: 'Pacchetto lungo più lungo'}
    // ])

    const [fullInfoElement, setFullInfoElement] = useState();

    //FOR TESTING
    // const [roomTypes, setRoomTypes] = useState([
    //     {tipo: 'Singola', capienza: 1},
    //     {tipo: 'Doppia', capienza: 2},
    //     {tipo: 'Tripla', capienza: 3},
    //     {tipo: 'Suite', capienza: 4}
    // ])
    
    const [mostraClientiCollocati, setMostraClientiCollocati] = useState(false);
    const [mostraSoloTesserati, setMostraSoloTesserati] = useState(false);
    const [clientGroups, setClientGroups] = useState([]);
    const [roomGroups, setRoomGroups] = useState([]);
    const [roomsGroupingKey, setRoomsGroupingKey] = useState('none');

    // const CheckClienteIsTesserato = (cf) => {
    //     for (let i = 0; i < tesseramenti.length; i++) {
    //         if (tesseramenti[i].cf?.toLowerCase() == cf?.toLowerCase()) return tesseramenti[i].tesseramentoAttivo;
    //     }

    //     return false;
    // }

    const SortClients = (orderClientsBy) => {
        setCurrentClientsOrder(orderClientsBy);
        let sortedClients = [...clienti];

        if (orderClientsBy === 'nomeAZ'){

            sortedClients.sort((a, b) => {
                let keyA = a.nome.toLowerCase(), keyB = b.nome.toLowerCase();
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

        } else if (orderClientsBy === 'nomeZA') {

            sortedClients.sort((a, b) => {
                let keyA = a.nome.toLowerCase(), keyB = b.nome.toLowerCase();
                if (keyA < keyB) return 1;
                if (keyA > keyB) return -1;
                return 0;
            });

        } else if (orderClientsBy === 'cognomeAZ') {

            sortedClients.sort((a, b) => {
                if (a.cognome == null){ return 1; }
                if (b.cognome == null){ return -1; }

                let keyA = a.cognome.toLowerCase(), keyB = b.cognome.toLowerCase();
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

        } else if (orderClientsBy === 'cognomeZA') {

            sortedClients.sort((a, b) => {
                if (a.cognome == null){ return 1; }
                if (b.cognome == null){ return -1; }

                let keyA = a.cognome.toLowerCase(), keyB = b.cognome.toLowerCase();
                if (keyA < keyB) return 1;
                if (keyA > keyB) return -1;
                return 0;
            });

        }

        setClienti([...sortedClients]);

    }

    const SortClientsAfterUpdate = (clientiUpdated) => {
        if (currentClientsOrder === 'nomeAZ'){

            clientiUpdated.sort((a, b) => {
                let keyA = a.nome.toLowerCase(), keyB = b.nome.toLowerCase();
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

        } else if (currentClientsOrder === 'nomeZA') {

            clientiUpdated.sort((a, b) => {
                let keyA = a.nome.toLowerCase(), keyB = b.nome.toLowerCase();
                if (keyA < keyB) return 1;
                if (keyA > keyB) return -1;
                return 0;
            });

        } else if (currentClientsOrder === 'cognomeAZ') {

            clientiUpdated.sort((a, b) => {
                if (a.cognome == null){ return 1; }
                if (b.cognome == null){ return -1; }

                let keyA = a.cognome.toLowerCase(), keyB = b.cognome.toLowerCase();
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

        } else if (currentClientsOrder === 'cognomeZA') {

            clientiUpdated.sort((a, b) => {
                if (a.cognome == null){ return 1; }
                if (b.cognome == null){ return -1; }

                let keyA = a.cognome.toLowerCase(), keyB = b.cognome.toLowerCase();
                if (keyA < keyB) return 1;
                if (keyA > keyB) return -1;
                return 0;
            });

        }

        return clientiUpdated;

    }

    const SortRooms = (orderRoomsBy) => {
        let sortedRooms = [...stanze];

        if (orderRoomsBy === 'numeroCrescente'){

            sortedRooms.sort((a, b) => {
                let keyA = a.numero, keyB = b.numero;
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

        } else if (orderRoomsBy === 'numeroDecrescente') {

            sortedRooms.sort((a, b) => {
                let keyA = a.numero, keyB = b.numero;
                if (keyA < keyB) return 1;
                if (keyA > keyB) return -1;
                return 0;
            });

        } else if (orderRoomsBy === 'capienzaCrescente') {

            sortedRooms.sort((a, b) => {
                let keyA = a.capienza, keyB = b.capienza;
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

        } else if (orderRoomsBy === 'capienzaDecrescente') {

            sortedRooms.sort((a, b) => {
                let keyA = a.capienza, keyB = b.capienza;
                if (keyA < keyB) return 1;
                if (keyA > keyB) return -1;
                return 0;
            });

        } else if (orderRoomsBy === 'liberiCrescente') {

            sortedRooms.sort((a, b) => {
                let keyA = a.capienza - a.postiOccupati, keyB = b.capienza - b.postiOccupati;
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

        } else if (orderRoomsBy === 'liberiDecrescente') {

            sortedRooms.sort((a, b) => {
                let keyA = a.capienza - a.postiOccupati, keyB = b.capienza - b.postiOccupati;
                if (keyA < keyB) return 1;
                if (keyA > keyB) return -1;
                return 0;
            });

        }

        setStanze([...sortedRooms]);

    }

    const GroupClients = (groupClientsBy) => {

        if (groupClientsBy === 'senzaConCollocati'){

            setClientGroups(['Senza Pacchetto', 'Con Pacchetto', 'Collocati']);

        } else if (groupClientsBy === 'collocatiConSenza') {

            setClientGroups(['Collocati', 'Con Pacchetto', 'Senza Pacchetto']);

        } else { //default to 'noGrouping'

            setClientGroups([]);

        }

    }

    const GroupRooms = (groupRoomsBy) => {
        let newGroups = [];
        
        if (groupRoomsBy === 'pieneParzialiVuote'){

            setRoomsGroupingKey('special');
            setRoomGroups(['Piene', 'Parzialmente Piene', 'Vuote']);

        } else if (groupRoomsBy === 'vuoteParzialiPiene') {

            setRoomsGroupingKey('special');
            setRoomGroups(['Vuote', 'Parzialmente Piene', 'Piene']);

        } else if (groupRoomsBy === 'tipo') {

            setRoomsGroupingKey(groupRoomsBy);
            stanze.forEach(stanza => {
                if (!(newGroups.includes(stanza.tipo))){
                    newGroups.push(stanza.tipo);
                }
            });
            newGroups.sort((a, b) => {
                let keyA, keyB;
                for (let i = 0; i < roomTypes.length; i++){
                    if (roomTypes[i].tipo == a) {keyA=roomTypes[i].capienza};
                    if (roomTypes[i].tipo == b) {keyB=roomTypes[i].capienza};
                }
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });
            setRoomGroups([...newGroups]);

        } else if (groupRoomsBy === 'nomeHotel') {

            setRoomsGroupingKey(groupRoomsBy);
            stanze.forEach(stanza => {
                if (!(newGroups.includes(stanza.nomeHotel))){
                    newGroups.push(stanza.nomeHotel);
                }
            });
            newGroups.sort();
            setRoomGroups([...newGroups]);

        } else { //default to 'noGrouping'

            setRoomsGroupingKey('none');
            setRoomGroups([]);

        }

    }

    const ExpandAllClients = () => {
        let clientsList = document.getElementsByClassName('client-div');
        let togglesList = document.getElementsByClassName('toggle-client-div');

        for (let i = 0; i < clientsList.length; i++){
            clientsList[i].querySelector('#client-data').className = 'client-data';
        }

        for (let i = 0; i < togglesList.length; i++){
            togglesList[i].children[0].getElementsByClassName('plus')[0].style.display = 'none';
            togglesList[i].children[0].getElementsByClassName('minus')[0].style.display = 'block';
        }

    }

    const CollapseAllClients = () => {
        let clientsList = document.getElementsByClassName('client-div');
        let togglesList = document.getElementsByClassName('toggle-client-div');

        for (let i = 0; i < clientsList.length; i++){
            clientsList[i].querySelector('#client-data').className = 'client-data client-data-collapsed';
        }

        for (let i = 0; i < togglesList.length; i++){
            togglesList[i].children[0].getElementsByClassName('plus')[0].style.display = 'block';
            togglesList[i].children[0].getElementsByClassName('minus')[0].style.display = 'none';
        }

    }

    const ExpandAllRooms = () => {
        let roomsList = document.getElementsByClassName('room-div');
        let togglesList = document.getElementsByClassName('toggle-room-div');

        for (let i = 0; i < roomsList.length; i++){
            roomsList[i].querySelector('#room-data').className = 'room-data';
        }

        for (let i = 0; i < togglesList.length; i++){
            togglesList[i].children[0].getElementsByClassName('plus')[0].style.display = 'none';
            togglesList[i].children[0].getElementsByClassName('minus')[0].style.display = 'block';
        }

    }

    const CollapseAllRooms = () => {
        let roomsList = document.getElementsByClassName('room-div');
        let togglesList = document.getElementsByClassName('toggle-room-div');

        for (let i = 0; i < roomsList.length; i++){
            roomsList[i].querySelector('#room-data').className = 'room-data room-data-collapsed';
        }

        for (let i = 0; i < togglesList.length; i++){
            togglesList[i].children[0].getElementsByClassName('plus')[0].style.display = 'block';
            togglesList[i].children[0].getElementsByClassName('minus')[0].style.display = 'none';
        }

    }

    const PassInfoElement = (e, id) => {

        if (e.currentTarget.className === 'client-name'){
            for (let i = 0; i < clienti.length; i++){
                if (clienti[i].id == id){
                    setFullInfoElement(clienti[i]);
                    break;
                }
            }
        } else if (e.currentTarget.className === 'room-name'){
            for (let i = 0; i < stanze.length; i++){
                if (stanze[i].id == id){
                    setFullInfoElement(stanze[i]);
                    break;
                }
            }
        }

    }

    const PassClientInfoFromRoom = (id) => {

        for (let i = 0; i < clienti.length; i++){
            if (clienti[i].id == id){
                setFullInfoElement(clienti[i]);
                break;
            }
        }

    }

    const ToggleMatrimoniale = async (id) => {

        if (queryingServer) return; //Don't do anything if we are already querying the server

        setQueryingServer(true);
        SetStatusLed(true);

        let res = false;

        for (let i = 0; i < stanze.length; i++){
            if (stanze[i].id == id){
                let updatedRoomData = {
                    idStanza: id,
                    piena: stanze[i].piena,
                    matrimoniale: !stanze[i].matrimoniale,
                    note: stanze[i].note == '' ? null : stanze[i].note
                }

                res = await CollaboratorUpdateRoomData(updatedRoomData);
                break;
            }
        }

        setQueryingServer(false);
        SetStatusLed(false);

        if (res) {
            let stanzeUpdated = [...stanze];

            for (let i = 0; i < stanzeUpdated.length; i++){
                if (stanzeUpdated[i].id == id){
                    stanzeUpdated[i].matrimoniale = !stanzeUpdated[i].matrimoniale;
                    break;
                }
            }

            setStanze([...stanzeUpdated]);
        }
        //Otherwise do nothing (the error displaying is already handled by CollaboratorUpdateRoomData)
    }

    const TogglePiena = async (id) => {

        if (queryingServer) return; //Don't do anything if we are already querying the server

        setQueryingServer(true);
        SetStatusLed(true);

        let res = false;

        for (let i = 0; i < stanze.length; i++){
            if (stanze[i].id == id){
                let updatedRoomData = {
                    idStanza: id,
                    piena: !stanze[i].piena,
                    matrimoniale: stanze[i].matrimoniale,
                    note: stanze[i].note == '' ? null : stanze[i].note
                }

                res = await CollaboratorUpdateRoomData(updatedRoomData);
                break;
            }
        }

        setQueryingServer(false);
        SetStatusLed(false);

        if (res) {
            let stanzeUpdated = [...stanze];

            for (let i = 0; i < stanzeUpdated.length; i++){
                if (stanzeUpdated[i].id == id){
                    stanzeUpdated[i].piena = !stanzeUpdated[i].piena;
                    break;
                }
            }

            setStanze([...stanzeUpdated]);
        }
        //Otherwise do nothing (the error displaying is already handled by CollaboratorUpdateRoomData)
    }

    const CreateNewClient = ( newClient ) => {

        let clientiUpdated = [...clienti];
        let stanzeUpdated = [...stanze];

        let clientToPush = {
            id: newClient.id,
            cf: newClient.cf,
            nome: newClient.nomePartecipante,
            cognome: newClient.cognomePartecipante,
            sesso: newClient.sessoPartecipante,
            nascita: newClient.nascitaPartecipante == null ? null : newClient.nascitaPartecipante.split('-').reverse().join('/'),
            indirizzo: newClient.indirizzoPartecipante,
            telefono: newClient.telefonoPartecipante,
            presenza: newClient.presenzaPartecipante,
            email: newClient.mailPartecipante,
            note: newClient.notePartecipante,
            noteModificabili: true,
        }

        if (newClient.idPacchetto){
            clientToPush.idPacchetto = newClient.idPacchetto;
            for (let i = 0; i < pacchetti.length; i++){
                if (pacchetti[i].id == clientToPush.idPacchetto){
                    clientToPush.nomePacchetto = pacchetti[i].nome;
                    clientToPush.prezzoPacchetto = pacchetti[i].prezzo;
                    clientToPush.durataPacchetto = pacchetti[i].durata;
                    clientToPush.dataInizioPacchetto = pacchetti[i].dataInizio;
                    clientToPush.dataFinePacchetto = pacchetti[i].dataFine;
                    clientToPush.notePacchetto = pacchetti[i].note;
                    break;
                }
            }

            if(newClient.idStanza){
                clientToPush.idStanza = newClient.idStanza;
                clientToPush.dataIn = newClient.dataIn == null ? null : newClient.dataIn.split('-').reverse().join('/');
                clientToPush.dataOut = newClient.dataOut == null ? null : newClient.dataOut.split('-').reverse().join('/');
                for (let i = 0; i < stanzeUpdated.length; i++){
                    if (stanzeUpdated[i].id == clientToPush.idStanza){
                        //Add data to client
                        clientToPush.codiceStanza = stanzeUpdated[i].codice;
                        clientToPush.nomeHotel = stanzeUpdated[i].nomeHotel;
    
                        //TODO: MAY need to add cf field to collocati, needs testing
                        //Add client to room's 'collocati'
                        let newCollocato = {
                            idPartecipante: clientToPush.id,
                            idCollaboratore: sessionData.id,
                            nome: clientToPush.nome,
                            cognome: clientToPush.cognome,
                            cf: clientToPush.cf,
                            tesserato: clientToPush.tesserato,
                            idPacchettoCliente: clientToPush.idPacchetto,
                            nomePacchettoCliente: clientToPush.nomePacchetto,
                            dataIn: clientToPush.dataIn,
                            dataOut: clientToPush.dataOut
                        }
    
                        stanzeUpdated[i].collocati.push(newCollocato);
                        stanzeUpdated[i].postiOccupati++;
                        if (stanzeUpdated[i].postiOccupati >= stanzeUpdated[i].capienza){
                            stanzeUpdated[i].piena = true;
                        }
                        break;
                    }
                }
            }
        }

        clientiUpdated.push(clientToPush);
        clientiUpdated = SortClientsAfterUpdate(clientiUpdated);
        setClienti([...clientiUpdated]);
        setStanze([...stanzeUpdated]);

    }

    const ModifyClient = ( updatedClient ) => {

        let clientiUpdated = [...clienti];
        let stanzeUpdated = [...stanze];

        for (let i = 0; i < clientiUpdated.length; i++){
            if (clientiUpdated[i].id == updatedClient.id){
                clientiUpdated[i] = updatedClient;
            }
        }

        //TODO: MAY need to add cf to collocati, needs testing
        for (let i = 0; i < stanzeUpdated.length; i++){
            for ( let j = 0; j < stanzeUpdated[i].collocati.length; j++){
                if (stanzeUpdated[i].collocati[j].idPartecipante == updatedClient.id){
                    stanzeUpdated[i].collocati[j].nome = updatedClient.nome;
                    stanzeUpdated[i].collocati[j].cognome = updatedClient.cognome;
                    stanzeUpdated[i].collocati[j].cf = updatedClient.cf;
                    stanzeUpdated[i].collocati[j].idPacchettoCliente = updatedClient.idPacchetto;
                    stanzeUpdated[i].collocati[j].nomePacchettoCliente = updatedClient.nomePacchetto;
                    stanzeUpdated[i].collocati[j].dataIn = updatedClient.dataIn;
                    stanzeUpdated[i].collocati[j].dataOut = updatedClient.dataOut;
                }
            }
        }

        if(fullInfoElement != null && fullInfoElement.id == updatedClient.id) //If we are displaying the updated client in the info div, update the data we are passing to the info div as well
                setFullInfoElement(updatedClient);

        clientiUpdated = SortClientsAfterUpdate(clientiUpdated);

        setClienti([...clientiUpdated]);
        setStanze([...stanzeUpdated]);

        return true;

    }

    const SetClientBundle = ( pacchetto ) => {
        let updatedClient = currentlyEditedClient;

        updatedClient.idPacchetto = pacchetto.id;
        updatedClient.nomePacchetto = pacchetto.nome;
        updatedClient.prezzoPacchetto = pacchetto.prezzo;
        updatedClient.durataPacchetto = pacchetto.durata;
        updatedClient.dataInizioPacchetto = pacchetto.dataInizio;
        updatedClient.dataFinePacchetto = pacchetto.dataFine;
        updatedClient.notePacchetto = pacchetto.note;

        return ModifyClient(updatedClient);

    }

    const AddClientToRoom = ( id, dataIn, dataOut ) => {
        let stanzeUpdated = [...stanze];

        RemoveClientFromRooms(currentlyEditedClient.id); //Each client can only be in one room at a time. If the client is already listed somewhere, this is an update either changing the duration of the stay or changing rooms, either way delete the old version of the client in collocati before pushing the new one

        for (let i = 0; i < stanzeUpdated.length; i++){
            if (stanzeUpdated[i].id == id){

                stanzeUpdated[i].collocati.push({idPartecipante: currentlyEditedClient.id, idCollaboratore: currentlyEditedClient.idCollaboratore, nome: currentlyEditedClient.nome, cognome: currentlyEditedClient.cognome, cf: currentlyEditedClient.cf, tesserato: currentlyEditedClient.tesserato, idPacchettoCliente: currentlyEditedClient.idPacchetto, nomePacchettoCliente: currentlyEditedClient.nomePacchetto, dataIn: dataIn, dataOut: dataOut});
                stanzeUpdated[i].postiOccupati = stanzeUpdated[i].collocati.length;
                if (stanzeUpdated[i].capienza == stanzeUpdated[i].postiOccupati) { stanzeUpdated[i].piena = true; }

                setStanze([...stanzeUpdated]);
                return true;
            }
        }

        return false;

    }

    const SetClientRoom = ( stanza ) => {
        let updatedClient = currentlyEditedClient;

        if (updatedClient.idPacchetto != null && updatedClient.idPacchetto != ''){ //Only add room if the client has a bundle, this shouldn't be possible, but better safe than sorry
            updatedClient.idStanza = stanza.id;
            updatedClient.codiceStanza = stanza.codice;
            updatedClient.dataIn = stanza.dataIn;
            updatedClient.dataOut = stanza.dataOut;
            updatedClient.nomeHotel = stanza.nomeHotel;
        } else {
            return false;
        }

        if (!AddClientToRoom(stanza.id, stanza.dataIn, stanza.dataOut)) { return false; }

        return ModifyClient(updatedClient);

    }

    const UpdateClientData = (id) => {

        for (let i = 0; i < clienti.length; i++){
            if (clienti[i].id == id){
                if (currentlyEditedClient == clienti[i]){
                    setCurrentOverlayPage('modifyClientData');
                    //ShowModifyClientDataOverlay();
                }
                else{
                    setCurrentlyEditedClient(clienti[i]);
                    setCurrentOverlayPage('modifyClientData');
                }
                return true;
            }
        }

        return false
    }

    const DeleteClientPressed = (id) => {

        for (let i = 0; i < clienti.length; i++){
            if (clienti[i].id == id){
                if (currentlyEditedClient == clienti[i]){
                    setCurrentOverlayPage('confirmDeleteClient');
                    //ShowConfirmDeleteClientOverlay();
                }
                else{
                    setCurrentlyEditedClient(clienti[i]);
                    setCurrentOverlayPage('confirmDeleteClient');
                }
                return true;
            }
        }

        return false
    }

    const RoomStaysPressed = (id) => {

        for (let i = 0; i < stanze.length; i++){
            if (stanze[i].id == id){
                if (currentlyEditedRoom == stanze[i]){
                    setCurrentOverlayPage('roomStays');
                }
                else{
                    setCurrentlyEditedRoom(stanze[i]);
                    setCurrentOverlayPage('roomStays');
                }
                return true;
            }
        }

        return false

    }

    const RoomNotesPlusPressed = (id) => {

        for (let i = 0; i < stanze.length; i++){
            if (stanze[i].id == id){
                if (currentlyEditedRoom == stanze[i]){
                    setCurrentOverlayPage('changeRoomNotes');
                }
                else{
                    setCurrentlyEditedRoom(stanze[i]);
                    setCurrentOverlayPage('changeRoomNotes');
                }
                return true;
            }
        }

        return false

    }

    const ClientNotesPlusPressed = (id) => {

        for (let i = 0; i < stanze.length; i++){
            if (clienti[i].id == id){
                if (currentlyEditedClient == clienti[i]){
                    setCurrentOverlayPage('changeClientNotes');
                }
                else{
                    setCurrentlyEditedClient(clienti[i]);
                    setCurrentOverlayPage('changeClientNotes');
                }
                return true;
            }
        }

        return false

    }

    const AddBundleToClient = (id) => {

        for (let i = 0; i < clienti.length; i++){
            if (clienti[i].id == id){
                if (currentlyEditedClient == clienti[i]){
                    setCurrentOverlayPage('addBundle');
                    //ShowAddBundleOverlay();
                }
                else{
                    setCurrentlyEditedClient(clienti[i]);
                    setCurrentOverlayPage('addBundle');
                }
                return true;
            }
        }

        return false

    }

    const PlaceClientIntoRoom = (id) => {

        for (let i = 0; i < clienti.length; i++){
            if (clienti[i].id == id){
                if (currentlyEditedClient == clienti[i]){
                    setCurrentOverlayPage('selectRoom');
                    //ShowSelectRoomOverlay();
                }
                else{
                    setCurrentlyEditedClient(clienti[i]);
                    setCurrentOverlayPage('selectRoom');
                }
                return true;
            }
        }

        return false
    }

    const RemoveClientFromRooms = ( id ) => {
        let stanzeUpdated = [...stanze];

        for (let i = 0; i < stanzeUpdated.length; i++){
            for(let j = 0; j < stanzeUpdated[i].collocati.length; j++){
                if (stanzeUpdated[i].collocati[j].idPartecipante == id){ 
                    stanzeUpdated[i].collocati.splice(j, 1);
                    stanzeUpdated[i].postiOccupati--;
                    stanzeUpdated[i].piena = false;
                    break;
                }
            }
        }

        setStanze([...stanzeUpdated]);
        return true;

    }

    const DeleteClient = (id) => {

        let res = RemoveClientFromRooms(id);
        let clientiUpdated = [...clienti];
        
        if (res){
            if (currentlyEditedClient != null && currentlyEditedClient.id == id)
                setCurrentlyEditedClient(null);
            
            if(fullInfoElement != null && fullInfoElement.id == id)
                setFullInfoElement(null);
            
            for(let i = 0; i < clientiUpdated.length; i++){
                if (clientiUpdated[i].id == id){ 
                    clientiUpdated.splice(i, 1);
                    break;
                }
            }
        }

        setClienti([...clientiUpdated]);
        return true;
        
    }

    const RemoveBundleFromClient = async (id) => {

        if (queryingServer) return; //Don't do anything if we are already querying the server

        setQueryingServer(true);
        SetStatusLed(true);

        let res = await RemoveDBClientBundle(id);

        setQueryingServer(false);
        SetStatusLed(false);

        if (res) {
            let clientiUpdated = [...clienti];

            for (let i = 0; i < clientiUpdated.length; i++){
                if (clientiUpdated[i].id == id){
                    delete clientiUpdated[i].idPacchetto;
                    delete clientiUpdated[i].nomePacchetto;
                    delete clientiUpdated[i].prezzoPacchetto;
                    delete clientiUpdated[i].durataPacchetto;
                    delete clientiUpdated[i].dataInizioPacchetto;
                    delete clientiUpdated[i].dataFinePacchetto;
                    delete clientiUpdated[i].notePacchetto;
                    delete clientiUpdated[i].idStanza;
                    delete clientiUpdated[i].codiceStanza;
                    delete clientiUpdated[i].dataIn;
                    delete clientiUpdated[i].dataOut;
                    delete clientiUpdated[i].nomeHotel;
    
                    RemoveClientFromRooms(id);
    
                    setClienti([...clientiUpdated]); //Unnecessary since we are shallow copying the state
    
                    return;
                }
            }
        }
        //Otherwise do nothing (the error displaying is already handled by RemoveDBClientBundle)

    }

    const DeleteClientStay = async (id) => {

        if (queryingServer) return; //Don't do anything if we are already querying the server

        setQueryingServer(true);
        SetStatusLed(true);

        let res = await RemoveDBClientFromRoom(id);

        setQueryingServer(false);
        SetStatusLed(false);

        if (res) {
            let clientiUpdated = [...clienti];

            RemoveClientFromRooms(id);

            for (let i = 0; i < clientiUpdated.length; i++){
                if (clientiUpdated[i].id == id){
                    delete clientiUpdated[i].idStanza;
                    delete clientiUpdated[i].codiceStanza;
                    delete clientiUpdated[i].dataIn;
                    delete clientiUpdated[i].dataOut;
                    delete clientiUpdated[i].nomeHotel;

                    setClienti([...clientiUpdated]);

                    return;
                }
            }
        }
    }

    const ConfirmRoomStaysCreation = async ( client ) => {

        if (queryingServer) return; //Don't do anything if we are already querying the server

        setQueryingServer(true);
        SetStatusLed(true);

        if (client.id == null || client.id == ''){ //New Client
            
            let clientCreationData = {
                cfPartecipante: client.cf == '' ? null : client.cf,
                nomePartecipante: client.nome == '' ? null : client.nome,
                cognomePartecipante: client.cognome == '' ? null : client.cognome,
                sessoPartecipante: client.sesso == '' ? null : client.sesso,
                nascitaPartecipante: client.nascita == '' ? null : client.nascita.split('/').reverse().join('-'),
                indirizzoPartecipante: client.indirizzo == '' ? null : client.indirizzo,
                mailPartecipante: client.email == '' ? null : client.email,
                telefonoPartecipante: client.telefono == '' ? null : client.telefono,
                notePartecipante: client.note == '' ? null : client.note,
                partecipanteNoteModificabili: client.noteModificabili ?? true,
                idEvento: localStorage.getItem('currEventID') == '' ? null: localStorage.getItem('currEventID'),
                idCollaboratore: sessionData.id == '' ? null : sessionData.id,
                idPacchetto: client.idPacchetto == '' ? null : client.idPacchetto,
                acconto: 0.00,
                saldo: client.prezzoPacchetto === '' ? null : client.prezzoPacchetto,
                totale: client.prezzoPacchetto === '' ? null : client.prezzoPacchetto,
                stato: 'Da Pagare',
                idStanza: client.idStanza == '' ? null : client.idStanza,
                dataIn: client.dataIn == '' ? null : client.dataIn.split('/').reverse().join('-'),
                dataOut: client.dataOut == '' ? null : client.dataOut.split('/').reverse().join('-')
            }

            let newClientID = await CreateClient(clientCreationData);

            setQueryingServer(false);
            SetStatusLed(false);

            if (newClientID) {
                client.id = newClientID;

                let clientiUpdated = [...clienti];

                clientiUpdated.push(client);
                clientiUpdated = SortClientsAfterUpdate(clientiUpdated);

                setClienti([...clientiUpdated]);
            } else {
                return false;
            }

        } else { //Existing Client
            let res = false;

            for (let i = 0; i < clienti.length; i++){
                if (clienti[i].id == client.id){
                    if (clienti[i].idPacchetto == '' || clienti[i].idPacchetto == null){ //Client did not have a bundle, we need to create both prenotazione and pernottamento

                        let prenotazioneAndPernottamentoData = {
                            idPartecipante: client.id,
                            idPacchetto: client.idPacchetto == '' ? null : client.idPacchetto,
                            acconto: 0.00,
                            saldo: client.prezzoPacchetto === '' ? null : client.prezzoPacchetto,
                            totale: client.prezzoPacchetto === '' ? null : client.prezzoPacchetto,
                            stato: 'Da Pagare',
                            idStanza: client.idStanza == '' ? null : client.idStanza,
                            dataIn: client.dataIn == '' ? null : client.dataIn.split('/').reverse().join('-'),
                            dataOut: client.dataOut == '' ? null : client.dataOut.split('/').reverse().join('-')
                        }

                        res = await AddBundleAndRoomToClient(prenotazioneAndPernottamentoData);

                    } else { //Client already had a bundle, we only need to create pernottamento

                        let pernottamentoData = {
                            idPartecipante: client.id,
                            idStanza: client.idStanza,
                            dataIn: client.dataIn == '' ? null : client.dataIn.split('/').reverse().join('-'),
                            dataOut: client.dataOut == '' ? null : client.dataOut.split('/').reverse().join('-')
                        }

                        res = await AddClientWithBundleToRoom(pernottamentoData);

                    }
                    break;
                }
            }

            setQueryingServer(false);
            SetStatusLed(false);

            if (res){
                ModifyClient(client);
            } else {
                return false;
            }
        }

        let stanzeUpdated = [...stanze];

        RemoveClientFromRooms(client.id); //Each client can only be in one room at a time. If the client is already listed somewhere (it shouldn't) this is to be sure

        for (let i = 0; i < stanzeUpdated.length; i++){
            if (stanzeUpdated[i].id == client.idStanza){

                stanzeUpdated[i].collocati.push({idPartecipante: client.id, idCollaboratore: client.idCollaboratore, nome: client.nome, cognome: client.cognome, cf: client.cf, tesserato: client.tesserato, idPacchettoCliente: client.idPacchetto, nomePacchettoCliente: client.nomePacchetto, dataIn: client.dataIn, dataOut: client.dataOut});
                stanzeUpdated[i].postiOccupati = stanzeUpdated[i].collocati.length;
                if (stanzeUpdated[i].capienza == stanzeUpdated[i].postiOccupati) { stanzeUpdated[i].piena = true; }

                setStanze([...stanzeUpdated]);
                return true;
            }
        }

        return true; //Returning true because if the room didn't exist, the query to the server would have failed, so the change actually did go through

    }

    const UpdateRoomNotes = ( note ) => {
        let stanzeUpdated = [...stanze];

        for (let i = 0; i < stanzeUpdated.length; i++){
            if (stanzeUpdated[i].id == currentlyEditedRoom.id){
                stanzeUpdated[i].note = note;
                setStanze([...stanzeUpdated]);
                return true;
            }
        }

        return false;

    }


    const SetStatusLed = (status) => {
        document.getElementById('status-led').className = status ? 'status-querying' : 'status-updated';
    }

    return (
        <div id='container-collaborator-event' className='container-event'>
            <CollaboratorEventCommands setCurrentOverlayPage={setCurrentOverlayPage} SortClients={SortClients} SortRooms={SortRooms} setMostraSoloTesserati={setMostraSoloTesserati} setMostraClientiCollocati={setMostraClientiCollocati} GroupClients={GroupClients} GroupRooms={GroupRooms} ExpandAllClients={ExpandAllClients} CollapseAllClients={CollapseAllClients} ExpandAllRooms={ExpandAllRooms} CollapseAllRooms={CollapseAllRooms} currEventData={currEventData} />

            {isLoading &&
                <div className='content-home'>
                    <h2 className='loading-message'>Caricamento...</h2>
                </div>
            }

            {!isLoading &&
                <div className='content-event'>
                    <div id='status-led' className='status-updated' data-tip='INDICATORE DI STATO<br><br>Verde: Tutte le modifiche sono state salvate correttamente.<br><br>Rosso: Connessione al server in corso. Se il colore rosso persiste, ricaricare la pagina per verificare che i dati siano stati aggiornati correttamente e in caso contrario contattare un amministratore o referente.' data-for='status-tooltip' data-place='bottom' data-effect='solid' data-class='status-info-tooltip' />
                    <ReactTooltip id='status-tooltip' multiline={true} />
                    <div className='content-column'>
                        <div className='content-column-header'>
                            <h2>PARTECIPANTI</h2>
                            <a className={'a-tooltip'} data-tip={'Partecipanti senza una camera:<br>' + clienti.filter(cliente => cliente.idStanza == null).length + ' su ' + clienti.length} data-for='column-info-tooltip' data-place='left' data-effect='solid' data-class='column-info-tooltip' >({clienti.filter(cliente => cliente.idStanza == null).length})</a>
                            <ReactTooltip id='column-info-tooltip' multiline={true} />
                        </div>
                        <div className='column-divider' />
                        <div id='clients-column-body' className='column-body'>

                            {clientGroups.length <= 0 && //No grouping
                                <div className='grid-content-div no-padding'>
                                    {
                                    clienti.filter(cliente => (mostraClientiCollocati || cliente.idStanza == null) && (!mostraSoloTesserati || cliente.tesserato)).map(cliente =>
                                        <Client key={cliente.id} {...cliente} eventStartDate={currEventData.dataInizio.split('/').reverse().join('-')} ClientNotesPlusPressed={ClientNotesPlusPressed} PassInfoElement={PassInfoElement} AddBundleToClient={AddBundleToClient} RemoveBundleFromClient={RemoveBundleFromClient} UpdateClientData={UpdateClientData} PlaceClientIntoRoom={PlaceClientIntoRoom} DeleteClientPressed={DeleteClientPressed} />
                                    )
                                    }
                                </div>

                                // <Masonry
                                //     items={
                                //         clienti.filter(cliente => mostraClientiCollocati || cliente.idStanza == null).map( cliente => {
                                //             return (
                                //                 {key: cliente.id, node: <Client {...cliente} ClientNotesPlusPressed={ClientNotesPlusPressed} PassInfoElement={PassInfoElement} AddBundleToClient={AddBundleToClient} RemoveBundleFromClient={RemoveBundleFromClient} UpdateClientData={UpdateClientData} PlaceClientIntoRoom={PlaceClientIntoRoom} DeleteClientPressed={DeleteClientPressed} />}
                                //             )
                                //         })
                                //     }
                                //     minColumnWidth={272}
                                //     gap={16}
                                // />
                            }

                            {clientGroups.length > 0 && //Special client grouping
                                clientGroups.map( groupName => {
                                    return(
                                        <div className='groups-div'>

                                            {groupName === 'Senza Pacchetto' &&
                                            <div className='group-div'>
                                                <h3> {groupName} </h3>
                                                <div className='group-divider' />

                                                <div className='grid-content-div no-padding'>
                                                    {
                                                    clienti.filter(cliente => (cliente.idPacchetto == null && cliente.idStanza == null) && (!mostraSoloTesserati || cliente.tesserato)).map(cliente =>
                                                        <Client key={cliente.id} {...cliente} eventStartDate={currEventData.dataInizio.split('/').reverse().join('-')} ClientNotesPlusPressed={ClientNotesPlusPressed} PassInfoElement={PassInfoElement} AddBundleToClient={AddBundleToClient} RemoveBundleFromClient={RemoveBundleFromClient} UpdateClientData={UpdateClientData} PlaceClientIntoRoom={PlaceClientIntoRoom} DeleteClientPressed={DeleteClientPressed} />
                                                    )
                                                    }
                                                </div>

                                                {/* <Masonry
                                                    items={
                                                        clienti.filter(cliente => cliente.idPacchetto == null && cliente.idStanza == null).map( cliente => {
                                                            return (
                                                                {key: cliente.id, node: <Client {...cliente} ClientNotesPlusPressed={ClientNotesPlusPressed} PassInfoElement={PassInfoElement} AddBundleToClient={AddBundleToClient} RemoveBundleFromClient={RemoveBundleFromClient} UpdateClientData={UpdateClientData} PlaceClientIntoRoom={PlaceClientIntoRoom} DeleteClientPressed={DeleteClientPressed} />}
                                                            )
                                                        })
                                                    }
                                                    minColumnWidth={272}
                                                    gap={16}
                                                /> */}

                                            </div>
                                            }

                                            {groupName === 'Con Pacchetto' &&
                                            <div className='group-div'>
                                                <h3> {groupName} </h3>
                                                <div className='group-divider' />

                                                <div className='grid-content-div no-padding'>
                                                    {
                                                    clienti.filter(cliente => (cliente.idPacchetto != null && cliente.idStanza == null) && (!mostraSoloTesserati || cliente.tesserato)).map(cliente =>
                                                        <Client key={cliente.id} {...cliente} eventStartDate={currEventData.dataInizio.split('/').reverse().join('-')} ClientNotesPlusPressed={ClientNotesPlusPressed} PassInfoElement={PassInfoElement} AddBundleToClient={AddBundleToClient} RemoveBundleFromClient={RemoveBundleFromClient} UpdateClientData={UpdateClientData} PlaceClientIntoRoom={PlaceClientIntoRoom} DeleteClientPressed={DeleteClientPressed} />
                                                    )
                                                    }
                                                </div>

                                                {/* <Masonry
                                                    items={
                                                        clienti.filter(cliente => cliente.idPacchetto != null && cliente.idStanza == null).map( cliente => {
                                                            return (
                                                                {key: cliente.id, node: <Client {...cliente} ClientNotesPlusPressed={ClientNotesPlusPressed} PassInfoElement={PassInfoElement} AddBundleToClient={AddBundleToClient} RemoveBundleFromClient={RemoveBundleFromClient} UpdateClientData={UpdateClientData} PlaceClientIntoRoom={PlaceClientIntoRoom} DeleteClientPressed={DeleteClientPressed} />}
                                                            )
                                                        })
                                                    }
                                                    minColumnWidth={272}
                                                    gap={16}
                                                /> */}
                                                
                                            </div>
                                            }

                                            {mostraClientiCollocati && groupName === 'Collocati' &&
                                            <div className='group-div'>
                                                <h3> {groupName} </h3>
                                                <div className='group-divider' />

                                                <div className='grid-content-div no-padding'>
                                                    {
                                                    clienti.filter(cliente => (cliente.idPacchetto != null && cliente.idStanza != null) && (!mostraSoloTesserati || cliente.tesserato)).map(cliente =>
                                                        <Client key={cliente.id} {...cliente} eventStartDate={currEventData.dataInizio.split('/').reverse().join('-')} ClientNotesPlusPressed={ClientNotesPlusPressed} PassInfoElement={PassInfoElement} AddBundleToClient={AddBundleToClient} RemoveBundleFromClient={RemoveBundleFromClient} UpdateClientData={UpdateClientData} PlaceClientIntoRoom={PlaceClientIntoRoom} DeleteClientPressed={DeleteClientPressed} />
                                                    )
                                                    }
                                                </div>

                                                {/* <Masonry
                                                    items={
                                                        clienti.filter(cliente => cliente.idPacchetto != null && cliente.idStanza != null).map( cliente => {
                                                            return (
                                                                {key: cliente.id, node: <Client {...cliente} ClientNotesPlusPressed={ClientNotesPlusPressed} PassInfoElement={PassInfoElement} AddBundleToClient={AddBundleToClient} RemoveBundleFromClient={RemoveBundleFromClient} UpdateClientData={UpdateClientData} PlaceClientIntoRoom={PlaceClientIntoRoom} DeleteClientPressed={DeleteClientPressed} />}
                                                            )
                                                        })
                                                    }
                                                    minColumnWidth={272}
                                                    gap={16}
                                                /> */}

                                            </div>
                                            }

                                        </div>
                                    )
                                })
                            }
                            
                            </div>
                    </div>

                    <div className='content-column'>
                        <div className='content-column-header'>
                            <h2>CAMERE</h2>
                            <a className={'a-tooltip'} data-tip={'Camere libere:<br>' + stanze.filter(stanza => stanza.piena == false).length + ' su ' + stanze.length} data-for='column-info-tooltip' data-place='left' data-effect='solid' data-class='column-info-tooltip' >({stanze.filter(stanza => stanza.piena == false).length})</a>
                            <ReactTooltip id='column-info-tooltip' multiline={true} />
                        </div>
                        <div className='column-divider' />
                        <div id='rooms-column-body' className='column-body'>

                            {roomGroups.length <= 0 && //No grouping
                                <div className='grid-content-div no-padding'>
                                    {
                                    stanze.map(stanza =>
                                        <Room key={stanza.id} {...stanza} PassInfoElement={PassInfoElement} ToggleMatrimoniale={ToggleMatrimoniale} TogglePiena={TogglePiena} PassClientInfoFromRoom={PassClientInfoFromRoom} RoomStaysPressed={RoomStaysPressed} RoomNotesPlusPressed={RoomNotesPlusPressed} />
                                    )
                                    }
                                </div>
                            
                                // <Masonry
                                //     items={
                                //         stanze.map( stanza => {
                                //             return(
                                //                 {key: stanza.id, node: <Room {...stanza} PassInfoElement={PassInfoElement} ToggleMatrimoniale={ToggleMatrimoniale} TogglePiena={TogglePiena} PassClientInfoFromRoom={PassClientInfoFromRoom} RoomStaysPressed={RoomStaysPressed} RoomNotesPlusPressed={RoomNotesPlusPressed} />}
                                //             )
                                //         })
                                //     }
                                //     minColumnWidth={272}
                                //     gap={16}
                                // />
                            }

                            {roomGroups.length > 0 && (roomGroups.includes('Vuote') && roomGroups.includes('Parzialmente Piene') && roomGroups.includes('Piene')) && //Special room grouping
                                roomGroups.map( groupName => {
                                    return(
                                        <div className='groups-div'>

                                            {groupName === 'Vuote' &&
                                            <div className='group-div'>
                                                <h3> {groupName} </h3>
                                                <div className='group-divider' />

                                                <div className='grid-content-div no-padding'>
                                                    {
                                                    stanze.filter(stanza => stanza.postiOccupati == 0 && stanza.piena == false).map(stanza =>
                                                        <Room key={stanza.id} {...stanza} PassInfoElement={PassInfoElement} ToggleMatrimoniale={ToggleMatrimoniale} TogglePiena={TogglePiena} PassClientInfoFromRoom={PassClientInfoFromRoom} RoomStaysPressed={RoomStaysPressed} RoomNotesPlusPressed={RoomNotesPlusPressed} />
                                                    )
                                                    }
                                                </div>

                                                {/* <Masonry
                                                    items={
                                                        stanze.filter(stanza => stanza.postiOccupati == 0 && stanza.piena == false).map( stanza => {
                                                            return (
                                                                {key: stanza.id, node: <Room {...stanza} PassInfoElement={PassInfoElement} ToggleMatrimoniale={ToggleMatrimoniale} TogglePiena={TogglePiena} PassClientInfoFromRoom={PassClientInfoFromRoom} RoomStaysPressed={RoomStaysPressed} RoomNotesPlusPressed={RoomNotesPlusPressed} />}
                                                            )
                                                        })
                                                    }
                                                    minColumnWidth={272}
                                                    gap={16}
                                                /> */}

                                            </div>
                                            }

                                            {groupName === 'Parzialmente Piene' &&
                                            <div className='group-div'>
                                                <h3> {groupName} </h3>
                                                <div className='group-divider' />

                                                <div className='grid-content-div no-padding'>
                                                    {
                                                    stanze.filter(stanza => stanza.postiOccupati < stanza.capienza && stanza.postiOccupati != 0 && stanza.piena == false).map(stanza =>
                                                        <Room key={stanza.id} {...stanza} PassInfoElement={PassInfoElement} ToggleMatrimoniale={ToggleMatrimoniale} TogglePiena={TogglePiena} PassClientInfoFromRoom={PassClientInfoFromRoom} RoomStaysPressed={RoomStaysPressed} RoomNotesPlusPressed={RoomNotesPlusPressed} />
                                                    )
                                                    }
                                                </div>

                                                {/* <Masonry
                                                    items={
                                                        stanze.filter(stanza => stanza.postiOccupati < stanza.capienza && stanza.postiOccupati != 0 && stanza.piena == false).map( stanza => {
                                                            return (
                                                                {key: stanza.id, node: <Room {...stanza} PassInfoElement={PassInfoElement} ToggleMatrimoniale={ToggleMatrimoniale} TogglePiena={TogglePiena} PassClientInfoFromRoom={PassClientInfoFromRoom} RoomStaysPressed={RoomStaysPressed} RoomNotesPlusPressed={RoomNotesPlusPressed}/>}
                                                            )
                                                        })
                                                    }
                                                    minColumnWidth={272}
                                                    gap={16}
                                                /> */}
                                                
                                            </div>
                                            }

                                            {groupName === 'Piene' &&
                                            <div className='group-div'>
                                                <h3> {groupName} </h3>
                                                <div className='group-divider' />

                                                <div className='grid-content-div no-padding'>
                                                    {
                                                    stanze.filter(stanza => stanza.postiOccupati >= stanza.capienza || stanza.piena == true).map(stanza =>
                                                        <Room key={stanza.id} {...stanza} PassInfoElement={PassInfoElement} ToggleMatrimoniale={ToggleMatrimoniale} TogglePiena={TogglePiena} PassClientInfoFromRoom={PassClientInfoFromRoom} RoomStaysPressed={RoomStaysPressed} RoomNotesPlusPressed={RoomNotesPlusPressed} />
                                                    )
                                                    }
                                                </div>

                                                {/* <Masonry
                                                    items={
                                                        stanze.filter(stanza => stanza.postiOccupati >= stanza.capienza || stanza.piena == true).map( stanza => { //Checking for stanza.piena's value should be enough, but better safe than sorry
                                                            return (
                                                                {key: stanza.id, node: <Room {...stanza} PassInfoElement={PassInfoElement} ToggleMatrimoniale={ToggleMatrimoniale} TogglePiena={TogglePiena} PassClientInfoFromRoom={PassClientInfoFromRoom} RoomStaysPressed={RoomStaysPressed} RoomNotesPlusPressed={RoomNotesPlusPressed}/>}
                                                            )
                                                        })
                                                    }
                                                    minColumnWidth={272}
                                                    gap={16}
                                                /> */}

                                            </div>
                                            }

                                        </div>
                                    )
                                })
                            }

                            {roomGroups.length > 0 && !(roomGroups.includes('Vuote') && roomGroups.includes('Parzialmente Piene') && roomGroups.includes('Piene')) && //Standard key-value grouping
                                roomGroups.map( groupName => {
                                    return(
                                        <div className='groups-div'>
                                            <div className='group-div'>
                                                <h3> {groupName} </h3>
                                                <div className='group-divider' />

                                                <div className='grid-content-div no-padding'>
                                                    {
                                                    stanze.filter(stanza => stanza[roomsGroupingKey] == groupName).map(stanza =>
                                                        <Room key={stanza.id} {...stanza} PassInfoElement={PassInfoElement} ToggleMatrimoniale={ToggleMatrimoniale} TogglePiena={TogglePiena} PassClientInfoFromRoom={PassClientInfoFromRoom} RoomStaysPressed={RoomStaysPressed} RoomNotesPlusPressed={RoomNotesPlusPressed} />
                                                    )
                                                    }
                                                </div>

                                                {/* <Masonry
                                                    items={
                                                        stanze.filter(stanza => stanza[roomsGroupingKey] == groupName).map( stanza => {
                                                            return (
                                                                {key: stanza.id, node: <Room {...stanza} PassInfoElement={PassInfoElement} ToggleMatrimoniale={ToggleMatrimoniale} TogglePiena={TogglePiena} PassClientInfoFromRoom={PassClientInfoFromRoom} RoomStaysPressed={RoomStaysPressed} RoomNotesPlusPressed={RoomNotesPlusPressed}/>}
                                                            )
                                                        })
                                                    }
                                                    minColumnWidth={272}
                                                    gap={16}
                                                /> */}
                                            </div>
                                        </div>
                                    )
                                })
                            }



                            
                        </div>
                    </div>

                    

                </div>
            }
            
            <CollaboratorEventInfo {...fullInfoElement} eventStartDate={currEventData?.dataInizio?.split('/')?.reverse()?.join('-') ?? ''} ToggleMatrimoniale={ToggleMatrimoniale} TogglePiena={TogglePiena} PassClientInfoFromRoom={PassClientInfoFromRoom} RoomStaysPressed={RoomStaysPressed} RoomNotesPlusPressed={RoomNotesPlusPressed} ClientNotesPlusPressed={ClientNotesPlusPressed} UpdateClientData={UpdateClientData} AddBundleToClient={AddBundleToClient} PlaceClientIntoRoom={PlaceClientIntoRoom} DeleteClientStay={DeleteClientStay}/>


            {/* OVERLAYS */}

            {currentOverlayPage != 'none' &&
                <div id='main-overlay' className='black-overlay'>
                
                {/* Client Overlays */}

                {currentOverlayPage === 'createNewClient' &&
                    <NewClient setCurrentOverlayPage={setCurrentOverlayPage} CreateNewClient={CreateNewClient} pacchetti={pacchetti} stanze={stanze} roomTypes={roomTypes} collabID={sessionData.id} SetStatusLed={SetStatusLed} eventStartDate={currEventData.dataInizio.split('/').reverse().join('-')}/>
                }

                {currentlyEditedClient != null && currentOverlayPage === 'modifyClientData' &&
                    <ModifyClientData setCurrentOverlayPage={setCurrentOverlayPage} currentlyEditedClient={currentlyEditedClient} ModifyClient={ModifyClient} collabID={sessionData.id} SetStatusLed={SetStatusLed} eventStartDate={currEventData.dataInizio.split('/').reverse().join('-')} />
                }

                {currentlyEditedClient != null && currentOverlayPage === 'addBundle' &&
                    <AddBundle setCurrentOverlayPage={setCurrentOverlayPage} currentlyEditedClient={currentlyEditedClient} setCurrentlyEditedClient={setCurrentlyEditedClient} pacchetti={pacchetti} SetClientBundle={SetClientBundle} SetStatusLed={SetStatusLed} />
                }

                {currentlyEditedClient != null && currentOverlayPage === 'selectRoom' &&
                    <SelectRoom setCurrentOverlayPage={setCurrentOverlayPage} currentlyEditedClient={currentlyEditedClient} stanze={stanze} SetClientRoom={SetClientRoom} roomTypes={roomTypes} SetStatusLed={SetStatusLed} />
                }

                {currentlyEditedClient != null && currentOverlayPage === 'confirmDeleteClient' &&
                    <ConfirmDeleteClient setCurrentOverlayPage={setCurrentOverlayPage} currentlyEditedClient={currentlyEditedClient} DeleteClient={DeleteClient} SetStatusLed={SetStatusLed} />
                }

                {currentlyEditedClient != null && currentOverlayPage === 'changeClientNotes' &&
                    <ChangeClientNotes setCurrentOverlayPage={setCurrentOverlayPage} currentlyEditedClient={currentlyEditedClient} ModifyClient={ModifyClient} SetStatusLed={SetStatusLed} />
                }

                {/* Room Overlays */}

                {currentlyEditedRoom != null && currentOverlayPage === 'roomStays' &&
                    <RoomStays setCurrentOverlayPage={setCurrentOverlayPage} currentlyEditedRoom={currentlyEditedRoom} pacchetti={pacchetti} clienti={clienti} sfollati={clienti.filter(client => client.idStanza == null || client.idStanza == '')} ConfirmRoomStaysCreation={ConfirmRoomStaysCreation} DeleteClientStay={DeleteClientStay} ModifyClient={ModifyClient} SetStatusLed={SetStatusLed} />
                }

                {currentlyEditedRoom != null && currentOverlayPage === 'changeRoomNotes' &&
                    <ChangeRoomNotes setCurrentOverlayPage={setCurrentOverlayPage} currentlyEditedRoom={currentlyEditedRoom} UpdateRoomNotes={UpdateRoomNotes} SetStatusLed={SetStatusLed} />
                }

            </div>
            }


        </div>
    )
}
