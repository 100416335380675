import React from 'react'
import { FiMinus, FiPlus } from 'react-icons/fi';
import { ImPlus } from 'react-icons/im';

export default function AdminRoom({ id, idCollaboratore, codice, tipo, nomeHotel, siglaHotel, capienza, postiOccupati, note, noteModificabili, collocati, matrimoniale, piena, collaborators, RoomNotesPlusPressed, ToggleNoteModificabili, TogglePiena, ToggleMatrimoniale, UpdateRoomData, DeleteRoomPressed  }) {

    // const [nomeCollaboratore, setNomeCollaboratore] = useState('');

    let nomeCollaboratore;
    for (let i = 0; i < collaborators.length; i++) {
        if (collaborators[i].id == idCollaboratore) {
            nomeCollaboratore = collaborators[i].cognome == null ? collaborators[i].nome : (collaborators[i].cognome + ' ' + collaborators[i].nome);
        }
        
    }

    const ToggleRoom = (e) => {
        let sections = e.currentTarget.parentElement.parentElement.children;
        let toggleIcons = e.currentTarget.children;

        for (let i = 0; i < sections.length; i++){
            if (sections[i].id == 'admin-room-data'){
                if (sections[i].className == 'admin-room-data'){
                    sections[i].className = 'admin-room-data admin-room-data-collapsed';
                    for (let i = 0; i < toggleIcons.length; i++){
                        if (toggleIcons[i].className.baseVal == 'plus'){
                            toggleIcons[i].style.display = 'block';
                        } else {
                            toggleIcons[i].style.display = 'none';
                        }
                    }
                } else {
                    sections[i].className = 'admin-room-data';
                    for (let i = 0; i < toggleIcons.length; i++){
                        if (toggleIcons[i].className.baseVal == 'minus'){
                            toggleIcons[i].style.display = 'block';
                        } else {
                            toggleIcons[i].style.display = 'none';
                        }
                    }
                }
            }
        }
    }

    return (
        <div className='room-div' >
            <div className='toggle-room-div'> <button className='toggle-button' onClick={e => ToggleRoom(e)}> <FiPlus className='plus' /> <FiMinus className='minus' /> </button> </div>
            <div class='room-name'>
                <h3> Camera {codice} {siglaHotel ? (' (' + siglaHotel + ')') : ''} </h3>
                <div class='top-divider' />
            </div>

            <div id='admin-room-data' class='admin-room-data admin-room-data-collapsed'>

                {tipo &&
                    <div className='data-field room-type-field'>
                        <h4 className='data-field-key'> Tipo: </h4>
                        <div className='data-field-value'>
                            <h4> {tipo} </h4>
                        </div>
                    </div>
                }

                {nomeHotel &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Hotel: </h4>
                        <div className='data-field-value'>
                            <h4> {nomeHotel} </h4>
                        </div>
                    </div>
                }

                {capienza != null &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Capienza Massima: </h4>
                        <div className='data-field-value'>
                            <h4> {capienza} </h4>
                        </div>
                    </div>
                }

                {postiOccupati != null &&
                    <div className='data-field admin-room-vacancy-field'>
                        <h4 className='data-field-key'> Posti Occupati: </h4>
                        <div className='data-field-value'>
                            <h4> {postiOccupati}/{capienza} </h4>
                        </div>
                    </div>
                }

                {(idCollaboratore == null || idCollaboratore == '') &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Collaboratore: </h4>
                        <div className='data-field-value'>
                            <h4> N/P </h4>
                        </div>
                    </div>
                }

                {idCollaboratore != null && idCollaboratore != '' &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Collaboratore: </h4>
                        <div className='data-field-value'>
                            <h4> {nomeCollaboratore} </h4>
                        </div>
                    </div>
                }

                {(note == null || note == '') &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Note: </h4>
                        <div className='data-field-value'>
                            <h4> N/P </h4>
                            <div className='data-field-divider' />
                            <div className='data-field-command'> <ImPlus onClick={() => RoomNotesPlusPressed(id)} /> </div>
                        </div>
                    </div>
                }
                {note &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Note: </h4>
                        <div className='data-field-value'>
                            <h4> {note} </h4>
                            <div className='data-field-divider' />
                            <div className='data-field-command'> <ImPlus onClick={() => RoomNotesPlusPressed(id)} /> </div>
                        </div>
                    </div>
                }

                {collocati != null && collocati.length > 0 && 
                    <div className='data-field admin-room-reservations-field'>
                        <h4 className='data-field-key'> Partecipanti: </h4>
                        <div className='data-field-value'>
                            <ul>
                                {collocati.map(client => {
                                    return(
                                        <li><h4> {client.cognome} {client.nome} </h4></li>
                                    )
                                })
                                }
                            </ul>
                        </div>
                    </div>
                }

                {(collocati == null || collocati.length <= 0) && 
                    <div className='data-field admin-room-reservations-field'>
                        <h4 className='data-field-key'> Partecipanti: </h4>
                        <div className='data-field-value'>
                            <h4>N/P</h4>
                        </div>
                    </div>
                }


                
                <div className= 'data-field room-checkboxes-field'>
                    <label>
                        <input type='checkbox' checked={noteModificabili} onClick={() => ToggleNoteModificabili(id)} />
                        {/* onClick={() => TogglePiena(id)}  */}
                        <h4> Permetti Modifica Note </h4>
                    </label>
                    {(tipo == 'Doppia' || tipo == 'Tripla') &&
                        <label>
                            <input type='checkbox' checked={matrimoniale} onClick={() => ToggleMatrimoniale(id)} />
                            <h4> Letto Matrimoniale </h4>
                        </label>
                    }
                    <label>
                        <input type='checkbox' checked={piena} disabled={postiOccupati == capienza} onClick={() => TogglePiena(id)} />
                        <h4> Segna come Piena </h4>
                    </label>
                </div>

                <div className='bottom-divider'></div>
                <div className='room-commands'>
                    <a className='clickable' onClick={() => UpdateRoomData(id)} >Modifica</a>
                    <a className='clickable' onClick={() => DeleteRoomPressed(id)} >Elimina</a>
                    {/* onClick={() => RoomStaysPressed(id)}  */}
                </div>

            </div>

        </div>
    )
}

export function AdminRoomToDelete({ id, idCollaboratore, codice, tipo, nomeHotel, siglaHotel, capienza, postiOccupati, note, noteModificabili, collocati, matrimoniale, piena, collaborators, ToggleRoomToDelete, roomsToDeleteIDs }){

    let nomeCollaboratore;
    for (let i = 0; i < collaborators.length; i++) {
        if (collaborators[i].id == idCollaboratore) {
            nomeCollaboratore = collaborators[i].cognome == null ? collaborators[i].nome : (collaborators[i].cognome + ' ' + collaborators[i].nome);
        }
        
    }

    let toBeDeleted = false;
    for (let i = 0; i < roomsToDeleteIDs.length; i++){
        if (roomsToDeleteIDs[i] == id){
            toBeDeleted = true;
        }
    }

    const ToggleRoom = (e) => {
        let sections = e.currentTarget.parentElement.parentElement.children;
        let toggleIcons = e.currentTarget.children;

        for (let i = 0; i < sections.length; i++){
            if (sections[i].id == 'admin-room-data'){
                if (sections[i].className == 'admin-room-data'){
                    sections[i].className = 'admin-room-data admin-room-data-collapsed';
                    for (let i = 0; i < toggleIcons.length; i++){
                        if (toggleIcons[i].className.baseVal == 'plus'){
                            toggleIcons[i].style.display = 'block';
                        } else {
                            toggleIcons[i].style.display = 'none';
                        }
                    }
                } else {
                    sections[i].className = 'admin-room-data';
                    for (let i = 0; i < toggleIcons.length; i++){
                        if (toggleIcons[i].className.baseVal == 'minus'){
                            toggleIcons[i].style.display = 'block';
                        } else {
                            toggleIcons[i].style.display = 'none';
                        }
                    }
                }
            }
        }
    }

    return (
        <div className='room-div room-to-delete' >
            <div className='multi-delete-checkbox-div'> <input type='checkbox' checked={toBeDeleted} onClick={() => ToggleRoomToDelete(id)} /> </div>
            <div className='toggle-room-div'> <button className='toggle-button' onClick={e => ToggleRoom(e)}> <FiPlus className='plus' /> <FiMinus className='minus' /> </button> </div>
            <div class='room-name'>
                <h3> Camera {codice} {siglaHotel ? (' (' + siglaHotel + ')') : ''} </h3>
                <div class='top-divider' />
            </div>

            <div id='admin-room-data' class='admin-room-data admin-room-data-collapsed'>

                {tipo &&
                    <div className='data-field room-type-field'>
                        <h4 className='data-field-key'> Tipo: </h4>
                        <div className='data-field-value'>
                            <h4> {tipo} </h4>
                        </div>
                    </div>
                }

                {nomeHotel &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Hotel: </h4>
                        <div className='data-field-value'>
                            <h4> {nomeHotel} </h4>
                        </div>
                    </div>
                }

                {capienza != null &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Capienza Massima: </h4>
                        <div className='data-field-value'>
                            <h4> {capienza} </h4>
                        </div>
                    </div>
                }

                {postiOccupati != null &&
                    <div className='data-field admin-room-vacancy-field'>
                        <h4 className='data-field-key'> Posti Occupati: </h4>
                        <div className='data-field-value'>
                            <h4> {postiOccupati}/{capienza} </h4>
                        </div>
                    </div>
                }

                {(idCollaboratore == null || idCollaboratore == '') &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Collaboratore: </h4>
                        <div className='data-field-value'>
                            <h4> N/P </h4>
                        </div>
                    </div>
                }

                {idCollaboratore != null && idCollaboratore != '' &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Collaboratore: </h4>
                        <div className='data-field-value'>
                            <h4> {nomeCollaboratore} </h4>
                        </div>
                    </div>
                }

                {(note == null || note == '') &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Note: </h4>
                        <div className='data-field-value'>
                            <h4> N/P </h4>
                        </div>
                    </div>
                }
                {note &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Note: </h4>
                        <div className='data-field-value'>
                            <h4> {note} </h4>
                        </div>
                    </div>
                }

                {collocati != null && collocati.length > 0 && 
                    <div className='data-field admin-room-reservations-field'>
                        <h4 className='data-field-key'> Partecipanti: </h4>
                        <div className='data-field-value'>
                            <ul>
                                {collocati.map(client => {
                                    return(
                                        <li><h4> {client.cognome} {client.nome} </h4></li>
                                    )
                                })
                                }
                            </ul>
                        </div>
                    </div>
                }

                {(collocati == null || collocati.length <= 0) && 
                    <div className='data-field admin-room-reservations-field'>
                        <h4 className='data-field-key'> Partecipanti: </h4>
                        <div className='data-field-value'>
                            <h4>N/P</h4>
                        </div>
                    </div>
                }


                
                <div className= 'data-field room-checkboxes-field'>
                    <label>
                        <input type='checkbox' checked={noteModificabili} disabled={true}/>
                        <h4> Permetti Modifica Note </h4>
                    </label>
                    {(tipo == 'Doppia' || tipo == 'Tripla') &&
                        <label>
                            <input type='checkbox' checked={matrimoniale} disabled={true}/>
                            <h4> Letto Matrimoniale </h4>
                        </label>
                    }
                    <label>
                        <input type='checkbox' checked={piena} disabled={true}/>
                        <h4> Segna come Piena </h4>
                    </label>
                </div>

                <div className='bottom-divider'></div>

            </div>

        </div>
    )

}

export function ManagementAdminRoom({ id, idCollaboratore, codice, tipo, nomeHotel, siglaHotel, capienza, postiOccupati, note, noteModificabili, collocati, matrimoniale, piena, collaborators, RoomNotesPlusPressed, ToggleNoteModificabili, TogglePiena, ToggleMatrimoniale, RoomStaysPressed,  PassClientInfoFromRoom, PassInfoElement }) {

    let nomeCollaboratore;
    for (let i = 0; i < collaborators.length; i++) {
        if (collaborators[i].id == idCollaboratore) {
            nomeCollaboratore = collaborators[i].cognome == null ? collaborators[i].nome : (collaborators[i].cognome + ' ' + collaborators[i].nome);
        }
        
    }

    const ToggleRoom = (e) => {
        let sections = e.currentTarget.parentElement.parentElement.children;
        let toggleIcons = e.currentTarget.children;

        for (let i = 0; i < sections.length; i++){
            if (sections[i].id == 'room-data'){
                if (sections[i].className == 'room-data'){
                    sections[i].className = 'room-data room-data-collapsed';
                    for (let i = 0; i < toggleIcons.length; i++){
                        if (toggleIcons[i].className.baseVal == 'plus'){
                            toggleIcons[i].style.display = 'block';
                        } else {
                            toggleIcons[i].style.display = 'none';
                        }
                    }
                } else {
                    sections[i].className = 'room-data';
                    for (let i = 0; i < toggleIcons.length; i++){
                        if (toggleIcons[i].className.baseVal == 'minus'){
                            toggleIcons[i].style.display = 'block';
                        } else {
                            toggleIcons[i].style.display = 'none';
                        }
                    }
                }
            }
        }
    }

    return (
        <div className='room-div' >
            <div className='toggle-room-div'> <button className='toggle-button' onClick={e => ToggleRoom(e)}> <FiPlus className='plus' /> <FiMinus className='minus' /> </button> </div>
            <div class='room-name' onClick={e => PassInfoElement(e, id)} >
                <h3> Camera {codice} {siglaHotel ? (' (' + siglaHotel + ')') : ''} </h3>
                <div class='top-divider' />
            </div>

            <div id='room-data' class='room-data room-data-collapsed'>

                {tipo &&
                    <div className='data-field room-type-field'>
                        <h4 className='data-field-key'> Tipo: </h4>
                        <div className='data-field-value'>
                            <h4> {tipo} </h4>
                        </div>
                    </div>
                }

                {nomeHotel &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Hotel: </h4>
                        <div className='data-field-value'>
                            <h4> {nomeHotel} </h4>
                        </div>
                    </div>
                }

                {capienza != null &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Capienza Massima: </h4>
                        <div className='data-field-value'>
                            <h4> {capienza} </h4>
                        </div>
                    </div>
                }

                {postiOccupati != null &&
                    <div className='data-field room-vacancy-field'>
                        <h4 className='data-field-key'> Posti Occupati: </h4>
                        <div className='data-field-value'>
                            <h4> {postiOccupati}/{capienza} </h4>
                        </div>
                    </div>
                }

                {(idCollaboratore == null || idCollaboratore == '') &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Collaboratore: </h4>
                        <div className='data-field-value'>
                            <h4> N/P </h4>
                        </div>
                    </div>
                }

                {idCollaboratore != null && idCollaboratore != '' &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Collaboratore: </h4>
                        <div className='data-field-value'>
                            <h4> {nomeCollaboratore} </h4>
                        </div>
                    </div>
                }

                {(note == null || note == '') &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Note: </h4>
                        <div className='data-field-value'>
                            <h4> N/P </h4>
                            <div className='data-field-divider' />
                            <div className='data-field-command'> <ImPlus onClick={() => RoomNotesPlusPressed(id)} /> </div>
                        </div>
                    </div>
                }
                {note &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Note: </h4>
                        <div className='data-field-value'>
                            <h4> {note} </h4>
                            <div className='data-field-divider' />
                            <div className='data-field-command'> <ImPlus onClick={() => RoomNotesPlusPressed(id)} /> </div>
                        </div>
                    </div>
                }

                {collocati != null && collocati.length > 0 && 
                    <div className='data-field'>
                        <h4 className='data-field-key'> Partecipanti: </h4>
                        <div className='data-field-value'>
                            <ul>
                                {collocati.map(client => {
                                    return(
                                        <li><h4 className='clickable' onClick={() =>  PassClientInfoFromRoom(client.idPartecipante)}> {client.cognome} {client.nome} </h4></li>
                                    )
                                })
                                }
                            </ul>
                        </div>
                    </div>
                }

                {(collocati == null || collocati.length <= 0) && 
                    <div className='data-field'>
                        <h4 className='data-field-key'> Partecipanti: </h4>
                        <div className='data-field-value'>
                            <h4>N/P</h4>
                        </div>
                    </div>
                }


                
                <div className= 'data-field room-checkboxes-field'>
                    <label>
                        <input type='checkbox' checked={noteModificabili} onClick={() => ToggleNoteModificabili(id)} />
                        {/* onClick={() => TogglePiena(id)}  */}
                        <h4> Permetti Modifica Note </h4>
                    </label>
                    {(tipo == 'Doppia' || tipo == 'Tripla') &&
                        <label>
                            <input type='checkbox' checked={matrimoniale} onClick={() => ToggleMatrimoniale(id)} />
                            <h4> Letto Matrimoniale </h4>
                        </label>
                    }
                    <label>
                        <input type='checkbox' checked={piena} disabled={postiOccupati == capienza} onClick={() => TogglePiena(id)} />
                        <h4> Segna come Piena </h4>
                    </label>
                </div>

                <div className='bottom-divider'></div>
                <div className='room-commands'>
                    <a className='clickable' onClick={() => RoomStaysPressed(id)} >Pernottamenti / Colloca</a>
                    {/* onClick={() => RoomStaysPressed(id)}  */}
                </div>

            </div>

        </div>
    )
}

export function InfoAdminRoom({ DeleteClientStay, RoomStaysPressed, RoomNotesPlusPressed, ToggleNoteModificabili, ToggleMatrimoniale, TogglePiena, id, idCollaboratore, collaborators, codice, tipo, nomeHotel, capienza, postiOccupati, note, noteModificabili, collocati, piena, matrimoniale }) {
    
    let nomeCollaboratore;
    for (let i = 0; i < collaborators.length; i++) {
        if (collaborators[i].id == idCollaboratore) {
            nomeCollaboratore = collaborators[i].cognome == null ? collaborators[i].nome : (collaborators[i].cognome + ' ' + collaborators[i].nome);
        }
        
    }

    return (
        <div className='info-room-div' >

            {/* Info Stanza */}

            <h3> Camera {codice}</h3>
            <div class='info-top-divider' />

            {tipo && 
                <div className='data-field'>
                    <h4 className='data-field-key'> Tipo: </h4>
                    <div className='data-field-value'>
                        <h4> {tipo} </h4>
                    </div>
                </div>
            }
            
            {nomeHotel && 
                <div className='data-field'>
                    <h4 className='data-field-key'> Hotel: </h4>
                    <div className='data-field-value'>
                        <h4> {nomeHotel} </h4>
                    </div>
                </div>
            }
            
            {capienza != null && 
                <div className='data-field'>
                    <h4 className='data-field-key'> Capienza Massima: </h4>
                    <div className='data-field-value'>
                        <h4> {capienza} </h4>
                    </div>
                </div>
            }
            
            {postiOccupati != null && capienza != null && 
                <div className='data-field'>
                    <h4 className='data-field-key'> Posti Occupati: </h4>
                    <div className='data-field-value'>
                        <h4> {postiOccupati} / {capienza} </h4>
                    </div>
                </div>
            }

            {(idCollaboratore == null || idCollaboratore == '') &&
                <div className='data-field'>
                    <h4 className='data-field-key'> Collaboratore: </h4>
                    <div className='data-field-value'>
                        <h4> N/P </h4>
                    </div>
                </div>
            }

            {idCollaboratore != null && idCollaboratore != '' &&
                <div className='data-field'>
                    <h4 className='data-field-key'> Collaboratore: </h4>
                    <div className='data-field-value'>
                        <h4> {nomeCollaboratore} </h4>
                    </div>
                </div>
            }

            {(note == null || note == '') &&
                <div className='data-field'>
                    <h4 className='data-field-key'> Note: </h4>
                    <div className='data-field-value'>
                        <h4> N/P </h4>
                        <div className='data-field-divider' />
                        <div className='data-field-command'> <ImPlus onClick={() => RoomNotesPlusPressed(id)} /> </div>
                    </div>
                </div>
            }
            {note &&
                <div className='data-field'>
                    <h4 className='data-field-key'> Note: </h4>
                    <div className='data-field-value'>
                        <h4> {note} </h4>
                        <div className='data-field-divider' />
                        <div className='data-field-command'> <ImPlus onClick={() => RoomNotesPlusPressed(id)} /> </div>
                    </div>
                </div>
            }

            {collocati != null && collocati.length > 0 && 
                <div className='data-field'>
                    <h4 className='data-field-key'> Partecipanti: </h4>
                    <div className='data-field-value'>
                        <ul>
                            {collocati.map(client => {
                                return(
                                    <li><h4> {client.cognome} {client.nome} </h4></li>
                                )
                            })
                            }
                        </ul>
                    </div>
                </div>
            }

            <div className= 'data-field room-checkboxes-field'>
                <label>
                    <input type='checkbox' checked={noteModificabili} onClick={() => ToggleNoteModificabili(id)} />
                    {/* onClick={() => TogglePiena(id)}  */}
                    <h4> Permetti Modifica Note </h4>
                </label>
                {(tipo == 'Doppia' || tipo == 'Tripla') &&
                    <label>
                        <input type='checkbox' checked={matrimoniale} onClick={() => ToggleMatrimoniale(id)} /> {/* checked={matrimoniale} should work, but doesn't currently allow for changing the checkbox state, but if onClick we changed the value of matrimoniale, it should act like a normal checkbox */}
                        <h4> Letto Matrimoniale </h4>
                    </label>
                }
                <label>
                    <input type='checkbox' checked={piena} disabled={postiOccupati == capienza} onClick={() => TogglePiena(id)} /> {/* Come sopra + se capienza == posti occupati, segna la camera come piena e non dare la possibilità di metterla non-piena perchè lo è e basta */}
                    <h4> Segna come Piena </h4>
                </label>
            </div>

            <button onClick={() => RoomStaysPressed(id)} >Pernottamenti</button>


            {/* Lista Clienti */}

            {collocati != null && collocati.length > 0 &&
            <div className='staying-clients-div'>
                {collocati.map( cliente => {
                    return(
                        <div>
                            <h3>{cliente.cognome} {cliente.nome}</h3>

                            <div class='info-top-divider' />

                            <div className='data-field'>
                                <h4 className='data-field-key'> Pacchetto: </h4>
                                <div className='data-field-value'>
                                    <h4> {cliente.nomePacchettoCliente} </h4>
                                </div>
                            </div>

                            <div className='data-field'>
                                <h4 className='data-field-key'> Data In: </h4>
                                <div className='data-field-value'>
                                    <h4> {cliente.dataIn} </h4>
                                </div>
                            </div>

                            <div className='data-field'>
                                <h4 className='data-field-key'> Data Out: </h4>
                                <div className='data-field-value'>
                                    <h4> {cliente.dataOut} </h4>
                                </div>
                            </div>

                            <button onClick={() => DeleteClientStay(cliente.idPartecipante)} >Rimuovi</button>
                        </div>
                    )
                })}
            </div>
            }

        </div>
    
    )
}