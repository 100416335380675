import { MenuItem, Select, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup'
import { SettleDBReservations } from '../../../../serverRequests/patchRequests'
import { UpdateDBReservationData } from '../../../../serverRequests/putRequests';
import { BiIdCard } from 'react-icons/bi';

const validationSchema = yup.object({
    acconto: yup
    .number('Acconto deve essere un numero')
    .required('Acconto obbligatorio anche se 0')
    .min(0, 'Minimo 0€'),
    totale: yup
    .number('Totale deve essere un numero')
    .required('Totale obbligatorio anche se 0')
    .min(0, 'Minimo 0€'),
    metodoAcconto: yup
    .string('Metodo acconto deve essere una stringa')
    .nullable(true)
    .max(50, 'Massimo 20 caratteri'),
    destinatarioAcconto: yup
    .string('Destinatario acconto deve essere una stringa')
    .nullable(true)
    .max(50, 'Massimo 50 caratteri'),
    metodoSaldo: yup
    .string('Metodo saldo deve essere una stringa')
    .nullable(true)
    .max(50, 'Massimo 20 caratteri'),
    destinatarioSaldo: yup
    .string('Destinatario saldo deve essere una stringa')
    .nullable(true)
    .max(50, 'Massimo 50 caratteri'),
    stato: yup
    .string('Stato deve essere una stringa')
    .required('Stato obbligatorio')
    .max(20, 'Massimo 20 caratteri'),
});

export default function ConfirmSettleReservations({ setCurrentOverlayPage, reservationsToSettleIDs, SettleReservations, paymentMethods, destinatariAcconto, SetStatusLed }) {

    //Detect mobile device and use default inputs in case
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
            setWidth(window.innerWidth);
        }
    useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);

    let isMobile = (width < 1024);



    const CloseOverlay = () => {
        setCurrentOverlayPage('none');
    }

    const HandleSubmit = async ( data, setSubmitting ) => {

        setSubmitting(true);
        SetStatusLed(true);

        let settlingData = {
            metodoSaldo: data.settleMethod == '' ? null : data.settleMethod,
            destinatarioSaldo: data.destinatarioSaldo == '' ? null : data.destinatarioSaldo,
            idPartecipanti: reservationsToSettleIDs
        }

        let res = await SettleDBReservations(settlingData);
        
        setSubmitting(false);
        SetStatusLed(false);


        if (res) {
            SettleReservations( data.settleMethod, data.destinatarioSaldo );
            CloseOverlay();
        }
        //Otherwise do nothing (the error displaying is already handled by CreateClient)

    }

    return (
        <div id='confirm-delete-admin-bundle-overlay' className='overlay-div confirm-delete-overlay confirm-settle-overlay'>
            <div className='close-overlay clickable' onClick={CloseOverlay} > <AiOutlineClose /> </div>

            <div className='inner-overlay-div'>

                <div className='confirm-delete-message'>
                    <h3>Conferma saldo di</h3>
                    <h2> {reservationsToSettleIDs.length} prenotazioni? </h2>
                </div>

                <Formik
                    initialValues={{
                        settleMethod: '',
                        destinatarioSaldo: '',
                    }}
                    onSubmit={(data, {setSubmitting}) => HandleSubmit(data, setSubmitting)}
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <div className='overlay-input-field centered'><h4 className='overlay-input-key'>Metodo di pagamento: </h4>
                            <Field name='settleMethod' as={Select} autoFocus>
                                <MenuItem value=''>Nessuno</MenuItem>
                            {paymentMethods.map( method =>{
                                    return(
                                        <MenuItem value={method.metodo}>{method.metodo}</MenuItem>
                                    )
                                })
                                }
                            </Field>
                            {/* {touched.settleMethod && errors.settleMethod ? <a className='validation'>{errors.settleMethod}</a> : ''} */}
                            </div>

                            <div className='overlay-input-field centered'><h4 className='overlay-input-key'>Destinatario Saldo: </h4>
                            <Field name='destinatarioSaldo' as={Select} >
                                <MenuItem value=''>Nessuno</MenuItem>
                                {destinatariAcconto.map( destAcc =>{
                                    return(
                                        <MenuItem value={destAcc.nome}>{destAcc.nome}</MenuItem>
                                    )
                                })
                                }
                            </Field>
                            {/* {touched.settleMethod && errors.settleMethod ? <a className='validation'>{errors.settleMethod}</a> : ''} */}
                            </div>

                            <button className='no-bottom-margin' disabled={isSubmitting} type='submit'> Conferma </button>
                        </Form>
                    )}
                </Formik>
                
                {/* <button onClick={HandleDeleteClick} > Elimina </button> */}

            </div>
        </div>
    )
}

export function ModifyReservationData({ setCurrentOverlayPage, currentlyEditedReservation, ModifyReservation, clienti, paymentMethods, paymentStates, destinatariAcconto, SetStatusLed }) {
    
    //Detect mobile device and use default inputs in case
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
            setWidth(window.innerWidth);
        }
    useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);

    let isMobile = (width < 1024);


    const [dataError, setDataError] = useState('');
    
    let nome, cognome, cf, tesserato;

    for (let i = 0; i < clienti.length; i++){
        if (clienti[i].id == currentlyEditedReservation.idPartecipante){
            nome = clienti[i].nome;
            cognome = clienti[i].cognome == null ? '' : clienti[i].cognome;
            cf = clienti[i].cf == null ? '' : clienti[i].cf;
            tesserato = clienti[i].tesserato;
            break;
        }
    }

    const CloseOverlay = () => {
        setCurrentOverlayPage('none');
    }

    const HandleSubmit = async (data, {setSubmitting}) => {

        if(data.acconto > data.totale){
            setDataError('Acconto non può essere maggiore del totale!');
            setSubmitting(false);
            return;
        }

        setSubmitting(true);
        SetStatusLed(true);

        let updatedReservationData = {
            idPartecipante: currentlyEditedReservation.idPartecipante,
            acconto: Number(data.acconto),
            metodoAcconto: data.metodoAcconto == '' ? null : data.metodoAcconto,
            dataAcconto: data.dataAcconto == '' ? null : data.dataAcconto,
            destinatarioAcconto: data.destinatarioAcconto == '' ? null : data.destinatarioAcconto,
            saldo: Number(data.totale) - Number(data.acconto),
            metodoSaldo: data.metodoSaldo == '' ? null : data.metodoSaldo,
            destinatarioSaldo: data.destinatarioSaldo == '' ? null : data.destinatarioSaldo,
            totale: Number(data.totale),
            stato: data.stato == '' ? null : data.stato
        }

        let res = await UpdateDBReservationData(updatedReservationData);

        
        setSubmitting(false);
        SetStatusLed(false);


        if (res) {
            let updatedReservation = currentlyEditedReservation;

            updatedReservation.acconto = Number(data.acconto);
            updatedReservation.saldo = Number(data.totale) - Number(data.acconto);
            updatedReservation.totale = Number(data.totale);
            updatedReservation.metodoAcconto = updatedReservationData.metodoAcconto;
            updatedReservation.dataAcconto = data.dataAcconto.split('-').reverse().join('/');
            updatedReservation.destinatarioAcconto = updatedReservationData.destinatarioAcconto;
            updatedReservation.metodoSaldo = updatedReservationData.metodoSaldo;
            updatedReservation.destinatarioSaldo = updatedReservationData.destinatarioSaldo;
            updatedReservation.stato = updatedReservationData.stato;

            ModifyReservation(updatedReservation);
            
            CloseOverlay();
        }
        //Otherwise do nothing (the error displaying is already handled by UpdateDBReservationData)
    }

    return (
        <div id='modify-reservation-data-overlay' className='overlay-div'>
            <div className='close-overlay clickable' onClick={CloseOverlay} > <AiOutlineClose /> </div>

            <div className='inner-overlay-div'>
                <h2> {cognome} {nome} {tesserato ? <BiIdCard className='tesserato-icon' title='Partecipante Tesserato'/> : null}</h2>
                <div className='overlay-top-divider' />
                
                <div className='overlay-content-div'>
                    <Formik
                        initialValues={{
                            acconto: currentlyEditedReservation.acconto,
                            totale: currentlyEditedReservation.totale,
                            metodoAcconto: currentlyEditedReservation.metodoAcconto == null ? '' : currentlyEditedReservation.metodoAcconto,
                            dataAcconto: currentlyEditedReservation.dataAcconto == null ? '' : currentlyEditedReservation.dataAcconto.split('/').reverse().join('-'),
                            destinatarioAcconto: currentlyEditedReservation.destinatarioAcconto == null ? '' : currentlyEditedReservation.destinatarioAcconto,
                            metodoSaldo: currentlyEditedReservation.metodoSaldo == null ? '' : currentlyEditedReservation.metodoSaldo,
                            destinatarioSaldo: currentlyEditedReservation.destinatarioSaldo == null ? '' : currentlyEditedReservation.destinatarioSaldo,
                            stato: currentlyEditedReservation.stato
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(data, {setSubmitting}) => HandleSubmit(data, {setSubmitting})}
                    >

                    {({ values, errors, touched, isSubmitting }) => (
                        <Form autoComplete='off'>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Acconto: </h4><Field className='overlay-number-input' type='number' name='acconto' as={TextField} autoFocus/> {touched.acconto && errors.acconto ? <a className='validation'>{errors.acconto}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Saldo: <a className='fixed-input'>{values.totale - values.acconto}</a> </h4></div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Totale: </h4><Field className='overlay-number-input' type='number' name='totale' as={TextField} /> {touched.totale && errors.totale ? <a className='validation'>{errors.totale}</a> : ''} </div>
                            
                            <div className='overlay-input-field'><h4 className='overlay-input-key'>Metodo acconto: </h4>
                            <Field name='metodoAcconto' as={Select} >
                                <MenuItem value=''>Nessuno</MenuItem>
                                {paymentMethods.map( method =>{
                                    return(
                                        <MenuItem value={method.metodo}>{method.metodo}</MenuItem>
                                    )
                                })
                                }
                            </Field>
                            {touched.metodoAcconto && errors.metodoAcconto ? <a className='validation'>{errors.metodoAcconto}</a> : ''}
                            </div>

                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Data acconto: </h4><Field className='overlay-date-input' type='date' name='dataAcconto' as={isMobile ? '' : TextField} /> {touched.dataAcconto && errors.dataAcconto ? <a className='validation'>{errors.dataAcconto}</a> : ''} </div>

                            <div className='overlay-input-field'><h4 className='overlay-input-key'>Destinatario Acconto: </h4>
                            <Field name='destinatarioAcconto' as={Select} >
                                <MenuItem value=''>Nessuno</MenuItem>
                                {destinatariAcconto.map( destAcc =>{
                                    return(
                                        <MenuItem value={destAcc.nome}>{destAcc.nome}</MenuItem>
                                    )
                                })
                                }
                            </Field>
                            {touched.destinatarioAcconto && errors.destinatarioAcconto ? <a className='validation'>{errors.destinatarioAcconto}</a> : ''}
                            </div>

                            <div className='overlay-input-field'><h4 className='overlay-input-key'>Metodo saldo: </h4>
                            <Field name='metodoSaldo' as={Select} >
                                <MenuItem value=''>Nessuno</MenuItem>
                                {paymentMethods.map( method =>{
                                    return(
                                        <MenuItem value={method.metodo}>{method.metodo}</MenuItem>
                                    )
                                })
                                }
                            </Field>
                            {touched.metodoSaldo && errors.metodoSaldo ? <a className='validation'>{errors.metodoSaldo}</a> : ''}
                            </div>

                            <div className='overlay-input-field'><h4 className='overlay-input-key'>Destinatario Saldo: </h4>
                            <Field name='destinatarioSaldo' as={Select} >
                                <MenuItem value=''>Nessuno</MenuItem>
                                {destinatariAcconto.map( destAcc =>{
                                    return(
                                        <MenuItem value={destAcc.nome}>{destAcc.nome}</MenuItem>
                                    )
                                })
                                }
                            </Field>
                            {touched.destinatarioSaldo && errors.destinatarioSaldo ? <a className='validation'>{errors.destinatarioSaldo}</a> : ''}
                            </div>

                            <div className='overlay-input-field'><h4 className='overlay-input-key'>Stato: </h4>
                            <Field name='stato' as={Select} >
                                {paymentStates.map( state =>{
                                    return(
                                        <MenuItem value={state.stato}>{state.stato}</MenuItem>
                                    )
                                })
                                }
                            </Field>
                            {touched.stato && errors.stato ? <a className='validation'>{errors.stato}</a> : ''}
                            </div>

                            <div className='form-submit-button-div'>
                                <button disabled={isSubmitting} type='submit'> Conferma </button>
                                {dataError !== '' ? <a className='data-error'>{dataError}</a> : ''}
                            </div>
                        </Form>
                    )}

                    </Formik>
                </div>
            </div>

        </div>
    )
}