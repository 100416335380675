import React, { useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import DeleteDBClient from '../../../../serverRequests/deleteRequests';
import { BiIdCard } from 'react-icons/bi';

export default function ConfirmDeleteClient({ setCurrentOverlayPage, currentlyEditedClient, SetStatusLed, DeleteClient }) {

    const [queryingServer, setQueryingServer] = useState(false);

    const CloseOverlay = () => {
        setCurrentOverlayPage('none');
    }

    const HandleDeleteClick = async () => {

        if (queryingServer) return; //Don't do anything if we are already querying the server

        setQueryingServer(true);
        SetStatusLed(true);
        
        let res = await DeleteDBClient(currentlyEditedClient.id);

        setQueryingServer(false);
        SetStatusLed(false);


        if (res) {
            DeleteClient(currentlyEditedClient.id)
            setCurrentOverlayPage('none');
        }
        //Otherwise do nothing (the error displaying is already handled by DeleteDBClient)

    }

    return (
        <div id='confirm-delete-client-overlay' className='overlay-div confirm-delete-overlay'>
            <div className='close-overlay clickable' onClick={CloseOverlay} > <AiOutlineClose /> </div>

            <div className='inner-overlay-div'>

                <div className='confirm-delete-message'>
                    <h3>Conferma eliminazione di</h3>
                    <h2> {currentlyEditedClient.cognome} {currentlyEditedClient.nome} {currentlyEditedClient.tesserato ? <BiIdCard className='tesserato-icon' title='Partecipante Tesserato'/> : null}</h2>
                    <h5>(Tutti i dati collegati al partecipante saranno cancellati)</h5>
                </div>

                <button onClick={HandleDeleteClick} > Elimina </button>

            </div>
        </div>
    )
}
