import { MenuItem, Select, TextareaAutosize, TextField } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai';
import * as yup from 'yup'
import { CreateRooms, CreateRoomType } from '../../../../serverRequests/postRequests'
import { UpdateDBRoomData } from '../../../../serverRequests/putRequests'
import { AdminUpdateRoomFlags } from '../../../../serverRequests/patchRequests'

const validationSchema = yup.object({
    numeroCamere: yup
    .number('Numero camere deve essere un numero')
    .required('Numero camere obbligatorio')
    .integer('Numero camere deve essere intero')
    .min(1, 'Minimo 1 camera')
    .max(1000, 'Massimo 1000 camere'),
    idHotel: yup
    .number('ID hotel deve essere un numero')
    .required('Hotel obbligatorio'),
    tipo: yup
    .string('Tipo deve essere una stringa')
    .required('Tipo obbligatorio'),
    capienza: yup
    .number('Capienza deve essere un numero')
    .required('Capienza obbligatoria')
    .integer('Capienza deve essere un numero intero')
    .min(1, 'Minimo 1 posto disponibile')
    .max(1000, 'Massimo 1000 posti disponibili'),
    note: yup
    .string('Note deve essere una stringa')
    .nullable(true)
    .max(255, 'Massimo 255 caratteri'),
});

const RoomTypeValidationSchema = yup.object({
    tipo: yup
    .string('Tipo deve essere una stringa')
    .required('Tipo obbligatorio')
    .max(50, 'Massimo 50 caratteri'),
    capienza: yup
    .number('Capienza deve essere un numero')
    .required('Capienza obbligatoria')
    .integer('Capienza deve essere un numero intero')
    .min(1, 'Minimo 1 posto disponibile')
    .max(1000, 'Massimo 1000 posti disponibili')
});

const noteValidationSchema = yup.object({
    note: yup
    .string('Note deve essere una stringa')
    .nullable(true)
    .max(255, 'Massimo 255 caratteri')
});

const ModifyValidationSchema = yup.object({
    idHotel: yup
    .number('ID hotel deve essere un numero')
    .required('Hotel obbligatorio'),
    tipo: yup
    .string('Tipo deve essere una stringa')
    .required('Tipo obbligatorio'),
    capienza: yup
    .number('Capienza deve essere un numero')
    .required('Capienza obbligatoria')
    .integer('Capienza deve essere un numero intero')
    .min(1, 'Minimo 1 posto disponibile')
    .max(1000, 'Massimo 1000 posti disponibili'),
    note: yup
    .string('Note deve essere una stringa')
    .nullable(true)
    .max(255, 'Massimo 255 caratteri'),
});

export default function NewRooms({ setCurrentOverlayPage, roomTypes, collaborators, hotels, CreateNewRooms, CreateNewRoomType, SetStatusLed }) {
    
    //Detect mobile device and use default inputs in case
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
            setWidth(window.innerWidth);
        }
    useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);

    let isMobile = (width < 1024);
    
    
    
    let [addType, setAddType] = useState(false);

    const CloseOverlay = () => {
        setCurrentOverlayPage('none');
    }

    const HandleTypeSelected = (e, setFieldValue) => {
        for (let i = 0; i < roomTypes.length; i++){
            if (roomTypes[i].tipo === e.target.value){
                setFieldValue('capienza', roomTypes[i].capienza);
            }
        }
    }

    const HandleSubmit = async (data, {setSubmitting}) => {

        setSubmitting(true);
        SetStatusLed(true);

        let newRoomsData = {
            numeroCamere: data.numeroCamere == '' ? null : data.numeroCamere,
            idHotel: data.idHotel == '' ? null : data.idHotel,
            tipo: data.tipo == '' ? null : data.tipo,
            capienza: data.capienza == '' ? null : data.capienza,
            note: data.note == '' ? null : data.note,
            idCollaboratore: data.idCollaboratore == '' ? null : data.idCollaboratore,
            idEvento: localStorage.getItem('currEventID') == '' ? null : localStorage.getItem('currEventID')
        }
        
        let newRooms = await CreateRooms(newRoomsData);
        
        setSubmitting(false);
        SetStatusLed(false);


        if (newRooms) {
            newRooms.forEach(room => {
                room.siglaHotel = hotels.filter(hotel => hotel.id == room.idHotel)[0]?.sigla;
            });
            CreateNewRooms(newRooms);
            CloseOverlay();
        }
        //Otherwise do nothing (the error displaying is already handled by CreateRooms)
    }

    const HandleSubmitNewType = async (data, {setSubmitting}) => {

        setSubmitting(true);
        SetStatusLed(true);

        let newRoomType = {
            nome: data.tipo,
            capienza: data.capienza
        }
        
        let res = await CreateRoomType(newRoomType);
        
        setSubmitting(false);
        SetStatusLed(false);

        if (res) {
            CreateNewRoomType(data);
            setAddType(false);
        }
        //Otherwise do nothing (the error displaying is already handled by CreateRoomType)
    }

    return (
        <div id='new-room-overlay' className='overlay-div'>
            <div className='close-overlay clickable' onClick={CloseOverlay} > <AiOutlineClose /> </div>

            <div className='inner-overlay-div'>
                <h2> Nuove Camere </h2>
                <div className='overlay-top-divider' />
                
                <div className='overlay-content-div'>
                    <Formik
                        initialValues={{
                            numeroCamere: 1,
                            idHotel: '',
                            tipo: '',
                            capienza:'',
                            idCollaboratore:'',
                            note:''
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(data, {setSubmitting}) => HandleSubmit(data, {setSubmitting})}
                    >

                    {({ errors, touched, isSubmitting, handleChange, setFieldValue }) => (
                        <Form autoComplete='off'>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Camere da aggiungere: </h4><Field className='overlay-number-input' type='number' name='numeroCamere' as={TextField} autoFocus/> {touched.numeroCamere && errors.numeroCamere ? <a className='validation'>{errors.numeroCamere}</a> : ''} </div>
                            
                            <div className='overlay-input-field'><h4 className='overlay-input-key'>Hotel: </h4>
                            <Field name='idHotel' as={Select} > {/* Does not use style or font in option and Mozilla fucks up the baseline alignment, so --> Make a custom style just for the select */}
                               { hotels.map( hotel =>{
                                    return(
                                        <MenuItem value={hotel.id}>{hotel.nome}</MenuItem>
                                    )
                                })
                                }
                            </Field>
                            {touched.idHotel && errors.idHotel ? <a className='validation'>{errors.idHotel}</a> : ''}
                            </div>

                            <div className='overlay-input-field'><h4 className='overlay-input-key'>Tipo: </h4>
                            <Field name='tipo' as={Select} onChange={(e) => {HandleTypeSelected(e, setFieldValue); handleChange(e);}} > {/* Does not use style or font in option and Mozilla fucks up the baseline alignment, so --> Make a custom style just for the select */}
                               { roomTypes.map( type =>{
                                    return(
                                        <MenuItem value={type.tipo}>{type.tipo}</MenuItem>
                                    )
                                })
                                }
                            </Field>
                            <a className='secondary clickable' onClick={() => setAddType(true)}>o  Aggiungi Tipo</a>
                            {touched.tipo && errors.tipo ? <a className='validation'>{errors.tipo}</a> : ''}
                            </div>

                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Capienza massima: </h4><Field className='overlay-number-input' type='number' name='capienza' as={TextField} /> {touched.capienza && errors.capienza ? <a className='validation'>{errors.capienza}</a> : ''} </div>
                            
                            <div className='overlay-input-field'><h4 className='overlay-input-key'>Collaboratore: </h4>
                            <Field name='idCollaboratore' as={Select} > {/* Does not use style or font in option and Mozilla fucks up the baseline alignment, so --> Make a custom style just for the select */}
                                <MenuItem value=''>Nessuno</MenuItem>
                                {collaborators.filter(collaborator => !collaborator.isAdmin).map( collaborator =>{
                                    return(
                                        <MenuItem value={collaborator.id}>{collaborator.cognome == null ? collaborator.nome : (collaborator.cognome + ' ' + collaborator.nome)}</MenuItem>
                                    )
                                })
                                }
                            </Field>
                            {touched.idCollaboratore && errors.idCollaboratore ? <a className='validation'>{errors.idCollaboratore}</a> : ''}
                            </div>

                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Note: </h4><Field className='overlay-date-input' type='text' name='note' as={TextareaAutosize} />{touched.note && errors.note ? <a className='validation'>{errors.note}</a> : ''}</div>
                            <button disabled={isSubmitting} type='submit'> Conferma </button>
                        </Form>
                    )}

                    </Formik>
                </div>
            </div>

            {addType &&
                <div className='upper-black-overlay' >
                    <div className='upper-overlay-div'>
                        <div className='close-overlay clickable' onClick={() => setAddType(false)} > <AiOutlineClose /> </div>
                        <div className='inner-upper-overlay-div'>
                            <h2> Nuovo Tipo di Camera </h2>
                            <div className='upper-overlay-top-divider' />

                            <Formik
                                initialValues={{
                                    tipo: '',
                                    capienza:''
                                }}
                                validationSchema={RoomTypeValidationSchema}
                                onSubmit={(data, {setSubmitting}) => HandleSubmitNewType(data, {setSubmitting})}
                            >

                            {({ errors, touched, isSubmitting }) => (
                                <Form autoComplete='off'>
                                    <div className='overlay-input-field'> <h4 className='overlay-input-key'>Nome: </h4><Field className='overlay-text-input' type='text' name='tipo' as={TextField} autoFocus/> {touched.tipo && errors.tipo ? <a className='validation'>{errors.tipo}</a> : ''} </div>
                                    <div className='overlay-input-field'> <h4 className='overlay-input-key'>Capienza: </h4><Field className='overlay-number-input' type='number' name='capienza' as={TextField} /> {touched.capienza && errors.capienza ? <a className='validation'>{errors.capienza}</a> : ''} </div>
                                    <button disabled={isSubmitting} type='submit'> Conferma </button>
                                </Form>
                            )}

                            </Formik>
                        
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}

export function ChangeAdminRoomNotes({ setCurrentOverlayPage, currentlyEditedRoom, UpdateRoomNotes, SetStatusLed }){

    const CloseOverlay = () => {
        setCurrentOverlayPage('none');
    }

    const HandleChangeRoomNotes = async (data, {setSubmitting}) => {

        setSubmitting(true);
        SetStatusLed(true);

        let roomFlags = {
            idStanza: currentlyEditedRoom.id,
            piena: currentlyEditedRoom.piena,
            matrimoniale: currentlyEditedRoom.matrimoniale,
            noteModificabili: currentlyEditedRoom.noteModificabili,
            note: data.note == '' ? null : data.note
        }

        let res = await AdminUpdateRoomFlags(roomFlags);
        
        setSubmitting(false);
        SetStatusLed(false);


        if (res) {
            UpdateRoomNotes(data.note);
            CloseOverlay();
        }
        //Otherwise do nothing (the error displaying is already handled by AdminUpdateRoomFlags)

    }

    return (
        <div id='change-room-notes-overlay' className='overlay-div'>
            <div className='close-overlay clickable' onClick={CloseOverlay} > <AiOutlineClose /> </div>

            <div className='inner-overlay-div'>
                <h2> Camera {currentlyEditedRoom.codice} - {currentlyEditedRoom.nomeHotel} </h2>
                <div className='overlay-top-divider' />

                <Formik
                    initialValues={{
                        note: currentlyEditedRoom.note
                    }}
                    validationSchema={noteValidationSchema}
                    onSubmit={(data, {setSubmitting}) => HandleChangeRoomNotes(data, {setSubmitting})}
                >

                {({ errors, touched, isSubmitting }) => (
                    <Form autoComplete='off'>
                        <div className='overlay-input-field'> <h4 className='overlay-input-key'>Note: </h4><Field className='overlay-date-input' type='text' name='note' as={TextareaAutosize} autoFocus/>{touched.note && errors.note ? <a className='validation'>{errors.note}</a> : ''}</div>
                        
                        <button disabled={isSubmitting} type='submit'> Conferma </button>
                    </Form>
                )}

                </Formik>
                

            </div>

        </div>
    )
}

export  function ModifyRoomData({ setCurrentOverlayPage, roomTypes, collaborators, hotels, currentlyEditedRoom, ModifyRoom, CreateNewRoomType, SetStatusLed }) {
    
    //Detect mobile device and use default inputs in case
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
            setWidth(window.innerWidth);
        }
    useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);

    let isMobile = (width < 1024);
    
    
    
    let [addType, setAddType] = useState(false);
    const [dataError, setDataError] = useState('');

    const CloseOverlay = () => {
        setCurrentOverlayPage('none');
    }

    const HandleTypeSelected = (e, setFieldValue) => {
        for (let i = 0; i < roomTypes.length; i++){
            if (roomTypes[i].tipo === e.target.value){
                setFieldValue('capienza', roomTypes[i].capienza);
            }
        }
    }

    const HandleSubmit = async (data, {setSubmitting}) => {

        if(data.capienza < currentlyEditedRoom.postiOccupati){
            setDataError('Capienza minore del numero di posti occupati');
            setSubmitting(false);
            return;
        }

        setSubmitting(true);
        SetStatusLed(true);

        let updatedRoomData = {
            idStanza: currentlyEditedRoom.id,
            idCollaboratore: data.idCollaboratore == '' ? null : data.idCollaboratore,
            tipo: data.tipo == '' ? null : data.tipo,
            note: data.note == '' ? null : data.note,
            capienza: data.capienza == '' ? null : data.capienza,
            idHotel: data.idHotel == '' ? null : data.idHotel
        }

        let res = await UpdateDBRoomData(updatedRoomData);
        
        setSubmitting(false);
        SetStatusLed(false);


        if (res) {
            updatedRoomData.id = updatedRoomData.idStanza;
            updatedRoomData.numero = currentlyEditedRoom.numero;
            updatedRoomData.codice = updatedRoomData.tipo[0] + String(currentlyEditedRoom.numero);
            updatedRoomData.piena = currentlyEditedRoom.piena;
            updatedRoomData.noteModificabili = currentlyEditedRoom.noteModificabili;
            updatedRoomData.postiOccupati = currentlyEditedRoom.postiOccupati;
            updatedRoomData.matrimoniale = currentlyEditedRoom.matrimoniale;
            updatedRoomData.collocati = currentlyEditedRoom.collocati;

    
            if(data.capienza > currentlyEditedRoom.capienza){
                updatedRoomData.piena = false;
            }
    
            for (let h = 0; h < hotels.length; h++){
                if (hotels[h].id == updatedRoomData.idHotel){
                    updatedRoomData.nomeHotel = hotels[h].nome;
                    updatedRoomData.siglaHotel = hotels[h].sigla;
                    break;
                }
            }
    
            if(!(updatedRoomData.tipo == 'Doppia' || updatedRoomData.tipo == 'Tripla')){
                updatedRoomData.matrimoniale = false;
            }
    
            if (updatedRoomData.postiOccupati == updatedRoomData.capienza){
                updatedRoomData.piena = true;
            }

            ModifyRoom(updatedRoomData);
            CloseOverlay();
        }
        //Otherwise do nothing (the error displaying is already handled by UpdateDBRoomData)
    }

    const HandleSubmitNewType = async (data, {setSubmitting}) => {

        setSubmitting(true);
        SetStatusLed(true);

        let newRoomType = {
            nome: data.tipo,
            capienza: data.capienza
        }
        
        let res = await CreateRoomType(newRoomType);
        
        setSubmitting(false);
        SetStatusLed(false);

        if (res) {
            CreateNewRoomType(data);
            setAddType(false);
        }
        //Otherwise do nothing (the error displaying is already handled by CreateRoomType)
    }

    return (
        <div id='modify-room-data-overlay' className='overlay-div'>
            <div className='close-overlay clickable' onClick={CloseOverlay} > <AiOutlineClose /> </div>

            <div className='inner-overlay-div'>
                <h2> Camera {currentlyEditedRoom.codice} </h2>
                <div className='overlay-top-divider' />
                
                <div className='overlay-content-div'>
                    <Formik
                        initialValues={{
                            idHotel: currentlyEditedRoom.idHotel,
                            tipo: currentlyEditedRoom.tipo,
                            capienza: currentlyEditedRoom.capienza,
                            idCollaboratore: currentlyEditedRoom.idCollaboratore == null ? '' : currentlyEditedRoom.idCollaboratore,
                            note: currentlyEditedRoom.note == null ? '' : currentlyEditedRoom.note
                        }}
                        validationSchema={ModifyValidationSchema}
                        onSubmit={(data, {setSubmitting}) => HandleSubmit(data, {setSubmitting})}
                    >

                    {({ errors, touched, isSubmitting, handleChange, setFieldValue }) => (
                        <Form autoComplete='off'>
                            <div className='overlay-input-field'><h4 className='overlay-input-key'>Hotel: </h4>
                            <Field name='idHotel' as={Select} autoFocus> {/* Does not use style or font in option and Mozilla fucks up the baseline alignment, so --> Make a custom style just for the select */}
                               { hotels.map( hotel =>{
                                    return(
                                        <MenuItem value={hotel.id}>{hotel.nome}</MenuItem>
                                    )
                                })
                                }
                            </Field>
                            {touched.idHotel && errors.idHotel ? <a className='validation'>{errors.idHotel}</a> : ''}
                            </div>

                            <div className='overlay-input-field'><h4 className='overlay-input-key'>Tipo: </h4>
                            <Field name='tipo' as={Select} onChange={(e) => {HandleTypeSelected(e, setFieldValue); handleChange(e);}} > {/* Does not use style or font in option and Mozilla fucks up the baseline alignment, so --> Make a custom style just for the select */}
                               { roomTypes.map( type =>{
                                    return(
                                        <MenuItem value={type.tipo}>{type.tipo}</MenuItem>
                                    )
                                })
                                }
                            </Field>
                            <a className='secondary clickable' onClick={() => setAddType(true)}>o  Aggiungi Tipo</a>
                            {touched.tipo && errors.tipo ? <a className='validation'>{errors.tipo}</a> : ''}
                            </div>

                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Capienza massima: </h4><Field className='overlay-number-input' type='number' name='capienza' as={TextField} /> {touched.capienza && errors.capienza ? <a className='validation'>{errors.capienza}</a> : ''} </div>
                            
                            <div className='overlay-input-field'><h4 className='overlay-input-key'>Collaboratore: </h4>
                            <Field name='idCollaboratore' as={Select} > {/* Does not use style or font in option and Mozilla fucks up the baseline alignment, so --> Make a custom style just for the select */}
                                <MenuItem value=''>Nessuno</MenuItem>
                                {collaborators.filter(collaborator => !collaborator.isAdmin).map( collaborator =>{
                                    return(
                                        <MenuItem value={collaborator.id}>{collaborator.cognome == null ? collaborator.nome : (collaborator.cognome + ' ' + collaborator.nome)}</MenuItem>
                                    )
                                })
                                }
                            </Field>
                            {touched.idCollaboratore && errors.idCollaboratore ? <a className='validation'>{errors.idCollaboratore}</a> : ''}
                            </div>

                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Note: </h4><Field className='overlay-date-input' type='text' name='note' as={TextareaAutosize} />{touched.note && errors.note ? <a className='validation'>{errors.note}</a> : ''}</div>
                            <div className='form-submit-button-div'>
                                <button disabled={isSubmitting} type='submit'> Conferma </button>
                                {dataError !== '' ? <a className='data-error'>{dataError}</a> : ''}
                            </div>
                        </Form>
                    )}

                    </Formik>
                </div>
            </div>

            {addType &&
                <div className='upper-black-overlay' >
                    <div className='upper-overlay-div'>
                        <div className='close-overlay clickable' onClick={() => setAddType(false)} > <AiOutlineClose /> </div>
                        <div className='inner-upper-overlay-div'>
                            <h2> Nuovo Tipo di Camera </h2>
                            <div className='upper-overlay-top-divider' />

                            <Formik
                                initialValues={{
                                    tipo: '',
                                    capienza:''
                                }}
                                validationSchema={RoomTypeValidationSchema}
                                onSubmit={(data, {setSubmitting}) => HandleSubmitNewType(data, {setSubmitting})}
                            >

                            {({ errors, touched, isSubmitting }) => (
                                <Form autoComplete='off'>
                                    <div className='overlay-input-field'> <h4 className='overlay-input-key'>Nome: </h4><Field className='overlay-text-input' type='text' name='tipo' as={TextField} autoFocus/> {touched.tipo && errors.tipo ? <a className='validation'>{errors.tipo}</a> : ''} </div>
                                    <div className='overlay-input-field'> <h4 className='overlay-input-key'>Capienza: </h4><Field className='overlay-number-input' type='number' name='capienza' as={TextField} /> {touched.capienza && errors.capienza ? <a className='validation'>{errors.capienza}</a> : ''} </div>
                                    <button disabled={isSubmitting} type='submit'> Conferma </button>
                                </Form>
                            )}

                            </Formik>
                        
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}