import { React, useContext, useState } from 'react'
import '../index.css'
import { Form, Formik, Field } from 'formik'
import * as yup from 'yup'
import SessionDataContext from '../contexts/sessionData'
import LoginRequest from '../serverRequests/loginRequest'
import ResetPasswordOverlay from './passwordReset/resetPasswordOverlay'

const validationSchema = yup.object({
    username: yup
    .string('Username deve essere una stringa')
    .required('Username obbligatorio')
    .max(50, 'Massimo 50 caratteri'),
    password: yup
    .string('Password deve essere una stringa')
    .required('Password obbligatoria')
    .min(8, 'Minimo 8 caratteri')
    .max(50, 'Massimo 50 caratteri')
});


export default function Login(){
    const [loginError, setLoginError] = useState('');
    const [resetPasswordOverlayVisible, setResetPasswordOverlayVisible] = useState(false);

    const {sessionData, setSessionData} = useContext(SessionDataContext);



    return(
        <div className='container-login' >
            <div className='login-window' >
                <Formik
                    initialValues={{username: '', password: ''}}
                    validationSchema={validationSchema}
                    onSubmit={(data, {setSubmitting}) => LoginRequest(data, setLoginError, setSessionData, {setSubmitting})}
                >
                    {({errors, touched, isSubmitting})=> (
                        <Form>
                            <h2>Login</h2>

                            <label>Username</label>
                            {/* <MyTextField name='username' placeholder='Username' type='text' /> */}
                            <div className='text-input-div'>
                                <Field className='text-input' name='username' placeholder='Username' type='text' />
                                {touched.username && errors.username ? <a className='validation'>{errors.username}</a> : ''}
                            </div>
                            
                            <label>Password</label>
                            {/* <MyTextField name='password' placeholder='Password' type='password' /> */}
                            <div className='text-input-div'>
                                <Field className='text-input' name='password' placeholder='Password' type='password' />
                                {touched.password && errors.password ? <a className='validation'>{errors.password}</a> : ''}
                            </div>

                            <div className='text-input-div'>
                                <button disabled={isSubmitting} type='submit'>Accedi</button>
                                {loginError !== '' ? <a className='login-error'>{loginError}</a> : ''}
                            </div>

                        </Form>
                    )}
                </Formik>
                <a className='reset-password-link' onClick={() => setResetPasswordOverlayVisible(true)}>Password dimenticata</a>
            </div>
        

            {/* RESET PASSWORD OVERLAY */}
            
            {resetPasswordOverlayVisible === true &&
                <div id='main-overlay' className='black-overlay'>
                    <ResetPasswordOverlay setResetPasswordOverlayVisible={setResetPasswordOverlayVisible} />
                </div>
            }
            
        </div>
    )
}