import React from 'react'
import { Form, Formik, Field } from 'formik';
import * as yup from 'yup'
import { TextField } from '@material-ui/core';
import { UpdatePernottamentoData } from '../../../serverRequests/putRequests';

const validationSchema = yup.object({
    dataIn: yup
    .string('Data In deve essere una stringa')
    .required('Data In obbligatoria'),
    dataOut: yup
    .string('Data Out deve essere una stringa')
    .required('Data Out obbligatoria')
});

export default function Stay({dataIn, dataOut, idPartecipante, GoToChangeStayPage}) {
    return (
        <div className='stay-div'>
            <h3>Pernottamento</h3>
            <div class='top-divider' />

            {dataIn != null &&
                <div className='data-field'>
                    <h4 className='data-field-key'> Data In: </h4>
                    <div className='data-field-value'>
                        <h4> {dataIn} </h4>
                    </div>
                </div>
            }

            {dataOut != null &&
                <div className='data-field'>
                    <h4 className='data-field-key'> Data Out: </h4>
                    <div className='data-field-value'>
                        <h4> {dataOut} </h4>
                    </div>
                </div>
            }
            
            <button onClick={() => GoToChangeStayPage(idPartecipante)} > Modifica </button>

        </div>
    )
}

export function ConfirmStay({dataIn, dataOut, ConfirmClientStay}){

    const HandleConfirmStay = (data, {setSubmitting}) => {

        setSubmitting(true);
        
        ConfirmClientStay(data.dataIn.split('-').reverse().join('/'), data.dataOut.split('-').reverse().join('/'));
        
        setSubmitting(false);
    }

    return (
        <div className='stay-div'>
            <h3>Pernottamento</h3>
            <div class='top-divider' />

            <Formik
                initialValues={{
                    dataIn: dataIn == null ? '' : dataIn.split('/').reverse().join('-'),
                    dataOut: dataOut == null ? '' : dataOut.split('/').reverse().join('-'),
                }}
                validationSchema={validationSchema}
                onSubmit={(data, {setSubmitting}) => HandleConfirmStay(data, {setSubmitting})}
            >

            {({ errors, touched, isSubmitting }) => (
                <Form autoComplete='off'>
                    <div className='data-field'> <h4 className='data-field-key'>Data In: </h4><Field className='overlay-date-input' type='date' name='dataIn' as={TextField} autoFocus/> {touched.dataIn && errors.dataIn ? <a className='validation'>{errors.dataIn}</a> : ''} </div>
                    <div className='data-field'> <h4 className='data-field-key'>Data Out: </h4><Field className='overlay-date-input' type='date' name='dataOut' as={TextField} /> {touched.dataOut && errors.dataOut ? <a className='validation'>{errors.dataOut}</a> : ''} </div>
                    <button disabled={isSubmitting} type='submit'> Conferma </button>
                </Form>
            )}

            </Formik>

        </div>
    )
}

export function ChangeStay({idPartecipante, idStanza, dataIn, dataOut, SetStatusLed, ModifyClientStay}){

    const HandleConfirmStay = async (data, {setSubmitting}) => {

        setSubmitting(true);
        SetStatusLed (true);

        let pernottamentoData = {
            idPartecipante: idPartecipante == '' ? null : idPartecipante,
            idStanza: idStanza == '' ? null : idStanza,
            dataIn: data.dataIn == '' ? null : data.dataIn,
            dataOut: data.dataOut == '' ? null : data.dataOut
        }
        
        let res = await UpdatePernottamentoData(pernottamentoData);
        
        setSubmitting(false);
        SetStatusLed (false);

        if (res) {
            ModifyClientStay(data.dataIn.split('-').reverse().join('/'), data.dataOut.split('-').reverse().join('/'));
        }
        //Otherwise do nothing (the error displaying is already handled by UpdatePernottamentoData)
    }

    return (
        <div className='stay-div change-stay-div'>
            <h3>Pernottamento</h3>
            <div class='top-divider' />

            <Formik
                initialValues={{
                    dataIn: dataIn == null ? '' : dataIn.split('/').reverse().join('-'),
                    dataOut: dataOut == null ? '' : dataOut.split('/').reverse().join('-'),
                }}
                validationSchema={validationSchema}
                onSubmit={(data, {setSubmitting}) => HandleConfirmStay(data, {setSubmitting})}
            >

            {({ errors, touched, isSubmitting }) => (
                <Form autoComplete='off'>
                    <div className='data-field'> <h4 className='data-field-key'>Data In: </h4><Field className='overlay-date-input' type='date' name='dataIn' as={TextField} autoFocus/> {touched.dataIn && errors.dataIn ? <a className='validation'>{errors.dataIn}</a> : ''} </div>
                    <div className='data-field'> <h4 className='data-field-key'>Data Out: </h4><Field className='overlay-date-input' type='date' name='dataOut' as={TextField} /> {touched.dataOut && errors.dataOut ? <a className='validation'>{errors.dataOut}</a> : ''} </div>
                    <button disabled={isSubmitting} type='submit'> Conferma </button>
                </Form>
            )}

            </Formik>

        </div>
    )
}