import { Field, Form, Formik } from 'formik';
import React, { useContext, useState } from 'react'
import { FaAngleLeft, FaSearch } from 'react-icons/fa';
import SessionDataContext from '../../../contexts/sessionData';
import { SessionLogOutRequest } from '../../../serverRequests/loginRequest';
import { UploadExcelTesseramenti } from '../../../serverRequests/postRequests';
import fileDialog from 'file-dialog';
import TesseramentiContext from '../../../contexts/tesseramentiContext';
import { SERVER_URL } from '../../../serverRequests/consts';

export default function AdminHomeCommands({ activePage, setActivePage, groupTesseramenti, setGroupTesseramenti, mostraEventiPassati, setMostraEventiPassati, mostraTesseramentiScaduti, setMostraTesseramentiScaduti, mostraSoloTesseramentiAttivi, setMostraSoloTesseramentiAttivi, setCopyingEvent, sortEvents, findEvents/*, sortCollaborators, findCollaborators*/, ExpandAllCollaborators, CollapseAllCollaborators, ExpandAllTesseramenti, CollapseAllTesseramenti, sortTesseramenti, sortAdmins, findAdmins, findTesseramenti, setCurrentOverlayPage, currentEventsOrder/*, currentCollaboratorsOrder*/, currentAdminsOrder, currentTesseramentiOrder, DeleteMultipleTesseramentiPressed, CancelDeleteMultipleTesseramenti, RenewMultipleTesseramentiPressed, CancelRenewMultipleTesseramenti }) {
    const {sessionData, setSessionData} = useContext(SessionDataContext);
    const {setTesseramenti} = useContext(TesseramentiContext);
    
    const logOut = () => {
        setSessionData({role: 0, nome: '', cognome: '', id: ''});
        SessionLogOutRequest();
    }


    const ExpandCollapseCommandsDiv = () => {
        // let page = document.getElementById('container-collaborator-event');
        let commandsDiv = document.getElementById('admin-home-commands-div');

        if (commandsDiv.className == 'commands-div' ){
            commandsDiv.className = 'commands-div collapsed';
        }
        else{
            commandsDiv.className = 'commands-div';
        }

    }

    const handleFilterEventsClick = (e) => {
        setMostraEventiPassati(e.target.checked);
    }

    const handleFilterTesseramentiScadutiClick = (e) => {
        setMostraTesseramentiScaduti(e.target.checked);
    }

    const handleFilterSoloTesseramentiAttiviClick = (e) => {
        setMostraSoloTesseramentiAttivi(e.target.checked);
    }

    const handleGroupTesseramentiClick = (e) => {
        setGroupTesseramenti(e.target.checked);
    }

    const handleOrderEventsByClick = (e) => {
        sortEvents(e.target.value);
    }

    // const handleOrderCollaboratorsByClick = (e) => {
    //     sortCollaborators(e.target.value);
    // }

    const handleOrderAdminsByClick = (e) => {
        sortAdmins(e.target.value);
    }

    const handleOrderTesseramentiByClick = (e) => {
        sortTesseramenti(e.target.value);
    }

    const handleFindEventsByChange = (e, setFieldValue) => {
        if (e.target.value == 'Nome'){
            setFieldValue('findEvents', '');
            document.getElementById('find-by-name-input').style.display = 'block';
            document.getElementById('find-by-date-input').style.display = 'none';
        } else {
            document.getElementById('find-by-name-input').style.display = 'none';
            document.getElementById('find-by-date-input').style.display = 'block';
        }
    }

    const DownloadListaTesserati = () => {
        window.location.href=SERVER_URL + '/downloadTesseramentiList';
    }

    const HandleUploadTesseramentiExcelClicked = async () => {
        fileDialog({multiple: false, accept: '.xlsx'}, async (files) => {

            const res = await UploadExcelTesseramenti(files[0], setTesseramenti);

            if (res) {
                alert('[✓] Excel caricato con successo!')
                // //Show success toast
                // Swal.fire({
                //     toast: true,
                //     position: "top",
                //     titleText: "Modulo Pre-Corso caricato!",
                //     icon: "success",
                //     target: 'body',
                //     backdrop: false,
                //     iconColor: '#59DECD',
                //     color: '#E7E7E7',
                //     background: '#555',
                //     timer: 1500,
                //     timerProgressBar: false,
                //     showConfirmButton: false,
                //     customClass: {
                //         title: 'reg-18-white'
                //     }
                // })
            } else {
                alert('[X] Errore nel caricamento dell\'Excel!\n\nRicaricare la pagina per aggiornare i dati e riprovare. Se il problema persiste contattare un amministratore.')
                // //Show fail toast
                // Swal.fire({
                //     toast: true,
                //     position: "top",
                //     titleText: "Errore nel caricamento del Modulo Pre-Corso!",
                //     text: "Ricaricare la pagina per aggiornare i dati e riprovare. Se il problema persiste contattare un amministratore.",
                //     icon: "error",
                //     target: 'body',
                //     backdrop: false,
                //     iconColor: '#DE6159',
                //     color: '#E7E7E7',
                //     background: '#555',
                //     timer: 10000,
                //     timerProgressBar: true,
                //     showConfirmButton: false,
                //     customClass: {
                //         title: 'reg-18-white',
                //         htmlContainer: 'reg-16-gray'
                //     }
                // })
            }

        });
    }

    return (
        <div id='admin-home-commands-div' className='commands-div'>
            <div id='toggle-home-commands-div' className='toggle-commands-div'> <FaAngleLeft onClick={ExpandCollapseCommandsDiv}/> </div>
            <div className='inner-commands-div'>
                <h4> {sessionData.cognome} {sessionData.nome}</h4>
                <a className='clickable' onClick={logOut}>Log Out</a>
                <div className='divider' />

                <div className='commands-div-elements'>

                    <div className='commands-page-selection'>
                        <h3 className={activePage==='events' ? 'page-name clickable' : 'page-name'} onClick={() => setActivePage('events')}>Eventi</h3>
                        {/* <h3 className={activePage==='collaborators' ? 'page-name clickable' : 'page-name'} onClick={() => setActivePage('collaborators')}>Collaboratori</h3> */}
                        <h3 className={activePage==='admins' ? 'page-name clickable' : 'page-name'} onClick={() => setActivePage('admins')}>Admin</h3>
                        <h3 className={activePage==='tesseramenti' ? 'page-name clickable' : 'page-name'} onClick={() => setActivePage('tesseramenti')}>Tesseramenti</h3>
                    </div>

                    <div className='divider' />


                    { activePage === 'events' &&
                    <div className='commands-div-elements'>
                        <div className='commands-add'>
                            <h3>AGGIUNGI</h3>
                            <div className='commands-add-buttons'>
                                <button onClick={() => setCurrentOverlayPage('createNewEvent')} > Nuovo Evento </button>
                                <button onClick={() => setCopyingEvent(true)}> Copia Evento </button>
                            </div>
                        </div>

                        <div className='commands-filter'>
                            <h3>FILTRA</h3>
                            <label>
                                <input checked={mostraEventiPassati} type='checkbox' onClick={handleFilterEventsClick}/>
                                <h4> Mostra eventi passati </h4>
                            </label>
                        </div>

                        <div className='commands-order' >
                            <h3>ORDINA PER</h3>
                            <Formik
                                initialValues={{
                                    orderEventsBy: currentEventsOrder,
                                }}
                            >
                                {() => (
                                    <Form>
                                        <label> <Field type='radio' name='orderEventsBy' value='nomeAZ' onClick={handleOrderEventsByClick} /> <a>{'Nome (A -> Z)'}</a> </label>
                                        <label> <Field type='radio' name='orderEventsBy' value='nomeZA' onClick={handleOrderEventsByClick} /> <a>{'Nome (Z -> A)'}</a> </label>
                                        <label> <Field type='radio' name='orderEventsBy' value='dataCronologico' onClick={handleOrderEventsByClick} /> <a>{'Data (Vecchio -> Nuovo)'}</a> </label>
                                        <label> <Field type='radio' name='orderEventsBy' value='dataInverso' onClick={handleOrderEventsByClick} /> <a>{'Data (Nuovo -> Vecchio)'}</a> </label>
                                    </Form>
                                )}
                            </Formik>
                        </div>

                        <div className='commands-find'>
                            <h3>RICERCA</h3>
                            <Formik
                                initialValues = {{findEventsBy: 'Nome', findEvents: ''}}
                                onSubmit = {(data) => {
                                        findEvents(data.findEventsBy, data.findEvents);
                                    }
                                }
                            >
                                {({ values, handleChange, setFieldValue }) => (
                                    <Form>
                                        <div className='find-div'>
                                            <label>
                                                <a>Ricerca per: </a>
                                                <Field as='select' name='findEventsBy' onChange={e => {handleChange(e); handleFindEventsByChange(e, setFieldValue);}} >
                                                    <option value='Nome'>Nome</option>
                                                    <option value='Data esatta'>Data esatta</option>
                                                    <option value='Data successiva al'>Data successiva al</option>
                                                    <option value='Data precedente al'>Data precedente al</option>
                                                </Field>
                                            </label>
                                            <div className='find-input-div'>
                                                <Field id='find-by-name-input' className='text-input' type='text' name='findEvents' />
                                                <Field id='find-by-date-input' className='date-input' type='date' name='findEvents' />
                                                <button type='submit'> <FaSearch className='search-icon'/> </button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>

                    </div>
                    }

                    {/* { activePage === 'collaborators' &&
                    <div className='commands-div-elements'>
                        <div className='commands-add'>
                            <h3>AGGIUNGI</h3>
                            <div className='commands-add-buttons'>
                                <button onClick={() => setCurrentOverlayPage('createNewCollaborator')} > Nuovo Collaboratore </button>
                            </div>
                        </div>

                        <div className='commands-order' >
                            <h3>ORDINA PER</h3>
                            <Formik
                                initialValues={{
                                    orderCollaboratorsBy: currentCollaboratorsOrder,
                                }}
                            >
                                {() => (
                                    <Form>
                                        <label> <Field type='radio' name='orderCollaboratorsBy' value='nomeAZ' onClick={handleOrderCollaboratorsByClick} /> <a>{'Nome (A -> Z)'}</a> </label>
                                        <label> <Field type='radio' name='orderCollaboratorsBy' value='nomeZA' onClick={handleOrderCollaboratorsByClick} /> <a>{'Nome (Z -> A)'}</a> </label>
                                        <label> <Field type='radio' name='orderCollaboratorsBy' value='cognomeAZ' onClick={handleOrderCollaboratorsByClick} /> <a>{'Cognome (A -> Z)'}</a> </label>
                                        <label> <Field type='radio' name='orderCollaboratorsBy' value='cognomeZA' onClick={handleOrderCollaboratorsByClick} /> <a>{'Cognome (Z -> A)'}</a> </label>
                                    </Form>
                                )}
                            </Formik>
                        </div>

                        <div className='commands-find'>
                            <h3>RICERCA</h3>
                            <Formik
                                initialValues = {{findCollaboratorsBy: 'Nome', findCollaborators: ''}}
                                onSubmit = {(data) => {
                                        findCollaborators(data.findCollaboratorsBy, data.findCollaborators);
                                    }
                                }
                            >
                                {({ values, handleChange, setFieldValue }) => (
                                    <Form>
                                        <div className='find-div'>
                                            <label>
                                                <a>Ricerca per: </a>
                                                <Field as='select' name='findCollaboratorsBy' >
                                                    <option value='Nome'>Nome</option>
                                                    <option value='Cognome'>Cognome</option>
                                                    <option value='Username'>Username</option>
                                                    <option value='Riferimento'>Riferimento</option>
                                                </Field>
                                            </label>
                                            <div className='find-input-div'>
                                                <Field id='find-by-name-input' className='text-input' type='text' name='findCollaborators' />
                                                <button type='submit'> <FaSearch className='search-icon'/> </button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>

                        <div className='commands-toggle'>
                            <h3>COLLASSA / ESPANDI</h3>
                            <div className='toggle-clients-buttons-div'>
                                <button onClick={CollapseAllCollaborators} > Collassa tutti </button>
                                <button onClick={ExpandAllCollaborators} > Espandi tutti </button>
                            </div>
                        </div>

                    </div>
                    } */}
                    
                    { activePage === 'admins' &&
                    <div className='commands-div-elements'>
                        <div className='commands-add'>
                            <h3>AGGIUNGI</h3>
                            <div className='commands-add-buttons'>
                                <button onClick={() => setCurrentOverlayPage('createNewAdmin')} > Nuovo Admin </button>
                            </div>
                        </div>

                        <div className='commands-order' >
                            <h3>ORDINA PER</h3>
                            <Formik
                                initialValues={{
                                    orderAdminsBy: currentAdminsOrder,
                                }}
                            >
                                {() => (
                                    <Form>
                                        <label> <Field type='radio' name='orderAdminsBy' value='nomeAZ' onClick={handleOrderAdminsByClick} /> <a>{'Nome (A -> Z)'}</a> </label>
                                        <label> <Field type='radio' name='orderAdminsBy' value='nomeZA' onClick={handleOrderAdminsByClick} /> <a>{'Nome (Z -> A)'}</a> </label>
                                        <label> <Field type='radio' name='orderAdminsBy' value='cognomeAZ' onClick={handleOrderAdminsByClick} /> <a>{'Cognome (A -> Z)'}</a> </label>
                                        <label> <Field type='radio' name='orderAdminsBy' value='cognomeZA' onClick={handleOrderAdminsByClick} /> <a>{'Cognome (Z -> A)'}</a> </label>
                                    </Form>
                                )}
                            </Formik>
                        </div>

                        <div className='commands-find'>
                            <h3>RICERCA</h3>
                            <Formik
                                initialValues = {{findAdminsBy: 'Cognome', findAdmins: ''}}
                                onSubmit = {(data) => {
                                        findAdmins(data.findAdminsBy, data.findAdmins);
                                    }
                                }
                            >
                                {({ values, handleChange, setFieldValue }) => (
                                    <Form>
                                        <div className='find-div'>
                                            <label>
                                                <a>Ricerca per: </a>
                                                <Field as='select' name='findAdminsBy' >
                                                    <option value='Cognome'>Cognome</option>
                                                    <option value='Nome'>Nome</option>
                                                    <option value='Username'>Username</option>
                                                    <option value='Riferimento'>Riferimento</option>
                                                </Field>
                                            </label>
                                            <div className='find-input-div'>
                                                <Field id='find-by-name-input' className='text-input' type='text' name='findAdmins' />
                                                <button type='submit'> <FaSearch className='search-icon'/> </button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>

                        <div className='commands-toggle'>
                            <h3>COLLASSA / ESPANDI</h3>
                            <div className='toggle-clients-buttons-div'>
                                <button onClick={CollapseAllCollaborators} > Collassa tutti </button>
                                <button onClick={ExpandAllCollaborators} > Espandi tutti </button>
                            </div>
                        </div>

                    </div>
                    }

                    {activePage === 'tesseramenti' &&
                    <div className='commands-div-elements'>
                        <div className='commands-add'>
                            <h3>AGGIUNGI</h3>
                            <div className='commands-add-buttons'>
                                <button onClick={() => {setCurrentOverlayPage('createNewTesseramento'); CancelDeleteMultipleTesseramenti(); CancelRenewMultipleTesseramenti();}} > Nuovo Tesserato </button>
                            </div>
                            {/* <div className='commands-add-buttons'>
                                <button onClick={HandleUploadTesseramentiExcelClicked} > Carica Excel </button>
                            </div> */}
                        </div>

                        <div className='commands-add'>
                            <h3>RINNOVA</h3>
                            <div className='commands-add-buttons'>
                                <button onClick={RenewMultipleTesseramentiPressed} > Rinnova Tesseramenti </button>
                            </div>
                        </div>

                        <div className='commands-add'>
                            <h3>ELIMINA</h3>
                            <div className='commands-add-buttons'>
                                <button onClick={DeleteMultipleTesseramentiPressed} > Elimina Tesserati </button>
                            </div>
                        </div>

                        <div className='commands-filter'>
                            <h3>FILTRA</h3>
                            <label>
                                <input checked={mostraTesseramentiScaduti} disabled={mostraSoloTesseramentiAttivi} type='checkbox' onClick={handleFilterTesseramentiScadutiClick}/>
                                <h4> Mostra tesseramenti scaduti </h4>
                            </label>
                            <label>
                                <input checked={mostraSoloTesseramentiAttivi} type='checkbox' onClick={handleFilterSoloTesseramentiAttiviClick}/>
                                <h4> Mostra solo attivi </h4>
                            </label>
                        </div>

                        <div className='commands-filter'>
                            <h3>RAGGRUPPA</h3>
                            <label>
                                <input checked={groupTesseramenti} type='checkbox' onClick={handleGroupTesseramentiClick}/>
                                <h4> Dividi per stato </h4>
                            </label>
                        </div>

                        <div className='commands-order' >
                            <h3>ORDINA PER</h3>
                            <Formik
                                initialValues={{
                                    orderTesseramentiBy: currentTesseramentiOrder,
                                }}
                            >
                                {() => (
                                    <Form>
                                        <label> <Field type='radio' name='orderTesseramentiBy' value='cognomeAZ' onClick={handleOrderTesseramentiByClick} /> <a>{'Cognome (A -> Z)'}</a> </label>
                                        {/* <label> <Field type='radio' name='orderTesseramentiBy' value='cognomeZA' onClick={handleOrderTesseramentiByClick} /> <a>{'Cognome (Z -> A)'}</a> </label> */}
                                        <label> <Field type='radio' name='orderTesseramentiBy' value='nomeAZ' onClick={handleOrderTesseramentiByClick} /> <a>{'Nome (A -> Z)'}</a> </label>
                                        {/* <label> <Field type='radio' name='orderTesseramentiBy' value='nomeZA' onClick={handleOrderTesseramentiByClick} /> <a>{'Nome (Z -> A)'}</a> </label> */}
                                        <label> <Field type='radio' name='orderTesseramentiBy' value='scadenza' onClick={handleOrderTesseramentiByClick} /> <a>{'Scadenza'}</a> </label>
                                    </Form>
                                )}
                            </Formik>
                        </div>

                        <div className='commands-find'>
                            <h3>RICERCA</h3>
                            <Formik
                                initialValues = {{findTesseramentiBy: 'Cognome', findTesseramenti: ''}}
                                onSubmit = {(data) => {
                                        findTesseramenti(data.findTesseramentiBy, data.findTesseramenti);
                                    }
                                }
                            >
                                {({ values, handleChange, setFieldValue }) => (
                                    <Form>
                                        <div className='find-div'>
                                            <label>
                                                <a>Ricerca per: </a>
                                                <Field as='select' name='findTesseramentiBy' >
                                                    <option value='Cognome'>Cognome</option>
                                                    <option value='Nome'>Nome</option>
                                                </Field>
                                            </label>
                                            <div className='find-input-div'>
                                                <Field id='find-by-name-input' className='text-input' type='text' name='findTesseramenti' />
                                                <button type='submit'> <FaSearch className='search-icon'/> </button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>

                        <div className='commands-toggle'>
                            <h3>COLLASSA / ESPANDI</h3>
                            <div className='toggle-clients-buttons-div'>
                                <button onClick={CollapseAllTesseramenti} > Collassa tutti </button>
                                <button onClick={ExpandAllTesseramenti} > Espandi tutti </button>
                            </div>
                        </div>

                        <div className='commands-add'>
                            <h3>RESOCONTO</h3>
                            <div className='commands-add-buttons'>
                                <button onClick={DownloadListaTesserati} > Scarica lista tesserati </button>
                            </div>
                        </div>
                    </div>
                    }

                </div>
            </div>
        </div>
    )
}
