import React, { useEffect, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { OverviewBundle } from '../bundle'
import Stay from '../stay'
import { RoomStaysNewClient, RoomStaysSelectClient } from './clientData';
import { SelectStayingClientBundle, ChangeStayingClientBundle } from './selectBundle';
import { ChangeStayingClientStay, DefineStay } from './selectRoom';
import { BiIdCard } from 'react-icons/bi';

export default function RoomStays({ setCurrentOverlayPage, DeleteClientStay, currentlyEditedRoom, pacchetti, clienti, sfollati, ConfirmRoomStaysCreation, ModifyClient, SetStatusLed }) {

    let [currentPage, setCurrentPage] = useState('overview');
    let [stayingClient, setStayingClient] = useState();
    let [clientToChangeBundleOf, setClientToChangeBundleOf] = useState();
    let [clientToChangeStayOf, setClientToChangeStayOf] = useState();

    const CloseOverlay = () => {

        if (currentPage === 'overview') {
            setCurrentOverlayPage('none');
        } else {
            if (window.confirm('Uscendo adesso i dati del nuovo pernottamento inseriti fino ad ora andranno persi. Continuare?')) {
                setCurrentOverlayPage('none');
              }
        }
    }

    const GetBundleData = (id, key) => {

        for (let i = 0; i < pacchetti.length; i++){
            if (pacchetti[i].id == id){
                return pacchetti[i][key];
            }
        }

        return null;

    }

    const NewStayingClient = ( {cf, nome, cognome, sesso, nascita, indirizzo, email, telefono, note, noteModificabili, tesserato} ) => {
        let newClient = {cf: cf, nome: nome, cognome: cognome, sesso: sesso, nascita: nascita, indirizzo: indirizzo, email: email, telefono: telefono, note: note, noteModificabili: noteModificabili ?? true, tesserato: tesserato};

        setStayingClient(newClient);
    }

    const setExistingStayingClient = (id) =>{

        for (let i = 0; i < sfollati.length; i++){
            if (sfollati[i].id == id){
                setStayingClient(JSON.parse(JSON.stringify(sfollati[i]))); //Copia lo sfollato. Senza usare JSON, lo stato sarebbe comunque collegato a quello originale, ma ci sarebbero poi problemi con il rendering dei dati
                return true;
            }
        }

        return false;

    }

    const SetStayingClientBundle = (id) => {
        let updatedStayingClient = JSON.parse(JSON.stringify(stayingClient));

        for (let i = 0; i < pacchetti.length; i++){
            if (pacchetti[i].id == id){
                updatedStayingClient.idPacchetto = pacchetti[i].id;
                updatedStayingClient.nomePacchetto = pacchetti[i].nome;
                updatedStayingClient.prezzoPacchetto = pacchetti[i].prezzo;
                updatedStayingClient.durataPacchetto = pacchetti[i].durata;
                updatedStayingClient.dataInizioPacchetto = pacchetti[i].dataInizio;
                updatedStayingClient.dataFinePacchetto = pacchetti[i].dataFine;
                updatedStayingClient.notePacchetto = pacchetti[i].note;

                setStayingClient(updatedStayingClient);
                return;
            }
        }
    }

    const ModifyClientBundle = (id) => {
        let updatedClient = JSON.parse(JSON.stringify(clientToChangeBundleOf));

        for (let i = 0; i < pacchetti.length; i++){
            if (pacchetti[i].id == id){
                updatedClient.idPacchetto = pacchetti[i].id;
                updatedClient.nomePacchetto = pacchetti[i].nome;
                updatedClient.prezzoPacchetto = pacchetti[i].prezzo;
                updatedClient.durataPacchetto = pacchetti[i].durata;
                updatedClient.dataInizioPacchetto = pacchetti[i].dataInizio;
                updatedClient.dataFinePacchetto = pacchetti[i].dataFine;
                updatedClient.notePacchetto = pacchetti[i].note;

                // Not checking if dataIn and dataOut exist because we're sure they do due to where we are (RoomStays)
                updatedClient.dataIn = pacchetti[i].dataInizio;
                updatedClient.dataOut = pacchetti[i].dataFine;

                if(ModifyClient(updatedClient)){
                    setClientToChangeBundleOf(null);
                    return true;
                }

                break;

            }
        }

        return false;

    }

    const ModifyClientStay = (dataIn, dataOut) => {
        let updatedClient = JSON.parse(JSON.stringify(clientToChangeStayOf));

        updatedClient.dataIn = dataIn;
        updatedClient.dataOut = dataOut;

        if(ModifyClient(updatedClient)){
            setClientToChangeStayOf(null);
            return true;
        }

        return false;

    }
    
    const ConfirmClientStay = (dataIn, dataOut) => {
        let updatedStayingClient = JSON.parse(JSON.stringify(stayingClient));

        updatedStayingClient.idStanza = currentlyEditedRoom.id;
        updatedStayingClient.codiceStanza = currentlyEditedRoom.codice;
        updatedStayingClient.dataIn = dataIn;
        updatedStayingClient.dataOut = dataOut;
        updatedStayingClient.nomeHotel = currentlyEditedRoom.nomeHotel;

        setStayingClient(updatedStayingClient);
    }


    const GoToChangeBundlePage = (id) => {

        for (let i = 0; i < clienti.length; i++){
            if (clienti[i].id == id){
                setClientToChangeBundleOf(JSON.parse(JSON.stringify(clienti[i])));
                return true;
            }
        }

        return false;

    }

    const GoToChangeStayPage = (id) => {

        for (let i = 0; i < clienti.length; i++){
            if (clienti[i].id == id){
                setClientToChangeStayOf(JSON.parse(JSON.stringify(clienti[i])));
                return true;
            }
        }

        return false;

    }

    useEffect(() => {
        //when stayingClient changes, go to the next phase

        if (currentPage === 'newClient') setCurrentPage('selectBundle')

        if (currentPage === 'selectClient'){
            if (stayingClient.idPacchetto == null || stayingClient.idPacchetto == '')
                setCurrentPage('selectBundle');
            else
                setCurrentPage('defineStay');
        }

        if (currentPage === 'selectBundle') setCurrentPage('defineStay')

        if (currentPage === 'changeBundle'){
            let res = ModifyClient(stayingClient);

            if (res){
                setStayingClient(null);
                setCurrentPage('overview');
            } else {
                alert('Some f\'d up');
            }
        }

        if (currentPage === 'defineStay'){

            let res = ConfirmRoomStaysCreation(JSON.parse(JSON.stringify(stayingClient)));

            if (res){
                setStayingClient(null);
                setCurrentPage('overview');
            }
            //Otherwise do nothing (the error displaying is already handled by ConfirmRoomStaysCreation)
        }

    }, [stayingClient])

    useEffect(() => {

        if (clientToChangeBundleOf != null){
            setCurrentPage('changeBundle');
        } else {
            setCurrentPage('overview');
        }

    }, [clientToChangeBundleOf])

    useEffect(() => {

        if (clientToChangeStayOf != null){
            setCurrentPage('changeStay');
        } else {
            setCurrentPage('overview');
        }

    }, [clientToChangeStayOf])

    return (
        <div id='room-stays-overlay' className='overlay-div'>
            <div className='close-overlay clickable' onClick={CloseOverlay} > <AiOutlineClose /> </div>

            <div className='inner-overlay-div'>
                <h3 className='overlay-pre-title'>Pernottamenti:</h3>
                <div className='room-stays-title-div'>
                    <h2> Camera {currentlyEditedRoom.codice} - {currentlyEditedRoom.nomeHotel} </h2>
                    {currentPage !== 'overview' &&
                        <a id='back-to-overview' className='clickable' onClick={() => setCurrentPage('overview')}>Annulla</a>
                    }
                </div>
                <div className='overlay-top-divider room-stays-top-divider' />


                {currentPage === 'overview' &&
                    <div className='overlay-content-div'>
                        {currentlyEditedRoom.collocati.map(client => {
                            return (
                                <div className='room-stays-client-overview'>
                                    <div className='room-stays-client-overview-title'>
                                        <h3> {client.cognome} {client.nome} {client.tesserato ? <BiIdCard className='tesserato-icon' title='Partecipante Tesserato'/> : null}</h3>
                                        <a>-</a>
                                        <a className='clickable' onClick={() => DeleteClientStay(client.idPartecipante)} >Rimuovi</a>
                                    </div>
                                    <div className='overlay-top-divider' />
                                    <div className='room-stays-client-overview-data'>
                                        <OverviewBundle
                                            nome={GetBundleData(client.idPacchettoCliente, 'nome')} 
                                            prezzo={GetBundleData(client.idPacchettoCliente, 'prezzo')} 
                                            durata={GetBundleData(client.idPacchettoCliente, 'durata')} 
                                            dataInizio={GetBundleData(client.idPacchettoCliente, 'dataInizio')} 
                                            dataFine={GetBundleData(client.idPacchettoCliente, 'dataFine')} 
                                            note={GetBundleData(client.idPacchettoCliente, 'note')}
                                            modifyButtonActive={true}
                                            idPartecipante={client.idPartecipante}
                                            GoToChangeBundlePage={GoToChangeBundlePage}
                                        />
                                        <Stay
                                            dataIn={client.dataIn}
                                            dataOut={client.dataOut}
                                            idPartecipante={client.idPartecipante}
                                            GoToChangeStayPage={GoToChangeStayPage}
                                        />
                                    </div>
                                </div>
                            )
                        })}

                        {currentlyEditedRoom.piena == false &&
                            <div className='add-room-stay-div'>
                                <h4 className='add-room-stay-command clickable' onClick={() => setCurrentPage('selectClient')}>Aggiungi partecipante esistente</h4>
                                <h4 className='add-room-stay-command'>o</h4>
                                <h4 className='add-room-stay-command clickable' onClick={() => setCurrentPage('newClient')}>Aggiungi nuovo partecipante</h4>
                            </div>
                        }
                        
                    </div>
                }

                {currentPage === 'newClient' &&
                    <RoomStaysNewClient NewStayingClient={NewStayingClient} />
                }

                {currentPage === 'selectClient' &&
                    <RoomStaysSelectClient sfollati={sfollati} setExistingStayingClient={setExistingStayingClient} />
                }

                {currentPage === 'selectBundle' && stayingClient != null &&
                    <SelectStayingClientBundle stayingClient={stayingClient} pacchetti={pacchetti} SetStayingClientBundle={SetStayingClientBundle} />
                }

                {currentPage === 'changeBundle' && clientToChangeBundleOf != null &&
                    <ChangeStayingClientBundle clientToChangeBundleOf={clientToChangeBundleOf} pacchetti={pacchetti} ModifyClientBundle={ModifyClientBundle} SetStatusLed={SetStatusLed} />
                }

                {currentPage === 'changeStay' && clientToChangeStayOf != null &&
                    <ChangeStayingClientStay clientToChangeStayOf={clientToChangeStayOf} idStanza={currentlyEditedRoom.id} SetStatusLed={SetStatusLed} ModifyClientStay={ModifyClientStay} />
                }

                {currentPage === 'defineStay' && stayingClient != null &&
                    <DefineStay stayingClient={stayingClient} ConfirmClientStay={ConfirmClientStay} />
                }

            </div>


        </div>
    )
}
