import React from 'react';
import { Redirect, Route } from 'react-router-dom';

export default function LoginRoute({sessionData, component:Component, ...rest}) {
    return (
        <Route
            {...rest}
            render = {() => {
                if(sessionData.role === 0){
                    return <Component />;
                }

                if(sessionData.role === 1){
                    return <Redirect to='/collaborator-home' />
                }

                if(sessionData.role === 2){
                    return <Redirect to='/admin-home' />
                }
            }
            }
        />
    )
}
