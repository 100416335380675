import { Form, Formik, Field } from 'formik';
import React, { useContext } from 'react'
import SessionDataContext from '../../../contexts/sessionData'
import { FaAngleLeft, FaSearch } from 'react-icons/fa'
import { SessionLogOutRequest } from '../../../serverRequests/loginRequest';

export default function CollaboratorHomeCommands({sortEvents, findEvents}) {
    const {sessionData, setSessionData} = useContext(SessionDataContext);

    
    const logOut = () => {
        setSessionData({role: 0, nome: '', cognome: '', id: ''});
        SessionLogOutRequest();
    }


    const ExpandCollapseCommandsDiv = () => {
        // let page = document.getElementById('container-collaborator-event');
        let commandsDiv = document.getElementById('collaborator-home-commands-div');

        if (commandsDiv.className == 'commands-div' ){
            commandsDiv.className = 'commands-div collapsed';
        }
        else{
            commandsDiv.className = 'commands-div';
        }

    }

    const handleOrderByClick = (e) => {
        sortEvents(e.target.value);
    }

    const handleFindByChange = (e, setFieldValue) => {
        if (e.target.value == 'Nome'){
            setFieldValue('find', '');
            document.getElementById('find-by-name-input').style.display = 'block';
            document.getElementById('find-by-date-input').style.display = 'none';
        } else {
            document.getElementById('find-by-name-input').style.display = 'none';
            document.getElementById('find-by-date-input').style.display = 'block';
        }
    }

    return (
        <div id='collaborator-home-commands-div' className='commands-div'>
            <div id='toggle-home-commands-div' className='toggle-commands-div'> <FaAngleLeft onClick={ExpandCollapseCommandsDiv}/> </div>
            <div className='inner-commands-div'>
                <h4> {sessionData.cognome} {sessionData.nome}</h4>
                <a className='clickable' onClick={logOut}>Log Out</a>
                <div className='divider' />

                <div className='commands-div-elements'>

                    <div className='commands-order'>
                        <h3>ORDINA PER</h3>
                        <Formik
                            initialValues={{
                                orderBy: 'dataCronologico',
                            }}
                        >
                            {() => (
                                <Form>
                                    <label> <Field type='radio' name='orderBy' value='nomeAZ' onClick={handleOrderByClick} /> <a>{'Nome (A -> Z)'}</a> </label>
                                    <label> <Field type='radio' name='orderBy' value='nomeZA'  onClick={handleOrderByClick}  /> <a>{'Nome (Z -> A)'}</a> </label>
                                    <label> <Field type='radio' name='orderBy' value='dataCronologico'  onClick={handleOrderByClick}  /> <a>{'Data (Vecchio -> Nuovo)'}</a> </label>
                                    <label> <Field type='radio' name='orderBy' value='dataInverso' onClick={handleOrderByClick} /> <a>{'Data (Nuovo -> Vecchio)'}</a> </label>
                                </Form>
                            )}
                        </Formik>
                    </div>
                    <div className='commands-find'>
                        <h3>RICERCA</h3>
                        <Formik
                            initialValues = {{findBy: 'Nome', find: ''}}
                            onSubmit = {(data) => {
                                    findEvents(data.findBy, data.find);
                                }
                            }
                        >
                            {({ values, handleChange, setFieldValue }) => (
                                <Form>
                                    <div className='find-div'>
                                        <label>
                                            <a>Ricerca per: </a>
                                            <Field as='select' name='findBy' onChange={e => {handleChange(e); handleFindByChange(e, setFieldValue);}} >
                                                <option value='Nome'>Nome</option>
                                                <option value='Data esatta'>Data esatta</option>
                                                <option value='Data successiva al'>Data successiva al</option>
                                                <option value='Data precedente al'>Data precedente al</option>
                                            </Field>
                                        </label>
                                        <div className='find-input-div'>
                                            <Field id='find-by-name-input' className='text-input' type='text' name='find' />
                                            <Field id='find-by-date-input' className='date-input' type='date' name='find' />
                                            <button type='submit'> <FaSearch className='search-icon'/> </button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>

                </div>
            </div>
        </div>
    )
}
