import { MenuItem, Select, TextField } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai';
import * as yup from 'yup'
import { CreateAdmin } from '../../../../serverRequests/postRequests'
import { UpdateDBAdminData } from '../../../../serverRequests/putRequests'

const validationSchema = yup.object({
    username: yup
    .string('Username deve essere una stringa')
    .required('Username obbligatorio')
    .max(50, 'Massimo 50 caratteri'),
    password: yup
    .string('Password deve essere una stringa')
    .required('Password obbligatoria')
    .min(8, 'Minimo 8 caratteri')
    .max(20, 'Massimo 20 caratteri'),
    confermaPassword: yup
    .string('Password deve essere una stringa')
    .required('Password obbligatoria')
    .min(8, 'Minimo 8 caratteri')
    .max(20, 'Massimo 20 caratteri'),
    nome: yup
    .string('Nome deve essere una stringa')
    .required('Nome obbligatorio')
    .max(50, 'Massimo 50 caratteri'),
    cognome: yup
    .string('Cognome deve essere una stringa')
    .nullable(true)
    .max(50, 'Massimo 50 caratteri'),
    riferimento: yup
    .string('Riferimento deve essere una stringa')
    .nullable(true)
    .max(100, 'Massimo 100 caratteri'),
    sesso: yup
    .string('Sesso deve essere una stringa')
    .nullable(true)
    .max(5, 'Scegliere una delle opzioni tra M, F o Altro'),
    indirizzo: yup
    .string('Indirizzo deve essere una stringa')
    .nullable(true)
    .max(255, 'Massimo 255 caratteri'),
    email: yup
    .string().email('Inserire una mail valida')
    .required('E-Mail obbligatoria')
    .max(255, 'Massimo 255 caratteri'),
    telefono: yup
    .string('Indirizzo deve essere una stringa')
    .required('Telefono obbligatorio')
    .max(15, 'Massimo 15 caratteri'),
});

const validationSchemaUpdate = yup.object({
    username: yup
    .string('Username deve essere una stringa')
    .required('Username obbligatorio')
    .max(50, 'Massimo 50 caratteri'),
    nome: yup
    .string('Nome deve essere una stringa')
    .required('Nome obbligatorio')
    .max(50, 'Massimo 50 caratteri'),
    cognome: yup
    .string('Cognome deve essere una stringa')
    .nullable(true)
    .max(50, 'Massimo 50 caratteri'),
    riferimento: yup
    .string('Riferimento deve essere una stringa')
    .nullable(true)
    .max(100, 'Massimo 100 caratteri'),
    sesso: yup
    .string('Sesso deve essere una stringa')
    .nullable(true)
    .max(5, 'Scegliere una delle opzioni tra M, F o Altro'),
    indirizzo: yup
    .string('Indirizzo deve essere una stringa')
    .nullable(true)
    .max(255, 'Massimo 255 caratteri'),
    email: yup
    .string().email('Inserire una mail valida')
    .required('E-Mail obbligatoria')
    .max(255, 'Massimo 255 caratteri'),
    telefono: yup
    .string('Indirizzo deve essere una stringa')
    .required('Telefono obbligatorio')
    .max(15, 'Massimo 15 caratteri'),
});

export default function NewAdmin({ setCurrentOverlayPage, CreateNewAdmin, admins, collaborators, SetStatusLed }) {

    //Detect mobile device and use default inputs in case
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
            setWidth(window.innerWidth);
        }
    useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);

    let isMobile = (width < 1024);



    const [dataError, setDataError] = useState('');

    const CloseOverlay = () => {
        setCurrentOverlayPage('none');
    }

    const HandleSubmit = async (data, {setSubmitting}) => {

        //These checks could be done server side to have clearer errors in case the FE is a little behind on updates, but the chances two people will end up creating someone with the same username at nearly the same time are extremely slim to negligible
        for (let i = 0; i < admins.length; i++){
            if (admins[i].username == data.username){
                setDataError('Username già esistente');
                setSubmitting(false);
                return;
            }
        }

        for (let i = 0; i < collaborators.length; i++){
            if (collaborators[i].username == data.username){
                setDataError('Username già esistente');
                setSubmitting(false);
                return;
            }
        }

        if (data.password != data.confermaPassword){
            setDataError('Le password inserite non coincidono');
            setSubmitting(false);
            return;
        }


        setSubmitting(true);
        SetStatusLed(true);

        let newAdmin = {
            username: data.username == '' ? null : data.username,
            password: data.password == '' ? null : data.password,
            nome: data.nome == '' ? null : data.nome,
            cognome: data.cognome == '' ? null : data.cognome,
            riferimento: data.riferimento == '' ? null : data.riferimento,
            sesso: data.sesso == '' ? null : data.sesso,
            nascita: data.nascita == '' ? null : data.nascita,
            indirizzo: data.indirizzo == '' ? null : data.indirizzo,
            mail: data.email == '' ? null : data.email,
            telefono: data.telefono == '' ? null : data.telefono,
        }
        
        let newAdminID = await CreateAdmin(newAdmin);
        
        setSubmitting(false);
        SetStatusLed(false);


        if (newAdminID) {
            newAdmin.id = newAdminID;
            newAdmin.nascita = newAdmin.nascita == null ? null : newAdmin.nascita.split('-').reverse().join('/');
            newAdmin.email = newAdmin.mail;
            delete newAdmin.mail;
            delete newAdmin.password;
            CreateNewAdmin(newAdmin);
            setCurrentOverlayPage('none');
        }
        //Otherwise do nothing (the error displaying is already handled by CreateAdmin)

    }

    return (
        <div id='new-admin-overlay' className='overlay-div'>
            <div className='close-overlay clickable' onClick={CloseOverlay} > <AiOutlineClose /> </div>

            <div className='inner-overlay-div'>
                <h2> Nuovo Admin </h2>
                <div className='overlay-top-divider' />
                
                <div className='overlay-content-div'>
                    <Formik
                        initialValues={{
                            username: '',
                            password: '',
                            confermaPassword: '',
                            nome:'',
                            cognome:'',
                            riferimento:'',
                            sesso:'',
                            nascita:'',
                            indirizzo:'',
                            email:'',
                            telefono:''
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(data, {setSubmitting}) => HandleSubmit(data, {setSubmitting})}
                    >

                    {({ errors, touched, isSubmitting }) => (
                        <Form autoComplete='off'>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Username: </h4><Field className='overlay-text-input' type='text' name='username' as={TextField} autoFocus/> {touched.username && errors.username ? <a className='validation'>{errors.username}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Password: </h4><Field className='overlay-text-input' type='password' name='password' as={TextField}/> {touched.password && errors.password ? <a className='validation'>{errors.password}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Conferma password: </h4><Field className='overlay-text-input' type='password' name='confermaPassword' as={TextField}/> {touched.confermaPassword && errors.confermaPassword ? <a className='validation'>{errors.confermaPassword}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Nome: </h4><Field className='overlay-text-input' type='text' name='nome' as={TextField}/> {touched.nome && errors.nome ? <a className='validation'>{errors.nome}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Cognome: </h4><Field className='overlay-text-input' type='text' name='cognome' as={TextField} /> {touched.cognome && errors.cognome ? <a className='validation'>{errors.cognome}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Riferimento: </h4><Field className='overlay-text-input' type='text' name='riferimento' as={TextField} /> {touched.riferimento && errors.riferimento ? <a className='validation'>{errors.riferimento}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Sesso: </h4>
                            <Field name='sesso' as={Select} > {/* Does not use style or font in option and Mozilla fucks up the baseline alignment, so --> Make a custom style just for the select */}
                                <MenuItem value='M'>M</MenuItem>
                                <MenuItem value='F'>F</MenuItem>
                                <MenuItem value='Altro'>Altro</MenuItem>
                            </Field>
                            {touched.sesso && errors.sesso ? <a className='validation'>{errors.sesso}</a> : ''} 
                            </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Data di nascita: </h4><Field className='overlay-date-input' type='date' name='nascita' as={isMobile ? '' : TextField} /></div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Indirizzo: </h4><Field className='overlay-text-input' type='text' name='indirizzo' as={TextField} /> {touched.indirizzo && errors.indirizzo ? <a className='validation'>{errors.indirizzo}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Mail: </h4><Field className='overlay-text-input' type='text' name='email' as={TextField} /> {touched.email && errors.email ? <a className='validation'>{errors.email}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Telefono: </h4><Field className='overlay-text-input' type='text' name='telefono' as={TextField} /> {touched.telefono && errors.telefono ? <a className='validation'>{errors.telefono}</a> : ''} </div>
                            <div className='form-submit-button-div'>
                                <button disabled={isSubmitting} type='submit'> Conferma </button>
                                {dataError !== '' ? <a className='data-error'>{dataError}</a> : ''}
                            </div>
                        </Form>
                    )}

                    </Formik>
                </div>
            </div>
            
        </div>
    )
}

export function ModifyAdminData({ setCurrentOverlayPage, ModifyAdmin, currentlyEditedAdmin, admins, collaborators, SetStatusLed }) {

    //Detect mobile device and use default inputs in case
    const [width, setWidth] = useState(window.innerWidth);
    function handleWindowSizeChange() {
            setWidth(window.innerWidth);
        }
    useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);

    let isMobile = (width < 1024);



    const [dataError, setDataError] = useState('');

    const CloseOverlay = () => {
        setCurrentOverlayPage('none');
    }

    const HandleSubmit = async (data, {setSubmitting}) => {

        for (let i = 0; i < admins.length; i++){
            if (admins[i].username == data.username && admins[i].id != currentlyEditedAdmin.id){
                setDataError('Username già esistente');
                setSubmitting(false);
                return;
            }
        }

        for (let i = 0; i < collaborators.length; i++){
            if (collaborators[i].username == data.username){
                setDataError('Username già esistente');
                setSubmitting(false);
                return;
            }
        }

        setSubmitting(true);
        SetStatusLed(true);
        
        let updatedAdminData = {
            idAdmin: currentlyEditedAdmin.id,
            username: data.username == '' ? null : data.username,
            nome: data.nome == '' ? null : data.nome,
            cognome: data.cognome == '' ? null : data.cognome,
            riferimento: data.riferimento == '' ? null : data.riferimento,
            sesso: data.sesso == '' ? null : data.sesso,
            nascita: data.nascita == '' ? null : data.nascita,
            indirizzo: data.indirizzo == '' ? null : data.indirizzo,
            mail: data.email == '' ? null : data.email,
            telefono: data.telefono == '' ? null : data.telefono,
        }
        
        let res = await UpdateDBAdminData(updatedAdminData);
        
        setSubmitting(false);
        SetStatusLed(false);


        if (res) {
            updatedAdminData.id = updatedAdminData.idAdmin;
            updatedAdminData.nascita = updatedAdminData.nascita == null ? null : updatedAdminData.nascita.split('-').reverse().join('/');
            updatedAdminData.email = updatedAdminData.mail;
            delete updatedAdminData.idAdmin;
            delete updatedAdminData.mail;
            ModifyAdmin(updatedAdminData);
            setCurrentOverlayPage('none');
        }
        //Otherwise do nothing (the error displaying is already handled by UpdateDBAdminData)

    }

    return (
        <div id='modify-admin-data-overlay' className='overlay-div'>
            <div className='close-overlay clickable' onClick={CloseOverlay} > <AiOutlineClose /> </div>

            <div className='inner-overlay-div'>
                <h2> Modifica Admin </h2>
                <div className='overlay-top-divider' />
                
                <div className='overlay-content-div'>
                    <Formik
                        initialValues={{
                            username: currentlyEditedAdmin.username,
                            nome: currentlyEditedAdmin.nome,
                            cognome: currentlyEditedAdmin.cognome,
                            riferimento: currentlyEditedAdmin.riferimento,
                            sesso: currentlyEditedAdmin.sesso,
                            nascita: currentlyEditedAdmin.nascita == null ? null : currentlyEditedAdmin.nascita.split('/').reverse().join('-'),
                            indirizzo: currentlyEditedAdmin.indirizzo,
                            email: currentlyEditedAdmin.email,
                            telefono: currentlyEditedAdmin.telefono
                        }}
                        validationSchema={validationSchemaUpdate}
                        onSubmit={(data, {setSubmitting}) => HandleSubmit(data, {setSubmitting})}
                    >

                    {({ errors, touched, isSubmitting }) => (
                        <Form autoComplete='off'>
                        <div className='overlay-input-field'> <h4 className='overlay-input-key'>Username: </h4><Field className='overlay-text-input' type='text' name='username' as={TextField} autoFocus/> {touched.username && errors.username ? <a className='validation'>{errors.username}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Nome: </h4><Field className='overlay-text-input' type='text' name='nome' as={TextField}/> {touched.nome && errors.nome ? <a className='validation'>{errors.nome}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Cognome: </h4><Field className='overlay-text-input' type='text' name='cognome' as={TextField} /> {touched.cognome && errors.cognome ? <a className='validation'>{errors.cognome}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Riferimento: </h4><Field className='overlay-text-input' type='text' name='riferimento' as={TextField} /> {touched.riferimento && errors.riferimento ? <a className='validation'>{errors.riferimento}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Sesso: </h4>
                            <Field name='sesso' as={Select} >
                                <MenuItem value='M'>M</MenuItem>
                                <MenuItem value='F'>F</MenuItem>
                                <MenuItem value='Altro'>Altro</MenuItem>
                            </Field>
                            {touched.sesso && errors.sesso ? <a className='validation'>{errors.sesso}</a> : ''} 
                            </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Data di nascita: </h4><Field className='overlay-date-input' type='date' name='nascita' as={isMobile ? '' : TextField} /></div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Indirizzo: </h4><Field className='overlay-text-input' type='text' name='indirizzo' as={TextField} /> {touched.indirizzo && errors.indirizzo ? <a className='validation'>{errors.indirizzo}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Mail: </h4><Field className='overlay-text-input' type='text' name='email' as={TextField} /> {touched.email && errors.email ? <a className='validation'>{errors.email}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Telefono: </h4><Field className='overlay-text-input' type='text' name='telefono' as={TextField} /> {touched.telefono && errors.telefono ? <a className='validation'>{errors.telefono}</a> : ''} </div>
                            <div className='form-submit-button-div'>
                                <button disabled={isSubmitting} type='submit'> Conferma </button>
                                {dataError !== '' ? <a className='data-error'>{dataError}</a> : ''}
                            </div>
                        </Form>
                    )}

                    </Formik>
                </div>
            </div>
            
        </div>
    )
}
