import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom';
import SessionDataContext from '../../../contexts/sessionData'
import { FaAngleLeft } from 'react-icons/fa';
import { Form, Formik, Field } from 'formik';
import { SessionLogOutRequest } from '../../../serverRequests/loginRequest';


export default function CollaboratorEventCommands({ setCurrentOverlayPage, SortClients, SortRooms, setMostraSoloTesserati, setMostraClientiCollocati, GroupClients, GroupRooms, ExpandAllClients, CollapseAllClients, ExpandAllRooms, CollapseAllRooms, currEventData }) {
    const {sessionData, setSessionData} = useContext(SessionDataContext);
    const history = useHistory();

    
    const logOut = () => {
        setSessionData({role: 0, nome: '', cognome: '', id: ''});
        SessionLogOutRequest();
    }


    const ExpandCollapseCommandsDiv = () => {
        // let page = document.getElementById('container-collaborator-event');
        let commandsDiv = document.getElementById('collaborator-event-commands-div');

        if (commandsDiv.className == 'commands-div' ){
            commandsDiv.className = 'commands-div collapsed';
        }
        else{
            commandsDiv.className = 'commands-div';
        }

    }

    const handleOrderClientsByClick = (e) => {
        SortClients(e.target.value);
    }
    const handleOrderRoomsByClick = (e) => {
        SortRooms(e.target.value);
    }

    const handleGroupClientsByClick = (e) => {
        GroupClients(e.target.value);
    }

    const handleGroupRoomsByClick = (e) => {
        GroupRooms(e.target.value);
    }

    const handleFilterClientsClick = (e) => {
        setMostraClientiCollocati(e.target.checked);
    }

    const handleFilterTesseratiClick = (e) => {
        setMostraSoloTesserati(e.target.checked);
    }

    return (
        <div id='collaborator-event-commands-div' className='commands-div'>
            <div id='toggle-commands-div' className='toggle-commands-div'> <FaAngleLeft onClick={ExpandCollapseCommandsDiv}/> </div>
            <div className='inner-commands-div'>
                <h4> {sessionData.cognome} {sessionData.nome}</h4>
                <a className='margin-bottom-10'> {(currEventData ?? {}).nome} </a>
                <div><a className='clickable' onClick={() => history.replace('/collaborator-home')}>Agli eventi</a> <a>•</a> <a className='clickable' onClick={logOut}>Log Out</a></div>
                <div className='divider' />

                <div className='commands-div-elements'>

                    <div className='commands-add'>
                        <h3>AGGIUNGI PARTECIPANTE</h3>
                        <div className='commands-add-buttons'>
                            <button onClick={() => setCurrentOverlayPage('createNewClient')}> Nuovo Partecipante </button>
                        </div>
                    </div>

                    <div className='commands-order'>
                        <h3>ORDINA PARTECIPANTI PER</h3>
                        <Formik
                            initialValues={{
                                orderClientsBy: 'cognomeAZ',
                            }}
                        >
                            {() => (
                                <Form>
                                    <label> <Field type='radio' name='orderClientsBy' value='nomeAZ' onClick={handleOrderClientsByClick} /> <a>{'Nome (A -> Z)'}</a> </label>
                                    <label> <Field type='radio' name='orderClientsBy' value='nomeZA'  onClick={handleOrderClientsByClick}  /> <a>{'Nome (Z -> A)'}</a> </label>
                                    <label> <Field type='radio' name='orderClientsBy' value='cognomeAZ'  onClick={handleOrderClientsByClick}  /> <a>{'Cognome (A -> Z)'}</a> </label>
                                    <label> <Field type='radio' name='orderClientsBy' value='cognomeZA' onClick={handleOrderClientsByClick} /> <a>{'Cognome (Z -> A)'}</a> </label>
                                </Form>
                            )}
                        </Formik>
                    </div>

                    <div className='commands-group'>
                        <h3>RAGGRUPPA PARTECIPANTI PER</h3>
                        <Formik
                            initialValues={{
                                groupClientsBy: 'noGrouping',
                            }}
                        >
                            {() => (
                                <Form>
                                    <label> <Field type='radio' name='groupClientsBy' value='senzaConCollocati' onClick={handleGroupClientsByClick} /> <a>{'Senza pacchetto / Con pacchetto / Collocati'}</a> </label>
                                    <label> <Field type='radio' name='groupClientsBy' value='collocatiConSenza'  onClick={handleGroupClientsByClick}  /> <a>{'Collocati / Con pacchetto / Senza pacchetto'}</a> </label>
                                    <label> <Field type='radio' name='groupClientsBy' value='noGrouping'  onClick={handleGroupClientsByClick}  /> <a>{'Non raggruppare'}</a> </label>
                                </Form>
                            )}
                        </Formik>
                    </div>

                    <div className='commands-filter'>
                        <h3>FILTRA PARTECIPANTI</h3>
                        <label>
                            <input type='checkbox' onClick={handleFilterClientsClick}/>
                            <h4> Mostra partecipanti collocati </h4>
                        </label>
                        <label>
                            <input type='checkbox' onClick={handleFilterTesseratiClick}/>
                            <h4> Mostra SOLO partecipanti tesserati </h4>
                        </label>
                    </div>

                    <div className='commands-toggle'>
                        <h3>COLLASSA / ESPANDI PARTECIPANTI</h3>
                        <div className='toggle-clients-buttons-div'>
                            <button onClick={CollapseAllClients}> Collassa tutti </button>
                            <button onClick={ExpandAllClients}> Espandi tutti </button>
                        </div>
                    </div>

                    <div className='divider' />

                    <div className='commands-order'>
                        <h3>ORDINA CAMERE PER</h3>
                        <Formik
                            initialValues={{
                                orderRoomsBy: 'numeroCrescente',
                            }}
                        >
                            {() => (
                                <Form>
                                    <label> <Field type='radio' name='orderRoomsBy' value='numeroCrescente' onClick={handleOrderRoomsByClick} /> <a>{'Numero di camera (crescente)'}</a> </label>
                                    <label> <Field type='radio' name='orderRoomsBy' value='numeroDecrescente' onClick={handleOrderRoomsByClick} /> <a>{'Numero di camera (decrescente)'}</a> </label>
                                    <label> <Field type='radio' name='orderRoomsBy' value='capienzaCrescente' onClick={handleOrderRoomsByClick} /> <a>{'Capienza massima (crescente)'}</a> </label>
                                    <label> <Field type='radio' name='orderRoomsBy' value='capienzaDecrescente' onClick={handleOrderRoomsByClick} /> <a>{'Capienza massima (decrescente)'}</a> </label>
                                    <label> <Field type='radio' name='orderRoomsBy' value='liberiCrescente' onClick={handleOrderRoomsByClick} /> <a>{'Posti liberi (crescente)'}</a> </label>
                                    <label> <Field type='radio' name='orderRoomsBy' value='liberiDecrescente' onClick={handleOrderRoomsByClick} /> <a>{'Posti liberi (decrescente)'}</a> </label>
                                </Form>
                            )}
                        </Formik>
                    </div>

                    <div className='commands-group'>
                        <h3>RAGGRUPPA CAMERE PER</h3>
                        <Formik
                            initialValues={{
                                groupRoomsBy: 'noGrouping',
                            }}
                        >
                            {() => (
                                <Form>
                                    <label> <Field type='radio' name='groupRoomsBy' value='pieneParzialiVuote' onClick={handleGroupRoomsByClick} /> <a>{'Piene / Parziali / Vuote'}</a> </label>
                                    <label> <Field type='radio' name='groupRoomsBy' value='vuoteParzialiPiene' onClick={handleGroupRoomsByClick} /> <a>{'Vuote / Parziali /Piene'}</a> </label>
                                    <label> <Field type='radio' name='groupRoomsBy' value='tipo' onClick={handleGroupRoomsByClick} /> <a>{'Tipo di camera'}</a> </label>
                                    <label> <Field type='radio' name='groupRoomsBy' value='nomeHotel' onClick={handleGroupRoomsByClick} /> <a>{'Hotel'}</a> </label>
                                    <label> <Field type='radio' name='groupRoomsBy' value='noGrouping' onClick={handleGroupRoomsByClick} /> <a>{'Non raggruppare'}</a> </label>
                                </Form>
                            )}
                        </Formik>
                    </div>

                    <div className='commands-toggle'>
                        <h3>COLLASSA / ESPANDI CAMERE</h3>
                        <div className='toggle-clients-buttons-div'>
                            <button onClick={CollapseAllRooms}> Collassa tutte </button>
                            <button onClick={ExpandAllRooms}> Espandi tutte </button>
                        </div>
                    </div>

                    <div className='divider' />

                    {currEventData != null &&
                        <div className='side-event-info'>
                            <h3> {currEventData.nome} </h3>
                            <h4> {currEventData.dataInizio} - {currEventData.dataFine} </h4>
                            <h4> {currEventData.luogo} </h4>
                            <h4> {currEventData.descrizione} </h4>
                        </div>
                    }

                </div>
            </div>
        </div>
    )
}
