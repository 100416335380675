import React from 'react'

export default function Bundle({nome, prezzo, durata, dataInizio, dataFine, note}) {

    return (
        <div className='bundle-div'>
            <h3>{nome}</h3>
            <div class='top-divider' />

            {prezzo != null &&
                <div className='data-field'>
                    <h4 className='data-field-key'> Prezzo: </h4>
                    <div className='data-field-value'>
                        <h4> {prezzo}€ </h4>
                    </div>
                </div>
            }

            {durata != null &&
                <div className='data-field'>
                    <h4 className='data-field-key'> Durata: </h4>
                    <div className='data-field-value'>
                        <h4> {durata} giorni</h4>
                    </div>
                </div>
            }

            {dataInizio != null &&
                <div className='data-field'>
                    <h4 className='data-field-key'> Data Inizio: </h4>
                    <div className='data-field-value'>
                        <h4> {dataInizio} </h4>
                    </div>
                </div>
            }

            {dataFine != null &&
                <div className='data-field'>
                    <h4 className='data-field-key'> Data Fine: </h4>
                    <div className='data-field-value'>
                        <h4> {dataFine} </h4>
                    </div>
                </div>
            }

            {note != null &&
                <div className='data-field'>
                    <h4 className='data-field-key'> Note: </h4>
                    <div className='data-field-value'>
                        <h4> {note} </h4>
                    </div>
                </div>
            }



        </div>
    )
}

export function OverviewBundle({nome, prezzo, durata, dataInizio, dataFine, note, modifyButtonActive, idPartecipante, GoToChangeBundlePage}) {

    return (
        <div className='bundle-div'>
            <h3>{nome}</h3>
            <div class='overview-bundle-top-divider' />

            {prezzo != null &&
                <div className='data-field'>
                    <h4 className='data-field-key'> Prezzo: </h4>
                    <div className='data-field-value'>
                        <h4> {prezzo}€ </h4>
                    </div>
                </div>
            }

            {durata != null &&
                <div className='data-field'>
                    <h4 className='data-field-key'> Durata: </h4>
                    <div className='data-field-value'>
                        <h4> {durata} giorni</h4>
                    </div>
                </div>
            }

            {dataInizio != null &&
                <div className='data-field'>
                    <h4 className='data-field-key'> Data Inizio: </h4>
                    <div className='data-field-value'>
                        <h4> {dataInizio} </h4>
                    </div>
                </div>
            }

            {dataFine != null &&
                <div className='data-field'>
                    <h4 className='data-field-key'> Data Fine: </h4>
                    <div className='data-field-value'>
                        <h4> {dataFine} </h4>
                    </div>
                </div>
            }

            {note != null &&
                <div className='data-field'>
                    <h4 className='data-field-key'> Note: </h4>
                    <div className='data-field-value'>
                        <h4> {note} </h4>
                    </div>
                </div>
            }

            {modifyButtonActive &&
                <button onClick={() => GoToChangeBundlePage(idPartecipante)}> Modifica </button>
            }
            

        </div>
    )
}
