import React, { useContext, useEffect, useState } from 'react'
import { Masonry } from 'react-masonry-responsive';
import AdminHomeCommands from './components/adminHomeCommands'
import Event from './components/event';
import Collaborator from './components/collaborator';
import Admin from './components/admin';
import NewAdmin, { ModifyAdminData } from './components/overlays/adminData'
import NewCollaborator, { ModifyCollaboratorData } from './components/overlays/collaboratorData'
import NewEvent, { ModifyEventData, CopyEventData } from './components/overlays/eventData';
import { ConfirmDeleteCollaborator, ConfirmDeleteAdmin, ConfirmDeleteEvent, ConfirmDeleteTesseramento, ConfirmDeleteMultipleTesseramenti } from './components/overlays/confirmDeletion'
import ReactTooltip from 'react-tooltip';
import EventsContext from '../../contexts/eventsContext';
import CollaboratorsContext from '../../contexts/collaboratorsContext';
import AdminsContext from '../../contexts/adminsContext';
import SessionDataContext from '../../contexts/sessionData';
import HotelsContext from '../../contexts/hotelsContext';
import RoomsContext from '../../contexts/roomsContext';
import RoomTypesContext from '../../contexts/roomTypesContext';
import BundlesContext from '../../contexts/bundlesContext';
import ClientsContext from '../../contexts/clientsContext';
import PaymentMethodsContext from '../../contexts/paymentMethodsContext';
import PaymentStatesContext from '../../contexts/paymentStatesContext';
import ReservationsContext from '../../contexts/reservationsContext';
import TesseramentiContext from '../../contexts/tesseramentiContext';
import { FetchAdminHomeData } from '../../serverRequests/fetchRequest'
import { SessionLogOutRequest } from '../../serverRequests/loginRequest';
import Tesseramento, { TesseramentoToDelete, TesseramentoToRenew } from './components/tesseramento';
import NewTesseramento, { ModifyTesseramentoData, ConfirmRenewMultipleTesseramenti, ConfirmRenewTesseramento } from './components/overlays/tesseramentoData';

export default function AdminHome() {
    const [activePage, setActivePage] = useState('events');
    const [isLoading, setIsLoading] = useState(true);
    const {sessionData, setSessionData} = useContext(SessionDataContext);
    const {events, setEvents} = useContext(EventsContext);
    const {collaborators, setCollaborators} = useContext(CollaboratorsContext);
    const {admins, setAdmins} = useContext(AdminsContext);
    const {tesseramenti, setTesseramenti} = useContext(TesseramentiContext);

    const {setHotels} = useContext(HotelsContext);
    const {setStanze} = useContext(RoomsContext);
    const {setRoomTypes} = useContext(RoomTypesContext);
    const {setPacchetti} = useContext(BundlesContext);
    const {setClienti} = useContext(ClientsContext);
    const {setPaymentMethods} = useContext(PaymentMethodsContext);
    const {setPaymentStates} = useContext(PaymentStatesContext);
    const {setReservations} = useContext(ReservationsContext);

    useEffect(() => {
        setEvents([]);
        setCollaborators([]);
        setAdmins([]);
        setTesseramenti([]);
        
        setHotels([]);
        setStanze([]);
        setRoomTypes([]);
        setPacchetti([]);
        setClienti([]);
        setPaymentMethods([]);
        setPaymentStates([]);
        setReservations([]);

        FetchAdminHomeData(setEvents, setCollaborators, setAdmins, setTesseramenti, setIsLoading);

    }, [])

    //FOR TESTING
    // const [events, setEvents] = useState([
    //     {id: 1, nome: 'Sagra della salsicciola e poi altro testo per la seconda riga', luogo: 'Sotto il ponte di Baracca dove ci sta Piero che fa gli stronzoni in via dei Fantacannonieri di Macedonia', dataInizio: '01/03/2021', dataFine:'20/05/2021', descrizione:'Non avevo voglia di scrivere granchè nella descrizione, fatemi causa dai su provateci voglio proprio vedervici che tanto alla fine ho scritto un botto qua dentro.'},
    //     {id: 2, nome: 'Evento figo per davvero', luogo:'A casa del Const', dataInizio:'10/01/2021', dataFine:'20/05/2021', descrizione:'Ci spacchiamo di pasta al tonno, musica e ballo E BASTA'},
    //     {id: 3, nome: 'Evento figo per davvero', luogo:'A casa del Const', dataInizio:'10/01/2021', dataFine:'20/05/2021', descrizione:''},
    //     {id: 4, nome: 'Evento figo per davvero', luogo:'A casa del Const', dataInizio:'10/01/2021', dataFine:'20/05/2021'},
    //     {id: 5, nome: 'Evento figo per davvero', luogo:'A casa del Const', dataInizio:'10/05/2021', dataFine:'20/05/2021', descrizione:'Ci spacchiamo di pasta al tonno, musica e ballo E BASTA'},
    //     {id: 6, nome: 'Evento figo per davvero', luogo:'A casa del Const', dataInizio:'10/05/2021', dataFine:'20/05/2021', descrizione:'Ci spacchiamo di pasta al tonno, musica e ballo E BASTA'},
    //     {id: 7, nome: 'Evento figo per davvero', luogo:'A casa del Const', dataInizio:'10/05/2021', dataFine:'20/05/2021', descrizione:'Ci spacchiamo di pasta al tonno, musica e ballo E BASTA'}
    // ]);

    //FOR TESTING
    //Collaborators and Admins could be had in one array/state like they are in adminEvent -> Would be more uniform but also less clear and maintainable, ALSO, it works and there is no reason to dump more time into it
    // const [collaborators, setCollaborators] = useState([
    //     {id: 1, username: 'salknd', nome: 'Tizio', cognome: 'Caio', riferimento: 'Mefisclotele', sesso: 'M', nascita:'01/02/1003', indirizzo:'Via Dalle Balle, 69', email:'a.a@a.a', telefono:'1234567890'},
    //     {id: 2, username: 'sdfsd', nome: 'Tizia', cognome: 'Caia', riferimento: 'Mefisclotela', sesso: 'F', nascita:'01/02/1003', indirizzo:'Via Dalle Balle, 69', email:'a.a@a.a', telefono:'1234567890'},
    //     {id: 3, username: 'sadfsdflknd', nome: 'Minimo', email:'a.a@a.a', telefono:'1234567890'},
    //     {id: 4, username: 'mvbmbgh', nome: 'Massimo', cognome: 'Potere', riferimento: 'Yamom', sesso: 'M', nascita:'01/02/1003', indirizzo:'Via Dalle Balle, 69', email:'a.a@a.a', telefono:'1234567890'},
    //     {id: 5, username: 'sfdghyjuk', nome: 'Bella', cognome: 'Zio', riferimento: 'Mefisclotele', sesso: 'Altro', nascita:'01/02/1003', indirizzo:'Via Dalle Balle, 69', email:'a.a@a.a', telefono:'1234567890'},
    // ])

    //FOR TESTING
    // const [admins, setAdmins] = useState([
    //     {id: 1, username: 'dhfj', nome: 'Tizio', cognome: 'Caio', riferimento: 'Mefisclotele', sesso: 'M', nascita:'01/02/1003', indirizzo:'Via Dalle Balle, 69', email:'a.a@a.a', telefono:'1234567890'},
    //     {id: 2, username: 'jeyt', nome: 'Tizia', cognome: 'Caia', riferimento: 'Mefisclotela', sesso: 'F', nascita:'01/02/1003', indirizzo:'Via Dalle Balle, 69', email:'a.a@a.a', telefono:'1234567890'},
    //     {id: 3, username: 'jdytnh', nome: 'Minimo', email:'a.a@a.a', telefono:'1234567890'},
    //     {id: 4, username: 'upoi', nome: 'Massimo', cognome: 'Potere', riferimento: 'Yamom', sesso: 'M', nascita:'01/02/1003', indirizzo:'Via Dalle Balle, 69', email:'a.a@a.a', telefono:'1234567890'},
    //     {id: 5, username: 'qer', nome: 'Bella', cognome: 'Zio', riferimento: 'Mefisclotele', sesso: 'Altro', nascita:'01/02/1003', indirizzo:'Via Dalle Balle, 69', email:'a.a@a.a', telefono:'1234567890'},
    // ])

    const [currentEventsOrder, setCurrentEventsOrder] = useState('dataCronologico');
    // const [currentCollaboratorsOrder, setCurrentCollaboratorsOrder] = useState('nomeAZ');
    const [currentAdminsOrder, setCurrentAdminsOrder] = useState('cognomeAZ');

    const [copyingEvent, setCopyingEvent] = useState(false);
    
    const [searchingEvents, setSearchingEvents] = useState(false);
    const [searchEventsResultsIDs, setSearchEventsResultsIDs] = useState([]);

    // const [searchingCollaborators, setSearchingCollaborators] = useState(false);
    // const [searchCollaboratorsResultsIDs, setSearchCollaboratorsResultsIDs] = useState([]);

    const [searchingAdmins, setSearchingAdmins] = useState(false);
    const [searchAdminsResultsIDs, setSearchAdminsResultsIDs] = useState([]);

    const [mostraEventiPassati, setMostraEventiPassati] = useState(false);

    const [currentOverlayPage, setCurrentOverlayPage] = useState('none');

    const [currentlyEditedEvent, setCurrentlyEditedEvent] = useState();
    // const [currentlyEditedCollaborator, setCurrentlyEditedCollaborator] = useState();
    const [currentlyEditedAdmin, setCurrentlyEditedAdmin] = useState();
    const [currentlyEditedTesseramento, setCurrentlyEditedTesseramento] = useState();

    const [mostraTesseramentiScaduti, setMostraTesseramentiScaduti] = useState(true);
    const [mostraSoloTesseramentiAttivi, setMostraSoloTesseramentiAttivi] = useState(false);

    const [groupTesseramenti, setGroupTesseramenti] = useState(false);

    const [currentTesseramentiOrder, setCurrentTesseramentiOrder] = useState('cognomeAZ');

    const [searchingTesseramenti, setSearchingTesseramenti] = useState(false);
    const [searchTesseramentiResultsIDs, setSearchTesseramentiResultsIDs] = useState([]);

    const [deletingTesseramenti, setDeletingTesseramenti] = useState(false);
    const [tesseramentiToDeleteIDs, setTesseramentiToDeleteIDs] = useState([]);

    const [renewingTesseramenti, setRenewingTesseramenti] = useState(false);
    const [tesseramentiToRenewIDs, setTesseramentiToRenewIDs] = useState([]);



    const CancelCopyEvent = () => {
        setCopyingEvent(false);
    }

    const CancelSearchEvents = () => {
        setSearchingEvents(false);
    }

    // const CancelSearchCollaborators = () => {
    //     setSearchingCollaborators(false);
    // }

    const CancelSearchAdmins = () => {
        setSearchingAdmins(false);
    }

    const CancelSearchTesseramenti = () => {
        setSearchingTesseramenti(false);
    }

    const sortEvents = (order) => {
        setCurrentEventsOrder(order);
        let sortedEvents = [...events];

        if(order === 'nomeAZ'){

            sortedEvents.sort((a, b) => {
                let keyA = a.nome.toLowerCase(), keyB = b.nome.toLowerCase();
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

        }
        else if (order === 'nomeZA'){

            sortedEvents.sort((a, b) => {
                let keyA = a.nome.toLowerCase(), keyB = b.nome.toLowerCase();
                if (keyA < keyB) return 1;
                if (keyA > keyB) return -1;
                return 0;
            });

        }
        else if (order === 'dataInverso'){

            sortedEvents.sort((a, b) => {
                let keyA = new Date(a.dataInizio.split('/').reverse().join('-')).getTime(), keyB = new Date(b.dataInizio.split('/').reverse().join('-')).getTime();
                if (keyA < keyB) return 1;
                if (keyA > keyB) return -1;
                return 0;
            });

        }
        else { //Defaults to orderBy = 'dataCronologico'

            sortedEvents.sort((a, b) => {
                let keyA = new Date(a.dataInizio.split('/').reverse().join('-')).getTime(), keyB = new Date(b.dataInizio.split('/').reverse().join('-')).getTime();
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

        }

        setEvents([...sortedEvents]);
        
    }

    const SortEventsAfterUpdate = (eventsUpdated) => {
        if (currentEventsOrder === 'nomeAZ'){

            eventsUpdated.sort((a, b) => {
                let keyA = a.nome.toLowerCase(), keyB = b.nome.toLowerCase();
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

        } else if (currentEventsOrder === 'nomeZA') {

            eventsUpdated.sort((a, b) => {
                let keyA = a.nome.toLowerCase(), keyB = b.nome.toLowerCase();
                if (keyA < keyB) return 1;
                if (keyA > keyB) return -1;
                return 0;
            });

        } else if (currentEventsOrder === 'dataInverso') {

            eventsUpdated.sort((a, b) => {
                let keyA = new Date(a.dataInizio.split('/').reverse().join('-')).getTime(), keyB = new Date(b.dataInizio.split('/').reverse().join('-')).getTime();
                if (keyA < keyB) return 1;
                if (keyA > keyB) return -1;
                return 0;
            });

        } else { //Defaults to orderBy = 'dataCronologico'

            eventsUpdated.sort((a, b) => {
                let keyA = new Date(a.dataInizio.split('/').reverse().join('-')).getTime(), keyB = new Date(b.dataInizio.split('/').reverse().join('-')).getTime();
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

        }

        return eventsUpdated;

    }

    // const sortCollaborators = (order) => {
    //     setCurrentCollaboratorsOrder(order);
    //     let sortedCollaborators = [...collaborators];

    //     if (order === 'nomeAZ'){

    //         sortedCollaborators.sort((a, b) => {
    //             let keyA = a.nome.toLowerCase(), keyB = b.nome.toLowerCase();
    //             if (keyA < keyB) return -1;
    //             if (keyA > keyB) return 1;
    //             return 0;
    //         });

    //     } else if (order === 'nomeZA') {

    //         sortedCollaborators.sort((a, b) => {
    //             let keyA = a.nome.toLowerCase(), keyB = b.nome.toLowerCase();
    //             if (keyA < keyB) return 1;
    //             if (keyA > keyB) return -1;
    //             return 0;
    //         });

    //     } else if (order === 'cognomeAZ') {

    //         sortedCollaborators.sort((a, b) => {
    //             if (a.cognome == null){ return 1; }
    //             if (b.cognome == null){ return -1; }

    //             let keyA = a.cognome.toLowerCase(), keyB = b.cognome.toLowerCase();
    //             if (keyA < keyB) return -1;
    //             if (keyA > keyB) return 1;
    //             return 0;
    //         });

    //     } else if (order === 'cognomeZA') {

    //         sortedCollaborators.sort((a, b) => {
    //             if (a.cognome == null){ return 1; }
    //             if (b.cognome == null){ return -1; }

    //             let keyA = a.cognome.toLowerCase(), keyB = b.cognome.toLowerCase();
    //             if (keyA < keyB) return 1;
    //             if (keyA > keyB) return -1;
    //             return 0;
    //         });

    //     }

    //     setCollaborators([...sortedCollaborators]);
        
    // }

    // const SortCollaboratorsAfterUpdate = (collaboratorsUpdated) => {
    //     if (currentCollaboratorsOrder === 'nomeAZ'){

    //         collaboratorsUpdated.sort((a, b) => {
    //             let keyA = a.nome.toLowerCase(), keyB = b.nome.toLowerCase();
    //             if (keyA < keyB) return -1;
    //             if (keyA > keyB) return 1;
    //             return 0;
    //         });

    //     } else if (currentCollaboratorsOrder === 'nomeZA') {

    //         collaboratorsUpdated.sort((a, b) => {
    //             let keyA = a.nome.toLowerCase(), keyB = b.nome.toLowerCase();
    //             if (keyA < keyB) return 1;
    //             if (keyA > keyB) return -1;
    //             return 0;
    //         });

    //     } else if (currentCollaboratorsOrder === 'cognomeAZ') {

    //         collaboratorsUpdated.sort((a, b) => {
    //             if (a.cognome == null){ return 1; }
    //             if (b.cognome == null){ return -1; }

    //             let keyA = a.cognome.toLowerCase(), keyB = b.cognome.toLowerCase();
    //             if (keyA < keyB) return -1;
    //             if (keyA > keyB) return 1;
    //             return 0;
    //         });

    //     } else if (currentCollaboratorsOrder === 'cognomeZA') {

    //         collaboratorsUpdated.sort((a, b) => {
    //             if (a.cognome == null){ return 1; }
    //             if (b.cognome == null){ return -1; }

    //             let keyA = a.cognome.toLowerCase(), keyB = b.cognome.toLowerCase();
    //             if (keyA < keyB) return 1;
    //             if (keyA > keyB) return -1;
    //             return 0;
    //         });

    //     }

    //     return collaboratorsUpdated;

    // }

    const sortAdmins = (order) => {
        setCurrentAdminsOrder(order);
        let sortedAdmins = [...admins];

        if (order === 'nomeAZ'){

            sortedAdmins.sort((a, b) => {
                let keyA = a.nome.toLowerCase(), keyB = b.nome.toLowerCase();
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

        } else if (order === 'nomeZA') {

            sortedAdmins.sort((a, b) => {
                let keyA = a.nome.toLowerCase(), keyB = b.nome.toLowerCase();
                if (keyA < keyB) return 1;
                if (keyA > keyB) return -1;
                return 0;
            });

        } else if (order === 'cognomeAZ') {

            sortedAdmins.sort((a, b) => {
                if (a.cognome == null){ return 1; }
                if (b.cognome == null){ return -1; }

                let keyA = a.cognome.toLowerCase(), keyB = b.cognome.toLowerCase();
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

        } else if (order === 'cognomeZA') {

            sortedAdmins.sort((a, b) => {
                if (a.cognome == null){ return 1; }
                if (b.cognome == null){ return -1; }

                let keyA = a.cognome.toLowerCase(), keyB = b.cognome.toLowerCase();
                if (keyA < keyB) return 1;
                if (keyA > keyB) return -1;
                return 0;
            });

        }

        setAdmins([...sortedAdmins]);
        
    }

    const SortAdminsAfterUpdate = (adminsUpdated) => {
        if (currentAdminsOrder === 'nomeAZ'){

            adminsUpdated.sort((a, b) => {
                let keyA = a.nome.toLowerCase(), keyB = b.nome.toLowerCase();
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

        } else if (currentAdminsOrder === 'nomeZA') {

            adminsUpdated.sort((a, b) => {
                let keyA = a.nome.toLowerCase(), keyB = b.nome.toLowerCase();
                if (keyA < keyB) return 1;
                if (keyA > keyB) return -1;
                return 0;
            });

        } else if (currentAdminsOrder === 'cognomeAZ') {

            adminsUpdated.sort((a, b) => {
                if (a.cognome == null){ return 1; }
                if (b.cognome == null){ return -1; }

                let keyA = a.cognome.toLowerCase(), keyB = b.cognome.toLowerCase();
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

        } else if (currentAdminsOrder === 'cognomeZA') {

            adminsUpdated.sort((a, b) => {
                if (a.cognome == null){ return 1; }
                if (b.cognome == null){ return -1; }

                let keyA = a.cognome.toLowerCase(), keyB = b.cognome.toLowerCase();
                if (keyA < keyB) return 1;
                if (keyA > keyB) return -1;
                return 0;
            });

        }

        return adminsUpdated;

    }
    
    const sortTesseramenti = (order) => {
        setCurrentTesseramentiOrder(order);
        let sortedTesseramenti = [...tesseramenti];

        if (order === 'nomeAZ'){

            sortedTesseramenti.sort((a, b) => {
                let keyA = a.nome.toLowerCase(), keyB = b.nome.toLowerCase();
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

        } else if (order === 'nomeZA') {

            sortedTesseramenti.sort((a, b) => {
                let keyA = a.nome.toLowerCase(), keyB = b.nome.toLowerCase();
                if (keyA < keyB) return 1;
                if (keyA > keyB) return -1;
                return 0;
            });

        } else if (order === 'cognomeAZ') {

            sortedTesseramenti.sort((a, b) => {
                if (a.cognome == null){ return 1; }
                if (b.cognome == null){ return -1; }

                let keyA = a.cognome.toLowerCase(), keyB = b.cognome.toLowerCase();
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

        } else if (order === 'cognomeZA') {

            sortedTesseramenti.sort((a, b) => {
                if (a.cognome == null){ return 1; }
                if (b.cognome == null){ return -1; }

                let keyA = a.cognome.toLowerCase(), keyB = b.cognome.toLowerCase();
                if (keyA < keyB) return 1;
                if (keyA > keyB) return -1;
                return 0;
            });

        } else if (order === 'scadenza') {

            sortedTesseramenti.sort((a, b) => {
                if (a.scadenzaTesseramento == null){ return 1; }
                if (b.scadenzaTesseramento == null){ return -1; }

                let keyA = new Date(a.scadenzaTesseramento.split('/').reverse().join('-')), keyB = new Date(b.scadenzaTesseramento.split('/').reverse().join('-'));
                if (keyA > keyB) return 1;
                if (keyA < keyB) return -1;
                return 0;
            });

        }

        setTesseramenti([...sortedTesseramenti]);
        
    }
    
    const sortTesseramentiAfterUpdate = (tesseramentiUpdated) => {
        if (currentTesseramentiOrder === 'nomeAZ'){

            tesseramentiUpdated.sort((a, b) => {
                let keyA = a.nome.toLowerCase(), keyB = b.nome.toLowerCase();
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

        } else if (currentTesseramentiOrder === 'nomeZA') {

            tesseramentiUpdated.sort((a, b) => {
                let keyA = a.nome.toLowerCase(), keyB = b.nome.toLowerCase();
                if (keyA < keyB) return 1;
                if (keyA > keyB) return -1;
                return 0;
            });

        } else if (currentTesseramentiOrder === 'cognomeAZ') {

            tesseramentiUpdated.sort((a, b) => {
                if (a.cognome == null){ return 1; }
                if (b.cognome == null){ return -1; }

                let keyA = a.cognome.toLowerCase(), keyB = b.cognome.toLowerCase();
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

        } else if (currentTesseramentiOrder === 'cognomeZA') {

            tesseramentiUpdated.sort((a, b) => {
                if (a.cognome == null){ return 1; }
                if (b.cognome == null){ return -1; }

                let keyA = a.cognome.toLowerCase(), keyB = b.cognome.toLowerCase();
                if (keyA < keyB) return 1;
                if (keyA > keyB) return -1;
                return 0;
            });

        } else if (currentTesseramentiOrder === 'scadenza') {

            tesseramentiUpdated.sort((a, b) => {
                if (a.scadenzaTesseramento == null){ return 1; }
                if (b.scadenzaTesseramento == null){ return -1; }

                let keyA = new Date(a.scadenzaTesseramento.split('/').reverse().join('-')), keyB = new Date(b.scadenzaTesseramento.split('/').reverse().join('-'));
                if (keyA > keyB) return 1;
                if (keyA < keyB) return -1;
                return 0;
            });

        }

        return tesseramentiUpdated;
        
    }

    const findEvents = (findBy, searchTerm) => {

        if (searchTerm == ''){
            //do nothing
        }
        else{
            setSearchingEvents(true);
            let found = [];
            switch (findBy){
                case 'Nome':
                    events.forEach(event => {
                        if(event.nome.toLowerCase().includes(searchTerm.toLowerCase())) found.push(event.id);
                    })
                    break;
                case 'Data esatta':
                    events.forEach(event => {
                        if(new Date(event.dataInizio.split('/').reverse().join('-')).getTime() == new Date(searchTerm).getTime()) found.push(event.id);
                    })
                    break;
                case 'Data successiva al':
                    events.forEach(event => {
                        if(new Date(event.dataInizio.split('/').reverse().join('-')).getTime() >= new Date(searchTerm).getTime()) found.push(event.id);
                    })
                    break;
                case 'Data precedente al':
                    events.forEach(event => {
                        if(new Date(event.dataInizio.split('/').reverse().join('-')).getTime() <= new Date(searchTerm).getTime()) found.push(event.id);
                    })
                    break;
            }

            setSearchEventsResultsIDs(found);
        }

    }

    // const findCollaborators = (findBy, searchTerm) => {

    //     if (searchTerm == ''){
    //         //do nothing
    //     }
    //     else{
    //         setSearchingCollaborators(true);
    //         let found = [];
    //         switch (findBy){
    //             case 'Nome':
    //                 collaborators.forEach(collaborator => {
    //                     if(collaborator.nome.toLowerCase().includes(searchTerm.toLowerCase())) found.push(collaborator.id);
    //                 })
    //                 break;
    //             case 'Cognome':
    //                 collaborators.forEach(collaborator => {
    //                     if(collaborator.cognome != null)
    //                         if(collaborator.cognome.toLowerCase().includes(searchTerm.toLowerCase()))
    //                             found.push(collaborator.id);
    //                 })
    //                 break;
    //             case 'Username':
    //                 collaborators.forEach(collaborator => {
    //                     if(collaborator.username.toLowerCase().includes(searchTerm.toLowerCase())) found.push(collaborator.id);
    //                 })
    //                 break;
    //             case 'Riferimento':
    //                 collaborators.forEach(collaborator => {
    //                     if(collaborator.riferimento != null)
    //                         if(collaborator.riferimento.toLowerCase().includes(searchTerm.toLowerCase()))
    //                             found.push(collaborator.id);
    //                 })
    //                 break;
    //         }

    //         setSearchCollaboratorsResultsIDs(found);
    //     }

    // }

    const UpdateTesseramentoAttivo = (id, nuovoStato) => {
        let tesseramentiUpdated = [...tesseramenti];

        for(let i = 0; i < tesseramentiUpdated.length; i++){
            if (tesseramentiUpdated[i].id == id){
                tesseramentiUpdated[i].tesseramentoAttivo = nuovoStato;
                break;
            }
        }

        setTesseramenti([...tesseramentiUpdated]);
    }

    const findAdmins = (findBy, searchTerm) => {

        if (searchTerm == ''){
            //do nothing
        }
        else{
            setSearchingAdmins(true);
            let found = [];
            switch (findBy){
                case 'Nome':
                    admins.forEach(admin => {
                        if(admin.nome.toLowerCase().includes(searchTerm.toLowerCase())) found.push(admin.id);
                    })
                    break;
                case 'Cognome':
                    admins.forEach(admin => {
                        if(admin.cognome != null)
                            if(admin.cognome.toLowerCase().includes(searchTerm.toLowerCase()))
                                found.push(admin.id);
                    })
                    break;
                case 'Username':
                    admins.forEach(admin => {
                        if(admin.username.toLowerCase().includes(searchTerm.toLowerCase())) found.push(admin.id);
                    })
                    break;
                case 'Riferimento':
                    admins.forEach(admin => {
                        if(admin.riferimento != null)
                            if(admin.riferimento.toLowerCase().includes(searchTerm.toLowerCase()))
                                found.push(admin.id);
                    })
                    break;
            }

            setSearchAdminsResultsIDs(found);
        }

    }

    const findTesseramenti = (findBy, searchTerm) => {

        if (searchTerm == ''){
            //do nothing
        }
        else{
            setSearchingTesseramenti(true);
            let found = [];
            switch (findBy){
                case 'Nome':
                    tesseramenti.forEach(tesseramento => {
                        if(tesseramento.nome.toLowerCase().includes(searchTerm.toLowerCase())) found.push(tesseramento.id);
                    })
                    break;
                case 'Cognome':
                    tesseramenti.forEach(tesseramento => {
                        if(tesseramento.cognome != null)
                            if(tesseramento.cognome.toLowerCase().includes(searchTerm.toLowerCase()))
                                found.push(tesseramento.id);
                    })
                    break;
            }

            setSearchTesseramentiResultsIDs(found);
        }

    }

    const ModifyAdmin = ( updatedAdmin ) => {
        let adminsUpdated = [...admins];

        for (let i = 0; i < adminsUpdated.length; i++){
            if (adminsUpdated[i].id == updatedAdmin.id){
                adminsUpdated[i] = updatedAdmin;
            }
        }

        adminsUpdated = SortAdminsAfterUpdate(adminsUpdated);

        setAdmins([...adminsUpdated]);

        return true;

    }

    const ModifyTesseramento = ( updatedTesseramento ) => {
        let tesseramentiUpdated = [...tesseramenti];

        for (let i = 0; i < tesseramentiUpdated.length; i++){
            if (tesseramentiUpdated[i].id == updatedTesseramento.id){
                tesseramentiUpdated[i] = updatedTesseramento;
            }
        }

        tesseramentiUpdated = sortTesseramentiAfterUpdate(tesseramentiUpdated);

        setTesseramenti([...tesseramentiUpdated]);

        return true;

    }

    const RenewTesseramento = ( id, nuovaScadenza ) => {
        let tesseramentiUpdated = [...tesseramenti];

        for (let i = 0; i < tesseramentiUpdated.length; i++){
            if (tesseramentiUpdated[i].id == id){
                tesseramentiUpdated[i].scadenzaTesseramento = nuovaScadenza;
                tesseramentiUpdated[i].tesseramentoAttivo = true;
            }
        }

        tesseramentiUpdated = sortTesseramentiAfterUpdate(tesseramentiUpdated);

        setTesseramenti([...tesseramentiUpdated]);

        return true;

    }

    // const ModifyCollaborator = ( updatedCollaborator ) => {
    //     let collaboratorsUpdated = [...collaborators];

    //     for (let i = 0; i < collaboratorsUpdated.length; i++){
    //         if (collaboratorsUpdated[i].id == updatedCollaborator.id){
    //             collaboratorsUpdated[i] = updatedCollaborator;
    //         }
    //     }

    //     collaboratorsUpdated = SortCollaboratorsAfterUpdate(collaboratorsUpdated);

    //     setCollaborators([...collaboratorsUpdated]);

    //     return true;

    // }

    const ModifyEvent = ( updatedEvent ) => {
        let eventsUpdated = [...events];

        for (let i = 0; i < eventsUpdated.length; i++){
            if (eventsUpdated[i].id == updatedEvent.id){
                eventsUpdated[i] = updatedEvent;
            }
        }

        eventsUpdated = SortEventsAfterUpdate(eventsUpdated);

        setEvents([...eventsUpdated]);

        return true;

    }

    const CopyEvent = ( newEvent ) => {
        setCopyingEvent(false);
        CreateNewEvent(newEvent);
        return true;
    }

    const AdminDeleted = () => {
        //Admin deleted themselves successfully from DB, clear session and log out
        setSessionData({role: 0, nome: '', cognome: '', id: ''});
        SessionLogOutRequest();
    }

    // const DeleteCollaborator = (id) => {

    //     let collaboratorsUpdated = [...collaborators];
        
    //     if (currentlyEditedCollaborator != null && currentlyEditedCollaborator.id == id)
    //         setCurrentlyEditedCollaborator(null);
        
    //     for(let i = 0; i < collaboratorsUpdated.length; i++){
    //         if (collaboratorsUpdated[i].id == id){ 
    //             collaboratorsUpdated.splice(i, 1);
    //             break;
    //         }
    //     }

    //     setCollaborators([...collaboratorsUpdated]);
    //     return true;
        
    // }

    const DeleteEvent = (id) => {

        let eventsUpdated = [...events];
        
        if (currentlyEditedEvent != null && currentlyEditedEvent.id == id)
            setCurrentlyEditedEvent(null);
        
        for(let i = 0; i < eventsUpdated.length; i++){
            if (eventsUpdated[i].id == id){ 
                eventsUpdated.splice(i, 1);
                break;
            }
        }

        setEvents([...eventsUpdated]);
        return true;
        
    }

    const DeleteTesseramento = (id) => {

        let tesseramentiUpdated = [...tesseramenti];
        
        if (currentlyEditedTesseramento != null && currentlyEditedTesseramento.id == id)
            setCurrentlyEditedTesseramento(null);
        
        for(let i = 0; i < tesseramentiUpdated.length; i++){
            if (tesseramentiUpdated[i].id == id){ 
                tesseramentiUpdated.splice(i, 1);
                break;
            }
        }

        setTesseramenti([...tesseramentiUpdated]);
        return true;
        
    }

    const UpdateAdminData = (id) => {

        for (let i = 0; i < admins.length; i++){
            if (admins[i].id == id){
                if (currentlyEditedAdmin == admins[i]){
                    setCurrentOverlayPage('modifyAdminData');
                    //ShowModifyClientDataOverlay();
                }
                else{
                    setCurrentlyEditedAdmin(admins[i]);
                    setCurrentOverlayPage('modifyAdminData');
                }
                return true;
            }
        }

        return false
    }

    const UpdateTesseramentoData = (id) => {

        for (let i = 0; i < tesseramenti.length; i++){
            if (tesseramenti[i].id == id){
                if (currentlyEditedTesseramento == tesseramenti[i]){
                    setCurrentOverlayPage('modifyTesseramentoData');
                    //ShowModifyClientDataOverlay();
                }
                else{
                    setCurrentlyEditedTesseramento(tesseramenti[i]);
                    setCurrentOverlayPage('modifyTesseramentoData');
                }
                return true;
            }
        }

        return false
    }

    // const UpdateCollaboratorData = (id) => {

    //     for (let i = 0; i < collaborators.length; i++){
    //         if (collaborators[i].id == id){
    //             if (currentlyEditedCollaborator == collaborators[i]){
    //                 setCurrentOverlayPage('modifyCollaboratorData');
    //                 //ShowModifyClientDataOverlay();
    //             }
    //             else{
    //                 setCurrentlyEditedCollaborator(collaborators[i]);
    //                 setCurrentOverlayPage('modifyCollaboratorData');
    //             }
    //             return true;
    //         }
    //     }

    //     return false
    // }

    const UpdateEventData = (id) => {

        for (let i = 0; i < events.length; i++){
            if (events[i].id == id){
                if (currentlyEditedEvent == events[i]){
                    setCurrentOverlayPage('modifyEventData');
                    //ShowModifyClientDataOverlay();
                }
                else{
                    setCurrentlyEditedEvent(events[i]);
                    setCurrentOverlayPage('modifyEventData');
                }
                return true;
            }
        }

        return false
    }

    const CopyEventPressed = (id) => {

        for (let i = 0; i < events.length; i++){
            if (events[i].id == id){
                if (currentlyEditedEvent == events[i]){
                    setCurrentOverlayPage('copyEventData');
                    //ShowModifyClientDataOverlay();
                }
                else{
                    setCurrentlyEditedEvent(events[i]);
                    setCurrentOverlayPage('copyEventData');
                }
                return true;
            }
        }

        return false
    }

    const DeleteAdminPressed = (id) => {

        for (let i = 0; i < admins.length; i++){
            if (admins[i].id == id){
                if (currentlyEditedAdmin == admins[i]){
                    setCurrentOverlayPage('confirmDeleteAdmin');
                    //ShowModifyClientDataOverlay();
                }
                else{
                    setCurrentlyEditedAdmin(admins[i]);
                    setCurrentOverlayPage('confirmDeleteAdmin');
                }
                return true;
            }
        }

        return false
    }

    // const DeleteCollaboratorPressed = (id) => {

    //     for (let i = 0; i < collaborators.length; i++){
    //         if (collaborators[i].id == id){
    //             if (currentlyEditedCollaborator == collaborators[i]){
    //                 setCurrentOverlayPage('confirmDeleteCollaborator');
    //                 //ShowModifyClientDataOverlay();
    //             }
    //             else{
    //                 setCurrentlyEditedCollaborator(collaborators[i]);
    //                 setCurrentOverlayPage('confirmDeleteCollaborator');
    //             }
    //             return true;
    //         }
    //     }

    //     return false
    // }

    const DeleteEventPressed = (id) => {

        for (let i = 0; i < events.length; i++){
            if (events[i].id == id){
                if (currentlyEditedEvent == events[i]){
                    setCurrentOverlayPage('confirmDeleteEvent');
                    //ShowModifyClientDataOverlay();
                }
                else{
                    setCurrentlyEditedEvent(events[i]);
                    setCurrentOverlayPage('confirmDeleteEvent');
                }
                return true;
            }
        }

        return false
    }

    const DeleteTesseramentoPressed = (id) => {

        for (let i = 0; i < tesseramenti.length; i++){
            if (tesseramenti[i].id == id){
                if (currentlyEditedTesseramento == tesseramenti[i]){
                    setCurrentOverlayPage('confirmDeleteTesseramento');
                    //ShowModifyClientDataOverlay();
                }
                else{
                    setCurrentlyEditedTesseramento(tesseramenti[i]);
                    setCurrentOverlayPage('confirmDeleteTesseramento');
                }
                return true;
            }
        }

        return false
    }

    const RenewTesseramentoPressed = (id) => {

        for (let i = 0; i < tesseramenti.length; i++){
            if (tesseramenti[i].id == id){
                if (currentlyEditedTesseramento == tesseramenti[i]){
                    setCurrentOverlayPage('confirmRenewTesseramento');
                    //ShowModifyClientDataOverlay();
                }
                else{
                    setCurrentlyEditedTesseramento(tesseramenti[i]);
                    setCurrentOverlayPage('confirmRenewTesseramento');
                }
                return true;
            }
        }

        return false
    }

    const ExpandAllCollaborators = () => {
        let clientsList = document.getElementsByClassName('collaborator-div');
        let togglesList = document.getElementsByClassName('toggle-collaborator-div');

        for (let i = 0; i < clientsList.length; i++){
            clientsList[i].querySelector('#collaborator-data').className = 'collaborator-data';
        }

        for (let i = 0; i < togglesList.length; i++){
            togglesList[i].children[0].getElementsByClassName('plus')[0].style.display = 'none';
            togglesList[i].children[0].getElementsByClassName('minus')[0].style.display = 'block';
        }

    }

    const CollapseAllCollaborators = () => {
        let clientsList = document.getElementsByClassName('collaborator-div');
        let togglesList = document.getElementsByClassName('toggle-collaborator-div');

        for (let i = 0; i < clientsList.length; i++){
            clientsList[i].querySelector('#collaborator-data').className = 'collaborator-data collaborator-data-collapsed';
        }

        for (let i = 0; i < togglesList.length; i++){
            togglesList[i].children[0].getElementsByClassName('plus')[0].style.display = 'block';
            togglesList[i].children[0].getElementsByClassName('minus')[0].style.display = 'none';
        }

    }

    const ExpandAllTesseramenti = () => {
        let clientsList = document.getElementsByClassName('collaborator-div');
        let togglesList = document.getElementsByClassName('toggle-collaborator-div');

        for (let i = 0; i < clientsList.length; i++){
            clientsList[i].querySelector('#collaborator-data').className = 'collaborator-data';
        }

        for (let i = 0; i < togglesList.length; i++){
            togglesList[i].children[0].getElementsByClassName('plus')[0].style.display = 'none';
            togglesList[i].children[0].getElementsByClassName('minus')[0].style.display = 'block';
        }

    }

    const CollapseAllTesseramenti = () => {
        let clientsList = document.getElementsByClassName('collaborator-div');
        let togglesList = document.getElementsByClassName('toggle-collaborator-div');

        for (let i = 0; i < clientsList.length; i++){
            clientsList[i].querySelector('#collaborator-data').className = 'collaborator-data collaborator-data-collapsed';
        }

        for (let i = 0; i < togglesList.length; i++){
            togglesList[i].children[0].getElementsByClassName('plus')[0].style.display = 'block';
            togglesList[i].children[0].getElementsByClassName('minus')[0].style.display = 'none';
        }

    }

    const CreateNewAdmin = ( newAdmin ) => {
        let adminsUpdated = [...admins];

        adminsUpdated.push(newAdmin);
        adminsUpdated = SortAdminsAfterUpdate(adminsUpdated);
        setAdmins([...adminsUpdated]);

        return true;
    }

    const CreateNewTesseramento = ( newTesseramento ) => {
        let tesseramentiUpdated = [...tesseramenti];

        tesseramentiUpdated.push(newTesseramento);
        tesseramentiUpdated = sortTesseramentiAfterUpdate(tesseramentiUpdated);
        setTesseramenti([...tesseramentiUpdated]);

        return true;
    }

    // const CreateNewCollaborator = ( newCollaborator ) => {
    //     let collaboratorsUpdated = [...collaborators];

    //     collaboratorsUpdated.push(newCollaborator);
    //     collaboratorsUpdated = SortCollaboratorsAfterUpdate(collaboratorsUpdated);
    //     setCollaborators([...collaboratorsUpdated]);

    //     return true;
    // }

    const CreateNewEvent = ( newEvent ) => {
        let eventsUpdated = [...events];

        eventsUpdated.push(newEvent);
        eventsUpdated = SortEventsAfterUpdate(eventsUpdated);
        setEvents([...eventsUpdated]);

        return true;
    }


    const isTesseramentoScaduto = (scadenzaTesseramento) => {
        const today = new Date();
        const scadenzaDate = new Date(scadenzaTesseramento.split('/').reverse().join('-'));

        return today > scadenzaDate;
    }

    const isTesseramentoInScadenza = (scadenzaTesseramento) => {
        const today = new Date();
        const scadenzaDate = new Date(scadenzaTesseramento.split('/').reverse().join('-'));
        const daysDifference = (scadenzaDate - today) / (1000*60*60*24);
        const inScadenza = daysDifference < 30 && daysDifference > 0;
        // console.log(scadenzaTesseramento);
        // console.log(inScadenza);
        return inScadenza;
    }

    const SetStatusLed = (status) => {
        document.getElementById('status-led').className = status ? 'status-querying' : 'status-updated';
    }

    const DeleteMultipleTesseramentiPressed = () => {
        if(!deletingTesseramenti){
            CancelRenewMultipleTesseramenti();
            setTesseramentiToDeleteIDs([]);
            setDeletingTesseramenti(true);
        }
    }

    const CancelDeleteMultipleTesseramenti = () => {
        if(deletingTesseramenti){
            setTesseramentiToDeleteIDs([]);
            setDeletingTesseramenti(false);
        }
    }

    const RenewMultipleTesseramentiPressed = () => {
        if(!renewingTesseramenti){
            CancelDeleteMultipleTesseramenti();
            setTesseramentiToRenewIDs([]);
            setRenewingTesseramenti(true);
        }
    }

    const CancelRenewMultipleTesseramenti = () => {
        if(renewingTesseramenti){
            setTesseramentiToRenewIDs([]);
            setRenewingTesseramenti(false);
        }
    }

    const ToggleTesseramentoToDelete = ( id ) => {
        let tesseramentiToDeleteIDsUpdated = [...tesseramentiToDeleteIDs];

        for (let i = 0; i < tesseramentiToDeleteIDs.length; i++){
            if(tesseramentiToDeleteIDs[i] == id){
                tesseramentiToDeleteIDsUpdated.splice(i, 1);
                setTesseramentiToDeleteIDs([...tesseramentiToDeleteIDsUpdated]);
                return;
            }
        }

        tesseramentiToDeleteIDsUpdated.push(id);
        setTesseramentiToDeleteIDs([...tesseramentiToDeleteIDsUpdated]);
    }

    const ToggleTesseramentoToRenew = ( id ) => {
        let tesseramentiToRenewIDsUpdated = [...tesseramentiToRenewIDs];

        for (let i = 0; i < tesseramentiToRenewIDs.length; i++){
            if(tesseramentiToRenewIDs[i] == id){
                tesseramentiToRenewIDsUpdated.splice(i, 1);
                setTesseramentiToRenewIDs([...tesseramentiToRenewIDsUpdated]);
                return;
            }
        }

        tesseramentiToRenewIDsUpdated.push(id);
        setTesseramentiToRenewIDs([...tesseramentiToRenewIDsUpdated]);
    }

    const DeleteMultipleSelectedTesseramenti = () => {
        let tesseramentiUpdated = [...tesseramenti];

        for (let i = 0; i < tesseramentiToDeleteIDs.length; i++){        
            if (currentlyEditedTesseramento != null && currentlyEditedTesseramento.id == tesseramentiToDeleteIDs[i])
                setCurrentlyEditedTesseramento(null);
            
            for(let j = 0; j < tesseramentiUpdated.length; j++){
                if (tesseramentiUpdated[j].id == tesseramentiToDeleteIDs[i]){ 
                    tesseramentiUpdated.splice(j, 1);
                    break;
                }
            }

        }

        setTesseramenti([...tesseramentiUpdated]);
        CancelDeleteMultipleTesseramenti();
        return true;
    }

    const RenewMultipleSelectedTesseramenti = ( nuovaScadenza ) => {
        let tesseramentiUpdated = [...tesseramenti];
        for (let i = 0; i < tesseramentiToRenewIDs.length; i++){

            for(let j = 0; j < tesseramentiUpdated.length; j++){
                if (tesseramentiUpdated[j].id == tesseramentiToRenewIDs[i]){ 
                    
                    tesseramentiUpdated[j].tesseramentoAttivo = true;
                    tesseramentiUpdated[j].scadenzaTesseramento = nuovaScadenza;

                }
            }

        }

        setTesseramenti([...tesseramentiUpdated]);
        CancelRenewMultipleTesseramenti();
        return true;
    }

    return (
        <div className='container-home'>
            <AdminHomeCommands activePage={activePage} setActivePage={setActivePage} groupTesseramenti={groupTesseramenti} setGroupTesseramenti={setGroupTesseramenti} mostraEventiPassati={mostraEventiPassati} setMostraEventiPassati={setMostraEventiPassati} mostraTesseramentiScaduti={mostraTesseramentiScaduti} setMostraTesseramentiScaduti={setMostraTesseramentiScaduti} mostraSoloTesseramentiAttivi={mostraSoloTesseramentiAttivi} setMostraSoloTesseramentiAttivi={setMostraSoloTesseramentiAttivi} setCopyingEvent={setCopyingEvent} sortEvents={sortEvents} findTesseramenti={findTesseramenti} findEvents={findEvents} /*sortCollaborators={sortCollaborators} findCollaborators={findCollaborators}*/ ExpandAllCollaborators={ExpandAllCollaborators} CollapseAllCollaborators={CollapseAllCollaborators} ExpandAllTesseramenti={ExpandAllTesseramenti} CollapseAllTesseramenti={CollapseAllTesseramenti} sortTesseramenti={sortTesseramenti} sortAdmins={sortAdmins} findAdmins={findAdmins} setCurrentOverlayPage={setCurrentOverlayPage} currentEventsOrder={currentEventsOrder} /*currentCollaboratorsOrder={currentCollaboratorsOrder}*/ currentAdminsOrder={currentAdminsOrder} currentTesseramentiOrder={currentTesseramentiOrder} DeleteMultipleTesseramentiPressed={DeleteMultipleTesseramentiPressed} CancelDeleteMultipleTesseramenti={CancelDeleteMultipleTesseramenti} RenewMultipleTesseramentiPressed={RenewMultipleTesseramentiPressed} CancelRenewMultipleTesseramenti={CancelRenewMultipleTesseramenti} />

            {isLoading &&
                <div className='content-home'>
                    <h2 className='loading-message'>Caricamento...</h2>
                </div>
            }

            {activePage === 'events' && !isLoading && events.length > 0 &&
            <div className='content-home'>
                <div id='status-led' className='status-updated' data-tip='INDICATORE DI STATO<br><br>Verde: Tutte le modifiche sono state salvate correttamente.<br><br>Rosso: Connessione al server in corso. Se il colore rosso persiste, ricaricare la pagina per verificare che i dati siano stati aggiornati correttamente e in caso contrario contattare un amministratore o referente.' data-for='status-tooltip' data-place='bottom' data-effect='solid' data-class='status-info-tooltip' />
                <ReactTooltip id='status-tooltip' multiline={true} />

                {copyingEvent &&
                    <div className='copy-event-header'>
                        <h4 className='copy-event-header-text'>Selezionare l'evento da copiare:</h4>
                        <a onClick={CancelCopyEvent}>Annulla</a>
                    </div>
                }

                {searchingEvents &&
                    <div className='found-header'>
                        <h4 className='found-header-text'>Risultati ricerca:</h4>
                        <a onClick={CancelSearchEvents}>Annulla</a>
                    </div>
                }

                {searchingEvents &&
                events.filter(evento => mostraEventiPassati || new Date(evento.dataInizio.split('/').reverse().join('-')).getTime() >= new Date().getTime()).map( event => {
                    if (searchEventsResultsIDs.includes(event.id)){
                        return(
                            <Event key={event.id} {...event} UpdateEventData={UpdateEventData} DeleteEventPressed={DeleteEventPressed} copyingEvent={copyingEvent} CopyEventPressed={CopyEventPressed} />
                        )
                    }
                })
                }

                {(events.filter(evento => mostraEventiPassati || new Date(evento.dataInizio.split('/').reverse().join('-')).getTime() >= new Date().getTime()).filter(evento => searchEventsResultsIDs.includes(evento.id)).length == 0) && searchingEvents && //searchEventsResultsIDs.length == 0ACTUALLY CHECK FOR SEARCH EVENTS RESULTS ID THAT WOULD NOT BE FILTERED BY mostraEventiPassati
                    <h4 className='no-results-text'>Nessun risultato!</h4>
                }

                {searchingEvents &&
                    <div className='divider' />
                }

                {searchingEvents &&
                    <h4 className='found-header-text'>Tutti gli eventi:</h4>
                }

                {events.filter(evento => mostraEventiPassati || new Date(evento.dataInizio.split('/').reverse().join('-')).getTime() >= new Date().getTime()).map( event => {
                    return(
                        <Event key={event.id} {...event} UpdateEventData={UpdateEventData} DeleteEventPressed={DeleteEventPressed} copyingEvent={copyingEvent} CopyEventPressed={CopyEventPressed} />
                    )
                })}

            </div>
            }

            {activePage === 'events' && !isLoading && events.length <= 0 &&
                <div className='content-home'>
                    <div id='status-led' className='status-updated' data-tip='INDICATORE DI STATO<br><br>Verde: Tutte le modifiche sono state salvate correttamente.<br><br>Rosso: Connessione al server in corso. Se il colore rosso persiste, ricaricare la pagina per verificare che i dati siano stati aggiornati correttamente e in caso contrario contattare un amministratore o referente.' data-for='status-tooltip' data-place='bottom' data-effect='solid' data-class='status-info-tooltip' />
                    <ReactTooltip id='status-tooltip' multiline={true} />

                    <div className='found-header'>
                        <h4 className='found-header-text'>Nessun evento in piano!</h4>
                    </div>
                </div>
            }

            {/* {activePage === 'collaborators' && !isLoading && collaborators.length > 0 &&
            <div className='content-home'>
                <div id='status-led' className='status-updated' data-tip='INDICATORE DI STATO<br><br>Verde: Tutte le modifiche sono state salvate correttamente.<br><br>Rosso: Connessione al server in corso. Se il colore rosso persiste, ricaricare la pagina per verificare che i dati siano stati aggiornati correttamente e in caso contrario contattare un amministratore o referente.' data-for='status-tooltip' data-place='bottom' data-effect='solid' data-class='status-info-tooltip' />
                <ReactTooltip id='status-tooltip' multiline={true} />

                {searchingCollaborators &&
                    <div className='found-header'>
                        <h4 className='found-header-text'>Risultati ricerca:</h4>
                        <a onClick={CancelSearchCollaborators}>Annulla</a>
                    </div>
                }

                {searchCollaboratorsResultsIDs.length > 0 && searchingCollaborators &&
                <div className='masonry-content-div'>
                    <div className='inner-masonry-content-div'>
                        <Masonry
                            items={
                                collaborators.filter(collaborator => searchCollaboratorsResultsIDs.includes(collaborator.id)).map(collaborator => {
                                    return (
                                        {key: collaborator.id, node: <Collaborator {...collaborator} UpdateCollaboratorData={UpdateCollaboratorData} DeleteCollaboratorPressed={DeleteCollaboratorPressed} /> }
                                    )
                                })
                            }
                            minColumnWidth={272}
                            gap={16}
                        />
                    </div>
                </div>
                }

                {searchCollaboratorsResultsIDs.length == 0 && searchingCollaborators &&
                    <h4 className='no-results-text'>Nessun risultato!</h4>
                }

                {searchingCollaborators &&
                    <div className='divider' />
                }

                {searchingCollaborators &&
                    <h4 className='found-header-text'>Tutti i collaboratori:</h4>
                }

                <div className='masonry-content-div'>
                    <div className='inner-masonry-content-div'>
                        <Masonry
                            items={
                                collaborators.map(collaborator => {
                                    return (
                                        {key: collaborator.id, node: <Collaborator {...collaborator} UpdateCollaboratorData={UpdateCollaboratorData} DeleteCollaboratorPressed={DeleteCollaboratorPressed} /> }
                                    )
                                })
                            }
                            minColumnWidth={272}
                            gap={16}
                        />
                    </div>
                </div>
            </div>
            } */}

            {/* {activePage === 'collaborators' && !isLoading && collaborators.length <= 0 &&
                <div className='content-home'>
                    <div id='status-led' className='status-updated' data-tip='INDICATORE DI STATO<br><br>Verde: Tutte le modifiche sono state salvate correttamente.<br><br>Rosso: Connessione al server in corso. Se il colore rosso persiste, ricaricare la pagina per verificare che i dati siano stati aggiornati correttamente e in caso contrario contattare un amministratore o referente.' data-for='status-tooltip' data-place='bottom' data-effect='solid' data-class='status-info-tooltip' />
                    <ReactTooltip id='status-tooltip' multiline={true} />

                    <div className='found-header'>
                        <h4 className='found-header-text'>Nessun collaboratore!</h4>
                    </div>
                </div>
            } */}

            {activePage === 'admins' && !isLoading &&
            <div className='content-home'>
                <div id='status-led' className='status-updated' data-tip='INDICATORE DI STATO<br><br>Verde: Tutte le modifiche sono state salvate correttamente.<br><br>Rosso: Connessione al server in corso. Se il colore rosso persiste, ricaricare la pagina per verificare che i dati siano stati aggiornati correttamente e in caso contrario contattare un amministratore o referente.' data-for='status-tooltip' data-place='bottom' data-effect='solid' data-class='status-info-tooltip' />
                <ReactTooltip id='status-tooltip' multiline={true} />

                {searchingAdmins &&
                    <div className='found-header'>
                        <h4 className='found-header-text'>Risultati ricerca:</h4>
                        <a onClick={CancelSearchAdmins}>Annulla</a>
                    </div>
                }

                {searchAdminsResultsIDs.length > 0 && searchingAdmins &&
                    <div className='grid-content-div'>
                        {
                        admins.filter(admin => searchAdminsResultsIDs.includes(admin.id)).map(admin => 
                            <Admin key={admin.id} {...admin} UpdateAdminData={UpdateAdminData} DeleteAdminPressed={DeleteAdminPressed} currentAdminID={sessionData.id} />
                        )
                        }
                    </div>
                // <div className='masonry-content-div'>
                //     <div className='inner-masonry-content-div'>
                //         <Masonry
                //             items={
                //                 admins.filter(admin => searchAdminsResultsIDs.includes(admin.id)).map(admin => {
                //                     return (
                //                         {key: admin.id, node: <Admin {...admin} UpdateAdminData={UpdateAdminData} DeleteAdminPressed={DeleteAdminPressed} currentAdminID={sessionData.id} /> }
                //                     )
                //                 })
                //             }
                //             minColumnWidth={272}
                //             gap={16}
                //         />
                //     </div>
                // </div>
                }

                {searchAdminsResultsIDs.length == 0 && searchingAdmins &&
                    <h4 className='no-results-text'>Nessun risultato!</h4>
                }

                {searchingAdmins &&
                    <div className='divider' />
                }

                {searchingAdmins &&
                    <h4 className='found-header-text'>Tutti gli admin:</h4>
                }

                <div className='grid-content-div'>
                    {
                    admins.map(admin => 
                        <Admin key={admin.id} {...admin} UpdateAdminData={UpdateAdminData} DeleteAdminPressed={DeleteAdminPressed} currentAdminID={sessionData.id} />
                    )
                    }
                </div>
                {/* <div className='masonry-content-div'>
                    <div className='inner-masonry-content-div'>
                        <Masonry
                            items={
                                admins.map(admin => {
                                    return (
                                        {key: admin.id, node: <Admin {...admin} UpdateAdminData={UpdateAdminData} DeleteAdminPressed={DeleteAdminPressed} currentAdminID={sessionData.id} /> }
                                    )
                                })
                            }
                            minColumnWidth={272}
                            gap={16}
                        />
                    </div>
                </div> */}
            </div>
            }

            {activePage === 'tesseramenti' && !isLoading && !groupTesseramenti &&
            <div className='content-home'>
                <div id='status-led' className='status-updated' data-tip='INDICATORE DI STATO<br><br>Verde: Tutte le modifiche sono state salvate correttamente.<br><br>Rosso: Connessione al server in corso. Se il colore rosso persiste, ricaricare la pagina per verificare che i dati siano stati aggiornati correttamente e in caso contrario contattare un amministratore o referente.' data-for='status-tooltip' data-place='bottom' data-effect='solid' data-class='status-info-tooltip' />
                <ReactTooltip id='status-tooltip' multiline={true} />

                {renewingTesseramenti &&
                    <div className='multi-delete-header'>
                        <div className='multi-delete-header-title'>
                            <h2 className='found-header-text'>Seleziona tesseramenti da rinnovare</h2>
                            <a> - </a>
                            <a className='clickable' onClick={CancelRenewMultipleTesseramenti}>Annulla</a>
                        </div>
                        <button disabled={tesseramentiToRenewIDs.length <= 0} onClick={() => setCurrentOverlayPage('confirmRenewMultipleTesseramenti')} >Rinnova selezione</button>
                        <div className='multi-delete-divider' />
                    </div>
                }

                {deletingTesseramenti &&
                    <div className='multi-delete-header'>
                        <div className='multi-delete-header-title'>
                            <h2 className='found-header-text'>Seleziona tesserati da eliminare</h2>
                            <a> - </a>
                            <a className='clickable' onClick={CancelDeleteMultipleTesseramenti}>Annulla</a>
                        </div>
                        <button disabled={tesseramentiToDeleteIDs.length <= 0} onClick={() => setCurrentOverlayPage('confirmDeleteMultipleTesseramenti')} >Elimina selezione</button>
                        <div className='multi-delete-divider' />
                    </div>
                }

                {searchingTesseramenti &&
                    <div className='found-header'>
                        <h4 className='found-header-text'>Risultati ricerca:</h4>
                        <a onClick={CancelSearchTesseramenti}>Annulla</a>
                    </div>
                }

                {searchTesseramentiResultsIDs.length > 0 && searchingTesseramenti &&
                    <div className='grid-content-div'>
                        {
                        tesseramenti.filter(tesseramento => searchTesseramentiResultsIDs.includes(tesseramento.id)).map(tesseramento => 
                            renewingTesseramenti ?
                            <TesseramentoToRenew key={tesseramento.id} {...tesseramento} ToggleTesseramentoToRenew={ToggleTesseramentoToRenew} tesseramentiToRenewIDs={tesseramentiToRenewIDs}/>
                            :
                            (
                            deletingTesseramenti ?
                            <TesseramentoToDelete key={tesseramento.id} {...tesseramento} ToggleTesseramentoToDelete={ToggleTesseramentoToDelete} tesseramentiToDeleteIDs={tesseramentiToDeleteIDs}/>
                            :
                            <Tesseramento key={tesseramento.id} {...tesseramento} UpdateTesseramentoAttivo={UpdateTesseramentoAttivo} UpdateTesseramentoData={UpdateTesseramentoData} DeleteTesseramentoPressed={DeleteTesseramentoPressed} RenewTesseramentoPressed={RenewTesseramentoPressed}/>
                            )
                        )
                        }
                    </div>
                }

                {searchTesseramentiResultsIDs.length == 0 && searchingTesseramenti &&
                    <h4 className='no-results-text'>Nessun risultato!</h4>
                }

                {searchingTesseramenti &&
                    <div className='divider' />
                }

                {searchingTesseramenti &&
                    <h4 className='found-header-text'>Tutti gli admin:</h4>
                }

                <div className='grid-content-div'>
                    {
                    tesseramenti
                    .filter(tesseramento => mostraTesseramentiScaduti || !isTesseramentoScaduto(tesseramento.scadenzaTesseramento))
                    .filter(tesseramento => !mostraSoloTesseramentiAttivi || tesseramento.tesseramentoAttivo)
                    .map(tesseramento => 
                        renewingTesseramenti ?
                        <TesseramentoToRenew key={tesseramento.id} {...tesseramento} ToggleTesseramentoToRenew={ToggleTesseramentoToRenew} tesseramentiToRenewIDs={tesseramentiToRenewIDs}/>
                        :
                        (
                        deletingTesseramenti ?
                        <TesseramentoToDelete key={tesseramento.id} {...tesseramento} ToggleTesseramentoToDelete={ToggleTesseramentoToDelete} tesseramentiToDeleteIDs={tesseramentiToDeleteIDs}/>
                        :
                        <Tesseramento key={tesseramento.id} {...tesseramento} UpdateTesseramentoAttivo={UpdateTesseramentoAttivo} UpdateTesseramentoData={UpdateTesseramentoData} DeleteTesseramentoPressed={DeleteTesseramentoPressed} RenewTesseramentoPressed={RenewTesseramentoPressed}/>
                        )
                    )
                    }
                </div>
            </div>
            }

            {activePage === 'tesseramenti' && !isLoading && groupTesseramenti &&
            <div className='content-home'>
                <div id='status-led' className='status-updated' data-tip='INDICATORE DI STATO<br><br>Verde: Tutte le modifiche sono state salvate correttamente.<br><br>Rosso: Connessione al server in corso. Se il colore rosso persiste, ricaricare la pagina per verificare che i dati siano stati aggiornati correttamente e in caso contrario contattare un amministratore o referente.' data-for='status-tooltip' data-place='bottom' data-effect='solid' data-class='status-info-tooltip' />
                <ReactTooltip id='status-tooltip' multiline={true} />

                {renewingTesseramenti &&
                    <div className='multi-delete-header'>
                        <div className='multi-delete-header-title'>
                            <h2 className='found-header-text'>Seleziona tesseramenti da rinnovare</h2>
                            <a> - </a>
                            <a className='clickable' onClick={CancelRenewMultipleTesseramenti}>Annulla</a>
                        </div>
                        <button disabled={tesseramentiToRenewIDs.length <= 0} onClick={() => setCurrentOverlayPage('confirmRenewMultipleTesseramenti')} >Rinnova selezione</button>
                        <div className='multi-delete-divider' />
                    </div>
                }

                {deletingTesseramenti &&
                    <div className='multi-delete-header'>
                        <div className='multi-delete-header-title'>
                            <h2 className='found-header-text'>Seleziona tesserati da eliminare</h2>
                            <a> - </a>
                            <a className='clickable' onClick={CancelDeleteMultipleTesseramenti}>Annulla</a>
                        </div>
                        <button disabled={tesseramentiToDeleteIDs.length <= 0} onClick={() => setCurrentOverlayPage('confirmDeleteMultipleTesseramenti')} >Elimina selezione</button>
                        <div className='multi-delete-divider' />
                    </div>
                }

                <div className='admin-groups-div'>
                    <div className='admin-group-div'>
                        <h3> Attivi </h3>
                        <div className='group-divider' />

                        <div className='grid-content-div no-padding'>
                            {
                            tesseramenti
                            .filter(tesseramento => tesseramento.tesseramentoAttivo && !isTesseramentoInScadenza(tesseramento.scadenzaTesseramento))
                            .map(tesseramento =>
                                renewingTesseramenti ?
                                <TesseramentoToRenew key={tesseramento.id} {...tesseramento} ToggleTesseramentoToRenew={ToggleTesseramentoToRenew} tesseramentiToRenewIDs={tesseramentiToRenewIDs}/>
                                :
                                (
                                deletingTesseramenti ?
                                <TesseramentoToDelete key={tesseramento.id} {...tesseramento} ToggleTesseramentoToDelete={ToggleTesseramentoToDelete} tesseramentiToDeleteIDs={tesseramentiToDeleteIDs}/>
                                :
                                <Tesseramento key={tesseramento.id} {...tesseramento} UpdateTesseramentoAttivo={UpdateTesseramentoAttivo} UpdateTesseramentoData={UpdateTesseramentoData} DeleteTesseramentoPressed={DeleteTesseramentoPressed} RenewTesseramentoPressed={RenewTesseramentoPressed}/>
                                )
                            )
                            }
                        </div>
                    </div>
                    
                    <div className='admin-group-div'>
                        <h3> In Scadenza </h3>
                        <div className='group-divider' />

                        <div className='grid-content-div no-padding'>
                            {
                            tesseramenti
                            .filter(tesseramento => tesseramento.tesseramentoAttivo && isTesseramentoInScadenza(tesseramento.scadenzaTesseramento))
                            .map(tesseramento =>
                                renewingTesseramenti ?
                                <TesseramentoToRenew key={tesseramento.id} {...tesseramento} ToggleTesseramentoToRenew={ToggleTesseramentoToRenew} tesseramentiToRenewIDs={tesseramentiToRenewIDs}/>
                                :
                                (
                                deletingTesseramenti ?
                                <TesseramentoToDelete key={tesseramento.id} {...tesseramento} ToggleTesseramentoToDelete={ToggleTesseramentoToDelete} tesseramentiToDeleteIDs={tesseramentiToDeleteIDs}/>
                                :
                                <Tesseramento key={tesseramento.id} {...tesseramento} UpdateTesseramentoAttivo={UpdateTesseramentoAttivo} UpdateTesseramentoData={UpdateTesseramentoData} DeleteTesseramentoPressed={DeleteTesseramentoPressed} RenewTesseramentoPressed={RenewTesseramentoPressed}/>
                                )
                            )
                            }
                        </div>
                    </div>
                    
                    <div className='admin-group-div'>
                        <h3> Inattivi </h3>
                        <div className='group-divider' />

                        <div className='grid-content-div no-padding'>
                            {
                            tesseramenti
                            .filter(tesseramento => !tesseramento.tesseramentoAttivo && !isTesseramentoScaduto(tesseramento.scadenzaTesseramento))
                            .map(tesseramento =>
                                renewingTesseramenti ?
                                <TesseramentoToRenew key={tesseramento.id} {...tesseramento} ToggleTesseramentoToRenew={ToggleTesseramentoToRenew} tesseramentiToRenewIDs={tesseramentiToRenewIDs}/>
                                :
                                (
                                deletingTesseramenti ?
                                <TesseramentoToDelete key={tesseramento.id} {...tesseramento} ToggleTesseramentoToDelete={ToggleTesseramentoToDelete} tesseramentiToDeleteIDs={tesseramentiToDeleteIDs}/>
                                :
                                <Tesseramento key={tesseramento.id} {...tesseramento} UpdateTesseramentoAttivo={UpdateTesseramentoAttivo} UpdateTesseramentoData={UpdateTesseramentoData} DeleteTesseramentoPressed={DeleteTesseramentoPressed} RenewTesseramentoPressed={RenewTesseramentoPressed}/>
                                )
                            )
                            }
                        </div>
                    </div>
                    
                    <div className='admin-group-div'>
                        <h3> Scaduti </h3>
                        <div className='group-divider' />

                        <div className='grid-content-div no-padding'>
                            {
                            tesseramenti
                            .filter(tesseramento => !tesseramento.tesseramentoAttivo && isTesseramentoScaduto(tesseramento.scadenzaTesseramento))
                            .map(tesseramento =>
                                renewingTesseramenti ?
                                <TesseramentoToRenew key={tesseramento.id} {...tesseramento} ToggleTesseramentoToRenew={ToggleTesseramentoToRenew} tesseramentiToRenewIDs={tesseramentiToRenewIDs}/>
                                :
                                (
                                deletingTesseramenti ?
                                <TesseramentoToDelete key={tesseramento.id} {...tesseramento} ToggleTesseramentoToDelete={ToggleTesseramentoToDelete} tesseramentiToDeleteIDs={tesseramentiToDeleteIDs}/>
                                :
                                <Tesseramento key={tesseramento.id} {...tesseramento} UpdateTesseramentoAttivo={UpdateTesseramentoAttivo} UpdateTesseramentoData={UpdateTesseramentoData} DeleteTesseramentoPressed={DeleteTesseramentoPressed} RenewTesseramentoPressed={RenewTesseramentoPressed}/>
                                )
                            )
                            }
                        </div>
                    </div>
                    
                </div>
            </div>
            }
            

            {/* OVERLAYS */}

            {currentOverlayPage != 'none' &&
                <div id='main-overlay' className='black-overlay'>

                    {/* Event Overlays */}

                    {currentOverlayPage === 'createNewEvent' &&
                        <NewEvent setCurrentOverlayPage={setCurrentOverlayPage} CreateNewEvent={CreateNewEvent} SetStatusLed={SetStatusLed} />
                    }

                    {currentlyEditedEvent != null && currentOverlayPage === 'modifyEventData' &&
                        <ModifyEventData setCurrentOverlayPage={setCurrentOverlayPage} ModifyEvent={ModifyEvent} currentlyEditedEvent={currentlyEditedEvent} SetStatusLed={SetStatusLed} />
                    }

                    {currentlyEditedEvent != null && currentOverlayPage === 'copyEventData' &&
                        <CopyEventData setCurrentOverlayPage={setCurrentOverlayPage} CopyEvent={CopyEvent} currentlyEditedEvent={currentlyEditedEvent} AdminID={sessionData.id} SetStatusLed={SetStatusLed} />
                    }

                    {currentlyEditedEvent != null && currentOverlayPage === 'confirmDeleteEvent' &&
                        <ConfirmDeleteEvent setCurrentOverlayPage={setCurrentOverlayPage} currentlyEditedEvent={currentlyEditedEvent} DeleteEvent={DeleteEvent} SetStatusLed={SetStatusLed} />
                    }




                    {/* Collaborator Overlays */}

                    {/* {currentOverlayPage === 'createNewCollaborator' &&
                        <NewCollaborator setCurrentOverlayPage={setCurrentOverlayPage} CreateNewCollaborator={CreateNewCollaborator} admins={admins} collaborators={collaborators} SetStatusLed={SetStatusLed} />
                    }

                    {currentlyEditedCollaborator != null && currentOverlayPage === 'modifyCollaboratorData' &&
                        <ModifyCollaboratorData setCurrentOverlayPage={setCurrentOverlayPage} ModifyCollaborator={ModifyCollaborator} currentlyEditedCollaborator={currentlyEditedCollaborator} admins={admins} collaborators={collaborators} SetStatusLed={SetStatusLed} />
                    }

                    {currentlyEditedCollaborator != null && currentOverlayPage === 'confirmDeleteCollaborator' &&
                        <ConfirmDeleteCollaborator setCurrentOverlayPage={setCurrentOverlayPage} currentlyEditedCollaborator={currentlyEditedCollaborator} DeleteCollaborator={DeleteCollaborator} SetStatusLed={SetStatusLed} />
                    } */}




                    {/* Admin Overlays */}

                    {currentOverlayPage === 'createNewAdmin' &&
                        <NewAdmin setCurrentOverlayPage={setCurrentOverlayPage} CreateNewAdmin={CreateNewAdmin} admins={admins} collaborators={collaborators} SetStatusLed={SetStatusLed} />
                    }

                    {currentlyEditedAdmin != null && currentOverlayPage === 'modifyAdminData' &&
                        <ModifyAdminData setCurrentOverlayPage={setCurrentOverlayPage} ModifyAdmin={ModifyAdmin} currentlyEditedAdmin={currentlyEditedAdmin} admins={admins} collaborators={collaborators} SetStatusLed={SetStatusLed} />
                    }

                    {currentlyEditedAdmin != null && currentOverlayPage === 'confirmDeleteAdmin' &&
                        <ConfirmDeleteAdmin setCurrentOverlayPage={setCurrentOverlayPage} currentlyEditedAdmin={currentlyEditedAdmin} AdminDeleted={AdminDeleted} SetStatusLed={SetStatusLed} />
                    }




                    {/* Tesseramenti Overlays */}

                    {currentOverlayPage === 'createNewTesseramento' &&
                        <NewTesseramento setCurrentOverlayPage={setCurrentOverlayPage} CreateNewTesseramento={CreateNewTesseramento} tesseramenti={tesseramenti} SetStatusLed={SetStatusLed} />
                    }

                    {currentlyEditedTesseramento != null && currentOverlayPage === 'modifyTesseramentoData' &&
                        <ModifyTesseramentoData setCurrentOverlayPage={setCurrentOverlayPage} ModifyTesseramento={ModifyTesseramento} currentlyEditedTesseramento={currentlyEditedTesseramento} tesseramenti={tesseramenti} SetStatusLed={SetStatusLed} />
                    }

                    {currentlyEditedTesseramento != null && currentOverlayPage === 'confirmDeleteTesseramento' &&
                        <ConfirmDeleteTesseramento setCurrentOverlayPage={setCurrentOverlayPage} currentlyEditedTesseramento={currentlyEditedTesseramento} DeleteTesseramento={DeleteTesseramento} SetStatusLed={SetStatusLed} />
                    }
                    
                    {tesseramentiToDeleteIDs.length > 0 && currentOverlayPage === 'confirmDeleteMultipleTesseramenti' &&
                        <ConfirmDeleteMultipleTesseramenti setCurrentOverlayPage={setCurrentOverlayPage} tesseramentiToDeleteIDs={tesseramentiToDeleteIDs} DeleteMultipleSelectedTesseramenti={DeleteMultipleSelectedTesseramenti} SetStatusLed={SetStatusLed} />
                    }
                    
                    {tesseramentiToRenewIDs.length > 0 && currentOverlayPage === 'confirmRenewMultipleTesseramenti' &&
                        <ConfirmRenewMultipleTesseramenti setCurrentOverlayPage={setCurrentOverlayPage} tesseramentiToRenewIDs={tesseramentiToRenewIDs} RenewMultipleSelectedTesseramenti={RenewMultipleSelectedTesseramenti} SetStatusLed={SetStatusLed} />
                    }
                    
                    {currentlyEditedTesseramento != null && currentOverlayPage === 'confirmRenewTesseramento' &&
                        <ConfirmRenewTesseramento setCurrentOverlayPage={setCurrentOverlayPage} currentlyEditedTesseramento={currentlyEditedTesseramento} RenewTesseramento={RenewTesseramento} SetStatusLed={SetStatusLed} />
                    }


                </div>
            }

        </div>
    )
}
