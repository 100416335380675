import React, { useContext } from 'react'
import { FiPlus, FiMinus } from 'react-icons/fi';
import { ImPlus } from 'react-icons/im';
import SessionDataContext from '../../../contexts/sessionData';

export default function Room({ id, codice, tipo, nomeHotel, capienza, postiOccupati, note, noteModificabili, collocati, matrimoniale, piena, RoomNotesPlusPressed, RoomStaysPressed, PassInfoElement, ToggleMatrimoniale, TogglePiena, PassClientInfoFromRoom }) {
    const {sessionData, setSessionData} = useContext(SessionDataContext);

    const ToggleRoom = (e) => {
        let sections = e.currentTarget.parentElement.parentElement.children;
        let toggleIcons = e.currentTarget.children;

        for (let i = 0; i < sections.length; i++){
            if (sections[i].id == 'room-data'){
                if (sections[i].className == 'room-data'){
                    sections[i].className = 'room-data room-data-collapsed';
                    for (let i = 0; i < toggleIcons.length; i++){
                        if (toggleIcons[i].className.baseVal == 'plus'){
                            toggleIcons[i].style.display = 'block';
                        } else {
                            toggleIcons[i].style.display = 'none';
                        }
                    }
                } else {
                    sections[i].className = 'room-data';
                    for (let i = 0; i < toggleIcons.length; i++){
                        if (toggleIcons[i].className.baseVal == 'minus'){
                            toggleIcons[i].style.display = 'block';
                        } else {
                            toggleIcons[i].style.display = 'none';
                        }
                    }
                }
            }
        }
    }

    return (
        <div className='room-div' >
            <div className='toggle-room-div'> <button className='toggle-button' onClick={e => ToggleRoom(e)}> <FiPlus className='plus' /> <FiMinus className='minus' /> </button> </div>
            <div class='room-name' onClick={e => PassInfoElement(e, id)} >
                <h3> Camera {codice}</h3>
                <div class='top-divider' />
            </div>

            <div id='room-data' class='room-data room-data-collapsed'>

                {tipo &&
                    <div className='data-field room-type-field'>
                        <h4 className='data-field-key'> Tipo: </h4>
                        <div className='data-field-value'>
                            <h4> {tipo} </h4>
                        </div>
                    </div>
                }

                {nomeHotel &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Hotel: </h4>
                        <div className='data-field-value'>
                            <h4> {nomeHotel} </h4>
                        </div>
                    </div>
                }

                {capienza != null &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Capienza Massima: </h4>
                        <div className='data-field-value'>
                            <h4> {capienza} </h4>
                        </div>
                    </div>
                }

                {postiOccupati != null &&
                    <div className='data-field room-vacancy-field'>
                        <h4 className='data-field-key'> Posti Occupati: </h4>
                        <div className='data-field-value'>
                            <h4> {postiOccupati}/{capienza} </h4>
                        </div>
                    </div>
                }

                {(note == null || note == '') && noteModificabili &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Note: </h4>
                        <div className='data-field-value'>
                            <h4> N/P </h4>
                            <div className='data-field-divider' />
                            <div className='data-field-command' onClick={() => RoomNotesPlusPressed(id)} > <ImPlus /> </div>
                        </div>
                    </div>
                }
                {(note == null || note == '') && !noteModificabili &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Note: </h4>
                        <div className='data-field-value'>
                            <h4> N/P </h4>
                        </div>
                    </div>
                }
                {note && noteModificabili &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Note: </h4>
                        <div className='data-field-value'>
                            <h4> {note} </h4>
                            <div className='data-field-divider' />
                            <div className='data-field-command' onClick={() => RoomNotesPlusPressed(id)} > <ImPlus /> </div>
                        </div>
                    </div>
                }
                {note && !noteModificabili &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Note: </h4>
                        <div className='data-field-value'>
                            <h4> {note} </h4>
                        </div>
                    </div>
                }

                {collocati != null && collocati.length > 0 && 
                    <div className='data-field'>
                        <h4 className='data-field-key'> Partecipanti: </h4>
                        <div className='data-field-value'>
                            <ul>
                                {collocati.map(client => {
                                    return(
                                        <li><h4 className='clickable' onClick={() => PassClientInfoFromRoom(client.idPartecipante)}> {client.cognome} {client.nome} </h4></li>
                                    )
                                })
                                }
                            </ul>
                        </div>
                    </div>
                }
                
                <div className= 'data-field room-checkboxes-field'>
                    {(tipo == 'Doppia' || tipo == 'Tripla') &&
                        <label>
                            <input type='checkbox' checked={matrimoniale} onClick={() => ToggleMatrimoniale(id)} />
                            <h4> Letto Matrimoniale </h4>
                        </label>
                    }
                    <label>
                        <input type='checkbox' checked={piena} disabled={postiOccupati == capienza} onClick={() => TogglePiena(id)} />
                        <h4> Segna come Piena </h4>
                    </label>
                </div>

                <div className='bottom-divider'></div>
                <div className='room-commands'>
                    <a className='clickable' onClick={() => RoomStaysPressed(id)} >Pernottamenti / Colloca</a>
                    
                </div>

            </div>

        </div>
    )
}

export function InfoRoom({ DeleteClientStay, RoomStaysPressed, RoomNotesPlusPressed, ToggleMatrimoniale, TogglePiena, id, codice, tipo, nomeHotel, capienza, postiOccupati, note, noteModificabili, collocati, piena, matrimoniale }) {
    return (
        <div className='info-room-div' >

            {/* Info Stanza */}

            <h3> Camera {codice}</h3>
            <div class='info-top-divider' />

            {tipo && 
                <div className='data-field'>
                    <h4 className='data-field-key'> Tipo: </h4>
                    <div className='data-field-value'>
                        <h4> {tipo} </h4>
                    </div>
                </div>
            }
            
            {nomeHotel && 
                <div className='data-field'>
                    <h4 className='data-field-key'> Hotel: </h4>
                    <div className='data-field-value'>
                        <h4> {nomeHotel} </h4>
                    </div>
                </div>
            }
            
            {capienza != null && 
                <div className='data-field'>
                    <h4 className='data-field-key'> Capienza Massima: </h4>
                    <div className='data-field-value'>
                        <h4> {capienza} </h4>
                    </div>
                </div>
            }
            
            {postiOccupati != null && capienza != null && 
                <div className='data-field'>
                    <h4 className='data-field-key'> Posti Occupati: </h4>
                    <div className='data-field-value'>
                        <h4> {postiOccupati} / {capienza} </h4>
                    </div>
                </div>
            }

            {(note == null || note == '') && noteModificabili &&
                <div className='data-field'>
                    <h4 className='data-field-key'> Note: </h4>
                    <div className='data-field-value'>
                        <h4> N/P </h4>
                        <div className='data-field-divider' />
                        <div className='data-field-command' onClick={() => RoomNotesPlusPressed(id)} > <ImPlus /> </div>
                    </div>
                </div>
            }
            {(note == null || note == '') && !noteModificabili &&
                <div className='data-field'>
                    <h4 className='data-field-key'> Note: </h4>
                    <div className='data-field-value'>
                        <h4> N/P </h4>
                    </div>
                </div>
            }
            {note && noteModificabili &&
                <div className='data-field'>
                    <h4 className='data-field-key'> Note: </h4>
                    <div className='data-field-value'>
                        <h4> {note} </h4>
                        <div className='data-field-divider' />
                        <div className='data-field-command' onClick={() => RoomNotesPlusPressed(id)} > <ImPlus />
                        </div>
                    </div>
                </div>
            }
            {note && !noteModificabili &&
                <div className='data-field'>
                    <h4 className='data-field-key'> Note: </h4>
                    <div className='data-field-value'>
                        <h4> {note} </h4>
                    </div>
                </div>
            }

            {collocati != null && collocati.length > 0 && 
                <div className='data-field'>
                    <h4 className='data-field-key'> Partecipanti: </h4>
                    <div className='data-field-value'>
                        <ul>
                            {collocati.map(client => {
                                return(
                                    <li><h4> {client.cognome} {client.nome} </h4></li>
                                )
                            })
                            }
                        </ul>
                    </div>
                </div>
            }

            <div className= 'data-field room-checkboxes-field'>
                {(tipo == 'Doppia' || tipo == 'Tripla') &&
                    <label>
                        <input type='checkbox' checked={matrimoniale} onClick={() => ToggleMatrimoniale(id)} /> {/* checked={matrimoniale} should work, but doesn't currently allow for changing the checkbox state, but if onClick we changed the value of matrimoniale, it should act like a normal checkbox */}
                        <h4> Letto Matrimoniale </h4>
                    </label>
                }
                <label>
                    <input type='checkbox' checked={piena} disabled={postiOccupati == capienza} onClick={() => TogglePiena(id)} /> {/* Come sopra + se capienza == posti occupati, segna la camera come piena e non dare la possibilità di metterla non-piena perchè lo è e basta */}
                    <h4> Segna come Piena </h4>
                </label>
            </div>

            <button onClick={() => RoomStaysPressed(id)} >Pernottamenti</button>


            {/* Lista Clienti */}

            {collocati != null && collocati.length > 0 &&
            <div className='staying-clients-div'>
                {collocati.map( cliente => {
                    return(
                        <div>
                            <h3>{cliente.cognome} {cliente.nome}</h3>

                            <div class='info-top-divider' />

                            <div className='data-field'>
                                <h4 className='data-field-key'> Pacchetto: </h4>
                                <div className='data-field-value'>
                                    <h4> {cliente.nomePacchettoCliente} </h4>
                                </div>
                            </div>

                            <div className='data-field'>
                                <h4 className='data-field-key'> Data In: </h4>
                                <div className='data-field-value'>
                                    <h4> {cliente.dataIn} </h4>
                                </div>
                            </div>

                            <div className='data-field'>
                                <h4 className='data-field-key'> Data Out: </h4>
                                <div className='data-field-value'>
                                    <h4> {cliente.dataOut} </h4>
                                </div>
                            </div>

                            <button onClick={() => DeleteClientStay(cliente.idPartecipante)} >Rimuovi</button>
                        </div>
                    )
                })}
            </div>
            }

        </div>
    
    )
}

export function ChoiceRoom({ codice, tipo, nomeHotel, capienza, postiOccupati, note, collocati}){

    const ToggleRoom = (e) => {
        let sections = e.currentTarget.parentElement.parentElement.children;
        let toggleIcons = e.currentTarget.children;

        for (let i = 0; i < sections.length; i++){
            if (sections[i].id == 'room-data'){
                if (sections[i].className == 'room-data'){
                    sections[i].className = 'room-data room-data-collapsed';
                    for (let i = 0; i < toggleIcons.length; i++){
                        if (toggleIcons[i].className.baseVal == 'plus'){
                            toggleIcons[i].style.display = 'block';
                        } else {
                            toggleIcons[i].style.display = 'none';
                        }
                    }
                } else {
                    sections[i].className = 'room-data';
                    for (let i = 0; i < toggleIcons.length; i++){
                        if (toggleIcons[i].className.baseVal == 'minus'){
                            toggleIcons[i].style.display = 'block';
                        } else {
                            toggleIcons[i].style.display = 'none';
                        }
                    }
                }
            }
        }
    }
    

    return (
        
        <div className='room-div' >
            <div className='toggle-room-div'> <button className='toggle-button' onClick={e => ToggleRoom(e)}> <FiPlus className='plus' /> <FiMinus className='minus' /> </button> </div>
            <div class='room-name'>
                <h3> Camera {codice}</h3>
                <div class='top-divider' />
            </div>

            <div id='room-data' class='room-data room-data-collapsed'>

                {tipo &&
                    <div className='data-field room-type-field'>
                        <h4 className='data-field-key'> Tipo: </h4>
                        <div className='data-field-value'>
                            <h4> {tipo} </h4>
                        </div>
                    </div>
                }

                {nomeHotel &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Hotel: </h4>
                        <div className='data-field-value'>
                            <h4> {nomeHotel} </h4>
                        </div>
                    </div>
                }

                {capienza != null &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Capienza Massima: </h4>
                        <div className='data-field-value'>
                            <h4> {capienza} </h4>
                        </div>
                    </div>
                }

                {postiOccupati != null &&
                    <div className='data-field room-vacancy-field'>
                        <h4 className='data-field-key'> Posti Occupati: </h4>
                        <div className='data-field-value'>
                            <h4> {postiOccupati}/{capienza} </h4>
                        </div>
                    </div>
                }

                {(note == null || note == '') &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Note: </h4>
                        <div className='data-field-value'>
                            <h4> N/P </h4>
                        </div>
                    </div>
                }
                {note &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Note: </h4>
                        <div className='data-field-value'>
                            <h4> {note} </h4>
                        </div>
                    </div>
                }

                {collocati != null && collocati.length > 0 && 
                    <div className='data-field'>
                        <h4 className='data-field-key'> Partecipanti: </h4>
                        <div className='data-field-value'>
                            <ul>
                                {collocati.map(client => {
                                    return(
                                        <li><h4> {client.cognome} {client.nome} </h4></li>
                                    )
                                })
                                }
                            </ul>
                        </div>
                    </div>
                }

            </div>

        </div>
    
    )
}