import axios from "axios";
import { SERVER_URL } from "./consts";

export default async function CreateClient(newClient){

    return await axios.post(SERVER_URL + '/createClient', newClient).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante la creazione del partecipante!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return res.data.newClientID;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}

export async function AddBundleToDBClient(reservationData){

    return await axios.post(SERVER_URL + '/addBundleToClient', reservationData).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante l\'assegnazione del pacchetto al partecipante!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return true;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}

export async function AddClientWithBundleToRoom(pernottamentoData){

    return await axios.post(SERVER_URL + '/addClientWithBundleToRoom', pernottamentoData).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante il collocamento del partecipante!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return true;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}

export async function AddBundleAndRoomToClient(prenotazioneAndPernottamentoData){

    return await axios.post(SERVER_URL + '/addBundleAndRoomToClient', prenotazioneAndPernottamentoData).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante il collocamento del partecipante!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return true;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}

export async function CreateCollaborator(newCollaborator){

    return await axios.post(SERVER_URL + '/createCollaborator', newCollaborator).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante la creazione del collaboratore!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return res.data.newCollaboratorID;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}

export async function CreateAdmin(newAdmin){

    return await axios.post(SERVER_URL + '/createAdmin', newAdmin).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante la creazione dell\'admin!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return res.data.newAdminID;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}

export async function CreateTesseramento(newTesseramento){

    return await axios.post(SERVER_URL + '/createTesseramento', newTesseramento).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante l\'inserimento del tesserato!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return res.data.newTesseramentoID;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}

export async function UploadExcelTesseramenti(excel, setTesseramenti){

    let formData = new FormData();
    if (excel != null) formData.append("tesseramenti", excel);

    return await axios.post(SERVER_URL + '/uploadTesseramentiExcel', formData, { headers: {'Content-Type': 'multipart/form-data' }}).then(async (res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante il caricamento dell\'Excel!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        if(res.data.success){
            //Alert of errors in errorsList
            if(res.data.errorsList) {
                alert('Parte dei dati contenuti nel modulo presentano problemi!\nI dati validi sono stati inseriti, si prega di verificare gli errori indicati di seguito:\n\n' + res.data.errorsList);
            }

            //Fetch updated studenti
            return await axios.get(SERVER_URL + '/fetchTesseramenti').then((resTesseramenti) => {
                setTesseramenti(resTesseramenti.data);
                return true;
            }).catch((errTesseramenti) => {
                alert('I dati sono stati aggiornati, ma non è stato possibile recuperarli!\nRicaricare la pagina o contattare l\'amministratore del sito se il problema persiste.\n\n' + (errTesseramenti.response == null ? errTesseramenti : ('Codice errore: ' + errTesseramenti.response.status + ' - ' + errTesseramenti.response.data)));
                return false;
            });
        }

        return false;

    }).catch((err) => {
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}

export async function UploadExcelClienti(idEvento, excel, setClienti){

    let formData = new FormData();
    if (idEvento != null) formData.append("idEvento", idEvento);
    if (excel != null) formData.append("partecipanti", excel);

    return await axios.post(SERVER_URL + '/uploadClientExcel', formData, { headers: {'Content-Type': 'multipart/form-data' }}).then(async (res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante il caricamento dell\'Excel!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        if(res.data.success){
            //Alert of errors in errorsList
            if(res.data.errorsList) {
                alert('Parte dei dati contenuti nel modulo presentano problemi!\nI dati validi sono stati inseriti, si prega di verificare gli errori indicati di seguito:\n\n' + res.data.errorsList);
            }

            //Fetch updated studenti
            return await axios.get(SERVER_URL + '/fetchAdminEventClients?EventID=' + idEvento).then((resPartecipanti) => {
                setClienti(resPartecipanti.data);
                return true;
            }).catch((errPartecipanti) => {
                alert('I dati sono stati aggiornati, ma non è stato possibile recuperarli!\nRicaricare la pagina o contattare l\'amministratore del sito se il problema persiste.\n\n' + (errPartecipanti.response == null ? errPartecipanti : ('Codice errore: ' + errPartecipanti.response.status + ' - ' + errPartecipanti.response.data)));
                return false;
            });
        }

        return false;

    }).catch((err) => {
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}

export async function CreateEvent(newEvent){

    return await axios.post(SERVER_URL + '/createEvent', newEvent).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante la creazione dell\'evento!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return res.data.newEventID;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}

export async function CopyDBEvent(eventCopyData){

    return await axios.post(SERVER_URL + '/copyEvent', eventCopyData).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante la copia dell\'evento!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return res.data.newEventID;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}

export async function CreateHotel(newHotelData){

    return await axios.post(SERVER_URL + '/createHotel', newHotelData).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante la creazione dell\'hotel!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return res.data;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}

export async function CreateRoomType(newRoomType){

    return await axios.post(SERVER_URL + '/createRoomType', newRoomType).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante la creazione del tipo di camera!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return true;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}

export async function CreateRooms(newRoomsData){

    return await axios.post(SERVER_URL + '/createRooms', newRoomsData).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante la creazione delle camere!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return res.data.newRooms;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}

export async function CreateBundle(newBundleData){

    return await axios.post(SERVER_URL + '/createBundle', newBundleData).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante la creazione del pacchetto!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return res.data.newBundleID;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}

