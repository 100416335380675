import React from 'react'
import { FiMinus, FiPlus } from 'react-icons/fi';

export default function Hotel({ id, nome, sigla, offsetStanza, stelle, indirizzo, riferimento, telefono, note, stanze, UpdateHotelData, DeleteHotelPressed }) {

    const ToggleHotel = (e) => {
        let sections = e.currentTarget.parentElement.parentElement.children;
        let toggleIcons = e.currentTarget.children;

        for (let i = 0; i < sections.length; i++){
            if (sections[i].id == 'hotel-data'){
                if (sections[i].className == 'hotel-data'){
                    sections[i].className = 'hotel-data hotel-data-collapsed';
                    for (let i = 0; i < toggleIcons.length; i++){
                        if (toggleIcons[i].className.baseVal == 'plus'){
                            toggleIcons[i].style.display = 'block';
                        } else {
                            toggleIcons[i].style.display = 'none';
                        }
                    }
                } else {
                    sections[i].className = 'hotel-data';
                    for (let i = 0; i < toggleIcons.length; i++){
                        if (toggleIcons[i].className.baseVal == 'minus'){
                            toggleIcons[i].style.display = 'block';
                        } else {
                            toggleIcons[i].style.display = 'none';
                        }
                    }
                }
            }
        }
    }

    return (
        <div className='hotel-div'>
            <div className='toggle-hotel-div'> <button className='toggle-button' onClick={e => ToggleHotel(e)}> <FiPlus className='plus' /> <FiMinus className='minus' /> </button> </div>
            <div class='hotel-name'>
                <h3>{nome} {sigla ? (' (' + sigla + ')') : ''}</h3>
                <div class='top-divider' />
            </div>

            <div id='hotel-data' class='hotel-data hotel-data-collapsed'>

                <div className='data-field hotel-free-rooms-field'>
                    <h4 className='data-field-key'> Camere libere: </h4>
                        <div className='data-field-value'>
                            <h4> {stanze.filter(stanza => stanza.idHotel == id && !stanza.piena).length}/{stanze.filter(stanza => stanza.idHotel == id).length} </h4>
                        </div>
                </div>

                {sigla != null && sigla != '' &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Sigla: </h4>
                        <div className='data-field-value'>
                            <h4> {sigla} </h4>
                        </div>
                    </div>
                }

                {(sigla == null || sigla == '') &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Sigla: </h4>
                        <div className='data-field-value'>
                            <h4> N/P </h4>
                        </div>
                    </div>
                }

                {offsetStanza != null &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Camere: </h4>
                        <div className='data-field-value'>
                            <h4> {offsetStanza === 0 ? '1-300' : ((offsetStanza + 1) + '-' + (offsetStanza + 100))} </h4>
                        </div>
                    </div>
                }

                {stelle != null && stelle != '' &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Stelle: </h4>
                        <div className='data-field-value'>
                            <h4> {stelle} </h4>
                        </div>
                    </div>
                }

                {indirizzo != null && indirizzo != '' &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Indirizzo: </h4>
                        <div className='data-field-value'>
                            <h4> {indirizzo} </h4>
                        </div>
                    </div>
                }

                {riferimento != null && riferimento != '' &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Riferimento: </h4>
                        <div className='data-field-value'>
                            <h4> {riferimento} </h4>
                        </div>
                    </div>
                }

                {telefono != null && telefono != '' &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Telefono: </h4>
                        <div className='data-field-value'>
                            <h4> {telefono} </h4>
                        </div>
                    </div>
                }

                {(note == null || note == '') &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Note: </h4>
                        <div className='data-field-value'>
                            <h4> N/P </h4>
                        </div>
                    </div>
                }

                {note &&
                    <div className='data-field'>
                        <h4 className='data-field-key'> Note: </h4>
                        <div className='data-field-value'>
                            <h4> {note} </h4>
                        </div>
                    </div>
                }




                <div className='bottom-divider'></div>

                <div className='hotel-commands'>
                    <a className='clickable' onClick={() => UpdateHotelData(id)} >Modifica</a>
                    <a className='clickable' onClick={() => DeleteHotelPressed(id)} >Elimina</a>
                </div>

            </div>

        </div>
    )
}
