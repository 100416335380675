import React from 'react';
import { useState } from 'react';
import { FiMinus, FiPlus } from 'react-icons/fi';
import { UpdateDBTesseramentoAttivo } from '../../../serverRequests/patchRequests';

export default function Tesseramento({id, cf, nome, cognome, dataNascita, luogoNascita, indirizzo, email, telefono, dataTesseramento, scadenzaTesseramento, tesseramentoAttivo, UpdateTesseramentoAttivo,  UpdateTesseramentoData, DeleteTesseramentoPressed, RenewTesseramentoPressed}) {
    
    const today = new Date();
    const scadenzaDate = Date.parse(scadenzaTesseramento.split('/').reverse().join('-'));

    const [queryingServer, setQueryingServer] = useState(false);

    const SetStatusLed = (status) => {
        document.getElementById('status-led').className = status ? 'status-querying' : 'status-updated';
    }


    const ToggleTesseramentoAttivo = async (e) => {
        if (queryingServer) return; //Don't do anything if we are already querying the server

        setQueryingServer(true);
        SetStatusLed(true);

        let res = await UpdateDBTesseramentoAttivo({
            idTesseramento: id,
            tesseramentoAttivo: e.target.checked
        });

        setQueryingServer(false);
        SetStatusLed(false);

        if (res) UpdateTesseramentoAttivo(id, !e.target.checked);
        //Otherwise do nothing (the error displaying is already handled by UpdateTesseramentoAttivo)
    }

    const ToggleCollaborator = (e) => {
        let sections = e.currentTarget.parentElement.parentElement.children;
        let toggleIcons = e.currentTarget.children;

        for (let i = 0; i < sections.length; i++){
            if (sections[i].id == 'collaborator-data'){
                if (sections[i].className == 'collaborator-data'){
                    sections[i].className = 'collaborator-data collaborator-data-collapsed';
                    for (let i = 0; i < toggleIcons.length; i++){
                        if (toggleIcons[i].className.baseVal == 'plus'){
                            toggleIcons[i].style.display = 'block';
                        } else {
                            toggleIcons[i].style.display = 'none';
                        }
                    }
                } else {
                    sections[i].className = 'collaborator-data';
                    for (let i = 0; i < toggleIcons.length; i++){
                        if (toggleIcons[i].className.baseVal == 'minus'){
                            toggleIcons[i].style.display = 'block';
                        } else {
                            toggleIcons[i].style.display = 'none';
                        }
                    }
                }
            }
        }
    }
    
    return (
        <div className='collaborator-div'>
            <div className='toggle-collaborator-div'> <button className='toggle-button' onClick={e => ToggleCollaborator(e)}> <FiPlus className='plus' /> <FiMinus className='minus' /> </button> </div>
            <div class='collaborator-name'>
                <h3>{cognome} {nome}</h3>
                <div class='top-divider' />
            </div>

            <div id='collaborator-data' class='collaborator-data collaborator-data-collapsed'>

                <div className='data-field'>
                    <h4 className='data-field-key'> CF: </h4>
                    <div className='data-field-value'>
                        <h4> {cf} </h4>
                    </div>
                </div>

                <div className='data-field'>
                    <h4 className='data-field-key'> Data nascita: </h4>
                    <div className='data-field-value'>
                        <h4> {dataNascita} </h4>
                    </div>
                </div>

                <div className='data-field'>
                    <h4 className='data-field-key'> Luogo nascita: </h4>
                    <div className='data-field-value'>
                        <h4> {luogoNascita} </h4>
                    </div>
                </div>

                <div className='data-field'>
                    <h4 className='data-field-key'> Indirizzo: </h4>
                    <div className='data-field-value'>
                        <h4> {indirizzo} </h4>
                    </div>
                </div>

                <div className='data-field'>
                    <h4 className='data-field-key'> Email: </h4>
                    <div className='data-field-value'>
                        <h4> {email} </h4>
                    </div>
                </div>

                <div className='data-field'>
                    <h4 className='data-field-key'> Telefono: </h4>
                    <div className='data-field-value'>
                        <h4> {telefono} </h4>
                    </div>
                </div>

                <div className='data-field'>
                    <h4 className='data-field-key'> Iscrizione: </h4>
                    <div className='data-field-value'>
                        <h4> {dataTesseramento} </h4>
                    </div>
                </div>

                <div className='data-field tesseramento-scadenza-field'>
                    <h4 className='data-field-key'> Scadenza: </h4>
                    <div className='data-field-value'>
                        <h4> {scadenzaTesseramento} </h4>
                    </div>
                </div>

                <label className='tesseramento-attivo-field'>
                    <input type='checkbox' checked={tesseramentoAttivo} disabled={!tesseramentoAttivo && scadenzaDate < today} onClick={ToggleTesseramentoAttivo} />
                    <h4> {tesseramentoAttivo? "Attivo" : (scadenzaDate > today ? "Inattivo" : "Scaduto")} </h4>
                </label>

                <div className='bottom-divider'></div>

                <div className='collaborator-commands'>
                    <a className='clickable' onClick={() => RenewTesseramentoPressed(id)}>Rinnova</a>
                    <a className='clickable' onClick={() => UpdateTesseramentoData(id)}>Modifica</a>
                    <a className='clickable' onClick={() => DeleteTesseramentoPressed(id)}>Elimina</a>
                </div>

            </div>

        </div>
    )
}

export function TesseramentoToDelete({id, cf, nome, cognome, dataNascita, luogoNascita, indirizzo, email, telefono, dataTesseramento, scadenzaTesseramento, tesseramentoAttivo, ToggleTesseramentoToDelete, tesseramentiToDeleteIDs}) {
    
    const today = new Date();
    const scadenzaDate = Date.parse(scadenzaTesseramento.split('/').reverse().join('-'));

    let toBeDeleted = false;
    for (let i = 0; i < tesseramentiToDeleteIDs.length; i++){
        if (tesseramentiToDeleteIDs[i] == id){
            toBeDeleted = true;
        }
    }

    const ToggleCollaborator = (e) => {
        let sections = e.currentTarget.parentElement.parentElement.children;
        let toggleIcons = e.currentTarget.children;

        for (let i = 0; i < sections.length; i++){
            if (sections[i].id == 'collaborator-data'){
                if (sections[i].className == 'collaborator-data'){
                    sections[i].className = 'collaborator-data collaborator-data-collapsed';
                    for (let i = 0; i < toggleIcons.length; i++){
                        if (toggleIcons[i].className.baseVal == 'plus'){
                            toggleIcons[i].style.display = 'block';
                        } else {
                            toggleIcons[i].style.display = 'none';
                        }
                    }
                } else {
                    sections[i].className = 'collaborator-data';
                    for (let i = 0; i < toggleIcons.length; i++){
                        if (toggleIcons[i].className.baseVal == 'minus'){
                            toggleIcons[i].style.display = 'block';
                        } else {
                            toggleIcons[i].style.display = 'none';
                        }
                    }
                }
            }
        }
    }
    
    return (
        <div className='collaborator-div client-to-delete'>
            <div className='multi-delete-checkbox-div'> <input type='checkbox' checked={toBeDeleted} onClick={() => ToggleTesseramentoToDelete(id)} /> </div>
            <div className='toggle-collaborator-div'> <button className='toggle-button' onClick={e => ToggleCollaborator(e)}> <FiPlus className='plus' /> <FiMinus className='minus' /> </button> </div>
            <div class='collaborator-name'>
                <h3>{cognome} {nome}</h3>
                <div class='top-divider' />
            </div>

            <div id='collaborator-data' class='collaborator-data collaborator-data-collapsed'>

                <div className='data-field'>
                    <h4 className='data-field-key'> CF: </h4>
                    <div className='data-field-value'>
                        <h4> {cf} </h4>
                    </div>
                </div>

                <div className='data-field'>
                    <h4 className='data-field-key'> Data nascita: </h4>
                    <div className='data-field-value'>
                        <h4> {dataNascita} </h4>
                    </div>
                </div>

                <div className='data-field'>
                    <h4 className='data-field-key'> Luogo nascita: </h4>
                    <div className='data-field-value'>
                        <h4> {luogoNascita} </h4>
                    </div>
                </div>

                <div className='data-field'>
                    <h4 className='data-field-key'> Indirizzo: </h4>
                    <div className='data-field-value'>
                        <h4> {indirizzo} </h4>
                    </div>
                </div>

                <div className='data-field'>
                    <h4 className='data-field-key'> Email: </h4>
                    <div className='data-field-value'>
                        <h4> {email} </h4>
                    </div>
                </div>

                <div className='data-field'>
                    <h4 className='data-field-key'> Telefono: </h4>
                    <div className='data-field-value'>
                        <h4> {telefono} </h4>
                    </div>
                </div>

                <div className='data-field'>
                    <h4 className='data-field-key'> Iscrizione: </h4>
                    <div className='data-field-value'>
                        <h4> {dataTesseramento} </h4>
                    </div>
                </div>

                <div className='data-field tesseramento-scadenza-field'>
                    <h4 className='data-field-key'> Scadenza: </h4>
                    <div className='data-field-value'>
                        <h4> {scadenzaTesseramento} </h4>
                    </div>
                </div>

                <label className='tesseramento-attivo-field'>
                    <input type='checkbox' checked={tesseramentoAttivo} disabled={true} onClick={null} />
                    <h4> {tesseramentoAttivo? "Attivo" : (scadenzaDate > today ? "Inattivo" : "Scaduto")} </h4>
                </label>

                <div className='bottom-divider'></div>
            </div>

        </div>
    )
}

export function TesseramentoToRenew({id, cf, nome, cognome, dataNascita, luogoNascita, indirizzo, email, telefono, dataTesseramento, scadenzaTesseramento, tesseramentoAttivo, ToggleTesseramentoToRenew, tesseramentiToRenewIDs}) {
    
    const today = new Date();
    const scadenzaDate = Date.parse(scadenzaTesseramento.split('/').reverse().join('-'));

    let toBeRenewed = false;
    for (let i = 0; i < tesseramentiToRenewIDs.length; i++){
        if (tesseramentiToRenewIDs[i] == id){
            toBeRenewed = true;
        }
    }

    const ToggleCollaborator = (e) => {
        let sections = e.currentTarget.parentElement.parentElement.children;
        let toggleIcons = e.currentTarget.children;

        for (let i = 0; i < sections.length; i++){
            if (sections[i].id == 'collaborator-data'){
                if (sections[i].className == 'collaborator-data'){
                    sections[i].className = 'collaborator-data collaborator-data-collapsed';
                    for (let i = 0; i < toggleIcons.length; i++){
                        if (toggleIcons[i].className.baseVal == 'plus'){
                            toggleIcons[i].style.display = 'block';
                        } else {
                            toggleIcons[i].style.display = 'none';
                        }
                    }
                } else {
                    sections[i].className = 'collaborator-data';
                    for (let i = 0; i < toggleIcons.length; i++){
                        if (toggleIcons[i].className.baseVal == 'minus'){
                            toggleIcons[i].style.display = 'block';
                        } else {
                            toggleIcons[i].style.display = 'none';
                        }
                    }
                }
            }
        }
    }
    
    return (
        <div className='collaborator-div client-to-delete'>
            <div className='multi-delete-checkbox-div'> <input type='checkbox' checked={toBeRenewed} onClick={() => ToggleTesseramentoToRenew(id)} /> </div>
            <div className='toggle-collaborator-div'> <button className='toggle-button' onClick={e => ToggleCollaborator(e)}> <FiPlus className='plus' /> <FiMinus className='minus' /> </button> </div>
            <div class='collaborator-name'>
                <h3>{cognome} {nome}</h3>
                <div class='top-divider' />
            </div>

            <div id='collaborator-data' class='collaborator-data collaborator-data-collapsed'>

                <div className='data-field'>
                    <h4 className='data-field-key'> CF: </h4>
                    <div className='data-field-value'>
                        <h4> {cf} </h4>
                    </div>
                </div>

                <div className='data-field'>
                    <h4 className='data-field-key'> Data nascita: </h4>
                    <div className='data-field-value'>
                        <h4> {dataNascita} </h4>
                    </div>
                </div>

                <div className='data-field'>
                    <h4 className='data-field-key'> Luogo nascita: </h4>
                    <div className='data-field-value'>
                        <h4> {luogoNascita} </h4>
                    </div>
                </div>

                <div className='data-field'>
                    <h4 className='data-field-key'> Indirizzo: </h4>
                    <div className='data-field-value'>
                        <h4> {indirizzo} </h4>
                    </div>
                </div>

                <div className='data-field'>
                    <h4 className='data-field-key'> Email: </h4>
                    <div className='data-field-value'>
                        <h4> {email} </h4>
                    </div>
                </div>

                <div className='data-field'>
                    <h4 className='data-field-key'> Telefono: </h4>
                    <div className='data-field-value'>
                        <h4> {telefono} </h4>
                    </div>
                </div>

                <div className='data-field'>
                    <h4 className='data-field-key'> Iscrizione: </h4>
                    <div className='data-field-value'>
                        <h4> {dataTesseramento} </h4>
                    </div>
                </div>

                <div className='data-field tesseramento-scadenza-field'>
                    <h4 className='data-field-key'> Scadenza: </h4>
                    <div className='data-field-value'>
                        <h4> {scadenzaTesseramento} </h4>
                    </div>
                </div>

                <label className='tesseramento-attivo-field'>
                    <input type='checkbox' checked={tesseramentoAttivo} disabled={true} onClick={null} />
                    <h4> {tesseramentoAttivo? "Attivo" : (scadenzaDate > today ? "Inattivo" : "Scaduto")} </h4>
                </label>

                <div className='bottom-divider'></div>
            </div>

        </div>
    )
}