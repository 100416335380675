import React, { useState, useEffect, useContext } from 'react';
import EventsContext from '../../contexts/eventsContext';
import SessionDataContext from '../../contexts/sessionData';
import '../../index.css'
import FetchCollaboratorEvents from '../../serverRequests/fetchRequest';
import CollaboratorHomeCommands from './components/collaboratorHomeCommands'
import Event from './components/event'
import ClientsContext from '../../contexts/clientsContext';
import RoomsContext from '../../contexts/roomsContext';
import BundlesContext from '../../contexts/bundlesContext';
import RoomTypesContext from '../../contexts/roomTypesContext';

export default function CollaboratorHome() {
    const [isLoading, setIsLoading] = useState(true);

    const {sessionData} = useContext(SessionDataContext);
    const {events, setEvents} = useContext(EventsContext);

    const {setClienti} = useContext(ClientsContext);
    const {setStanze} = useContext(RoomsContext);
    const {setPacchetti} = useContext(BundlesContext);
    const {setRoomTypes} = useContext(RoomTypesContext);

    //FOR TESTING
    // const [events, setEvents] = useState([
    //     {id: 1, nome: 'Sagra della salsicciola e poi altro testo per la seconda riga', luogo: 'Sotto il ponte di Baracca dove ci sta Piero che fa gli stronzoni in via dei Fantacannonieri di Macedonia', dataInizio: '01/03/2021', dataFine:'20/05/2021', descrizione:'Non avevo voglia di scrivere granchè nella descrizione, fatemi causa dai su provateci voglio proprio vedervici che tanto alla fine ho scritto un botto qua dentro.'},
    //     {id: 2, nome: 'Evento figo per davvero', luogo:'A casa del Const', dataInizio:'10/05/2021', dataFine:'20/05/2021', descrizione:'Ci spacchiamo di pasta al tonno, musica e ballo E BASTA'},
    //     {id: 3, nome: 'Evento figo per davvero', luogo:'A casa del Const', dataInizio:'10/05/2021', dataFine:'20/05/2021', descrizione:''},
    //     {id: 4, nome: 'Evento figo per davvero', luogo:'A casa del Const', dataInizio:'10/05/2021', dataFine:'20/05/2021'},
    //     {id: 5, nome: 'Evento figo per davvero', luogo:'A casa del Const', dataInizio:'10/05/2021', dataFine:'20/05/2021', descrizione:'Ci spacchiamo di pasta al tonno, musica e ballo E BASTA'},
    //     {id: 6, nome: 'Evento figo per davvero', luogo:'A casa del Const', dataInizio:'10/05/2021', dataFine:'20/05/2021', descrizione:'Ci spacchiamo di pasta al tonno, musica e ballo E BASTA'},
    //     {id: 7, nome: 'Evento figo per davvero', luogo:'A casa del Const', dataInizio:'10/05/2021', dataFine:'20/05/2021', descrizione:'Ci spacchiamo di pasta al tonno, musica e ballo E BASTA'}
    // ]);

    const [searching, setSearching] = useState(false);
    const [searchResultsIDs, setSearchResultsIDs] = useState([]);


    const sortEvents = (order) => {
        //setOrderBy(order);
        let sortedEvents = [...events];

        if(order === 'nomeAZ'){

            sortedEvents.sort((a, b) => {
                let keyA = a.nome.toLowerCase(), keyB = b.nome.toLowerCase();
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

        }
        else if (order === 'nomeZA'){

            sortedEvents.sort((a, b) => {
                let keyA = a.nome.toLowerCase(), keyB = b.nome.toLowerCase();
                if (keyA < keyB) return 1;
                if (keyA > keyB) return -1;
                return 0;
            });

        }
        else if (order === 'dataInverso'){

            sortedEvents.sort((a, b) => {
                let keyA = new Date(a.dataInizio.split('/').reverse().join('-')).getTime(), keyB = new Date(b.dataInizio.split('/').reverse().join('-')).getTime();
                if (keyA < keyB) return 1;
                if (keyA > keyB) return -1;
                return 0;
            });

        }
        else { //Defaults to orderBy = 'dataCronologico'

            sortedEvents.sort((a, b) => {
                let keyA = new Date(a.dataInizio.split('/').reverse().join('-')).getTime(), keyB = new Date(b.dataInizio.split('/').reverse().join('-')).getTime();
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
            });

        }

        setEvents([...sortedEvents]);
        
    }

    const findEvents = (findBy, searchTerm) => {

        if (searchTerm == ''){
            //do nothing
        }
        else{
            setSearching(true);
            let found = [];
            switch (findBy){
                case 'Nome':
                    events.forEach(event => {
                        if(event.nome.toLowerCase().includes(searchTerm.toLowerCase())) found.push(event.id);
                    })
                    break;
                case 'Data esatta':
                    events.forEach(event => {
                        if(new Date(event.dataInizio.split('/').reverse().join('-')).getTime() == new Date(searchTerm).getTime()) found.push(event.id);
                    })
                    break;
                case 'Data successiva al':
                    events.forEach(event => {
                        if(new Date(event.dataInizio.split('/').reverse().join('-')).getTime() >= new Date(searchTerm).getTime()) found.push(event.id);
                    })
                    break;
                case 'Data precedente al':
                    events.forEach(event => {
                        if(new Date(event.dataInizio.split('/').reverse().join('-')).getTime() <= new Date(searchTerm).getTime()) found.push(event.id);
                    })
                    break;
            }

            setSearchResultsIDs(found);
        }

    }

    const CancelSearch = () => {
        setSearching(false);
    }

    useEffect(() => {
        setEvents([]);
        setClienti([]);
        setStanze([]);
        setPacchetti([]);
        setRoomTypes([]);

        FetchCollaboratorEvents(setIsLoading, setEvents);
        
    }, [])

    
    return(
        <div className='container-home'>
            <CollaboratorHomeCommands sortEvents={sortEvents} findEvents={findEvents}/>

            {isLoading &&
                <div className='content-home'>
                    <h2 className='loading-message'>Caricamento...</h2>
                </div>
            }

            {!isLoading && events.length > 0 &&
                <div className='content-home'>

                    {searching &&
                        <div className='found-header'>
                            <h4 className='found-header-text'>Risultati ricerca:</h4>
                            <a onClick={CancelSearch}>Annulla</a>
                        </div>
                    }

                    {searching &&
                    events.map( event => {
                        if (searchResultsIDs.includes(event.id)){
                            return(
                                <Event key={event.id} {...event} />
                            )
                        }
                    })
                    }

                    {searchResultsIDs.length == 0 && searching &&
                        <h4 className='no-results-text'>Nessun risultato!</h4>
                    }

                    {searching &&
                        <div className='divider' />
                    }

                    {searching &&
                        <h4 className='found-header-text'>Tutti gli eventi:</h4>
                    }

                    {events.map( event => {
                        return(
                            <Event key={event.id} {...event} />
                        )
                    })}

                </div>
            }

            {!isLoading && events.length <= 0 &&
                <div className='content-home'>
                    <div className='found-header'>
                        <h4 className='found-header-text'>Nessun evento in piano!</h4>
                    </div>
                </div>
            }
            
        </div>
    )

}
