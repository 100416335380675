import { TextareaAutosize, TextField } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import React from 'react'
import { AiOutlineClose } from 'react-icons/ai';
import * as yup from 'yup'
import { CreateHotel } from '../../../../serverRequests/postRequests'
import { UpdateDBHotelData } from '../../../../serverRequests/putRequests'

const validationSchema = yup.object({
    nome: yup
    .string('Nome deve essere una stringa')
    .required('Nome obbligatorio')
    .max(100, 'Massimo 100 caratteri'),
    sigla: yup
    .string('Sigla deve essere una stringa')
    .nullable(true)
    .length(2, 'Sigla deve essere 2 lettere'),
    stelle: yup
    .number('Stelle deve essere un numero')
    .nullable(true)
    .min(1, 'Minimo 1 stella')
    .max(5, 'Massimo 5 stelle'),
    indirizzo: yup
    .string('Indirizzo deve essere una stringa')
    .nullable(true)
    .max(255, 'Massimo 255 caratteri'),
    riferimento: yup
    .string('Riferimento deve essere una stringa')
    .nullable(true)
    .max(100, 'Massimo 100 caratteri'),
    telefono: yup
    .string('Telefono deve essere una stringa')
    .nullable(true)
    .max(15, 'Massimo 15 caratteri'),
    note: yup
    .string('Note deve essere una stringa')
    .nullable(true)
    .max(255, 'Massimo 255 caratteri'),
});

export default function NewHotel({ setCurrentOverlayPage, CreateNewHotel, SetStatusLed }) {

    const CloseOverlay = () => {
        setCurrentOverlayPage('none');
    }

    const HandleSubmit = async (data, {setSubmitting}) => {

        setSubmitting(true);
        SetStatusLed(true);

        let newHotelData = {
            nomeHotel: data.nome == '' ? null : data.nome,
            siglaHotel: data.sigla == '' ? null : data.sigla,
            indirizzo: data.indirizzo == '' ? null : data.indirizzo,
            stelle: data.stelle == '' ? null : data.stelle,
            riferimento: data.riferimento == '' ? null : data.riferimento,
            telefono: data.telefono == '' ? null : data.telefono,
            note: data.note == '' ? null : data.note,
            idEvento: localStorage.getItem('currEventID')
        }
        
        let { newHotelID, newHotelOffsetStanza } = await CreateHotel(newHotelData);
        
        setSubmitting(false);
        SetStatusLed(false);


        if (newHotelID) {
            data.id = newHotelID;
            data.offsetStanza = newHotelOffsetStanza;
            CreateNewHotel(data);
            CloseOverlay();
        }
        //Otherwise do nothing (the error displaying is already handled by CreateHotel)

    }

    return (
        <div id='new-hotel-overlay' className='overlay-div'>
            <div className='close-overlay clickable' onClick={CloseOverlay} > <AiOutlineClose /> </div>

            <div className='inner-overlay-div'>
                <h2> Nuovo Hotel </h2>
                <div className='overlay-top-divider' />
                
                <div className='overlay-content-div'>
                    <Formik
                        initialValues={{
                            nome:'',
                            sigla:'',
                            stelle: '',
                            indirizzo:'',
                            riferimento:'',
                            telefono:'',
                            note:''
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(data, {setSubmitting}) => HandleSubmit(data, {setSubmitting})}
                    >

                    {({ errors, touched, isSubmitting }) => (
                        <Form autoComplete='off'>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Nome: </h4><Field className='overlay-text-input' type='text' name='nome' as={TextField} autoFocus/> {touched.nome && errors.nome ? <a className='validation'>{errors.nome}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Sigla: </h4><Field className='overlay-text-input' type='text' name='sigla' as={TextField}/> {touched.sigla && errors.sigla ? <a className='validation'>{errors.sigla}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Stelle: </h4><Field className='overlay-text-input' type='number' name='stelle' as={TextField} /> {touched.stelle && errors.stelle ? <a className='validation'>{errors.stelle}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Indirizzo: </h4><Field className='overlay-text-input' type='text' name='indirizzo' as={TextField} /> {touched.indirizzo && errors.indirizzo ? <a className='validation'>{errors.indirizzo}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Riferimento: </h4><Field className='overlay-text-input' type='text' name='riferimento' as={TextField} /> {touched.riferimento && errors.riferimento ? <a className='validation'>{errors.riferimento}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Telefono: </h4><Field className='overlay-text-input' type='text' name='telefono' as={TextField} /> {touched.telefono && errors.telefono ? <a className='validation'>{errors.telefono}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Note: </h4><Field className='overlay-date-input' type='text' name='note' as={TextareaAutosize} />{touched.note && errors.note ? <a className='validation'>{errors.note}</a> : ''}</div>
                            <button disabled={isSubmitting} type='submit'> Conferma </button>
                        </Form>
                    )}

                    </Formik>
                </div>
            </div>

        </div>
    )
}

export function ModifyHotelData({ setCurrentOverlayPage, ModifyHotel, currentlyEditedHotel, SetStatusLed }) {

    const CloseOverlay = () => {
        setCurrentOverlayPage('none');
    }

    const HandleSubmit = async (data, {setSubmitting}) => {

        setSubmitting(true);
        SetStatusLed(true);

        let updatedHotelData = {
            idHotel: currentlyEditedHotel.id,
            nomeHotel: data.nome == '' ? null : data.nome,
            siglaHotel: data.sigla == '' ? null : data.sigla,
            indirizzo: data.indirizzo == '' ? null : data.indirizzo,
            stelle: data.stelle == '' ? null : data.stelle,
            riferimento: data.riferimento == '' ? null : data.riferimento,
            telefono: data.telefono == '' ? null : data.telefono,
            note: data.note == '' ? null : data.note
        }
        
        let res = await UpdateDBHotelData(updatedHotelData);
        
        setSubmitting(false);
        SetStatusLed(false);


        if (res) {
            updatedHotelData.id = updatedHotelData.idHotel;
            updatedHotelData.nome = updatedHotelData.nomeHotel;
            updatedHotelData.sigla = updatedHotelData.siglaHotel;
            delete updatedHotelData.idHotel;
            delete updatedHotelData.nomeHotel;
            delete updatedHotelData.siglaHotel;
            ModifyHotel(updatedHotelData);
            CloseOverlay();
        }
        //Otherwise do nothing (the error displaying is already handled by UpdateDBHotelData)

    }

    return (
        <div id='modify-hotel-data-overlay' className='overlay-div'>
            <div className='close-overlay clickable' onClick={CloseOverlay} > <AiOutlineClose /> </div>

            <div className='inner-overlay-div'>
                <h2> Modifica Hotel </h2>
                <div className='overlay-top-divider' />
                
                <div className='overlay-content-div'>
                    <Formik
                        initialValues={{
                            nome: currentlyEditedHotel.nome,
                            sigla: currentlyEditedHotel.sigla,
                            stelle: currentlyEditedHotel.stelle == null ? '' : currentlyEditedHotel.stelle,
                            indirizzo: currentlyEditedHotel.indirizzo == null ? '' : currentlyEditedHotel.indirizzo,
                            riferimento: currentlyEditedHotel.riferimento == null ? '' : currentlyEditedHotel.riferimento,
                            telefono: currentlyEditedHotel.telefono == null ? '' : currentlyEditedHotel.telefono,
                            note: currentlyEditedHotel.note == null ? '' : currentlyEditedHotel.note
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(data, {setSubmitting}) => HandleSubmit(data, {setSubmitting})}
                    >

                    {({ errors, touched, isSubmitting }) => (
                        <Form autoComplete='off'>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Nome: </h4><Field className='overlay-text-input' type='text' name='nome' as={TextField} autoFocus/> {touched.nome && errors.nome ? <a className='validation'>{errors.nome}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Sigla: </h4><Field className='overlay-text-input' type='text' name='sigla' as={TextField}/> {touched.sigla && errors.sigla ? <a className='validation'>{errors.sigla}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Stelle: </h4><Field className='overlay-text-input' type='number' name='stelle' as={TextField} /> {touched.stelle && errors.stelle ? <a className='validation'>{errors.stelle}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Indirizzo: </h4><Field className='overlay-text-input' type='text' name='indirizzo' as={TextField} /> {touched.indirizzo && errors.indirizzo ? <a className='validation'>{errors.indirizzo}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Riferimento: </h4><Field className='overlay-text-input' type='text' name='riferimento' as={TextField} /> {touched.riferimento && errors.riferimento ? <a className='validation'>{errors.riferimento}</a> : ''} </div>
                            <div className='overlay-input-field'> <h4 className='overlay-input-key'>Telefono: </h4><Field className='overlay-text-input' type='text' name='telefono' as={TextField} /> {touched.telefono && errors.telefono ? <a className='validation'>{errors.telefono}</a> : ''} </div>
                        <div className='overlay-input-field'> <h4 className='overlay-input-key'>Note: </h4><Field className='overlay-date-input' type='text' name='note' as={TextareaAutosize} />{touched.note && errors.note ? <a className='validation'>{errors.note}</a> : ''}</div>
                            <button disabled={isSubmitting} type='submit'> Conferma </button>
                        </Form>
                    )}

                    </Formik>
                </div>
            </div>

        </div>
    )
}
