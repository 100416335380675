import axios from 'axios';
import { SERVER_URL } from './consts';

export default function FetchCollaboratorEvents(setIsLoading, setEvents) {
    
    axios.get(SERVER_URL + '/fetchCollaboratorEvents').then((res) => {

    setEvents(res.data);
    setIsLoading(false);
    return true;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRicaricare la pagina o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        setIsLoading(false);
        return false;
    });
    
}

export function VerifyCollaboratorEvents(eventID, setEvents, setCurrEventData, setIsLoading, history, setClienti, setStanze, setPacchetti, setRoomTypes, setTesseramenti){

    axios.get(SERVER_URL + '/fetchCollaboratorEvents').then((res) => {

    let eventPermission = false;

    setEvents(res.data);

    for(let i = 0 ; i < res.data.length; i++){
        if (eventID == res.data[i].id){ //If collaborator is allowed to see the event, load the events data and set current event data
            eventPermission = true;
            setCurrEventData(res.data[i]);
            return FetchCollaboratorEventData(eventID, setIsLoading, setClienti, setStanze, setPacchetti, setRoomTypes, setTesseramenti);
            break; //This is to avoid the for continuing while the async fetch function runs, we can stop it now since we already found the event (IT ISN'T ASYNC ATM, BUT THIS MAKES SURE WE COULD USE AN ASYNC FUNC)
        }
    }

    //If collaborator is not allowed to see this event, delete localStorage and return them to ./login
    if (!eventPermission){ //This check is necessary so that if we find an event the collaborator can see, we can fetch the data asynchronously (IT ISN'T ASYNC ATM, BUT THIS MAKES SURE WE COULD USE AN ASYNC FUNC)
        localStorage.removeItem('currEventID');
        history.replace('/login');
        setIsLoading(false);
        return false;
    }

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRicaricare la pagina o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        setIsLoading(false);
        return false;
    });
}

export function FetchCollaboratorEventData(eventID, setIsLoading, setClienti, setStanze, setPacchetti, setRoomTypes, setTesseramenti) {

    Promise.all([
        axios.get(SERVER_URL + '/fetchCollaboratorEventClients?EventID=' + eventID),
        axios.get(SERVER_URL + '/fetchCollaboratorEventRooms?EventID=' + eventID),
        axios.get(SERVER_URL + '/fetchEventBundles?EventID=' + eventID),
        axios.get(SERVER_URL + '/fetchRoomTypes'),
        axios.get(SERVER_URL + '/fetchTesseramenti')
    ]).then((res) => {
    
    setClienti(res[0].data);
    setStanze(res[1].data);
    setPacchetti(res[2].data);
    setRoomTypes(res[3].data);
    setTesseramenti(res[4].data);

    setIsLoading(false);
    return true;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRicaricare la pagina o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        setIsLoading(false);
        return false;
    });
    
}

export function FetchAdminHomeData(setEvents, setCollaborators, setAdmins, setTesseramenti, setIsLoading) {

    Promise.all([
        axios.get(SERVER_URL + '/fetchAdminEvents'),
        axios.get(SERVER_URL + '/fetchAllCollaborators'),
        axios.get(SERVER_URL + '/fetchAllAdmins'),
        axios.get(SERVER_URL + '/fetchTesseramenti')
    ]).then((res) => {

    setEvents(res[0].data);
    setCollaborators(res[1].data);
    setAdmins(res[2].data);
    setTesseramenti(res[3].data);

    setIsLoading(false);
    return true;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRicaricare la pagina o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        setIsLoading(false);
        return false;
    });
    
}

export function FetchAdminEventData(eventID, setIsLoading, setCollaborators, setHotels, setStanze, setRoomTypes, setPacchetti, setClienti, setPaymentMethods, setPaymentStates, setDestinatariAcconto, setReservations, setTesseramenti) {

    Promise.all([
        axios.get(SERVER_URL + '/fetchEventCollaboratorsAndAdmins?EventID=' + eventID),
        axios.get(SERVER_URL + '/fetchEventHotels?EventID=' + eventID),
        axios.get(SERVER_URL + '/fetchAdminEventRooms?EventID=' + eventID),
        axios.get(SERVER_URL + '/fetchRoomTypes'),
        axios.get(SERVER_URL + '/fetchEventBundles?EventID=' + eventID),
        axios.get(SERVER_URL + '/fetchAdminEventClients?EventID=' + eventID),
        axios.get(SERVER_URL + '/fetchPaymentMethods'),
        axios.get(SERVER_URL + '/fetchPaymentStates'),
        axios.get(SERVER_URL + '/fetchDestinatariAcconto'),
        axios.get(SERVER_URL + '/fetchEventReservations?EventID=' + eventID),
        axios.get(SERVER_URL + '/fetchTesseramenti')
    ]).then((res) => {

    setCollaborators(res[0].data);
    setHotels(res[1].data);
    setStanze(res[2].data);
    setRoomTypes(res[3].data);
    setPacchetti(res[4].data);
    setClienti(res[5].data);
    setPaymentMethods(res[6].data);
    setPaymentStates(res[7].data);
    setDestinatariAcconto(res[8].data)
    setReservations(res[9].data);
    setTesseramenti(res[10].data);

    setIsLoading(false);
    return true;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRicaricare la pagina o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        setIsLoading(false);
        return false;
    });

}

export function VerifyAdminEvents(AdminID, eventID, setEvents, setCurrEventData, setIsLoading, history, setCollaborators, setHotels, setStanze, setRoomTypes, setPacchetti, setClienti, setPaymentMethods, setPaymentStates, setDestinatariAcconto, setReservations, setTesseramenti){

    axios.get(SERVER_URL + '/fetchAdminEvents?AdminID=' + AdminID).then((res) => {

    let eventPermission = false;

    setEvents(res.data);

    for(let i = 0 ; i < res.data.length; i++){
        if (eventID == res.data[i].id){ //If collaborator is allowed to see the event, load the events data and set current event data
            eventPermission = true;
            setCurrEventData(res.data[i]);
            return FetchAdminEventData(eventID, setIsLoading, setCollaborators, setHotels, setStanze, setRoomTypes, setPacchetti, setClienti, setPaymentMethods, setPaymentStates, setDestinatariAcconto, setReservations, setTesseramenti);
            break; //This is to avoid the for continuing while the async fetch function runs, we can stop it now since we already found the event (IT ISN'T ASYNC ATM, BUT THIS MAKES SURE WE COULD USE AN ASYNC FUNC)
        }
    }

    //If collaborator is not allowed to see this event, delete localStorage and return them to ./login
    if (!eventPermission){ //This check is necessary so that if we find an event the collaborator can see, we can fetch the data asynchronously (IT ISN'T ASYNC ATM, BUT THIS MAKES SURE WE COULD USE AN ASYNC FUNC)
        localStorage.removeItem('currEventID');
        history.replace('/login');
        setIsLoading(false);
        return false;
    }

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRicaricare la pagina o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        setIsLoading(false);
        return false;
    });
}
