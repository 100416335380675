import React from 'react'
import { FaAngleRight } from 'react-icons/fa'
import { InfoAdminClient } from './client'
import { InfoAdminRoom } from './room';

export default function AdminEventInfo( props ) {

    const ExpandCollapseInfoDiv = () => {
        // let page = document.getElementById('container-collaborator-event');
        let infoDiv = document.getElementById('admin-event-info-div');

        // if (infoDiv.className === 'info-div' ){
        //     infoDiv.className = 'info-div collapsed';
        // }
        // else if (infoDiv.className === 'info-div admin-rooms-info-div'){
        //     infoDiv.className = 'info-div admin-rooms-info-div collapsed';
        // }
        // else if (infoDiv.className === 'info-div collapsed'){
        //     infoDiv.className = 'info-div';
        // }
        // else if (infoDiv.className === 'info-div admin-rooms-info-div collapsed'){
        //     infoDiv.className = 'info-div admin-rooms-info-div';
        // }

        if (!infoDiv.className.includes(' collapsed')){
            infoDiv.className += ' collapsed';
        } else {
            infoDiv.className = infoDiv.className.replace(' collapsed', '');
        }

    }

    return (
        <div id='admin-event-info-div' className={props.activePage==='rooms' ? 'info-div admin-rooms-info-div' : 'info-div'} >
            <div id='toggle-info-div' className='toggle-info-div'> <FaAngleRight onClick={ExpandCollapseInfoDiv}/> </div>

            {props.activePage==='rooms' &&
                <div className='inner-info-div'>
                    <div className='admin-rooms-info-title-div'>
                        <h3 className='admin-rooms-info-title'>Stato camere:</h3>

                        <h5 className='admin-rooms-info-body'>Totali: {props.stanze.length}</h5>
                        <h5 className='admin-rooms-info-body'>Piene: {props.stanze.filter(stanza => stanza.piena).length}</h5>
                        <h5 className='admin-rooms-info-body'>Parziali: {props.stanze.filter(stanza => !stanza.piena && (stanza.collocati ?? []).length > 0).length}</h5>
                        <h5 className='admin-rooms-info-body'>Vuote: {props.stanze.filter(stanza => (stanza.collocati ?? []).length <= 0).length}</h5>
                        
                        <br/>

                        {(props.roomTypes ?? []).map(roomType =>
                            props.stanze.filter(stanza => stanza.tipo == roomType.tipo).length > 0 ?
                            <>
                            <h3>{roomType.tipo}</h3>
                            <h5 className='admin-rooms-info-body'>Totali: {props.stanze.filter(stanza => stanza.tipo == roomType.tipo).length}</h5>
                            <h5 className='admin-rooms-info-body'>Piene: {props.stanze.filter(stanza => stanza.tipo == roomType.tipo && stanza.piena).length}</h5>
                            <h5 className='admin-rooms-info-body'>Parziali: {props.stanze.filter(stanza => stanza.tipo == roomType.tipo && !stanza.piena && (stanza.collocati ?? []).length > 0).length}</h5>
                            <h5 className='admin-rooms-info-body'>Vuote: {props.stanze.filter(stanza => stanza.tipo == roomType.tipo && (stanza.collocati ?? []).length <= 0).length}</h5>
                            <br/>
                            </>
                            :
                            <></>
                        )}
                    </div>

                {props.hotels.sort((a, b) => {
                    let keyA = a.nome, keyB = b.nome;
                    if (keyA < keyB) return -1;
                    if (keyA > keyB) return 1;
                    return 0;
                }).map(hotel => {
                    return(
                        <div className='admin-rooms-info-hotel-div'>
                            <h3 className='admin-rooms-info-subtitle'>{hotel.nome} {hotel.offsetStanza == null ? '' : '(' + (hotel.offsetStanza + 1) + '-' + (hotel.offsetStanza === 0 ? 300 : hotel.offsetStanza + 100) + ')'}</h3>

                            <h5 className='admin-rooms-info-body'>Totali: {props.stanze.filter(stanza => stanza.idHotel == hotel.id).length}</h5>
                            <h5 className='admin-rooms-info-body'>Piene: {props.stanze.filter(stanza => stanza.idHotel == hotel.id && stanza.piena).length}</h5>
                            <h5 className='admin-rooms-info-body'>Parziali: {props.stanze.filter(stanza => stanza.idHotel == hotel.id && !stanza.piena && (stanza.collocati ?? []).length > 0).length}</h5>
                            <h5 className='admin-rooms-info-body'>Vuote: {props.stanze.filter(stanza => stanza.idHotel == hotel.id && (stanza.collocati ?? []).length <= 0).length}</h5>
                            
                            <br/>

                            {(props.roomTypes ?? []).map(roomType =>
                                props.stanze.filter(stanza => stanza.idHotel == hotel.id && stanza.tipo == roomType.tipo).length > 0 ?
                                <>
                                <h3>{roomType.tipo}</h3>
                                <h5 className='admin-rooms-info-body'>Totali: {props.stanze.filter(stanza => stanza.idHotel == hotel.id && stanza.tipo == roomType.tipo).length}</h5>
                                <h5 className='admin-rooms-info-body'>Piene: {props.stanze.filter(stanza => stanza.idHotel == hotel.id && stanza.tipo == roomType.tipo && stanza.piena).length}</h5>
                                <h5 className='admin-rooms-info-body'>Parziali: {props.stanze.filter(stanza => stanza.idHotel == hotel.id && stanza.tipo == roomType.tipo && !stanza.piena && (stanza.collocati ?? []).length > 0).length}</h5>
                                <h5 className='admin-rooms-info-body'>Vuote: {props.stanze.filter(stanza => stanza.idHotel == hotel.id && stanza.tipo == roomType.tipo && (stanza.collocati ?? []).length <= 0).length}</h5>
                                <br/>
                                </>
                                :
                                <></>
                            )}
                        </div>
                    )
                })
                }

                </div>
            }


            {props.activePage==='clients' && props.fullInfoElement != null && props.fullInfoElement.nome != null  &&
                <div className='inner-info-div'>
                    <InfoAdminClient {...props.fullInfoElement} eventStartDate={props.eventStartDate} collaborators={props.collaborators} SetClienteTesserato={props.SetClienteTesserato} ClientNotesPlusPressed={props.ClientNotesPlusPressed} SetClienteNoteModificabili={props.SetClienteNoteModificabili} UpdateClientData={props.UpdateClientData} AddBundleToClient={props.AddBundleToClient} PlaceClientIntoRoom={props.PlaceClientIntoRoom} deletingClients={props.deletingClients} RoomStaysPressed={props.RoomStaysPressed} />
                </div>
            }

            {props.activePage==='clients' && (props.fullInfoElement == null || props.fullInfoElement.nome == null) &&
                <div className='inner-info-div'>
                    <h3>Cliccare il nome di un partecipante per mostrarne QUI tutte le info.</h3>
                </div>
            }


            {props.activePage==='management' && props.fullInfoElement != null && props.fullInfoElement.nome != null && props.fullInfoElement.numero == null && //fullInfoElement is a client
                <div className='inner-info-div'>
                    <InfoAdminClient {...props.fullInfoElement} eventStartDate={props.eventStartDate} collaborators={props.collaborators} SetClienteTesserato={props.SetClienteTesserato} SetClienteNoteModificabili={props.SetClienteNoteModificabili} ClientNotesPlusPressed={props.ClientNotesPlusPressed} UpdateClientData={props.UpdateClientData} AddBundleToClient={props.AddBundleToClient} PlaceClientIntoRoom={props.PlaceClientIntoRoom} deletingClients={props.deletingClients} RoomStaysPressed={props.RoomStaysPressed} />
                </div>
            }

            {props.activePage==='management' && props.fullInfoElement != null && props.fullInfoElement.nome == null && props.fullInfoElement.numero != null && //fullInfoElement is a room
                <div className='inner-info-div'>
                    <InfoAdminRoom {...props.fullInfoElement} collaborators={props.collaborators} ToggleMatrimoniale={props.ToggleMatrimoniale} ToggleNoteModificabili={props.ToggleNoteModificabili} TogglePiena={props.TogglePiena} RoomNotesPlusPressed={props.RoomNotesPlusPressed} RoomStaysPressed={props.RoomStaysPressed} DeleteClientStay={props.DeleteClientStay} />
                </div>
            }

            {props.activePage==='management' && (props.fullInfoElement == null || (props.fullInfoElement.nome == null && props.fullInfoElement.numero == null)) && //fullInfoElement is a room
                <div className='inner-info-div'>
                    <h3>Cliccare il nome di un partecipante o il numero di una camera per mostrarne QUI tutte le info.</h3>
                </div>
            }
            
        </div>
    )
}
