import React from 'react'
import { useHistory } from 'react-router-dom'

export default function Event({id, nome, luogo, dataInizio, descrizione, UpdateEventData, DeleteEventPressed, copyingEvent, CopyEventPressed }) {
    let history = useHistory();

    const HandleEventClicked = () => {
        if (copyingEvent){
            CopyEventPressed(id);
        } else {
            history.push({ pathname: '/admin-event', state: {id: id} });
        }
    }

    const HandleDeleteClicked = (e) => {
        e.stopPropagation();
        DeleteEventPressed(id);
    }

    const HandleModifyClicked = (e) => {
        e.stopPropagation();
        UpdateEventData(id);
    }

    return (
        <div className='admin-event-div' onClick={HandleEventClicked}>
            <div className='nome-evento'> <h1>{nome}</h1> </div>
            <div className='luogo-evento'> <h4>{luogo}</h4> </div>
            <div className='data-evento'> <h4>{dataInizio}</h4> </div>
            {descrizione != null && descrizione != '' &&
                <div className='descrizione-evento'> <p>{descrizione}</p> </div>
            }
            {(descrizione == null || descrizione == '') &&
                <div className='descrizione-evento'> <p>Nessuna descrizione disponibile</p> </div>
            }
            <div className='comandi-evento'>
                {/* <h4 className='clickable' onClick={HandleModifyClicked}>Copia</h4> */}
                <h4 className='clickable' onClick={HandleModifyClicked}>Modifica</h4>
                <h4 className='clickable' onClick={HandleDeleteClicked}>Elimina</h4>
            </div>
        </div>
    )
}
