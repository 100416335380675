import React, { useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai';
import { DeleteAbilitazione, DeleteDBAdmin, DeleteDBEvent, DeleteDBHotel, DeleteDBRooms, DeleteDBBundle, DeleteDBClients, DeleteDBReservations, DeleteDBTesseramenti } from '../../../../serverRequests/deleteRequests';
import { BiIdCard } from 'react-icons/bi';

export function ConfirmDeleteAdmin({ setCurrentOverlayPage, currentlyEditedAdmin, AdminDeleted, SetStatusLed }) {

    const [queryingServer, setQueryingServer] = useState(false);

    const CloseOverlay = () => {
        setCurrentOverlayPage('none');
    }

    const HandleDeleteClick = async () => {

        if (queryingServer) return; //Don't do anything if we are already querying the server

        setQueryingServer(true);
        SetStatusLed(true);
        
        let res = await DeleteDBAdmin(currentlyEditedAdmin.id);

        setQueryingServer(false);
        SetStatusLed(false);


        if (res) {
            AdminDeleted(); //Admin deleted from DB successfully, clear session and log out
        }
        //Otherwise do nothing (the error displaying is already handled by DeleteDBAdmin)

    }

    return (
        <div id='confirm-delete-collaborator-overlay' className='overlay-div confirm-delete-overlay'>
            <div className='close-overlay clickable' onClick={CloseOverlay} > <AiOutlineClose /> </div>

            <div className='inner-overlay-div'>

                <div className='confirm-delete-message'>
                    <h3>Conferma eliminazione di</h3>
                    <h2> {currentlyEditedAdmin.cognome} {currentlyEditedAdmin.nome}? </h2>
                    <h5>(Tutti i dati collegati all’admin saranno cancellati, verrà fatto automaticamente il log out e non sarà più possibile accedere con queste credenziali)</h5>
                </div>
                
                <button onClick={HandleDeleteClick} > Elimina </button>

            </div>
        </div>
    )
}

export function ConfirmDeleteTesseramento({ setCurrentOverlayPage, currentlyEditedTesseramento, DeleteTesseramento, SetStatusLed }) {

    const [queryingServer, setQueryingServer] = useState(false);

    const CloseOverlay = () => {
        setCurrentOverlayPage('none');
    }

    const HandleDeleteClick = async () => {

        if (queryingServer) return; //Don't do anything if we are already querying the server

        setQueryingServer(true);
        SetStatusLed(true);
        
        let res = await DeleteDBTesseramenti([currentlyEditedTesseramento.id]);

        setQueryingServer(false);
        SetStatusLed(false);


        if (res) {
            DeleteTesseramento(currentlyEditedTesseramento.id);
            setCurrentOverlayPage('none');
        }
        //Otherwise do nothing (the error displaying is already handled by DeleteDBTesseramento)

    }

    return (
        <div id='confirm-delete-collaborator-overlay' className='overlay-div confirm-delete-overlay'>
            <div className='close-overlay clickable' onClick={CloseOverlay} > <AiOutlineClose /> </div>

            <div className='inner-overlay-div'>

                <div className='confirm-delete-message'>
                    <h3>Conferma eliminazione di</h3>
                    <h2> {currentlyEditedTesseramento.cognome} {currentlyEditedTesseramento.nome}? </h2>
                    <h5>(Tutti i dati collegati al suo tesseramento saranno cancellati. NON saranno rimossi i dati relativi alla sua partecipazione agli eventi.)</h5>
                </div>
                
                <button onClick={HandleDeleteClick} > Elimina </button>

            </div>
        </div>
    )
}

export function ConfirmDeleteCollaborator({ setCurrentOverlayPage, currentlyEditedCollaborator, DeleteCollaborator, SetStatusLed }) {

    const [queryingServer, setQueryingServer] = useState(false);

    const CloseOverlay = () => {
        setCurrentOverlayPage('none');
    }

    const HandleDeleteClick = async () => {

        if (queryingServer) return; //Don't do anything if we are already querying the server

        setQueryingServer(true);
        SetStatusLed(true);

        let abilitazioneData = {
            idEvento: localStorage.getItem('currEventID'),
            idCollaboratore: currentlyEditedCollaborator.id
        }
        
        let res = await DeleteAbilitazione(abilitazioneData);

        setQueryingServer(false);
        SetStatusLed(false);


        if (res) {
            DeleteCollaborator(currentlyEditedCollaborator.id);
            setCurrentOverlayPage('none');
        }
        //Otherwise do nothing (the error displaying is already handled by DeleteDBCollaborator)

    }

    return (
        <div id='confirm-delete-collaborator-overlay' className='overlay-div confirm-delete-overlay'>
            <div className='close-overlay clickable' onClick={CloseOverlay} > <AiOutlineClose /> </div>

            <div className='inner-overlay-div'>

                <div className='confirm-delete-message'>
                    <h3>Conferma eliminazione di</h3>
                    <h2> {currentlyEditedCollaborator.cognome} {currentlyEditedCollaborator.nome}? </h2>
                </div>
                
                <button onClick={HandleDeleteClick} > Elimina </button>

            </div>
        </div>
    )
}

export function ConfirmDeleteEvent({ setCurrentOverlayPage, currentlyEditedEvent, DeleteEvent, SetStatusLed }) {

    const [queryingServer, setQueryingServer] = useState(false);

    const CloseOverlay = () => {
        setCurrentOverlayPage('none');
    }

    const HandleDeleteClick = async () => {

        if (queryingServer) return; //Don't do anything if we are already querying the server

        setQueryingServer(true);
        SetStatusLed(true);
        
        let res = await DeleteDBEvent(currentlyEditedEvent.id);

        setQueryingServer(false);
        SetStatusLed(false);


        if (res) {
            DeleteEvent(currentlyEditedEvent.id);
            setCurrentOverlayPage('none');
        }
        //Otherwise do nothing (the error displaying is already handled by DeleteDBEvent)

    }

    return (
        <div id='confirm-delete-event-overlay' className='overlay-div confirm-delete-overlay'>
            <div className='close-overlay clickable' onClick={CloseOverlay} > <AiOutlineClose /> </div>

            <div className='inner-overlay-div'>

                <div className='confirm-delete-message'>
                    <h3>Conferma eliminazione di</h3>
                    <h2> {currentlyEditedEvent.nome} - {currentlyEditedEvent.dataInizio}? </h2>
                    <h5>(Tutti i dati collegati all’evento saranno cancellati)</h5>
                </div>
                
                <button onClick={HandleDeleteClick} > Elimina </button>

            </div>
        </div>
    )
}

export function ConfirmDeleteHotel({ setCurrentOverlayPage, currentlyEditedHotel, DeleteHotel, SetStatusLed }) {

    const [queryingServer, setQueryingServer] = useState(false);

    const CloseOverlay = () => {
        setCurrentOverlayPage('none');
    }

    const HandleDeleteClick = async () => {

        if (queryingServer) return; //Don't do anything if we are already querying the server

        setQueryingServer(true);
        SetStatusLed(true);
        
        let res = await DeleteDBHotel(currentlyEditedHotel.id);

        setQueryingServer(false);
        SetStatusLed(false);


        if (res) {
            DeleteHotel(currentlyEditedHotel.id);
            setCurrentOverlayPage('none');
        }
        //Otherwise do nothing (the error displaying is already handled by DeleteDBHotel)

    }

    return (
        <div id='confirm-delete-hotel-overlay' className='overlay-div confirm-delete-overlay'>
            <div className='close-overlay clickable' onClick={CloseOverlay} > <AiOutlineClose /> </div>

            <div className='inner-overlay-div'>

                <div className='confirm-delete-message'>
                    <h3>Conferma eliminazione di</h3>
                    <h2> {currentlyEditedHotel.nome}? </h2>
                    <h5>(L’hotel, le sue camere e relativi dati saranno cancellati)</h5>
                </div>
                
                <button onClick={HandleDeleteClick} > Elimina </button>

            </div>
        </div>
    )
}

export function ConfirmDeleteAdminBundle({ setCurrentOverlayPage, currentlyEditedBundle, DeleteBundle, SetStatusLed }) {

    const [queryingServer, setQueryingServer] = useState(false);

    const CloseOverlay = () => {
        setCurrentOverlayPage('none');
    }

    const HandleDeleteClick = async () => {

        if (queryingServer) return; //Don't do anything if we are already querying the server

        setQueryingServer(true);
        SetStatusLed(true);
        
        let res = await DeleteDBBundle(currentlyEditedBundle.id);

        setQueryingServer(false);
        SetStatusLed(false);


        if (res) {
            DeleteBundle(currentlyEditedBundle.id);
            setCurrentOverlayPage('none');
        }
        //Otherwise do nothing (the error displaying is already handled by DeleteDBHotel)

    }

    return (
        <div id='confirm-delete-admin-bundle-overlay' className='overlay-div confirm-delete-overlay'>
            <div className='close-overlay clickable' onClick={CloseOverlay} > <AiOutlineClose /> </div>

            <div className='inner-overlay-div'>

                <div className='confirm-delete-message'>
                    <h3>Conferma eliminazione di</h3>
                    <h2> {currentlyEditedBundle.nome}? </h2>
                    <h5>(Tutte le prenotazioni e i dati collegati al pacchetto saranno cancellati)</h5>
                </div>
                
                <button onClick={HandleDeleteClick} > Elimina </button>

            </div>
        </div>
    )
}

export function ConfirmDeleteAdminRoom({ setCurrentOverlayPage, currentlyEditedRoom, DeleteRoom, SetStatusLed }) {

    const [queryingServer, setQueryingServer] = useState(false);

    const CloseOverlay = () => {
        setCurrentOverlayPage('none');
    }

    const HandleDeleteClick = async () => {

        if (queryingServer) return; //Don't do anything if we are already querying the server

        setQueryingServer(true);
        SetStatusLed(true);
        
        let res = await DeleteDBRooms([currentlyEditedRoom.id]);

        setQueryingServer(false);
        SetStatusLed(false);


        if (res) {
            DeleteRoom(currentlyEditedRoom.id);
            setCurrentOverlayPage('none');
        }
        //Otherwise do nothing (the error displaying is already handled by DeleteDBRooms)

    }

    return (
        <div id='confirm-delete-admin-bundle-overlay' className='overlay-div confirm-delete-overlay'>
            <div className='close-overlay clickable' onClick={CloseOverlay} > <AiOutlineClose /> </div>

            <div className='inner-overlay-div'>

                <div className='confirm-delete-message'>
                    <h3>Conferma eliminazione di</h3>
                    <h2> Camera {currentlyEditedRoom.codice}? </h2>
                    <h5>(Tutte le prenotazioni e i dati collegati alla camera saranno cancellati)</h5>
                </div>
                
                <button onClick={HandleDeleteClick} > Elimina </button>

            </div>
        </div>
    )
}

export function ConfirmDeleteMultipleRooms({ setCurrentOverlayPage, roomsToDeleteIDs, DeleteMultipleSelectedRooms, SetStatusLed }) {

    const [queryingServer, setQueryingServer] = useState(false);

    const CloseOverlay = () => {
        setCurrentOverlayPage('none');
    }

    const HandleDeleteClick = async () => {

        if (queryingServer) return; //Don't do anything if we are already querying the server

        setQueryingServer(true);
        SetStatusLed(true);
        
        let res = await DeleteDBRooms(roomsToDeleteIDs);

        setQueryingServer(false);
        SetStatusLed(false);


        if (res) {
            DeleteMultipleSelectedRooms();
            setCurrentOverlayPage('none');
        }
        //Otherwise do nothing (the error displaying is already handled by DeleteDBRooms)

    }

    return (
        <div id='confirm-delete-admin-bundle-overlay' className='overlay-div confirm-delete-overlay'>
            <div className='close-overlay clickable' onClick={CloseOverlay} > <AiOutlineClose /> </div>

            <div className='inner-overlay-div'>

                <div className='confirm-delete-message'>
                    <h3>Conferma eliminazione di</h3>
                    <h2> {roomsToDeleteIDs.length} camere? </h2>
                    <h5>(Tutte le prenotazioni e i dati collegati alle camere saranno cancellati)</h5>
                </div>
                
                <button onClick={HandleDeleteClick} > Elimina </button>

            </div>
        </div>
    )
}

export function ConfirmDeleteMultipleClients({ setCurrentOverlayPage, clientsToDeleteIDs, DeleteMultipleSelectedClients, SetStatusLed }) {

    const [queryingServer, setQueryingServer] = useState(false);

    const CloseOverlay = () => {
        setCurrentOverlayPage('none');
    }

    const HandleDeleteClick = async () => {

        if (queryingServer) return; //Don't do anything if we are already querying the server

        setQueryingServer(true);
        SetStatusLed(true);

        let clientIDs = String(clientsToDeleteIDs[0]);

        for (let i = 1; i < clientsToDeleteIDs.length; i++){
            clientIDs += ',' + clientsToDeleteIDs[i];
        }
        
        let res = await DeleteDBClients(clientIDs);

        setQueryingServer(false);
        SetStatusLed(false);


        if (res) {
            DeleteMultipleSelectedClients();
            setCurrentOverlayPage('none');
        }
        //Otherwise do nothing (the error displaying is already handled by DeleteDBClients)

    }

    return (
        <div id='confirm-delete-admin-bundle-overlay' className='overlay-div confirm-delete-overlay'>
            <div className='close-overlay clickable' onClick={CloseOverlay} > <AiOutlineClose /> </div>

            <div className='inner-overlay-div'>

                <div className='confirm-delete-message'>
                    <h3>Conferma eliminazione di</h3>
                    <h2> {clientsToDeleteIDs.length} partecipanti? </h2>
                    <h5>(Tutti i dati collegati ai partecipanti e le loro prenotazioni saranno cancellati)</h5>
                </div>
                
                <button onClick={HandleDeleteClick} > Elimina </button>

            </div>
        </div>
    )
}

export function ConfirmDeleteMultipleTesseramenti({ setCurrentOverlayPage, tesseramentiToDeleteIDs, DeleteMultipleSelectedTesseramenti, SetStatusLed }) {

    const [queryingServer, setQueryingServer] = useState(false);

    const CloseOverlay = () => {
        setCurrentOverlayPage('none');
    }

    const HandleDeleteClick = async () => {

        if (queryingServer) return; //Don't do anything if we are already querying the server

        setQueryingServer(true);
        SetStatusLed(true);

        let res = await DeleteDBTesseramenti(tesseramentiToDeleteIDs);

        setQueryingServer(false);
        SetStatusLed(false);


        if (res) {
            DeleteMultipleSelectedTesseramenti();
            setCurrentOverlayPage('none');
        }
        //Otherwise do nothing (the error displaying is already handled by DeleteDBTesseramenti)

    }

    return (
        <div id='confirm-delete-admin-bundle-overlay' className='overlay-div confirm-delete-overlay'>
            <div className='close-overlay clickable' onClick={CloseOverlay} > <AiOutlineClose /> </div>

            <div className='inner-overlay-div'>

                <div className='confirm-delete-message'>
                    <h3>Conferma eliminazione di</h3>
                    <h2> {tesseramentiToDeleteIDs.length} tesserati? </h2>
                    <h5>(Tutti i dati collegati ai loro tesseramenti saranno cancellati. NON saranno rimossi i dati relativi alla loro partecipazione agli eventi.)</h5>
                </div>
                
                <button onClick={HandleDeleteClick} > Elimina </button>

            </div>
        </div>
    )
}

export function ConfirmDeleteMultipleReservations({ setCurrentOverlayPage, reservationsToDeleteIDs, DeleteMultipleSelectedReservations, SetStatusLed }) {

    const [queryingServer, setQueryingServer] = useState(false);

    const CloseOverlay = () => {
        setCurrentOverlayPage('none');
    }

    const HandleDeleteClick = async () => {

        if (queryingServer) return; //Don't do anything if we are already querying the server

        setQueryingServer(true);
        SetStatusLed(true);

        let reservationIDs = String(reservationsToDeleteIDs[0]);

        for (let i = 1; i < reservationsToDeleteIDs.length; i++){
            reservationIDs += ',' + reservationsToDeleteIDs[i];
        }
        
        let res = await DeleteDBReservations(reservationIDs);

        setQueryingServer(false);
        SetStatusLed(false);


        if (res) {
            DeleteMultipleSelectedReservations();
            setCurrentOverlayPage('none');
        }
        //Otherwise do nothing (the error displaying is already handled by DeleteDBReservations)

    }

    return (
        <div id='confirm-delete-admin-bundle-overlay' className='overlay-div confirm-delete-overlay'>
            <div className='close-overlay clickable' onClick={CloseOverlay} > <AiOutlineClose /> </div>

            <div className='inner-overlay-div'>

                <div className='confirm-delete-message'>
                    <h3>Conferma eliminazione di</h3>
                    <h2> {reservationsToDeleteIDs.length} prenotazioni? </h2>
                    <h5>(Tutti i dati collegati alle prenotazioni saranno cancellati e al partecipante saranno tolti pacchetto ed eventuale pernottamento.)</h5>
                </div>
                
                <button onClick={HandleDeleteClick} > Elimina </button>

            </div>
        </div>
    )
}

export function ConfirmDeleteReservation({ setCurrentOverlayPage, currentlyEditedReservation, clienti, DeleteReservation, SetStatusLed }) {

    const [queryingServer, setQueryingServer] = useState(false);

    let nome, cognome, cf, tesserato;

    for (let i = 0; i < clienti.length; i++){
        if (clienti[i].id == currentlyEditedReservation.idPartecipante){
            nome = clienti[i].nome;
            cognome = clienti[i].cognome == null ? '' : clienti[i].cognome;
            cf = clienti[i].cf == null ? '' : clienti[i].cf;
            tesserato = clienti[i].tesserato;
            break;
        }
    }

    const CloseOverlay = () => {
        setCurrentOverlayPage('none');
    }

    const HandleDeleteClick = async () => {

        if (queryingServer) return; //Don't do anything if we are already querying the server

        setQueryingServer(true);
        SetStatusLed(true);

        let reservationIDs = String(currentlyEditedReservation.idPartecipante);
        
        let res = await DeleteDBReservations(reservationIDs);

        setQueryingServer(false);
        SetStatusLed(false);


        if (res) {
            DeleteReservation(currentlyEditedReservation.idPartecipante);
            setCurrentOverlayPage('none');
        }
        //Otherwise do nothing (the error displaying is already handled by DeleteDBReservations)

    }

    return (
        <div id='confirm-delete-admin-bundle-overlay' className='overlay-div confirm-delete-overlay'>
            <div className='close-overlay clickable' onClick={CloseOverlay} > <AiOutlineClose /> </div>

            <div className='inner-overlay-div'>

                <div className='confirm-delete-message'>
                    <h3>Conferma eliminazione della prenotazione di</h3>
                    <h2> {cognome} {nome} {tesserato ? <BiIdCard className='tesserato-icon' title='Partecipante Tesserato'/> : null}</h2>
                    <h5>(Tutti i dati collegati alla prenotazione saranno cancellati e al partecipante saranno tolti pacchetto ed eventuale pernottamento.)</h5>
                </div>
                
                <button onClick={HandleDeleteClick} > Elimina </button>

            </div>
        </div>
    )
}
