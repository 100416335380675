import axios from "axios";
import { SERVER_URL } from "./consts";

export default async function UpdateDBClientData(updatedClientData){

    return await axios.put(SERVER_URL + '/updateClientData', updatedClientData).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante la modifica dei dettagli del partecipante!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return true;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}

export async function UpdateDBClientBundle(updatedClientBundleData){

    return await axios.put(SERVER_URL + '/updateClientBundle', updatedClientBundleData).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante la modifica del pacchetto del partecipante!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return true;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}

export async function UpdatePernottamentoData(updatedPernottamentoData){

    return await axios.put(SERVER_URL + '/updatePernottamentoData', updatedPernottamentoData).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante la modifica della stanza del partecipante!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return true;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}

export async function UpdateDBCollaboratorData(updatedCollaboratorData){

    return await axios.put(SERVER_URL + '/updateCollaboratorData', updatedCollaboratorData).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante la modifica dei dettagli del collaboratore!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return true;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}

export async function UpdateDBAdminData(updatedAdminData){

    return await axios.put(SERVER_URL + '/updateAdminData', updatedAdminData).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante la modifica dei dettagli dell\'admin!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return true;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}

export async function UpdateDBTesseramentoData(updatedTesseramentoData){

    return await axios.put(SERVER_URL + '/updateTesseramentoData', updatedTesseramentoData).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante la modifica dei dettagli del tesserato!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return true;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}

export async function UpdateDBEventData(updatedEventData){

    return await axios.put(SERVER_URL + '/updateEventData', updatedEventData).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante la modifica dei dettagli dell\'evento!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return true;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}

export async function UpdateDBHotelData(updatedHotelData){

    return await axios.put(SERVER_URL + '/updateHotelData', updatedHotelData).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante la modifica dei dettagli dell\'hotel!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return true;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}

export async function UpdateDBRoomData(updatedRoomData){

    return await axios.put(SERVER_URL + '/updateRoomData', updatedRoomData).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante la modifica dei dettagli della camera!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return true;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}

export async function UpdateDBBundleData(updatedBundleData){

    return await axios.put(SERVER_URL + '/updateBundleData', updatedBundleData).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante la modifica dei dettagli del pacchetto!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return true;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}

export async function UpdateDBReservationData(updatedReservationData){

    return await axios.put(SERVER_URL + '/updateReservationData', updatedReservationData).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante la modifica dei dettagli della prenotazione!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return true;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}