// interface presenzaInterface [{
//     start: string;
//     end: string;
// }]

const ParsePartecipantePresenza = {
    // Formats presenza in a FF-LL format where FF is the first day of the interval and LL is the last day of the interval
    // Each number represents the difference between the selected day and the event's start date (basically a 0-starting index)
    // Multiple intervals will be joined with a '|'
    // e.g.: Event starts 01/01/2000 and two intervals have been selected:
    // 01/01/2000 - 03/01/2000 and 05/01/2000-12-01-2000
    // The output string will look like "00-02|04-11"
    DateArrayToFFLLString: (eventStartDateString, presenzaDateStringArray) => {
        // We may eventually handle validation better, but for the time being
        // If any date is earlier than the eventStartDate, retun null
        // If any end date is earlier than the corresponding start date, return null

        const eventStartDate = new Date(eventStartDateString);
        let formattedString = '';

        for (let i = 0; i < presenzaDateStringArray.length; i++) {
            const presenza = presenzaDateStringArray[i];
            const firstDate = new Date(presenza.start);
            const lastDate = new Date(presenza.end);

            if (lastDate < firstDate) return null;
            if (firstDate < eventStartDate) return null;
            if (lastDate < eventStartDate) return null; // Redundant, but descriptive

            // Currently not checking against event's end date because going over it doesn't cause logical errors

            const ff_num = (firstDate - eventStartDate) / (1000 * 60 * 60 * 24);
            const ff_str = ff_num > 9 ? ff_num.toString() : ('0' + ff_num);

            const ll_num = (lastDate - eventStartDate) / (1000 * 60 * 60 * 24);
            const ll_str = ll_num > 9 ? ll_num.toString() : ('0' + ll_num);

            const ffll = (i > 0 ? '|' : '') + ff_str + '-' + ll_str;
            formattedString += ffll;
        }

        return formattedString;
    },
    FFLLStringToDateArray: (eventStartDateString, presenzaFFLLString) => presenzaFFLLString.split('|').map(ffll => {
        const ff_str = ffll.split('-')[0];
        const ll_str = ffll.split('-')[1];

        const ff_date = new Date(eventStartDateString);
        const ll_date = new Date(eventStartDateString);

        ff_date.setDate(ff_date.getDate() + Number(ff_str));
        ll_date.setDate(ll_date.getDate() + Number(ll_str));

        return {
            start: ff_date.toISOString().split('T')[0].split('-').reverse().join('/').slice(0, 5),
            end: ll_date.toISOString().split('T')[0].split('-').reverse().join('/').slice(0, 5),
        }

    }),
    FFLLStringToYMDDateArray: (eventStartDateString, presenzaFFLLString) => presenzaFFLLString === null ? [] : presenzaFFLLString.split('|').map(ffll => {
        const ff_str = ffll.split('-')[0];
        const ll_str = ffll.split('-')[1];

        const ff_date = new Date(eventStartDateString);
        const ll_date = new Date(eventStartDateString);

        ff_date.setDate(ff_date.getDate() + Number(ff_str));
        ll_date.setDate(ll_date.getDate() + Number(ll_str));

        return {
            start: ff_date.toISOString().split('T')[0],
            end: ll_date.toISOString().split('T')[0],
        }

    })
}

export default ParsePartecipantePresenza;