import React, { useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { Masonry } from 'react-masonry-responsive';
import { AddBundleToDBClient } from '../../../../serverRequests/postRequests';
import { UpdateDBClientBundle } from '../../../../serverRequests/putRequests';
import Bundle from '../bundle'
import { BiIdCard } from 'react-icons/bi';

export function AddBundle({ setCurrentOverlayPage, currentlyEditedClient, SetStatusLed, pacchetti, SetClientBundle }) {

    const [isSubmitting, setSubmitting] = useState(false);

    const CloseOverlay = () => {
        setCurrentOverlayPage('none');
    }

    const HandleSelectBundle = async (pacchetto) => {

        setSubmitting(true);
        SetStatusLed(true);

        let res = false;

        if (currentlyEditedClient.idPacchetto == '' || currentlyEditedClient.idPacchetto == null){
            let reservationData = {
                idPartecipante: currentlyEditedClient.id,
                idPacchetto: pacchetto.id == '' ? null : pacchetto.id,
                acconto: 0.00,
                saldo: pacchetto.prezzo === '' ? null : pacchetto.prezzo,
                totale: pacchetto.prezzo === '' ? null : pacchetto.prezzo,
                stato: 'Da Pagare'
            }

            res = await AddBundleToDBClient(reservationData);

        } else {
            let updatedClientBundleData = {
                idPartecipante: currentlyEditedClient.id,
                idPacchetto: pacchetto.id == '' ? null : pacchetto.id,
            }

            res = await UpdateDBClientBundle(updatedClientBundleData);

        }
        

        setSubmitting(false);
        SetStatusLed(false);


        if (res) {
            SetClientBundle(pacchetto);
            CloseOverlay();
        }
        //Otherwise do nothing (the error displaying is already handled by UpdateDBClientData)

    }

    return (
        <div id='add-bundle-overlay' className='overlay-div'>
            <div className='close-overlay clickable' onClick={CloseOverlay} > <AiOutlineClose /> </div>

            <div className='inner-overlay-div'>
                <h2> {currentlyEditedClient.cognome} {currentlyEditedClient.nome} {currentlyEditedClient.tesserato ? <BiIdCard className='tesserato-icon' title='Partecipante Tesserato'/> : null}</h2>
                <div className='overlay-top-divider' />

                <div className='overlay-subtitle'>
                    <h3 className='overlay-subtitle-text'> Seleziona Pacchetto </h3>
                    <div className='overlay-subtitle-divider' />
                    <h5 className='clickable' onClick={CloseOverlay}> o Seleziona in un secondo momento </h5>
                </div>

                <div className='overlay-choice-list-top-fader' />
                <div className='overlay-choice-list'>

                    <div className='grid-content-div no-padding'>
                        {
                        pacchetti.map(pacchetto =>
                            <div key={pacchetto.id} className='bundle-choice'>
                                <Bundle {...pacchetto} />
                                {(currentlyEditedClient.idPacchetto == null || currentlyEditedClient.idPacchetto != pacchetto.id) &&
                                    <button onClick={() => HandleSelectBundle(pacchetto)} disabled={isSubmitting} > Seleziona Pacchetto </button>
                                }
                                {currentlyEditedClient.idPacchetto == pacchetto.id &&
                                    <div className='already-selected'><h4> Già selezionato </h4></div>
                                }
                            </div>
                        )
                        }
                    </div>

                    {/* <Masonry
                        items={
                            pacchetti.map(pacchetto => {
                                return(
                                    {key: pacchetto.id, node:
                                        <div className='bundle-choice'>
                                            <Bundle {...pacchetto} />
                                            {(currentlyEditedClient.idPacchetto == null || currentlyEditedClient.idPacchetto != pacchetto.id) &&
                                                <button onClick={() => HandleSelectBundle(pacchetto)} disabled={isSubmitting} > Seleziona Pacchetto </button>
                                            }
                                            {currentlyEditedClient.idPacchetto == pacchetto.id &&
                                                <div className='already-selected'><h4> Già selezionato </h4></div>
                                            }
                                        </div>
                                    }
                                    
                                )
                            })
                        }
                        minColumnWidth={240}
                        gap={16}
                    /> */}
                </div>
                


            </div>

        </div>
    )
    
}

export function SelectStayingClientBundle({stayingClient, pacchetti, SetStayingClientBundle}) {

    const [isSubmitting, setSubmitting] = useState(false);

    const CloseOverlay = () => {
        let mainOverlay = document.getElementById('main-overlay');
        mainOverlay.style.display = 'none';

        for(let i = 0; i < mainOverlay.children.length; i++){
            mainOverlay.children[i].style.display = 'none';
        }

    }

    const HandleSelectBundle = (id) => {

        setSubmitting(true);
        
        SetStayingClientBundle(id);
        
        setSubmitting(false);

    }

    return (
        <div className='overlay-content-div'>
            <h3 className='room-stays-subtitle'> {stayingClient.cognome} {stayingClient.nome} {stayingClient.tesserato ? <BiIdCard className='tesserato-icon' title='Partecipante Tesserato'/> : null}</h3>
            <div className='overlay-top-divider' />

            <div className='overlay-subtitle room-stays-overlay-subtitle'>
                <h3 className='room-stays-subtitle'> Seleziona Pacchetto </h3>
                <div className='overlay-subtitle-divider' />
            </div>

            <div className='overlay-choice-list-top-fader' />
            <div className='overlay-choice-list bundle-choice-list'>

                <div className='grid-content-div no-padding'>
                    {
                    pacchetti.map(pacchetto =>
                        <div key={pacchetto.id} className='bundle-choice'>
                            <Bundle {...pacchetto} />
                            {(stayingClient.idPacchetto == null || stayingClient.idPacchetto != pacchetto.id) &&
                                <button onClick={() => HandleSelectBundle(pacchetto.id)} disabled={isSubmitting} > Seleziona Pacchetto </button>
                            }
                            {stayingClient.idPacchetto == pacchetto.id &&
                                <div className='already-selected'><h4> Già selezionato </h4></div>
                            }
                        </div>
                    )
                    }
                </div>

                {/* <Masonry
                    items={
                        pacchetti.map(pacchetto => {
                            return(
                                {key: pacchetto.id, node:
                                    <div className='bundle-choice'>
                                        <Bundle {...pacchetto} />
                                        {(stayingClient.idPacchetto == null || stayingClient.idPacchetto != pacchetto.id) &&
                                            <button onClick={() => HandleSelectBundle(pacchetto.id)} disabled={isSubmitting} > Seleziona Pacchetto </button>
                                        }
                                        {stayingClient.idPacchetto == pacchetto.id &&
                                            <div className='already-selected'><h4> Già selezionato </h4></div>
                                        }
                                    </div>
                                }
                                
                            )
                        })
                    }
                    minColumnWidth={240}
                    gap={16}
                /> */}
            </div>
            


        </div>
    )
    
}

export function ChangeStayingClientBundle({clientToChangeBundleOf, pacchetti, ModifyClientBundle, SetStatusLed}) {

    const [isSubmitting, setSubmitting] = useState(false);

    const HandleSelectBundle = async (id) => {

        setSubmitting(true);
        SetStatusLed(true);
        
        let updatedClientBundleData = {
            idPartecipante: clientToChangeBundleOf.id,
            idPacchetto: id == '' ? null : id,
        }

        let res = await UpdateDBClientBundle(updatedClientBundleData);
        
        setSubmitting(false);
        SetStatusLed(false);

        if(res){
            ModifyClientBundle(id);
        }
        //Otherwise do nothing (the error displaying is already handled by UpdateDBClientData)

    }

    return (
        <div className='overlay-content-div'>
            <h3 className='room-stays-subtitle'> {clientToChangeBundleOf.cognome} {clientToChangeBundleOf.nome} {clientToChangeBundleOf.tesserato ? <BiIdCard className='tesserato-icon' title='Partecipante Tesserato'/> : null}</h3>
            <div className='overlay-top-divider' />

            <div className='overlay-subtitle room-stays-overlay-subtitle'>
                <h3 className='room-stays-subtitle'> Seleziona Pacchetto </h3>
                <div className='overlay-subtitle-divider' />
            </div>

            <div className='overlay-choice-list-top-fader' />
            <div className='overlay-choice-list bundle-choice-list'>

                <div className='grid-content-div no-padding'>
                    {
                    pacchetti.map(pacchetto =>
                        <div key={pacchetto.id} className='bundle-choice'>
                            <Bundle {...pacchetto} />
                            {(clientToChangeBundleOf.idPacchetto == null || clientToChangeBundleOf.idPacchetto != pacchetto.id) &&
                                <button onClick={() => HandleSelectBundle(pacchetto.id)} disabled={isSubmitting} > Seleziona Pacchetto </button>
                            }
                            {clientToChangeBundleOf.idPacchetto == pacchetto.id &&
                                <div className='already-selected'><h4> Già selezionato </h4></div>
                            }
                        </div>
                    )
                    }
                </div>

                {/* <Masonry
                    items={
                        pacchetti.map(pacchetto => {
                            return(
                                {key: pacchetto.id, node:
                                    <div className='bundle-choice'>
                                        <Bundle {...pacchetto} />
                                        {(clientToChangeBundleOf.idPacchetto == null || clientToChangeBundleOf.idPacchetto != pacchetto.id) &&
                                            <button onClick={() => HandleSelectBundle(pacchetto.id)} disabled={isSubmitting} > Seleziona Pacchetto </button>
                                        }
                                        {clientToChangeBundleOf.idPacchetto == pacchetto.id &&
                                            <div className='already-selected'><h4> Già selezionato </h4></div>
                                        }
                                    </div>
                                }
                                
                            )
                        })
                    }
                    minColumnWidth={240}
                    gap={16}
                /> */}
            </div>
            


        </div>
    )
    
}