import React from 'react'
import { useHistory } from 'react-router-dom'

export default function Event({id, nome, luogo, dataInizio, descrizione}) {
    let history = useHistory();

    const GoToEventPage = () => {
        history.push({ pathname: '/collaborator-event', state: {id: id} });
    }

    return (
        <div className='event-div' onClick={GoToEventPage}>
            <div className='nome-evento'> <h1>{nome}</h1> </div>
            <div className='luogo-evento'> <h4>{luogo}</h4> </div>
            <div className='data-evento'> <h4>{dataInizio}</h4> </div>
            {descrizione != null && descrizione != '' &&
                <div className='descrizione-evento'> <p>{descrizione}</p> </div>
            }
            {(descrizione == null || descrizione == '') &&
                <div className='descrizione-evento'> <p>Nessuna descrizione disponibile</p> </div>
            }
        </div>
    )
}
