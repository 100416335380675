import axios from 'axios'
import { SERVER_URL } from './consts';

export default async function SendResetLinkTo(username) {

    return await axios.get(SERVER_URL + '/sendResetLinkTo?username=' + username).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante l\'invio del link!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return res.data.mail;
    
    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRicaricare la pagina o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });
    
}

export async function VerifyResetPasswordID(id, setIsLoading, history) {

    return await axios.post(SERVER_URL + '/verifyResetPWID', {id: id}).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante la verifica dell\'identificativo per il reset della password!\n\nDettagli errore:\n' + res.data.err);
            history.replace('/login');
            return false;
        }

        setIsLoading(false);
        return true;
    
    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRicaricare la pagina o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        history.replace('/login');
        return false;
    });

}

export async function ResetCollaboratorPassword(resetData) {

    return await axios.patch(SERVER_URL + '/resetCollaboratorPW', resetData).then((res) => {

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            return false;
        }

        if(res.data.err != null){
            alert('Errore durante il reset della password!\n\nDettagli errore:\n' + res.data.err);
            return false;
        }

        return true;
    
    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRicaricare la pagina o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        return false;
    });

}