import { Field, Form, Formik } from 'formik';
import React, { useContext, useState } from 'react'
import { FaAngleLeft, FaSearch } from 'react-icons/fa'
import { useHistory } from 'react-router-dom';
import SessionDataContext from '../../../contexts/sessionData';
import { SessionLogOutRequest } from '../../../serverRequests/loginRequest';
import ClientsContext from '../../../contexts/clientsContext';
import { UploadExcelClienti } from '../../../serverRequests/postRequests';
import fileDialog from 'file-dialog';

export default function AdminEventCommands({ activePage, setActivePage, setCurrentOverlayPage, setMostraSoloTesserati, currEventData, DeleteMultipleRoomsPressed, CancelDeleteMultipleRooms, CancelDeleteMultipleClients, currentCollaboratorsOrder, SortCollaborators, findCollaborators, ExpandAllCollaborators, CollapseAllCollaborators,   currentHotelsOrder, SortHotels, CollapseAllHotels, ExpandAllHotels,    currentRoomsOrder, roomsGroupingKey, SortRooms, CollapseAllRooms, ExpandAllRooms, CollapseAllManagementRooms, ExpandAllManagementRooms, GroupRooms, setMostraClientiCollocati, findRooms,   currentBundlesOrder, SortBundles, CollapseAllBundles, ExpandAllBundles,    currentClientsOrder, clientsGroupingKey, CollapseAllClients, ExpandAllClients, findClients, GroupClients, SortClients, DeleteMultipleClientsPressed, currentReservationsOrder, reservationsGroupingKey, CollapseAllReservations, ExpandAllReservations, SortReservations, SettleReservationsPressed, DeleteMultipleReservationsPressed, findReservations, GroupReservations }) { //,    reportsToDownload, ToggleReportToDownload
    const {sessionData, setSessionData} = useContext(SessionDataContext);
    const {setClienti} = useContext(ClientsContext);
    const history = useHistory();

    
    const logOut = () => {
        setSessionData({role: 0, nome: '', cognome: '', id: ''});
        SessionLogOutRequest();
    }


    const ExpandCollapseCommandsDiv = () => {
        // let page = document.getElementById('container-collaborator-event');
        let commandsDiv = document.getElementById('admin-event-commands-div');

        if (commandsDiv.className == 'commands-div' ){
            commandsDiv.className = 'commands-div collapsed';
        }
        else{
            commandsDiv.className = 'commands-div';
        }

    }

    const handleOrderCollaboratorsByClick = (e) => {
        SortCollaborators(e.target.value);
    }

    const handleOrderHotelsByClick = (e) => {
        SortHotels(e.target.value);
    }

    const handleOrderRoomsByClick = (e) => {
        SortRooms(e.target.value);
    }

    const handleOrderBundlesByClick = (e) => {
        SortBundles(e.target.value);
    }

    const handleOrderClientsByClick = (e) => {
        SortClients(e.target.value);
    }

    const handleOrderReservationsByClick = (e) => {
        SortReservations(e.target.value);
    }
    

    const handleGroupRoomsByClick = (e) => {
        GroupRooms(e.target.value);
    }

    const handleGroupClientsByClick = (e) => {
        GroupClients(e.target.value);
    }

    const handleGroupReservationsByClick = (e) => {
        GroupReservations(e.target.value);
    }

    const handleFilterClientsClick = (e) => {
        setMostraClientiCollocati(e.target.checked);
    }

    const handleFilterTesseratiClick = (e) => {
        setMostraSoloTesserati(e.target.checked);
    }

    const HandleUploadClientsExcelClicked = () => {
        fileDialog({multiple: false, accept: '.xlsx'}, async (files) => {

            const res = await UploadExcelClienti(currEventData.id, files[0], setClienti);

            if (res) {
                alert('[✓] Excel caricato con successo!')
                // //Show success toast
                // Swal.fire({
                //     toast: true,
                //     position: "top",
                //     titleText: "Modulo Pre-Corso caricato!",
                //     icon: "success",
                //     target: 'body',
                //     backdrop: false,
                //     iconColor: '#59DECD',
                //     color: '#E7E7E7',
                //     background: '#555',
                //     timer: 1500,
                //     timerProgressBar: false,
                //     showConfirmButton: false,
                //     customClass: {
                //         title: 'reg-18-white'
                //     }
                // })
            } else {
                alert('[X] Errore nel caricamento dell\'Excel!\n\nRicaricare la pagina per aggiornare i dati e riprovare. Se il problema persiste contattare un amministratore.')
                // //Show fail toast
                // Swal.fire({
                //     toast: true,
                //     position: "top",
                //     titleText: "Errore nel caricamento del Modulo Pre-Corso!",
                //     text: "Ricaricare la pagina per aggiornare i dati e riprovare. Se il problema persiste contattare un amministratore.",
                //     icon: "error",
                //     target: 'body',
                //     backdrop: false,
                //     iconColor: '#DE6159',
                //     color: '#E7E7E7',
                //     background: '#555',
                //     timer: 10000,
                //     timerProgressBar: true,
                //     showConfirmButton: false,
                //     customClass: {
                //         title: 'reg-18-white',
                //         htmlContainer: 'reg-16-gray'
                //     }
                // })
            }

        });
    }

    // const handleIncludeReportsClick = (reportToToggle) => {
    //     //0: Generale; 1: Hotel; 2: Collaboratori; 3: Pacchetti; 4: Demografica
    //     ToggleReportToDownload(reportToToggle);
    // }

    return (
        <div id='admin-event-commands-div' className='commands-div'>
        <div id='toggle-commands-div' className='toggle-commands-div'> <FaAngleLeft onClick={ExpandCollapseCommandsDiv}/> </div>
            <div className='inner-commands-div'>
                <h4> {sessionData.cognome} {sessionData.nome}</h4>
                <a className='margin-bottom-10'> {(currEventData ?? {}).nome} </a>
                <div><a className='clickable' onClick={() => history.replace('/admin-home')}>Agli eventi</a> <a>•</a> <a className='clickable' onClick={logOut}>Log Out</a></div>
                <div className='divider' />

                <div className='commands-div-elements'>

                    <div className='commands-page-selection'>
                        <h3 className={activePage==='collaborators' ? 'page-name clickable' : 'page-name'} onClick={() => setActivePage('collaborators')}>Collaboratori</h3>
                        <h3 className={activePage==='hotels' ? 'page-name clickable' : 'page-name'} onClick={() => setActivePage('hotels')}>Hotel</h3>
                        <h3 className={activePage==='rooms' ? 'page-name clickable' : 'page-name'} onClick={() => setActivePage('rooms')}>Camere</h3>
                        <h3 className={activePage==='bundles' ? 'page-name clickable' : 'page-name'} onClick={() => setActivePage('bundles')}>Pacchetti</h3>
                        <h3 className={activePage==='clients' ? 'page-name clickable' : 'page-name'} onClick={() => setActivePage('clients')}>Partecipanti</h3>
                        <h3 className={activePage==='management' ? 'page-name clickable' : 'page-name'} onClick={() => setActivePage('management')}>Gestione</h3>
                        <h3 className={activePage==='reservations' ? 'page-name clickable' : 'page-name'} onClick={() => setActivePage('reservations')}>Prenotazioni</h3>
                        <h3 className={activePage==='reports' ? 'page-name clickable' : 'page-name'} onClick={() => setActivePage('reports')}>Report</h3>
                    </div>

                    <div className='divider' />

                    {activePage==='collaborators' &&
                    <div className='commands-div-elements'>
                        <div className='commands-add'>
                            <h3>AGGIUNGI</h3>
                            <div className='commands-add-buttons'>
                                <button onClick={() => setCurrentOverlayPage('createNewCollaborator')} > Nuovo Collaboratore </button>
                            </div>
                        </div>

                        <div className='commands-order' >
                            <h3>ORDINA PER</h3>
                            <Formik
                                initialValues={{
                                    orderCollaboratorsBy: currentCollaboratorsOrder,
                                }}
                            >
                                {() => (
                                    <Form>
                                        <label> <Field type='radio' name='orderCollaboratorsBy' value='nomeAZ' onClick={handleOrderCollaboratorsByClick} /> <a>{'Nome (A -> Z)'}</a> </label>
                                        <label> <Field type='radio' name='orderCollaboratorsBy' value='nomeZA' onClick={handleOrderCollaboratorsByClick} /> <a>{'Nome (Z -> A)'}</a> </label>
                                        <label> <Field type='radio' name='orderCollaboratorsBy' value='cognomeAZ' onClick={handleOrderCollaboratorsByClick} /> <a>{'Cognome (A -> Z)'}</a> </label>
                                        <label> <Field type='radio' name='orderCollaboratorsBy' value='cognomeZA' onClick={handleOrderCollaboratorsByClick} /> <a>{'Cognome (Z -> A)'}</a> </label>
                                    </Form>
                                )}
                            </Formik>
                        </div>

                        <div className='commands-find'>
                            <h3>RICERCA</h3>
                            <Formik
                                initialValues = {{findCollaboratorsBy: 'Cognome', findCollaborators: ''}}
                                onSubmit = {(data) => {
                                        findCollaborators(data.findCollaboratorsBy, data.findCollaborators);
                                    }
                                }
                            >
                                {({ values, handleChange, setFieldValue }) => (
                                    <Form>
                                        <div className='find-div'>
                                            <label>
                                                <a>Ricerca per: </a>
                                                <Field as='select' name='findCollaboratorsBy' >
                                                    <option value='Cognome'>Cognome</option>
                                                    <option value='Nome'>Nome</option>
                                                    <option value='Username'>Username</option>
                                                    <option value='Riferimento'>Riferimento</option>
                                                </Field>
                                            </label>
                                            <div className='find-input-div'>
                                                <Field id='find-by-name-input' className='text-input' type='text' name='findCollaborators' />
                                                <button type='submit'> <FaSearch className='search-icon'/> </button>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>

                        <div className='commands-toggle'>
                            <h3>COLLASSA / ESPANDI</h3>
                            <div className='toggle-clients-buttons-div'>
                                <button onClick={CollapseAllCollaborators} > Collassa tutti </button>
                                <button onClick={ExpandAllCollaborators} > Espandi tutti </button>
                            </div>
                        </div>

                    </div>
                    }


                    {activePage==='hotels' &&
                        <div className='commands-div-elements'>
                            <div className='commands-add'>
                                <h3>AGGIUNGI</h3>
                                <div className='commands-add-buttons'>
                                    <button onClick={() => setCurrentOverlayPage('createNewHotel')} > Nuovo Hotel </button>
                                </div>
                            </div>

                            <div className='commands-order' >
                                <h3>ORDINA PER</h3>
                                <Formik
                                    initialValues={{
                                        orderHotelsBy: currentHotelsOrder,
                                    }}
                                >
                                    {() => (
                                        <Form>
                                            <label> <Field type='radio' name='orderHotelsBy' value='nomeAZ' onClick={handleOrderHotelsByClick} /> <a>{'Nome (A -> Z)'}</a> </label>
                                            <label> <Field type='radio' name='orderHotelsBy' value='nomeZA' onClick={handleOrderHotelsByClick} /> <a>{'Nome (Z -> A)'}</a> </label>
                                            <label> <Field type='radio' name='orderHotelsBy' value='camereLibereCrescenti' onClick={handleOrderHotelsByClick} /> <a>{'Camere libere (crescenti)'}</a> </label>
                                            <label> <Field type='radio' name='orderHotelsBy' value='camereLibereDecrescenti' onClick={handleOrderHotelsByClick} /> <a>{'Camere libere (decrescenti)'}</a> </label>
                                        </Form>
                                    )}
                                </Formik>
                            </div>

                            <div className='commands-toggle'>
                                <h3>COLLASSA / ESPANDI</h3>
                                <div className='toggle-clients-buttons-div'>
                                    <button onClick={CollapseAllHotels} > Collassa tutti </button>
                                    <button onClick={ExpandAllHotels} > Espandi tutti </button>
                                </div>
                            </div>

                        </div>
                    }

                    {activePage==='rooms' &&
                        <div className='commands-div-elements'>
                            <div className='commands-add'>
                                <h3>AGGIUNGI</h3>
                                <div className='commands-add-buttons'>
                                    <button onClick={() => {setCurrentOverlayPage('createNewRooms'); CancelDeleteMultipleRooms();}} > Nuove Camere </button>
                                </div>
                            </div>

                            <div className='commands-add'>
                                <h3>ELIMINA</h3>
                                <div className='commands-add-buttons'>
                                    <button onClick={DeleteMultipleRoomsPressed}> Elimina Camere </button>
                                </div>
                            </div>

                            <div className='commands-order' >
                                <h3>ORDINA PER</h3>
                                <Formik
                                    initialValues={{
                                        orderRoomsBy: currentRoomsOrder,
                                    }}
                                >
                                    {() => (
                                        <Form>
                                            <label> <Field type='radio' name='orderRoomsBy' value='numeroCrescente' onClick={handleOrderRoomsByClick} /> <a>{'Numero di camera (crescente)'}</a> </label>
                                            <label> <Field type='radio' name='orderRoomsBy' value='numeroDecrescente' onClick={handleOrderRoomsByClick} /> <a>{'Numero di camera (decrescente)'}</a> </label>
                                            <label> <Field type='radio' name='orderRoomsBy' value='capienzaCrescente' onClick={handleOrderRoomsByClick} /> <a>{'Capienza massima (crescente)'}</a> </label>
                                            <label> <Field type='radio' name='orderRoomsBy' value='capienzaDecrescente' onClick={handleOrderRoomsByClick} /> <a>{'Capienza massima (decrescente)'}</a> </label>
                                            <label> <Field type='radio' name='orderRoomsBy' value='occupatiCrescente' onClick={handleOrderRoomsByClick} /> <a>{'Posti occupati (crescenti)'}</a> </label>
                                            <label> <Field type='radio' name='orderRoomsBy' value='occupatiDecrescente' onClick={handleOrderRoomsByClick} /> <a>{'Posti occupati (decrescenti)'}</a> </label>
                                            <label> <Field type='radio' name='orderRoomsBy' value='liberiCrescente' onClick={handleOrderRoomsByClick} /> <a>{'Posti liberi (crescenti)'}</a> </label>
                                            <label> <Field type='radio' name='orderRoomsBy' value='liberiDecrescente' onClick={handleOrderRoomsByClick} /> <a>{'Posti liberi (decrescenti)'}</a> </label>
                                        </Form>
                                    )}
                                </Formik>
                            </div>

                            <div className='commands-group'>
                                <h3>RAGGRUPPA PER</h3>
                                <Formik
                                    initialValues={{
                                        groupRoomsBy: roomsGroupingKey,
                                    }}
                                >
                                    {() => (
                                        <Form>
                                            <label> <Field type='radio' name='groupRoomsBy' value='pieneParzialiVuote' onClick={handleGroupRoomsByClick} /> <a>{'Piene / Parziali / Vuote'}</a> </label>
                                            <label> <Field type='radio' name='groupRoomsBy' value='vuoteParzialiPiene' onClick={handleGroupRoomsByClick} /> <a>{'Vuote / Parziali / Piene'}</a> </label>
                                            <label> <Field type='radio' name='groupRoomsBy' value='parzialiVuotePiene' onClick={handleGroupRoomsByClick} /> <a>{'Parziali / Vuote / Piene'}</a> </label>
                                            <label> <Field type='radio' name='groupRoomsBy' value='tipo' onClick={handleGroupRoomsByClick} /> <a>{'Tipo di camera'}</a> </label>
                                            <label> <Field type='radio' name='groupRoomsBy' value='nomeHotel' onClick={handleGroupRoomsByClick} /> <a>{'Hotel'}</a> </label>
                                            <label> <Field type='radio' name='groupRoomsBy' value='idCollaboratore' onClick={handleGroupRoomsByClick} /> <a>{'Collaboratore'}</a> </label>
                                            <label> <Field type='radio' name='groupRoomsBy' value='noGrouping' onClick={handleGroupRoomsByClick} /> <a>{'Non raggruppare'}</a> </label>
                                        </Form>
                                    )}
                                </Formik>
                            </div>

                            <div className='commands-find'>
                                <h3>RICERCA</h3>
                                <Formik
                                    initialValues = {{findRoomsBy: 'NomeCliente', findRooms: ''}}
                                    onSubmit = {(data) => {
                                            findRooms(data.findRoomsBy, data.findRooms);
                                        }
                                    }
                                >
                                    {({ values, handleChange, setFieldValue }) => (
                                        <Form>
                                            <div className='find-div'>
                                                <label>
                                                    <a>Ricerca per: </a>
                                                    <Field as='select' name='findClientsBy' >
                                                        <option value='NomeCliente'>Nome Partecipante</option>
                                                    </Field>
                                                </label>
                                                <div className='find-input-div'>
                                                    <Field id='find-by-name-input' className='text-input' type='text' name='findRooms' />
                                                    <button type='submit'> <FaSearch className='search-icon'/> </button>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>

                            <div className='commands-toggle'>
                                <h3>COLLASSA / ESPANDI</h3>
                                <div className='toggle-clients-buttons-div'>
                                    <button onClick={CollapseAllRooms} > Collassa tutte </button>
                                    <button onClick={ExpandAllRooms} > Espandi tutte </button>
                                </div>
                            </div>

                        </div>
                    }

                    {activePage==='bundles' &&
                        <div className='commands-div-elements'>
                            <div className='commands-add'>
                                <h3>AGGIUNGI</h3>
                                <div className='commands-add-buttons'>
                                    <button onClick={() => setCurrentOverlayPage('createNewBundle')} > Nuovo Pacchetto </button>
                                </div>
                            </div>

                            <div className='commands-order' >
                                <h3>ORDINA PER</h3>
                                <Formik
                                    initialValues={{
                                        orderBundlesBy: currentBundlesOrder,
                                    }}
                                >
                                    {() => (
                                        <Form>
                                            <label> <Field type='radio' name='orderBundlesBy' value='nomeAZ' onClick={handleOrderBundlesByClick} /> <a>{'Nome (A -> Z)'}</a> </label>
                                            <label> <Field type='radio' name='orderBundlesBy' value='nomeZA' onClick={handleOrderBundlesByClick} /> <a>{'Nome (Z -> A)'}</a> </label>
                                            <label> <Field type='radio' name='orderBundlesBy' value='durataCrescente' onClick={handleOrderBundlesByClick} /> <a>{'Durata (crescente)'}</a> </label>
                                            <label> <Field type='radio' name='orderBundlesBy' value='durataDecrescente' onClick={handleOrderBundlesByClick} /> <a>{'Durata (decrescente)'}</a> </label>
                                            <label> <Field type='radio' name='orderBundlesBy' value='dataInizioCrescente' onClick={handleOrderBundlesByClick} /> <a>{'Data Inizio (crescente)'}</a> </label>
                                            <label> <Field type='radio' name='orderBundlesBy' value='dataInizioDecrescente' onClick={handleOrderBundlesByClick} /> <a>{'Data Inizio (decrescente)'}</a> </label>
                                            <label> <Field type='radio' name='orderBundlesBy' value='dataFineCrescente' onClick={handleOrderBundlesByClick} /> <a>{'Data Fine (crescente)'}</a> </label>
                                            <label> <Field type='radio' name='orderBundlesBy' value='dataFineDecrescente' onClick={handleOrderBundlesByClick} /> <a>{'Data Fine (decrescente)'}</a> </label>
                                            <label> <Field type='radio' name='orderBundlesBy' value='prezzoCrescente' onClick={handleOrderBundlesByClick} /> <a>{'Prezzo (crescente)'}</a> </label>
                                            <label> <Field type='radio' name='orderBundlesBy' value='prezzoDecrescente' onClick={handleOrderBundlesByClick} /> <a>{'Prezzo (decrescente)'}</a> </label>
                                        </Form>
                                    )}
                                </Formik>
                            </div>

                            <div className='commands-toggle'>
                                <h3>COLLASSA / ESPANDI</h3>
                                <div className='toggle-clients-buttons-div'>
                                    <button onClick={CollapseAllBundles} > Collassa tutti </button>
                                    <button onClick={ExpandAllBundles} > Espandi tutti </button>
                                </div>
                            </div>
                        </div>
                    }

                    {activePage==='clients' &&
                        <div className='commands-div-elements'>
                            <div className='commands-add'>
                                <h3>AGGIUNGI</h3>
                                <div className='commands-add-buttons'>
                                    <button onClick={() => {setCurrentOverlayPage('createNewAdminClient'); CancelDeleteMultipleClients();}} > Nuovo Partecipante </button>
                                </div>
                                {/* <div className='commands-add-buttons'>
                                    <button onClick={HandleUploadClientsExcelClicked} > Carica Excel </button>
                                </div> */}
                            </div>

                            <div className='commands-add'>
                                <h3>ELIMINA</h3>
                                <div className='commands-add-buttons'>
                                    <button onClick={DeleteMultipleClientsPressed}> Elimina Partecipanti </button>
                                </div>
                            </div>

                            <div className='commands-order' >
                                <h3>ORDINA PER</h3>
                                <Formik
                                    initialValues={{
                                        orderClientsBy: currentClientsOrder,
                                    }}
                                >
                                    {() => (
                                        <Form>
                                            <label> <Field type='radio' name='orderClientsBy' value='nomeAZ' onClick={handleOrderClientsByClick} /> <a>{'Nome (A -> Z)'}</a> </label>
                                            <label> <Field type='radio' name='orderClientsBy' value='nomeZA' onClick={handleOrderClientsByClick} /> <a>{'Nome (Z -> A)'}</a> </label>
                                            <label> <Field type='radio' name='orderClientsBy' value='cognomeAZ' onClick={handleOrderClientsByClick} /> <a>{'Cognome (A -> Z)'}</a> </label>
                                            <label> <Field type='radio' name='orderClientsBy' value='cognomeZA' onClick={handleOrderClientsByClick} /> <a>{'Cognome (Z -> A)'}</a> </label>
                                        </Form>
                                    )}
                                </Formik>
                            </div>

                            <div className='commands-group'>
                                <h3>RAGGRUPPA PER</h3>
                                <Formik
                                    initialValues={{
                                        groupClientsBy: clientsGroupingKey,
                                    }}
                                >
                                    {() => (
                                        <Form>
                                            {/* <label> <Field type='radio' name='groupClientsBy' value='sesso' onClick={handleGroupClientsByClick} /> <a>{'Sesso'}</a> </label> NON UTILE VISTO CHE LA PAGINA è PER GESTIONE E NON DATI, PER QUELLO C'è LA PAGINA REPORT */}
                                            <label> <Field type='radio' name='groupClientsBy' value='tesseramento' onClick={handleGroupClientsByClick} /> <a>{'Tesserati'}</a> </label>
                                            <label> <Field type='radio' name='groupClientsBy' value='idCollaboratore' onClick={handleGroupClientsByClick} /> <a>{'Collaboratore'}</a> </label>
                                            <label> <Field type='radio' name='groupClientsBy' value='prenotazioni' onClick={handleGroupClientsByClick} /> <a>{'Prenotazioni'}</a> </label>
                                            <label> <Field type='radio' name='groupClientsBy' value='noGrouping' onClick={handleGroupClientsByClick} /> <a>{'Non raggruppare'}</a> </label>
                                        </Form>
                                    )}
                                </Formik>
                            </div>

                            <div className='commands-find'>
                                <h3>RICERCA</h3>
                                <Formik
                                    initialValues = {{findClientsBy: 'Cognome', findClients: ''}}
                                    onSubmit = {(data) => {
                                            findClients(data.findClientsBy, data.findClients);
                                        }
                                    }
                                >
                                    {({ values, handleChange, setFieldValue }) => (
                                        <Form>
                                            <div className='find-div'>
                                                <label>
                                                    <a>Ricerca per: </a>
                                                    <Field as='select' name='findClientsBy' >
                                                        <option value='Cognome'>Cognome</option>
                                                        <option value='Nome'>Nome</option>
                                                    </Field>
                                                </label>
                                                <div className='find-input-div'>
                                                    <Field id='find-by-name-input' className='text-input' type='text' name='findClients' />
                                                    <button type='submit'> <FaSearch className='search-icon'/> </button>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>

                            <div className='commands-toggle'>
                                <h3>COLLASSA / ESPANDI</h3>
                                <div className='toggle-clients-buttons-div'>
                                    {/* */}
                                    <button onClick={CollapseAllClients} > Collassa tutti </button>
                                    <button onClick={ExpandAllClients} > Espandi tutti </button>
                                </div>
                            </div>

                        </div>
                    }

                    {activePage==='management' &&
                        <div className='commands-div-elements'>
                            <div className='commands-add'>
                                <h3>AGGIUNGI PARTECIPANTE</h3>
                                <div className='commands-add-buttons'>
                                    <button onClick={() => setCurrentOverlayPage('createNewAdminClient')}> Nuovo Partecipante </button>
                                </div>
                                {/* <div className='commands-add-buttons'>
                                    <button onClick={HandleUploadClientsExcelClicked}> Carica Excel </button>
                                </div> */}
                            </div>
        
                            <div className='commands-order'>
                                <h3>ORDINA PARTECIPANTI PER</h3>
                                <Formik
                                    initialValues={{
                                        orderClientsBy: 'cognomeAZ',
                                    }}
                                >
                                    {() => (
                                        <Form>
                                            <label> <Field type='radio' name='orderClientsBy' value='nomeAZ' onClick={handleOrderClientsByClick} /> <a>{'Nome (A -> Z)'}</a> </label>
                                            <label> <Field type='radio' name='orderClientsBy' value='nomeZA'  onClick={handleOrderClientsByClick}  /> <a>{'Nome (Z -> A)'}</a> </label>
                                            <label> <Field type='radio' name='orderClientsBy' value='cognomeAZ'  onClick={handleOrderClientsByClick}  /> <a>{'Cognome (A -> Z)'}</a> </label>
                                            <label> <Field type='radio' name='orderClientsBy' value='cognomeZA' onClick={handleOrderClientsByClick} /> <a>{'Cognome (Z -> A)'}</a> </label>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
        
                            <div className='commands-group'>
                                <h3>RAGGRUPPA PARTECIPANTI PER</h3>
                                <Formik
                                    initialValues={{
                                        groupClientsBy: 'noGrouping',
                                    }}
                                >
                                    {() => (
                                        <Form>
                                            <label> <Field type='radio' name='groupClientsBy' value='senzaConCollocati' onClick={handleGroupClientsByClick} /> <a>{'Senza pacchetto / Con pacchetto / Collocati'}</a> </label>
                                            <label> <Field type='radio' name='groupClientsBy' value='collocatiConSenza'  onClick={handleGroupClientsByClick}  /> <a>{'Collocati / Con pacchetto / Senza pacchetto'}</a> </label>
                                            <label> <Field type='radio' name='groupClientsBy' value='noGrouping'  onClick={handleGroupClientsByClick}  /> <a>{'Non raggruppare'}</a> </label>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
        
                            <div className='commands-filter'>
                                <h3>FILTRA PARTECIPANTI</h3>
                                <label>
                                    <input type='checkbox' onClick={handleFilterClientsClick}/>
                                    <h4> Mostra partecipanti collocati </h4>
                                </label>
                                <label>
                                    <input type='checkbox' onClick={handleFilterTesseratiClick}/>
                                    <h4> Mostra SOLO partecipanti tesserati </h4>
                                </label>
                            </div>
        
                            <div className='commands-toggle'>
                                <h3>COLLASSA / ESPANDI PARTECIPANTI</h3>
                                <div className='toggle-clients-buttons-div'>
                                    <button onClick={CollapseAllClients}> Collassa tutti </button>
                                    <button onClick={ExpandAllClients}> Espandi tutti </button>
                                </div>
                            </div>
        
                            <div className='divider' />

                            <div className='commands-add'>
                                <h3>AGGIUNGI</h3>
                                <div className='commands-add-buttons'>
                                    <button onClick={() => {setCurrentOverlayPage('createNewRooms'); CancelDeleteMultipleRooms();}} > Nuove Camere </button>
                                </div>
                            </div>
        
                            <div className='commands-order'>
                                <h3>ORDINA CAMERE PER</h3>
                                <Formik
                                    initialValues={{
                                        orderRoomsBy: 'numeroCrescente',
                                    }}
                                >
                                    {() => (
                                        <Form>
                                            <label> <Field type='radio' name='orderRoomsBy' value='numeroCrescente' onClick={handleOrderRoomsByClick} /> <a>{'Numero di camera (crescente)'}</a> </label>
                                            <label> <Field type='radio' name='orderRoomsBy' value='numeroDecrescente' onClick={handleOrderRoomsByClick} /> <a>{'Numero di camera (decrescente)'}</a> </label>
                                            <label> <Field type='radio' name='orderRoomsBy' value='capienzaCrescente' onClick={handleOrderRoomsByClick} /> <a>{'Capienza massima (crescente)'}</a> </label>
                                            <label> <Field type='radio' name='orderRoomsBy' value='capienzaDecrescente' onClick={handleOrderRoomsByClick} /> <a>{'Capienza massima (decrescente)'}</a> </label>
                                            <label> <Field type='radio' name='orderRoomsBy' value='liberiCrescente' onClick={handleOrderRoomsByClick} /> <a>{'Posti liberi (crescente)'}</a> </label>
                                            <label> <Field type='radio' name='orderRoomsBy' value='liberiDecrescente' onClick={handleOrderRoomsByClick} /> <a>{'Posti liberi (decrescente)'}</a> </label>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
        
                            <div className='commands-group'>
                                <h3>RAGGRUPPA CAMERE PER</h3>
                                <Formik
                                    initialValues={{
                                        groupRoomsBy: 'noGrouping',
                                    }}
                                >
                                    {() => (
                                        <Form>
                                            <label> <Field type='radio' name='groupRoomsBy' value='pieneParzialiVuote' onClick={handleGroupRoomsByClick} /> <a>{'Piene / Parziali / Vuote'}</a> </label>
                                            <label> <Field type='radio' name='groupRoomsBy' value='vuoteParzialiPiene' onClick={handleGroupRoomsByClick} /> <a>{'Vuote / Parziali /Piene'}</a> </label>
                                            <label> <Field type='radio' name='groupRoomsBy' value='tipo' onClick={handleGroupRoomsByClick} /> <a>{'Tipo di camera'}</a> </label>
                                            <label> <Field type='radio' name='groupRoomsBy' value='nomeHotel' onClick={handleGroupRoomsByClick} /> <a>{'Hotel'}</a> </label>
                                            <label> <Field type='radio' name='groupRoomsBy' value='idCollaboratore' onClick={handleGroupRoomsByClick} /> <a>{'Collaboratore'}</a> </label>
                                            <label> <Field type='radio' name='groupRoomsBy' value='noGrouping' onClick={handleGroupRoomsByClick} /> <a>{'Non raggruppare'}</a> </label>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
        
                            <div className='commands-toggle'>
                                <h3>COLLASSA / ESPANDI CAMERE</h3>
                                <div className='toggle-clients-buttons-div'>
                                    <button onClick={CollapseAllManagementRooms}> Collassa tutte </button>
                                    <button onClick={ExpandAllManagementRooms}> Espandi tutte </button>
                                </div>
                            </div>
        
                        </div>
                    }

                    {activePage==='reservations' &&
                        <div className='commands-div-elements'>
                            <div className='commands-add'>
                                <h3>SALDA</h3>
                                <div className='commands-add-buttons'>
                                    <button onClick={SettleReservationsPressed} > Salda Pagamenti </button>
                                </div>
                            </div>

                            <div className='commands-add'>
                                <h3>ELIMINA</h3>
                                <div className='commands-add-buttons'>
                                    <button onClick={DeleteMultipleReservationsPressed} > Elimina Prenotazioni </button>
                                </div>
                            </div>

                            <div className='commands-order' >
                                <h3>ORDINA PER</h3>
                                <Formik
                                    initialValues={{
                                        orderReservationsBy: currentReservationsOrder,
                                    }}
                                >
                                    {() => (
                                        <Form>
                                            <label> <Field type='radio' name='orderReservationsBy' value='nomeAZ' onClick={handleOrderReservationsByClick} /> <a>{'Nome partecipante (A -> Z)'}</a> </label>
                                            <label> <Field type='radio' name='orderReservationsBy' value='nomeZA' onClick={handleOrderReservationsByClick} /> <a>{'Nome partecipante (Z -> A)'}</a> </label>
                                            <label> <Field type='radio' name='orderReservationsBy' value='cognomeAZ' onClick={handleOrderReservationsByClick} /> <a>{'Cognome partecipante (A -> Z)'}</a> </label>
                                            <label> <Field type='radio' name='orderReservationsBy' value='cognomeZA' onClick={handleOrderReservationsByClick} /> <a>{'Cognome partecipante (Z -> A)'}</a> </label>
                                            <label> <Field type='radio' name='orderReservationsBy' value='prezzoCrescente' onClick={handleOrderReservationsByClick} /> <a>{'Prezzo totale (crescente)'}</a> </label>
                                            <label> <Field type='radio' name='orderReservationsBy' value='prezzoDecrescente' onClick={handleOrderReservationsByClick} /> <a>{'Prezzo totale (decrescente)'}</a> </label>
                                        </Form>
                                    )}
                                </Formik>
                            </div>

                            <div className='commands-group'>
                                <h3>RAGGRUPPA PER</h3>
                                <Formik
                                    initialValues={{
                                        groupReservationsBy: reservationsGroupingKey,
                                    }}
                                >
                                    {() => (
                                        <Form>
                                            {/* <label> <Field type='radio' name='groupReservationsBy' value='sesso' onClick={handleGroupReservationsByClick} /> <a>{'Sesso'}</a> </label> */}
                                            <label> <Field type='radio' name='groupReservationsBy' value='tesseramento' onClick={handleGroupReservationsByClick} /> <a>{'Tesserati'}</a> </label>
                                            <label> <Field type='radio' name='groupReservationsBy' value='idCollaboratore' onClick={handleGroupReservationsByClick} /> <a>{'Collaboratore'}</a> </label>
                                            <label> <Field type='radio' name='groupReservationsBy' value='nomeHotel' onClick={handleGroupReservationsByClick} /> <a>{'Hotel'}</a> </label>
                                            {/* <label> <Field type='radio' name='groupReservationsBy' value='tipoCamera' onClick={handleGroupReservationsByClick} /> <a>{'Tipo camera'}</a> </label> */}
                                            {/* <label> <Field type='radio' name='groupReservationsBy' value='camera' onClick={handleGroupReservationsByClick} /> <a>{'Camera'}</a> </label> */}
                                            <label> <Field type='radio' name='groupReservationsBy' value='idPacchetto' onClick={handleGroupReservationsByClick} /> <a>{'Pacchetto'}</a> </label>
                                            <label> <Field type='radio' name='groupReservationsBy' value='statoPagamento' onClick={handleGroupReservationsByClick} /> <a>{'Stato Pagamento'}</a> </label>
                                            <label> <Field type='radio' name='groupReservationsBy' value='destinatarioAcconto' onClick={handleGroupReservationsByClick} /> <a>{'Destinatario Acconto'}</a> </label>
                                            <label> <Field type='radio' name='groupReservationsBy' value='destinatarioSaldo' onClick={handleGroupReservationsByClick} /> <a>{'Destinatario Saldo'}</a> </label>
                                            <label> <Field type='radio' name='groupReservationsBy' value='noGrouping' onClick={handleGroupReservationsByClick} /> <a>{'Non raggruppare'}</a> </label>
                                        </Form>
                                    )}
                                </Formik>
                            </div>

                            <div className='commands-find'>
                                <h3>RICERCA</h3>
                                <Formik
                                    initialValues = {{findReservationsBy: 'Cognome', findReservations: ''}}
                                    onSubmit = {(data) => {
                                            findReservations(data.findReservationsBy, data.findReservations);
                                        }
                                    }
                                >
                                    {({ values, handleChange, setFieldValue }) => (
                                        <Form>
                                            <div className='find-div'>
                                                <label>
                                                    <a>Ricerca per: </a>
                                                    <Field as='select' name='findReservationsBy' >
                                                        <option value='Cognome'>Cognome</option>
                                                        <option value='Nome'>Nome</option>
                                                    </Field>
                                                </label>
                                                <div className='find-input-div'>
                                                    <Field id='find-by-name-input' className='text-input' type='text' name='findReservations' />
                                                    <button type='submit'> <FaSearch className='search-icon'/> </button>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>

                            <div className='commands-toggle'>
                                <h3>COLLASSA / ESPANDI</h3>
                                <div className='toggle-clients-buttons-div'>
                                    <button onClick={CollapseAllReservations} > Collassa tutte </button>
                                    <button onClick={ExpandAllReservations} > Espandi tutte </button>
                                </div>
                            </div>

                        </div>
                    }

                    {/* {activePage==='reports' &&
                        <div className='commands-div-elements'>
                            <div className='commands-filter'>
                                <h3>INCLUDI</h3>
                                <label>
                                    <input type='checkbox' checked={reportsToDownload[0]} onClick={(e) => handleIncludeReportsClick(0)}/>
                                    <h4> Report generale </h4>
                                </label>
                                <label>
                                    <input type='checkbox' checked={reportsToDownload[1]} onClick={(e) => handleIncludeReportsClick(1)}/>
                                    <h4> Report hotel </h4>
                                </label>
                                <label>
                                    <input type='checkbox' checked={reportsToDownload[2]} onClick={(e) => handleIncludeReportsClick(2)}/>
                                    <h4> Report collaboratori </h4>
                                </label>
                                <label>
                                    <input type='checkbox' checked={reportsToDownload[3]} onClick={(e) => handleIncludeReportsClick(3)}/>
                                    <h4> Report pacchetti </h4>
                                </label>
                                <label>
                                    <input type='checkbox' checked={reportsToDownload[4]} onClick={(e) => handleIncludeReportsClick(4)}/>
                                    <h4> Report demografica </h4>
                                </label>
                            </div>
                        </div>
                    } */}

                    {activePage!=='reports' &&
                        <div className='divider' />
                    }

                    {currEventData != null &&
                        <div className='side-event-info'>
                            <h3> {currEventData.nome}</h3>
                            <h4> {currEventData.dataInizio} - {currEventData.dataFine} </h4>
                            <h4> {currEventData.luogo} </h4>
                            <h4> {currEventData.descrizione} </h4>
                        </div>
                    }

                </div>
            </div>
        </div>
    )
}
