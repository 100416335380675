import React from 'react'
import { FaAngleRight } from 'react-icons/fa'
import { InfoClient } from './client'
import { InfoRoom } from './room'

export default function CollaboratorEventInfo( props ) {

    const ExpandCollapseInfoDiv = () => {
        // let page = document.getElementById('container-collaborator-event');
        let infoDiv = document.getElementById('collaborator-event-info-div');

        if (infoDiv.className == 'info-div' ){
            infoDiv.className = 'info-div collapsed';
        }
        else{
            infoDiv.className = 'info-div';
        }

    }

    return (
        <div id='collaborator-event-info-div' className='info-div'>
            <div id='toggle-info-div' className='toggle-info-div'> <FaAngleRight onClick={ExpandCollapseInfoDiv}/> </div>
            <div className='inner-info-div'>

                {props.nome == null && props.numero == null && //no fullInfoElement
                    <h3>Cliccare il nome di un partecipante o il numero di una camera per mostrarne QUI tutte le info.</h3>
                }

                {props.nome == null && props.numero != null && //fullInfoElement is a room
                    <InfoRoom {...props} />
                }

                {props.nome != null && props.numero == null && //fullInfoElement is a client
                    <InfoClient {...props} />
                }

            </div>
        </div>
    )
}
