import axios from 'axios';
import { SERVER_URL } from './consts';

export default function LoginRequest(data, setLoginError, setSessionData, {setSubmitting}) {

    setSubmitting(true);

    axios.post(SERVER_URL + '/login', data).then((res) => {
        
        // console.log(res);

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            setSubmitting(false);
            return true;
        }

        if(res.data.err != null){
            setLoginError(res.data.err);
            setSubmitting(false);
            return true;
        }

        setLoginError('');
        setSubmitting(false);

        setSessionData({
            id: res.data.id,
            nome: res.data.nome,
            cognome: res.data.cognome,
            role: res.data.role
        })

        return true; //Will automatically do setSubmitting(false);
        }

    ).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        setSubmitting(false);
        return true; //Will automatically do setSubmitting(false);
    });

}

export function SessionLoginRequest(setSessionData, setIsConncecting) {

    axios.get(SERVER_URL + '/sessionLogin').then((res) => {

        // console.log(res)

        if(res.status !== 200){
            alert('Errore server inaspettato!\n\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + 'Codice errore: ' + res.status + ', ' + res.data);
            setIsConncecting(false);
            return false;
        }

        if(res.data.cookieGood == null){
            setIsConncecting(false);
            return false;
        }

        if(res.data.cookieGood == false){
            setIsConncecting(false);
            return false;
        }

        setSessionData({
            id: res.data.id,
            nome: res.data.nome,
            cognome: res.data.cognome,
            role: res.data.role
        })

        setIsConncecting(false);
        return true;

    }).catch((err) => {
        //console.log(err);
        alert('Errore server inaspettato!\nRiprovare o contattare l\'amministratore del sito se il problema persiste.\n\n' + (err.response == null ? err : ('Codice errore: ' + err.response.status + ' - ' + err.response.data)));
        setIsConncecting(false);
        return false;
    });

}

export function SessionLogOutRequest(){

    axios.get(SERVER_URL + '/sessionLogout').then((res) => {

        return true;

    }).catch((err) => {
        return false;
    });

}