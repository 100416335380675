import React from 'react'
import { FiMinus, FiPlus } from 'react-icons/fi';
import { ImPlus } from 'react-icons/im';

export default function AdminBundle({ id, nome, prezzo, durata, dataInizio, dataFine, note, UpdateBundleData, DeleteBundlePressed }) {

    const ToggleAdminBundle = (e) => {
        let sections = e.currentTarget.parentElement.parentElement.children;
        let toggleIcons = e.currentTarget.children;

        for (let i = 0; i < sections.length; i++){
            if (sections[i].id == 'admin-bundle-data'){
                if (sections[i].className == 'admin-bundle-data'){
                    sections[i].className = 'admin-bundle-data admin-bundle-data-collapsed';
                    for (let i = 0; i < toggleIcons.length; i++){
                        if (toggleIcons[i].className.baseVal == 'plus'){
                            toggleIcons[i].style.display = 'block';
                        } else {
                            toggleIcons[i].style.display = 'none';
                        }
                    }
                } else {
                    sections[i].className = 'admin-bundle-data';
                    for (let i = 0; i < toggleIcons.length; i++){
                        if (toggleIcons[i].className.baseVal == 'minus'){
                            toggleIcons[i].style.display = 'block';
                        } else {
                            toggleIcons[i].style.display = 'none';
                        }
                    }
                }
            }
        }
    }

    return (
        <div className='admin-bundle-div'>
            <div className='toggle-admin-bundle-div'> <button className='toggle-button' onClick={e => ToggleAdminBundle(e)}> <FiPlus className='plus' /> <FiMinus className='minus' /> </button> </div>
            <div class='admin-bundle-name'>
                <h3>{nome}</h3>
                <div class='top-divider' />
            </div>

            <div id='admin-bundle-data' class='admin-bundle-data admin-bundle-data-collapsed'>

            {prezzo != null &&
                <div className='data-field admin-bundle-price-field'>
                    <h4 className='data-field-key'> Prezzo: </h4>
                    <div className='data-field-value'>
                        <h4> {prezzo}€ </h4>
                    </div>
                </div>
            }

            {durata != null &&
                <div className='data-field admin-bundle-duration-field'>
                    <h4 className='data-field-key'> Durata: </h4>
                    <div className='data-field-value'>
                        <h4> {durata} giorni</h4>
                    </div>
                </div>
            }

            {dataInizio != null &&
                <div className='data-field'>
                    <h4 className='data-field-key'> Data Inizio: </h4>
                    <div className='data-field-value'>
                        <h4> {dataInizio} </h4>
                    </div>
                </div>
            }

            {dataFine != null &&
                <div className='data-field'>
                    <h4 className='data-field-key'> Data Fine: </h4>
                    <div className='data-field-value'>
                        <h4> {dataFine} </h4>
                    </div>
                </div>
            }

            {(note == null || note == '') &&
                <div className='data-field'>
                    <h4 className='data-field-key'> Note: </h4>
                    <div className='data-field-value'>
                        <h4> N/P </h4>
                        {/* <div className='data-field-divider' />
                        <div className='data-field-command' onClick={() => BundleNotesPlusPressed(id)} > <ImPlus /> </div> */}
                    </div>
                </div>
            }
            {note &&
                <div className='data-field'>
                    <h4 className='data-field-key'> Note: </h4>
                    <div className='data-field-value'>
                        <h4> {note} </h4>
                        {/* <div className='data-field-divider' />
                        <div className='data-field-command' onClick={() => BundleNotesPlusPressed(id)} > <ImPlus /> </div> */}
                    </div>
                </div>
            }

            <div className='bottom-divider'></div>

            <div className='admin-bundle-commands'>
                <a className='clickable' onClick={() => UpdateBundleData(id)} >Modifica</a>
                <a className='clickable' onClick={() => DeleteBundlePressed(id)} >Elimina</a>
            </div>

            </div>

        </div>
    )
}
